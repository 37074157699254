import React, { useState, } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import UserServiceClient from '../../../services/user-service.client';
import { Driver } from '../../../types/event/Driver';
import { NullifyPIIRequest } from '../../../types/User';
import DriverActionAreYouSureModal from './DriverActionAreYouSureModal';
import { useAuth } from '../../../AuthContext';

export interface DriverNullifyPIIActionModalProps {
    driver: Driver;
    game: string;
    show: boolean;
    onCancel: () => void;
    onPIINullified: () => void;
}

const DriverNullifyPIIActionModal = (props: DriverNullifyPIIActionModalProps) => {

    const auth = useAuth();
    const userService = new UserServiceClient();

    const [notes, setNotes] = useState<string>("");
    const onNotesChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
    }

    const [showAreYouSureModal, setShowAreYouSureModal] = useState<boolean>(false);

    const showAreYouSureModalAndHidePIIModal = () => {
        setShowAreYouSureModal(true);
        props?.onCancel();
    }

    const nullifyAccount = (
        requestDriver: Driver | undefined,
        requestUsername: string | undefined,
        requestNotes: string | undefined,
        requestGame: string | undefined,
        onSuccess: () => void | undefined,
    ) => {

        if(requestDriver == void(0) || requestUsername == void(0) || requestNotes == void(0) || requestGame == void(0) || onSuccess == void(0)) return;

        const nullifyPIIRequest: NullifyPIIRequest = {
            userId: requestDriver?.id,
            administrator: requestUsername,
            notes: requestNotes
        };

        userService.nullifyAccount(nullifyPIIRequest, requestGame)
            .then(Driver.prototype.refresh.bind(requestDriver, requestGame))
            .catch(console.error)
            .finally(() => (setShowAreYouSureModal(false), onSuccess()));
    }

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .strike-form-text-grey {
                        color: grey !important;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Erase PII data for {props.driver?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        This action will reset the account.
                                        All personal informtion will be erased and the account will be anonymised.
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        The user will not be able to access the account anymore,
                                        and must register again to use Race Control services.
                                        If the player is currently banned then their SteamID will be retained
                                        for the duration of the ban for the purposes maintaining the security
                                        and safety of the Race Control platform and its users.
                                        Permanently banned users' SteamID will be retained indefinitely.
                                        <br></br>
                                        Banned users will not be able to register again until the ban is lifted.
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        The following data will be erased:<br></br>
                                        - Usernames<br></br>
                                        - Email addresses<br></br>
                                        - SteamIDs<br></br>
                                        - Steam avatar<br></br>
                                        - Nationality<br></br>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        All event data, race results and logs will be anonymised and no longer relate to this user.
                                        Players viewing their race results with this user will see an anonymised player and avatar.
                                    </Col>
                                </Row>
                                <br></br>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label>Notes</Form.Label>
                                        <Form.Control as="textarea" rows={3} onChange={onNotesChanged} />
                                        <Form.Text className="strike-form-text-grey">
                                            Notes on the erasure can be placed here (they will not be sent to the user)
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{width: "100%"}}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{display: "flex", justifyContent: "flex-end"}} >
                            <Button variant="danger" onClick={showAreYouSureModalAndHidePIIModal.bind(this)}>Erase</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            <DriverActionAreYouSureModal
                show={showAreYouSureModal}
                onCancel={setShowAreYouSureModal.bind(this, false)}
                onConfirm={() => nullifyAccount(props?.driver, auth.self?.name, notes, props?.game, props?.onPIINullified)}
                customTitle="Are you sure you want to permanently erase this account?"
                customText={`This action cannot be undone. Press confirm to erase ${(`${props?.driver?.username}s'`) ?? "their"} account.`}
            />
        </>
    );
}

export default DriverNullifyPIIActionModal;