import '../css/dark-mode.css';

import { useState, useEffect, useRef,  useCallback } from 'react';
import TagsEdit from './TagsEdit';
import InstalledContentEdit from './InstalledContentEdit';
import ItemEdit from './ItemEdit';
import SubItemEdit from './SubItemEdit';

import ContentManagerClient from '../services/content-manager.client';
import { InstalledContent, Tag } from '../types/InstalledContent';
import { HandleChangeFunction } from './HandledChangeFunction';
import { ContentManagerStatus } from '../types/ContentManagerHealthResponse';
import { Col, Row, Tab, Nav, Modal, Button } from 'react-bootstrap';
import { SubItem } from '../types/event/Content';
import Search from './Search';
import Accordion from 'react-bootstrap/Accordion';
import Table from './Table';

const ContentForm = (): JSX.Element => {
  const [contents, setContents] = useState<InstalledContent[]>([]);
  const [tracks, setTracks] = useState<InstalledContent[]>([]);
  const [cars, setCars] = useState<InstalledContent[]>([]);
  const [selectedContent, setSelectedContent] = useState<InstalledContent | null>(null);

  const contentManager = useRef(new ContentManagerClient());

  const handleTagChange = (tagIndex: number, field: keyof Tag, value: string): void => {
    const newTags = [...selectedContent?.tags ?? []];
    newTags[tagIndex][field] = value;
    setSelectedContent({
      ...selectedContent,
      tags: newTags,
    } as InstalledContent);
  };

  const handleTagDelete = (tagIndex: number): void => {
    const newTags = [...selectedContent?.tags ?? []];
    newTags.splice(tagIndex, 1);
    setSelectedContent({
      ...selectedContent,
      tags: newTags,
    } as InstalledContent);
  };

  const handleTagAdd = (): void => {
    const newTag = {name: "", value: ""}; // Modify this as per your Tag structure
    const newTags = [...selectedContent?.tags ?? [], newTag];
    setSelectedContent({
      ...selectedContent,
      tags: newTags,
    } as InstalledContent);
  };

  const handleSubItemChange = (tagIndex: number, field: keyof SubItem, value: string): void => {
    const newSubItems = [...selectedContent?.content.subItems ?? []];
    newSubItems[tagIndex][field] = value;
    setSelectedContent({
      ...selectedContent,
      content: {
        ...selectedContent?.content,
        subItems: newSubItems,
      },
    } as InstalledContent);
  };

  const handleContentChange: HandleChangeFunction = (field, value) => {
    setSelectedContent({
      ...selectedContent,
      [field]: value,
    } as InstalledContent);
  };

  const handleItemChange: HandleChangeFunction = (field, value) => {
    setSelectedContent({
      ...selectedContent,
      content: {
        ...selectedContent?.content,
        [field]: value,
      },
    } as InstalledContent);
  };

  const handleSubmit = async (): Promise<void> => {
    if (selectedContent) {
      await contentManager.current.update(selectedContent);
      setSelectedContent(null);
    }
  };

  const handleDelete = async (content: InstalledContent): Promise<void> => {
    await contentManager.current.delete(content);
    setContents(contents.filter(c => c.Id !== content.Id));
  };

  const reinstall = async (content: InstalledContent): Promise<void> => {
    await contentManager.current.install(content.installType, content.workshopId, content.itemDefId.toString());
  }

  useEffect(() => {
    console.info('ContentForm: useEffect[selectedContent]');
  }, [selectedContent]);

  useEffect(() => {
      console.info('ContentForm: useEffect[contents]');

      const tracks = contents.filter(content => content.type === "track");
      const cars = contents.filter(content => content.type === "car");

      setTracks(tracks);
      setCars(cars);
    }, 
    [contents]
  );

  const handleSearchClick = useCallback((filteredContent: InstalledContent[]) => {
    setContents(filteredContent);
  }, [setContents]); 

  return (
    <div className={`${selectedContent ? "edit-content" : ""}`} style={{ margin: "2rem" }}>
      <h1>Content Management</h1>
      <Row>
        <Col sm={12}>
          <Tab.Container defaultActiveKey="tracks">
            <Row>
              <div className="d-flex flex-column" style={{ padding: 0 }}>
                <div className="d-flex flex" style={{ padding: 0 }}>
                  <Col sm={10}>
                    <div className="d-flex" style={{height: "100%"}}>
                      <div style={{ marginBottom: "0.5rem", alignSelf: "end"}}>
                        <Nav variant="pills" className="flex">
                          <Nav.Item>
                            <Nav.Link eventKey="tracks">Tracks</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="cars">Cars</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </div>
                  </Col>

                  <Search onClick={handleSearchClick} />
                </div>

                <Col sm={12} style={{marginTop: "1rem"}}>
                  <div style={{ maxHeight: "75vh", overflow: "auto" }}>
                    <Tab.Content>
                      <Tab.Pane eventKey="tracks">
                        <Table 
                          content={tracks} 
                          onReinstall={reinstall} 
                          onSelecteContent={c => setSelectedContent(c)} 
                          onDelete={handleDelete}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="cars">
                        <Table 
                          content={cars} 
                          onReinstall={reinstall} 
                          onSelecteContent={c => setSelectedContent(c)} 
                          onDelete={handleDelete}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </div>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
      
      <Modal show={selectedContent != null} onHide={() => setSelectedContent(null)} size="xl" className='modal-dark'>
        <Modal.Header closeButton className='modal-header-dark'>
          <Modal.Title>Editing: {selectedContent?.name} v{selectedContent?.version}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-dark'>
          {selectedContent && (
            <>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Base Config</Accordion.Header>
                <Accordion.Body>
                  <InstalledContentEdit content={selectedContent} showEditTitle={false} handleChange={handleContentChange} />
                  <ItemEdit item={selectedContent.content} handleChange={handleItemChange} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Sub Items <span>{selectedContent.content.subItems.length}</span></Accordion.Header>
                <Accordion.Body>
                  {selectedContent.content.subItems?.map((subItem, index) => (
                    <SubItemEdit key={index} subItem={subItem} handleChange={(name, value) => handleSubItemChange(index, name, value)} />
                  ))}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Tags <span>{selectedContent.tags ? selectedContent.tags.length : 0}</span></Accordion.Header>
                <Accordion.Body>
                  <TagsEdit tags={selectedContent.tags} 
                    handleTagChange={handleTagChange} 
                    handleTagDelete={handleTagDelete} 
                    hasHeader={false}
                    handleTagAdd={handleTagAdd} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedContent(null)}>Close</Button>
          <Button variant="primary" onClick={handleSubmit}>Submit Changes</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};



export default ContentForm;
