// We need to know the current route pattern so withAuthentication can check it against the allowed routes.
// This context provides it.

import { createContext, useContext } from "react";

export const RouteContext = createContext<string>("");

export function useRoute() {
    return useContext(RouteContext);
}
