import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col } from 'react-bootstrap';
import SecurityServiceClient from '../../services/security-service.client';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { HttpProtectedRole, HttpProtectedRouteViewModel, HttpProtectedUser, HttpProtectedUserViewModel } from '../../types/Security';
import RolesListViewItem from './RolesListViewItem';


interface IRolesListViewListViewProps {
    allUsers: Array<HttpProtectedUserViewModel>;
    allRoles: Array<HttpProtectedRole>;
    routes: Array<HttpProtectedRouteViewModel>;
    routesByService: Map<string, Array<HttpProtectedRouteViewModel>>;
    requestRefresh?: (indices: Array<number>) => void;
}

const RolesListView = (props: IRolesListViewListViewProps) => {

    return (
        <Row>
            <style>
            {`
                .user-list-view-header-row {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                }

                .user-list-view-header-item {   
                    color: snow;
                    font-weight: bold;
                }
            `}
            </style>
            <Col>
                <Row>
                    <Row className='user-list-view-header-row'>
                        <Col sm="1" md="1" lg="1" xl="1" xxl="1">
                            <Row>

                            </Row>
                        </Col>
                        <Col sm="8" md="8" lg="8" xl="8" xxl="8" className="user-list-item-info-col">
                            <Row>
                                <Col sm="12" md="12" lg="12" xl="12" xxl="12">
                                    <Row>
                                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" className="user-list-view-header-item">
                                            Role
                                        </Col>
                                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" className="user-list-view-header-item">
                                             Is Default
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <ListGroup>
                                {props?.allRoles?.map((role) => {
                                    return (
                                        <RolesListViewItem
                                            role={role}
                                            key={role?.name}
                                            allUsers={props?.allUsers}
                                            allRoles={props?.allRoles}
                                            routes={props?.routes}
                                            routesByService={props?.routesByService}
                                            requestRefresh={props?.requestRefresh}
                                        />
                                    );
                                })}
                            </ListGroup>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default withAuthentication(RolesListView);
