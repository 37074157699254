import React, { useCallback, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Driver } from '../../../types/event/Driver';
import { useAuth } from '../../../AuthContext';

export interface DriverManualStrikeActionModalProps {
    driver: Driver;
    game: string;
    show: boolean;
    onCancel: () => void;
    onStriken: () => void;
}

const DriverManualStrikeActionModal = (props: DriverManualStrikeActionModalProps) => {

    const auth = useAuth();

    const [reason, setReason] = useState<string>("");
    const onReasonChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReason(event.target.value);
    }

    const applyStrike = useCallback(() => {
        if (!auth.self) {
            return;
        }
        Driver.prototype.applyStrike
            .call(props?.driver, reason, auth.self.name, props?.game)
            .then(Driver.prototype.refresh.bind(props?.driver, props?.game))
            .catch(console.error)
            .finally(props?.onStriken);
    }, [props.driver, reason, auth.self, props.game, props.onStriken]);

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .strike-form-text-grey {
                        color: grey !important;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Apply strike against {props.driver?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row>
                            <Col>
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={onReasonChanged} />
                                <Form.Text className="strike-form-text-grey">
                                    Reason for the strike which will be sent to the user
                                </Form.Text>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{width: "100%"}}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{display: "flex", justifyContent: "flex-end"}} >
                            <Button variant="warning" onClick={applyStrike}>Strike</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverManualStrikeActionModal;