import { Col, Row, Button, Spinner, Form } from 'react-bootstrap';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Season } from "../models/Season";
import withAuthentication from "../../withAuthentication";
import SeasonViewItemComponent from './SeasonViewItemComponent';
import React from 'react';
import PaginationComponent from '../../components/generic/PaginationComponent';

export interface SeasonListViewComponentProps {
    customTitle?: string;
    seasons: Array<Season>;

    textSearch?: string;
    textSearchChange?: (text: string) => void;

    curPage?: number;
    total?: number;
    pageSize?: number;

    nextPage?: () => void;
    prevPage?: () => void;

    onEdit: (season: Season) => void;
    onCopy: (season: Season) => void;
    onDelete: (season: Season) => void;
}

const SeasonListViewComponent = (props: SeasonListViewComponentProps) => {

    const [seasonsLoadTimerExpired, setSeasonsLoadTimerExpired] = React.useState<boolean>(false);
    React.useEffect(() => {

        const timer = setTimeout(() => {
            setSeasonsLoadTimerExpired(true);
        }, 5000);

        return () => clearTimeout(timer);

    }, [props.seasons]);

    return (
        <>
            <style>
            {`
                .seasons-header {
                    background-color: #d5d5d5;
                    color: black;
                    border-bottom: 1px solid snow;
                    font-weight: bold;
                    padding: 0.5rem 0;
                    margin-bottom: 1rem;
                    margin-top: 0;
                }
                
                .seasons-header-item {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .seasons-header-item-start {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center;
                }

                .seasons-header-item-end {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end !important;
                    align-items: center;
                }

                .custom-title {
                    font-size: 1.2em;
                    font-weight: bolder;
                }

                #season-search {
                    background-color: rgba(0,0,0,0.25) !important;
                    border: 0 !important;
                    color: black !important;
                    font-weight: 600 !important;
                }
            `}
            </style>

            <Row>
                <Col xs={12}>
                    <Row className="seasons-header">
                        <Col xs={12} className="me-auto">
                            {
                                props?.customTitle != void (0) && props?.customTitle.length > 0 &&
                                <Row>
                                    <Col xs={3} className="seasons-header-item-start custom-title">{props?.customTitle}</Col>
                                    {
                                        props?.textSearchChange != void (0) &&
                                        <Col xs={9} className="seasons-header-item-start">
                                            <Form.Control
                                                id="season-search"
                                                className="season-search"
                                                type="text"
                                                placeholder="Search"
                                                value={props?.textSearch || ""}
                                                onChange={(e) => props?.textSearchChange?.(e.target.value)}
                                            />
                                        </Col>
                                    }
                                </Row>
                            }
                            <Row>
                                <Col xs={1} className="seasons-header-item-start">Game</Col>
                                <Col xs={5} className="seasons-header-item">Name</Col>
                                <Col xs={2} className="seasons-header-item">Starts</Col>
                                <Col xs={2} className="seasons-header-item">Ends</Col>
                                <Col xs={2} className="seasons-header-item">Actions</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                {
                    props?.seasons != void (0) && props?.seasons?.length > 0 &&
                    props?.seasons?.sort((a, b) => a.dateFrom < b.dateFrom ? 1 : -1).map((season, i) => (
                        <SeasonViewItemComponent
                            key={i}
                            season={season}
                            onEdit={props?.onEdit}
                            onCopy={props?.onCopy}
                            onDelete={props?.onDelete}
                        />
                    ))
                }
            </Row>
            <Row>
                {
                    props?.nextPage != void (0) && props?.prevPage != void (0) && props?.seasons != void (0) && props?.seasons?.length > 0 &&
                    <PaginationComponent
                        curPage={props?.curPage || 0}
                        total={props?.total || 0}
                        viewTotal={props?.seasons?.length}
                        pageSize={props?.pageSize || 0}
                        nextPage={props?.nextPage || (() => { })}
                        prevPage={props?.prevPage || (() => { })}
                    />
                }
            </Row>
            {
                props?.seasons == void (0) || props?.seasons?.length === 0 &&
                <Row style={{ height: "21vh" }}>
                    {
                        seasonsLoadTimerExpired &&
                        <Col className="d-flex flex-column justify-content-center align-items-center top-50 start-50 translate-middle">
                            <p>No seasons found</p>
                        </Col>
                    }
                    {
                        !seasonsLoadTimerExpired &&
                        <Col className="d-flex flex-column justify-content-center align-items-center top-50 start-50 translate-middle">
                            <Spinner animation="border" style={{ width: '7vh', height: '7vh' }} />
                            <div className="text-center">
                                <p>Loading...</p>
                            </div>
                        </Col>
                    }
                </Row>
            }
        </>
    )
}

export default withAuthentication(SeasonListViewComponent);
