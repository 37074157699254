import { Button, Col, Container, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import { IProtestSearchParameters, ProtestType, defaultProtestSearchParameters } from '../../types/Protest';

export interface PrioritySearchBarComponentProps {
  protestSearchParameters: IProtestSearchParameters;
  setProtestGameSearchParameter: (game: string) => void;
  setProtestPriorityRecentSearchParameter: (priorityRecent: boolean) => void;
  setProtestPriorityHighSearchParameter: (priorityHigh: boolean) => void;
  resetSearchParameters: () => void;
}

const PrioritySearchBarComponent = (props: PrioritySearchBarComponentProps) => {

  return (
    <Navbar bg="dark" expand="lg" className="protest-search-bar">
      <Container fluid>
        <Row>
          <Col>
            <NavDropdown style={{ paddingRight: "1%" }}
              id="protest-search-bar-type-dropdown"
              title={
                props?.protestSearchParameters.game === "rf2" ?
                  "RF2"
                  :
                  props?.protestSearchParameters.game === "lmu" ?
                    "LMU"
                    : "All Games"
              }
              menuVariant="dark"
            >
              <NavDropdown.Item onClick={props?.setProtestGameSearchParameter.bind(this, "rf2")}>
                RF2
              </NavDropdown.Item>
              <NavDropdown.Item onClick={props?.setProtestGameSearchParameter.bind(this, "lmu")}>
                LMU
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={props?.setProtestGameSearchParameter.bind(this, "all")}>
                All Games
              </NavDropdown.Item>
            </NavDropdown>
          </Col>
          <Col>
            <NavDropdown style={{ paddingRight: "1%" }}
              id="protest-search-bar-type-dropdown"
              title={
                props?.protestSearchParameters.priorityHigh === true ?
                  "Sort: Priority"
                  :
                  "Sort: Recent"
              }
              menuVariant="dark"
            >
              <NavDropdown.Item onClick={props?.setProtestPriorityHighSearchParameter.bind(this, true)}>
                Sort: Priority
              </NavDropdown.Item>
              <NavDropdown.Item onClick={props?.setProtestPriorityRecentSearchParameter.bind(this, true)}>
                Sort: Recent
              </NavDropdown.Item>
            </NavDropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="outline-success" onClick={props?.resetSearchParameters}>
              Reset Filters
            </Button>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default PrioritySearchBarComponent;