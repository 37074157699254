import moment from "moment";
import { Tier } from "./Tier";
import { DailyRacesContent } from "./DailyRaceContent";
import { Session } from "./Session";
import { ServerSettings } from "./ServerSettings";
import { Ratings } from "./Ratings";

export const games = ["rf2", "lmu"];
export const dailyTiers = ["beginner", "intermediate", "advanced"];
export const specialEventTiers = ["weekly", "special"];
export const multiFormationLap = [
  { key: "0", value: "Standing Start" },
  { key: "1", value: "Formation Lap" },
  { key: "2", value: "Rolling Start" },
  { key: "3", value: "Track Default" },
  { key: "4", value: "Fast Rolling" }
];
export const trackLimitsRule = [
  { key: "0", value: "Disabled" },
  { key: "1", value: "Default" },
  { key: "2", value: "Strict" },
  { key: "3", value: "Relaxed" }
];
export const ranks = [
  { key: "Bronze", value: "Bronze" },
  { key: "Silver", value: "Silver" },
  { key: "Gold", value: "Gold" },
  { key: "Platinum", value: "Platinum" }
];
export const closedRaceSessions = [
  { key: 0, value: "Open" },
  { key: 1, value: "Pending Open Session" },
  { key: 2, value: "Closed" }
];

export class DailyRaceConfiguration {
  ServerSize: string = "small";
  game: string = "RF2";
  seriesId: string = "";
  starts: string = moment().startOf('day').format();
  ends: string = moment().startOf('day').add(7, 'day').add(-1, 'second').format();
  frequency: string[] = ["0 9-22/1 * * *"];
  registrationOpens: string = "40m";
  tier: Tier = new Tier();
  splitSetting: string = "even"; // legacy, even, filltop
  content: DailyRacesContent[] = [];
  sessions: Session[] = [
    new Session("Practice", "5m", "0m"),
    new Session("Qualifying", "10m", "0m"),
    new Session("Race", "15m", "5m")
  ];
  settings: ServerSettings = new ServerSettings();
  ratings: Ratings = new Ratings();
}

export function multiFormationLapValue(key: number): string {
  return multiFormationLap.find((v) => v.key === key.toString())?.value || "";
}

export function trackLimitsRuleValue(key: string): string {
  return trackLimitsRule.find((v) => v.key === key)?.value || "";
}