import OAuthClient from "../../services/oauth.client";
import { Entry, LeaderboardResponse } from "./entry";
import { Leaderboard } from "./leaderboard";

class LeaderboardsClient {
  url = process.env.REACT_APP_RM_URL;
  oauth: OAuthClient;

  constructor() {
    this.oauth = new OAuthClient();
  }

  async list(search: string, page: number, take: number): Promise<Leaderboard[]> {
    const response = await this.oauth.fetch(`${this.url}/leaderboard?search=${search}&page=${page}&take=${take}`);
    return await response?.json() as Leaderboard[];
  }
  
  async entries(name: string, page: number, take: number): Promise<LeaderboardResponse> {
    const response = await this.oauth.fetch(`${this.url}/leaderboard/${name}/list?page=${page}&take=${take}`);
    return await response?.json() as LeaderboardResponse;
  }

  async deleteEntry(id: string, entryId: string) {
    return await this.oauth.fetch(`${this.url}/leaderboard/${id}/${entryId}`, "DELETE") as Response;
  }

  async updateEntry(id: string, entry: Entry) {
    return await this.oauth.fetch(`${this.url}/leaderboard`, "PUT", JSON.stringify(entry)) as Response;
  }

  async delete(name: string) {
    return await this.oauth.fetch(`${this.url}/leaderboard/${name}`, "DELETE") as Response;
  }
}

export default LeaderboardsClient;
