import { faEdit, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { InstalledContent } from "../types/InstalledContent";

interface TableProps {
  content: InstalledContent[];
  onReinstall: (content: InstalledContent) => void;
  onSelecteContent: (content: InstalledContent) => void;
  onDelete: (content: InstalledContent) => void;
}

const Table = (props: TableProps): JSX.Element => (
  <table className="table table-dark">
    <thead>
      <tr>
        <th scope='col'>Game</th>
        <th scope='col'>Workshop Id</th>
        <th scope='col'>Item Def ID</th>
        <th scope='col'>Type</th>
        <th scope='col'>Name</th>
        <th scope='col'>Version</th>
        <th scope='col'>Install Type</th>
        <th scope='col'>Broken</th>
        <th scope='col' style={{textAlign: "right"}}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {props.content.map((content: InstalledContent) => (
        <tr key={content.Id}>
          <td scope='row'>{content.game}</td>
          <td>{content.workshopId}</td>
          <td>{content.itemDefId}</td>
          <td>{content.type}</td>
          <td>{content.name}</td>
          <td>{content.version}</td>
          <td>{content.installType}</td>
          <td>
            {content.type === "track" && content.content.subItems.some(sub => !sub.scn) 
            ? "Yes"
            : "No"}
          </td>

          <td style={{textAlign: "right"}}>
            <button className='btn btn-primary' onClick={() => props.onSelecteContent(content)} style={{marginRight: '1rem'}}><FontAwesomeIcon icon={faEdit} /></button>
            <button className='btn btn-danger' onClick={() => props.onDelete(content)} style={{marginRight: '1rem'}}><FontAwesomeIcon icon={faTrash} /></button>
            <button className='btn btn-secondary' onClick={() => props.onReinstall(content)}><FontAwesomeIcon icon={faSync} /></button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default React.memo(Table);
