import React from 'react';
import { InstalledContent } from '../types/InstalledContent';
import { HandleChangeFunction } from './HandledChangeFunction';

interface InstalledContentEditProps {
  content: InstalledContent;
  showEditTitle?: boolean;
  handleChange: HandleChangeFunction;
}

const InstalledContentEdit = ({ content, showEditTitle, handleChange }: InstalledContentEditProps) => {
  return (
    <div className="">
      {/* <h4>Edit Content</h4> */}
      {showEditTitle && (
        <h5>Editing: {content.name} v{content.version}</h5>
      )}
      <div className="row mb-3">
        <div className="col-4">
          <label>Workshop ID:</label>
          <input
            type="number"
            name="workshopId"
            value={content.workshopId}
            onChange={(e) => handleChange('workshopId', e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-4">
          <label>Type:</label>
          <select 
            name="type" 
            value={content.type}
            onChange={(e) => handleChange('type', e.target.value)}
            className="form-control"
          >
            <option value="car">Car</option>
            <option value="track">Track</option>
          </select>
        </div>
        <div className="col-4">
          <label>Install Type:</label>
          <select 
            name="type" 
            value={content.installType}
            onChange={(e) => handleChange('installType', e.target.value)}
            className="form-control"
          >
            <option value="DLC">DLC</option>
            <option value="S397">S397</option>
            <option value="Workshop">Workshop</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default InstalledContentEdit;

