import React from 'react';

import {Driver, Suspension} from '../../types/event/Driver'
import { Col, Row, Spinner } from 'react-bootstrap';
import moment from 'moment';

export interface DriverAccordionViewItemBannedFlagProps {
  driver: Driver;
}

const DriverAccordionViewItemBannedFlag = (props: DriverAccordionViewItemBannedFlagProps) => {

    const isBanned = () => {

        const suspensions = props?.driver?.profile?.penaltiesApplied?.suspensions;
        if(suspensions == void(0)) return false;

        const activeSuspensions = suspensions?.filter((s: Suspension) => s.redacted == false)
            .filter((s: Suspension) => {
                const suspension = s as Suspension;
                return suspension?.to != void(0) && suspension?.to > Date.now();
            }
        );

        return activeSuspensions.length > 0;
    }

    const lastBanExpiration = () => {

        const suspensions = props?.driver?.profile?.penaltiesApplied?.suspensions;
        if(suspensions == void(0)) return null;

        const activeSuspensions = suspensions?.filter((s: Suspension) => s.redacted == false)
            .filter((s: Suspension) => {
                const suspension = s as Suspension;
                return suspension?.to != void(0) && suspension?.to > Date.now();
            }
        );

        if(activeSuspensions?.length == 0) return null;

        const lastBan = activeSuspensions?.reduce((prev: Suspension, current: Suspension) => {
            return prev?.to > current?.to ? prev : current;
        });

        return lastBan?.to;
    }

    const isPermanentlyBanned = () => {
            
        const suspensions = props?.driver?.profile?.penaltiesApplied?.suspensions;
        if(suspensions == void(0)) return false;

        const activeSuspensions = suspensions?.filter((s: Suspension) => s?.redacted === false)
            .filter((s: Suspension) => {
                const suspension = s as Suspension;
                return moment(suspension?.to)?.diff(moment(suspension?.from), 'hours') === 867240;
            }
        );

        return activeSuspensions?.length > 0;
    }

    return (
        isBanned() && isPermanentlyBanned() ?
            <Row style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                <Col>
                    <Spinner animation="grow" variant="danger" size="sm" style={{margin: 0, padding: 0}}/>
                    {"Banned: Permanently"}
                </Col>
            </Row>
        : isBanned() ?
            <Row style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                <Col>
                    <Spinner animation="grow" variant="danger" size="sm" style={{margin: 0, padding: 0}}/>
                    {"Banned: expires "} 
                    {moment(lastBanExpiration()).format("DD MMMM YYYY HH:mm")}
                </Col>
            </Row>
        :
            <></>
    );
}

export default DriverAccordionViewItemBannedFlag;