import React, { useCallback, useState } from 'react';
import {  Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Driver } from '../../../types/event/Driver';
import { useAuth } from '../../../AuthContext';

export interface DriverManualBanActionModalProps {
    driver: Driver;
    game: string;
    show: boolean;
    onCancel: () => void;
    onBanned: () => void;
}

const DriverManualBanActionModal = (props: DriverManualBanActionModalProps) => {

    const auth = useAuth();

    const banLengths: Map<string, string> = new Map<string, string>([
        ["24h", "1 Day"],
        ["48h", "2 Days"],
        ["168h", "7 Days"],
        ["720h", "30 Days"],
        ["4320h", "180 Days"],
        ["867240h", "Permanently"]
    ]);

    const [duration, setDuration] = useState<string>("24h");
    const onBanLengthChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDuration(event.target.value);
    }

    const [reason, setReason] = useState<string>("");
    const onReasonChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReason(event.target.value);
    }

    const applyBan = useCallback(() => {
        if (!auth.self) {
            return;
        }
        Driver.prototype.applyBan
            .call(props?.driver, duration, reason, auth.self.name, props?.game)
            .then(Driver.prototype.refresh.bind(props?.driver, props?.game))
            .catch(console.error)
            .finally(props?.onBanned);
    }, [props.driver, duration, reason, auth.self, props.game, props.onBanned]);

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-form-text-grey {
                        color: grey !important;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Ban user {props.driver?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row style={{marginBottom: "10px"}}>
                            <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                                <Form.Label>Ban Length (days)</Form.Label>
                                <Form.Select onChange={onBanLengthChanged} defaultValue={duration}>
                                    {Array.from(banLengths.keys()).map((key, index) =>
                                        <option key={index} value={key}>{banLengths.get(key)}</option>
                                    )}
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={onReasonChanged} />
                                <Form.Text className="ban-form-text-grey">
                                    Reason for the ban which will be sent to the user
                                </Form.Text>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{width: "100%"}}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{display: "flex", justifyContent: "flex-end"}} >
                            <Button variant="danger" onClick={applyBan}>Ban</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverManualBanActionModal;