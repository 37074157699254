import React, { useEffect, useState, } from 'react';
import { Accordion, Button, ButtonGroup, Card, Col, Container, Dropdown, DropdownButton, Figure, Form, Row } from 'react-bootstrap';

import { FaCheck, FaCross, FaQuestion } from "react-icons/fa";

import ProtestServiceClient from '../../services/protest-service.client';

import { Driver } from '../../types/event/Driver'
import DriverAccordionViewItemBannedFlag from './DriverAccordionViewItemBannedFlag';
import DriverAccordionViewItemPenaltyHistory from './DriverAccordionViewItemPenaltyHistory';
import DriverManualBanActionModal from './modals/DriverManualBanActionModal';
import DriverManualStrikeActionModal from './modals/DriverManualStrikeActionModal';
import DriverManualSafetyRatingActionModal from './modals/DriverManualSafetyRatingActionModal';
import DriverNullifyPIIActionModal from './modals/DriverNullifyPIIActionModal';
import DriverAccordionViewItemNullification from './DriverAccordionViewItemNullification';
import moment from 'moment';
import { ImCross } from 'react-icons/im';
import DriverViewNameChangesModal from './modals/DriverViewNameChangesModal';
import DriverManualNameChangeActionModal from './modals/DriverManualNameChangeActionModal';
import DriverManualNameViolationActionModal from './modals/DriverManualNameViolationActionModal';
import DriverRankBadge from '../driverRank';
import SafetyRankBadge from '../safteyRank';

export interface DriverAccordionViewItemComponentProps {
  driver: Driver;
  game: string;
  eventKey: string;
  requestRefresh?: (indices: Array<number>) => void;
}

const DriverAccordionViewItemComponent = (props: DriverAccordionViewItemComponentProps) => {

  const [showManualBanActionModal, setShowManualBanActionModal] = useState(false);
  const [showManualStrikeActionModal, setShowManualStrikeActionModal] = useState(false);
  const [showManualSafetyRatingActionModal, setShowManualSafetyRatingActionModal] = useState(false);

  const [showNullifyPIIActionModal, setShowNullifyPIIActionModal] = useState(false);

  const [showNameChangesModal, setShowNameChangesModal] = useState(false);
  const [showManualNameChangeModal, setShowManualNameChangeModal] = useState(false);
  const [showManualNameViolationActionModal, setShowManualNameViolationActionModal] = useState(false);

  const nameColor = () => {
    if (props?.driver?.profile?.verified?.msg || props?.driver?.profile?.verified?.s397) {
      return "red";
    } else if (props?.driver?.profile?.verified.account) {
      return "#6bc9ff";
    } else {
      return "white";
    }
  }

  const openNakamaProfile = () => {
    window.open(`${props?.game === "rf2" ? process.env.REACT_APP_RF2_NAKAMA_URL : process.env.REACT_APP_LMU_NAKAMA_URL}/#/accounts/${props?.driver?.id}/profile`, "_blank");
  }

  const openSteamProfile = () => {
    window.open(`https://steamcommunity.com/profiles/${props?.driver?.steamId}`, "_blank");
  }

  const hasPenalties = () => {
    return [
      ...(props?.driver?.profile?.penaltiesApplied?.suspensions ? (props?.driver?.profile?.penaltiesApplied?.suspensions) : []),
      ...(props?.driver?.profile?.penaltiesApplied?.safetyRankAdjustments ? (props?.driver?.profile?.penaltiesApplied?.safetyRankAdjustments) : []),
      ...(props?.driver?.profile?.penaltiesApplied?.strikes ? (props?.driver?.profile?.penaltiesApplied?.strikes) : []),
      ...(props?.driver?.profile?.penaltiesApplied?.usernameViolations ? (props?.driver?.profile?.penaltiesApplied?.usernameViolations) : [])
    ].length > 0;
  }

  const hasNameChanges = () => {
    return (props?.driver?.profile?.nameChanges?.history || [])?.length > 0;
  }

  const driverNull = () => {
    return props == void (0) || props?.driver == void (0) || props?.driver?.profile == void (0) || props?.driver?.id == void (0) || props?.driver?.steamId == void (0) || props?.driver?.username == void (0);
  }

  const accountedNullified = () => {
    return props?.driver?.piiNullification?.nullified;
  }

  const getUsername = (trim: Boolean = false) => {

    if (accountedNullified()) {
      return "Deleted User";
    }

    const trimmedName = trim && props?.driver?.username?.length >= 26 ?
      props?.driver?.username?.substring(0, 26) + "..."
      :
      props?.driver?.username

    return trimmedName;
  }

  const getDisplayname = (trim: Boolean = false) => {

    if (accountedNullified()) {
      return "Deleted User";
    }

    const trimmedName = trim && props?.driver?.name?.length >= 26 ?
      props?.driver?.name?.substring(0, 26) + "..."
      :
      props?.driver?.name

    return trimmedName;
  }

  const getSteamID = () => {

    if (accountedNullified() && (props?.driver?.steamId?.length === 0 || props?.driver?.steamId == void (0))) {
      return "Unlinked";
    }

    return props?.driver?.steamId;
  }

  const getEmail = () => {

    if (accountedNullified()) {
      return "Unlinked";
    }

    return props?.driver?.profile?.email;
  }

  return (
    <>
      <style>
        {`

          .driver-accordion-item-header-container {
            display: flex;
            align-items: center;
            justify-content: center; 
            width: fit-content;
            margin: 0;
            padding: 0;
            padding-left: 0.5vw;
          }

          .driver-accordion-item-header-figure > * {
            margin: 0;
            padding: 0;
          }

          .driver-accordion-item-email-data-container, .driver-accordion-item-data-icon {
            display: flex;
            align-items: center;
            justify-content: center; 
            width: fit-content;
            margin: 0;
            padding: 0;
            padding-left: 0.15vw;
            padding-bottom: 0.1vh;
          }

          .lime {
            color: lime;
          }

          .blue {
            color: blue;
          }

          .red {
            color: red;
          }
          
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #454d54 #373c42;
            overflow-y: scroll;
            overflow-x: hidden;
          }

          .custom-scrollbar:hover {
            scrollbar-color: #576775 #373c42;
          }

          .name-changes-modal-view-btn {
            cursor: pointer;
          }

          .name-changes-modal-view-btn:hover {
            background-color: #333333;
            text-decoration: underline;
          }

        `}
      </style>
      <Accordion.Item eventKey={props?.eventKey}>
        <Accordion.Header>
          {
            driverNull() ?
              <Row>
                Failed to load driver
              </Row>
              :
              <Row>
                <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="driver-accordion-item-header-container">
                  {
                    props?.driver?.profile?.verified?.msg &&
                    <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                      <Figure.Image src={`https://rf2-ui-images-prod.s3.eu-west-1.amazonaws.com/badges/msg-badge.svg`} height={23} width={23} />
                    </Figure>
                  }
                  {
                    props?.driver?.profile?.verified?.s397 &&
                    <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                      <Figure.Image src={`https://rf2-ui-images-prod.s3.eu-west-1.amazonaws.com/badges/397-badge.svg`} height={23} width={23} />
                    </Figure>
                  }
                  {
                    props?.driver?.profile?.verified?.account &&
                    <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                      <Figure.Image src={`https://rf2-ui-images-prod.s3.eu-west-1.amazonaws.com/badges/verified-badge.svg`} height={23} width={23} />
                    </Figure>
                  }
                  {
                    props?.driver?.profile?.verified?.alpha &&
                    <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                      <Figure.Image src={`https://rf2-ui-images-prod.s3.eu-west-1.amazonaws.com/badges/alpha-badge.svg`} height={23} width={23} />
                    </Figure>
                  }
                  {
                    !props?.driver?.profile?.verified?.msg && !props?.driver?.profile?.verified?.s397 && !props?.driver?.profile?.verified?.account && !props?.driver?.profile?.verified?.alpha &&
                    <span style={{ backgroundColor: "rgba(0,0,0,0)" }}></span>
                  }
                </Col>
                <Col sm="6" md="6" lg="6" xl="6" xxl="6" className="driver-accordion-item-header-container">
                  <div style={{ color: nameColor(), paddingLeft: "0.5vw" }}>
                    {
                      getUsername(true)
                    }
                  </div>
                </Col>
              </Row>
          }
        </Accordion.Header>
        <Accordion.Body className="custom-scrollbar">
          {
            driverNull() ?
              <Row></Row>
              :
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Row title={props?.driver?.username}>
                        <Col>
                          Username: {getUsername(false)}
                        </Col>
                      </Row>
                      <Row title={props?.driver?.name}>
                        <Col>
                          Display Name: {getDisplayname(false)}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Nakama ID: {props?.driver?.id}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          SteamID: {getSteamID()}
                        </Col>
                      </Row>
                      <Row title={props?.driver?.profile?.email}>
                        <Col style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                            Email: {getEmail()}
                            {
                                props?.driver?.profile?.verified?.email ?
                                    <div style={{ display: 'flex', alignItems: 'center' }} className="driver-accordion-item-email-data-container"><FaCheck className="driver-accordion-item-data-icon lime" /></div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center' }} className="driver-accordion-item-email-data-container"><FaQuestion className="driver-accordion-item-data-icon blue" /></div>
                            }
                        </Col>
                    </Row>
                      <Row>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          Nationality: {props?.driver?.profile?.nationality}
                          {
                            props?.driver?.profile?.nationality &&
                            <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                              <Figure.Image src={`https://rf2-ui-images-prod.s3.eu-west-1.amazonaws.com/nation-flags/${props?.driver?.profile?.nationality}.svg`} height={23} width={23} />
                            </Figure>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Last Online: {moment(props?.driver?.profile?.lastLogin)?.format("DD/MM/YYYY HH:mm")}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Requires Review: {
                            props?.driver?.profile?.requiresReview ?
                              <FaCheck className="lime" size="0.75rem"/>
                              :
                              <ImCross className="red" size="0.75rem"/>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="my-12" />

                  <Row>
                    <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
                      Ranks
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <Row>
                        <Col>
                          <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                            <DriverRankBadge dr={props?.driver?.driverRank} />
                          </Figure>
                        </Col>
                      </Row>
                      <Container>
                        <Row>
                          <Col>
                            Driver Rank: {props?.driver?.driverRank?.rank} ({props?.driver?.safetyRank?.tier})
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            Elo: {props?.driver?.driverRank?.elo} progress: {props?.driver?.driverRank?.progress}
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <Row>
                        <Col>
                          <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                            <SafetyRankBadge sr={props?.driver?.safetyRank} />
                          </Figure>
                        </Col>
                      </Row>
                      <Container>
                        <Row>
                          <Col>
                            Safety Rank: {props?.driver?.safetyRank?.rank} ({props?.driver?.safetyRank?.tier})
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            Rating: {props?.driver?.safetyRank?.rating} progress: {props?.driver?.safetyRank?.progress}
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                  <hr className="my-12" />
                  {
                    hasPenalties() &&
                    <>
                      <Row>
                          <Col style={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"}}>
                              Penalties
                          </Col>
                      </Row>
                      <Row>
                        <DriverAccordionViewItemBannedFlag driver={props?.driver} />
                        <DriverAccordionViewItemPenaltyHistory driver={props?.driver} game={props?.game} />
                      </Row>
                    </>
                  }
                  {
                    hasPenalties() &&
                    <hr className="my-12" />
                  }
                  {
                    hasNameChanges() &&
                    <>
                      <Row>
                          <Col style={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"}}>
                              Name Changes
                          </Col>
                      </Row>
                      <Row>
                        <Col onClick={setShowNameChangesModal.bind(this, true)} className="name-changes-modal-view-btn">
                          {props?.driver?.profile?.nameChanges?.history?.length}
                          <span style={{paddingLeft: "0.25rem"}}>change{(props?.driver?.profile?.nameChanges?.history || [])?.length > 1 ? "s" : ""} (click to view)</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Last change date: {moment(props?.driver?.lastNameChange)?.format("DD/MM/YYYY HH:mm")}
                        </Col>
                      </Row>
                    </>
                  }
                  {
                    hasNameChanges() &&
                    <hr className="my-12" />
                  }
                  {
                    accountedNullified() &&
                    <>
                      <Row>
                          <Col style={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"}}>
                              Account Deleted
                          </Col>
                      </Row>
                      <Row>
                        <DriverAccordionViewItemNullification driver={props?.driver} />
                      </Row>
                    </>
                  }
                  {
                    accountedNullified() &&
                    <hr className="my-12" />
                  }
                  <DropdownButton
                    data-bs-theme="dark"
                    as={ButtonGroup}
                    id="account-links-dropdown-1"
                    title={"Links"}
                    variant={"outline-secondary"}>
                    <Dropdown.Item variant={"outline-secondary"} onClick={openNakamaProfile}>
                      <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                        <Figure.Image src={`${props?.game === "rf2" ? process.env.REACT_APP_RF2_NAKAMA_URL : process.env.REACT_APP_LMU_NAKAMA_URL}/favicon.ico`} height={23} width={23} style={{ paddingRight: "5%" }} />
                        Nakama
                      </Figure>
                    </Dropdown.Item>
                    {
                      (!accountedNullified() && getSteamID()?.length > 0) &&
                      <Dropdown.Item onClick={openSteamProfile}>
                        <Figure style={{ width: "fit-content", margin: 0, padding: 0 }} className="driver-accordion-item-header-figure driver-accordion-item-header-container">
                          <Figure.Image src={`https://steamcommunity.com/favicon.ico`} height={23} width={23} style={{ paddingRight: "5%" }} />
                          Steam
                        </Figure>
                      </Dropdown.Item>
                    }
                  </DropdownButton>
                  {
                    (!accountedNullified() && getSteamID()?.length > 0) &&
                      <DropdownButton
                        data-bs-theme="dark"
                        as={ButtonGroup}
                        id="account-links-dropdown-1"
                        title={"Actions"}
                        variant={"outline-primary"}>

                        <Dropdown.Header>Penalties</Dropdown.Header>
                        <Dropdown.Item onClick={() => setShowManualNameViolationActionModal(true)}>
                          Username Violation
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowManualStrikeActionModal(true)}>
                          Strike
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowManualSafetyRatingActionModal(true)}>
                          SR Adjustment
                        </Dropdown.Item>
                        <Dropdown.Item variant={"outline-danger"} onClick={() => setShowManualBanActionModal(true)}>
                          Ban
                        </Dropdown.Item>
                        <Dropdown.Header>Utility</Dropdown.Header>
                        <Dropdown.Item onClick={() => setShowNullifyPIIActionModal(true)}>
                          Erase PII (GDPR)
                        </Dropdown.Item>
                        <Dropdown.Item onClick={setShowManualNameChangeModal.bind(this, true)}>
                          Manual Name Change
                        </Dropdown.Item>
                      </DropdownButton>
                  }
                </Col>
              </Row>
          }
        </Accordion.Body>
      </Accordion.Item>
      <DriverManualBanActionModal
        driver={props?.driver}
        game={props?.game}
        show={showManualBanActionModal}
        onCancel={() => setShowManualBanActionModal(false)}
        onBanned={() => setShowManualBanActionModal(false)}
      />
      <DriverManualStrikeActionModal
        driver={props?.driver}
        game={props?.game}
        show={showManualStrikeActionModal}
        onCancel={() => setShowManualStrikeActionModal(false)}
        onStriken={() => setShowManualStrikeActionModal(false)}
      />
      <DriverManualSafetyRatingActionModal
        driver={props?.driver}
        game={props?.game}
        show={showManualSafetyRatingActionModal}
        onCancel={() => setShowManualSafetyRatingActionModal(false)}
        onSafetyRatingAdjusted={() => setShowManualSafetyRatingActionModal(false)}
      />

      <DriverNullifyPIIActionModal
        driver={props?.driver}
        game={props?.game}
        show={showNullifyPIIActionModal}
        onCancel={() => setShowNullifyPIIActionModal(false)}
        onPIINullified={() => (props.requestRefresh?.([]), setShowNullifyPIIActionModal(false))}
      />

      <DriverViewNameChangesModal
        driver={props?.driver}
        game={props?.game}
        nameChanges={props?.driver?.profile?.nameChanges}
        show={showNameChangesModal}
        onCancel={() => setShowNameChangesModal(false)}
      />

      <DriverManualNameChangeActionModal
        driver={props?.driver}
        game={props?.game}
        show={showManualNameChangeModal}
        onCancel={() => setShowManualNameChangeModal(false)}
        onChanged={() => (props.requestRefresh?.([]), setShowManualNameChangeModal(false))}
      />
      <DriverManualNameViolationActionModal
        driver={props?.driver}
        game={props?.game}
        show={showManualNameViolationActionModal}
        onCancel={() => setShowManualNameViolationActionModal(false)}
        onApplied={() => (props.requestRefresh?.([]), setShowManualNameViolationActionModal(false))}
      />
    </>
  );
}

export default DriverAccordionViewItemComponent;