import { ChangeEvent } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { IProtestSearchParameters } from '../../types/Protest';
import Cookies from 'js-cookie';

export interface ProtestSettingsModalComponentProps {
    showSettingsModal: boolean;
    setShowSettingsModal: (show: boolean) => void;
    protestSearchParameters: IProtestSearchParameters;
    setProtestSearchPriorityParameters: (numberProtests: number, numberPunishments: number) => void;
    priorityProtestsCount: (numberProtests: number, numberPunishments: number) => void;
    setSettingsModalFormData: (settingsModalFormData: { numberProtests: number, numberPunishments: number }) => void;
    settingsModalFormData: { numberProtests: number, numberPunishments: number };
}

const ProtestSettingsModalComponent = (props: ProtestSettingsModalComponentProps) => {

  return (
    <Modal show={props?.showSettingsModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Header style={{ backgroundColor: "#222222", borderColor: "#333333" }}>
        <Modal.Title style={{ color: "snow" }}>
          <Row>
            <Col sm="12" md="12" lg="12" xl="12" xxl="12">
              Protest Settings
            </Col>
          </Row>
        </Modal.Title>
        
        <Button variant="light" onClick={() => props?.setShowSettingsModal(false)} className="protest-settings-close-button">x</Button>
      </Modal.Header>

      <Modal.Body className="protest-settings-modal" style={{ backgroundColor: "#222222" }}>

        <Row>
          <Col>
            <Row>
              <Col sm="3" md="3" lg="3" xl="3" xxl="3">
                <Form.Label>Priority Protests Filter</Form.Label>
                <Form.Text>These settings affect the search for the priority filter</Form.Text>
                <Form.Text>Use this to tweak who appears in the list, e.g. 5 reports or 1 previous punishment</Form.Text>
              </Col>
              <Col sm="3" md="3" lg="3" xl="3" xxl="3">
                <Form.Group>
                  <Form.Label>Number of protests {props?.settingsModalFormData.numberProtests}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Number of Protests"
                    defaultValue={props?.protestSearchParameters.priorityParameters.numberProtests}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props?.setSettingsModalFormData({
                      ...props?.settingsModalFormData,
                      numberProtests: parseInt(e.target.value)
                    })}
                  />
                </Form.Group>
              </Col>
              <Col sm="3" md="3" lg="3" xl="3" xxl="3">
                <Form.Group>
                  <Form.Label>Number of punishments {props?.settingsModalFormData.numberPunishments}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Number of Punishments"
                    defaultValue={props?.protestSearchParameters.priorityParameters.numberPunishments}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props?.setSettingsModalFormData({
                      ...props?.settingsModalFormData,
                      numberPunishments: parseInt(e.target.value)
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>

      </Modal.Body>

      <Modal.Footer style={{ backgroundColor: "#222222", borderColor: "#333333" }}>
        <Button style={{left: 0, position: "absolute", margin: "1.5%"}} variant="danger" onClick={props?.setShowSettingsModal.bind(this, false)}>Close</Button>
        <Button variant="success" onClick={() => {
          props?.setProtestSearchPriorityParameters(
            props?.settingsModalFormData.numberProtests,
            props?.settingsModalFormData.numberPunishments
          ); 

          const encodedSettings = btoa(JSON.stringify(props?.settingsModalFormData));
          props?.priorityProtestsCount(props?.settingsModalFormData.numberProtests, props?.settingsModalFormData.numberPunishments);
          Cookies.set('protest-settings', encodedSettings); 
        }}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProtestSettingsModalComponent;