import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col, Accordion } from 'react-bootstrap';
import SecurityServiceClient from '../../services/security-service.client';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { HttpProtectedRole, HttpProtectedRoute, HttpProtectedRouteViewModel, HttpProtectedUser } from '../../types/Security';
import RoutesListViewItem from './RoutesListViewItem';


interface IRoutesListViewProps {
    routes: Array<HttpProtectedRouteViewModel>;
    allRoles: Array<HttpProtectedRole>;
    requestRefresh?: (indices: Array<number>) => void;
}

const RoutesListView = (props: IRoutesListViewProps) => {

    const [groupedRoutes, setGroupedRoutes] = useState<Map<string, Array<HttpProtectedRouteViewModel>>>(new Map<string, Array<HttpProtectedRouteViewModel>>());

    useEffect(() => {
        const groupedRoutes = new Map<string, Array<HttpProtectedRouteViewModel>>();
        props?.routes?.forEach((route) => {
            const routeKey = route?.path?.split("/")[1];
            if (!groupedRoutes?.has(routeKey)) {
                groupedRoutes?.set(routeKey, []);
            }
            groupedRoutes?.get(routeKey)?.push(route);
        });
        setGroupedRoutes(groupedRoutes);
    }, [props?.routes]);

    return (
        <Row>
            <style>
            {`
                .user-list-view-header-row {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                }

                .user-list-view-header-item {   
                    color: snow;
                    font-weight: bold;
                }

                /* Style the Accordion */
                body .routes-accordion .accordion-button {
                    background-color: #222222 !important;
                }

                body .routes-accordion .accordion-button:not(.collapsed) {
                    background-color: #333333 !important;
                    border: 1px solid snow;
                }

                body .routes-accordion .accordion-button:hover {
                    background-color: #333333 !important;
                }

                /* Style the Accordion headers */
                body .routes-accordion .routes-accordion-header {
                    background-color: #222222 !important;
                }

                body .routes-accordion .accordion-button::after {
                    background-image: none;
                }

                body .routes-accordion .accordion-button::after {
                    content: '\\25BC';
                    color: snow !important;
                    transform: rotate(180deg);
                }
                
                body .routes-accordion .accordion-button:not(.collapsed)::after {
                    content: "\\25B2";
                    color: snow !important;
                }
            `}
            </style>
            <Col>
                <Row>
                    <Col>
                        <Row>
                            <Accordion defaultActiveKey="0" flush className="routes-accordion">
                            {
                                Array.from(groupedRoutes?.keys())?.map((key) => {
                                    return (
                                        <Accordion.Item eventKey={key} key={key}>
                                        <Accordion.Header className="routes-accordion-header">{key} Service Endpoints</Accordion.Header>
                                        <Accordion.Body style={{padding: 0, overflowX: "hidden"}}>
                                            <Row>
                                                <Col>
                                                    <ListGroup>
                                                    {
                                                        groupedRoutes?.get(key)?.map((route) => {
                                                            return (
                                                                <RoutesListViewItem
                                                                    route={route}
                                                                    key={route?.id}
                                                                    allRoles={props?.allRoles}
                                                                    requestRefresh={props?.requestRefresh}
                                                                />
                                                            );
                                                        })
                                                    }
                                                    </ListGroup>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                            </Accordion>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default withAuthentication(RoutesListView);
