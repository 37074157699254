import React, { useState, useEffect } from 'react';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { KeyValue } from "../../types/keyValue";
import { Assists } from '../../types/event/Assists';
import { get } from 'http';
import { Ratings } from '../../types/event/Ratings';

interface RatingsProps {
  ratings: Ratings | null;
  onChanged: (ratings: Ratings) => void;
}


const RatingsComponent: React.FC<RatingsProps> = (params: RatingsProps) => {
  const [ratings, setRatings] = useState<Ratings>(params.ratings ?? new Ratings());

  useEffect(() => params.onChanged(ratings), [ratings]);

  const onMultiplyierChanged = (eventKey: string | null) => {
    if (eventKey == null) return;
    const baseDR = 30;
    const baseSR = 6;
    const multiplier = Number(eventKey.replace("x", ""));

    if (multiplier == void (0) || isNaN(multiplier) || multiplier < 1 || multiplier > 6) return;

    setRatings({
      enabled: true,
      maxSRGain: baseSR * multiplier,
      drSettings: {
        k: baseDR * multiplier,
        d: ratings?.drSettings?.d,
        base: ratings?.drSettings?.base,
        log: ratings?.drSettings?.log
      }
    });
  }

  return (
    <>
    <Row>
      
    </Row>
    <Row>
      <Col>
        <Form.Group controlId="enabled">
          <Form.Label>Ratings Enabled:</Form.Label>
          <Form.Check type="checkbox" checked={ratings?.enabled ?? true} onChange={(e) => setRatings({ ...ratings, enabled: e.target.checked })} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="multiplier">
          <Form.Label>Multiplier:</Form.Label>
          <Form.Control className="dark-select"
            as="select"
            value={`x${ratings.maxSRGain / 6}`}
            onChange={(e) => onMultiplyierChanged(e.target.value)}
            >
            {["Please Select", "1x", "2x", "3x", "4x", "5x", "6x"].map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="maxSRGain">
          <Form.Label>Max Saftey Rating Gains:</Form.Label>
          <Form.Control type="number" readOnly={true} value={ratings?.maxSRGain ?? 10} onChange={(e) => setRatings({ ...ratings, maxSRGain: Number(e.target.value) })} />
        </Form.Group>
      </Col>
      <Col> </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group controlId="k">
          <Form.Label>ELO K:</Form.Label>
          <Form.Control type="number" readOnly={true} value={ratings.drSettings?.k ?? 32} onChange={(e) =>
            setRatings({ ...ratings, drSettings: { ...ratings.drSettings, k: Number(e.target.value) } })} 
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="d">
          <Form.Label>ELO D:</Form.Label>
          <Form.Control type="number" value={ratings.drSettings?.d ?? 400} onChange={(e) => 
            setRatings({ ...ratings, drSettings: { ...ratings.drSettings, d: Number(e.target.value) } })}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="base">
          <Form.Label>ELO BASE:</Form.Label>
          <Form.Control type="number" value={ratings.drSettings?.base ?? 1} onChange={(e) =>
            setRatings({ ...ratings, drSettings: { ...ratings.drSettings, base: Number(e.target.value) } })}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="log">
          <Form.Label>ELO LOG:</Form.Label>
          <Form.Control type="number" value={ratings.drSettings?.log ?? 10} onChange={(e) =>
            setRatings({ ...ratings, drSettings: { ...ratings.drSettings, log: Number(e.target.value) } })}
          />
        </Form.Group>
      </Col>
    </Row>
    </>
  )
}

export default RatingsComponent;