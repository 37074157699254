import React from 'react';
import TagEdit from './TagEdit';
import { Tag } from '../types/InstalledContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faEdit, faInfo, faClose } from '@fortawesome/free-solid-svg-icons'

interface TagsEditProps {
  tags: Tag[];
  hasHeader?: boolean;
  handleTagChange: (tagIndex: number, field: keyof Tag, value: string) => void;
  handleTagDelete: (tagIndex: number) => void;
  handleTagAdd: () => void;
}

const TagsEdit = ({ tags, hasHeader, handleTagChange, handleTagDelete, handleTagAdd }: TagsEditProps) => {
  return (
    <div >
      <div className="row">
        {hasHeader && (
          <div className="col-6">
            <h4>Tags</h4>
          </div>
        )}
      </div>
      {tags && tags.map((tag, index) => (
        <div className="row" key={index}>
          <TagEdit
            tag={tag}
            handleTagChange={(field, value) => handleTagChange(index, field, value)}
          />
          <div className="col-1 d-flex align-content-center flex-wrap">
            <label> </label>
            <button type="button" style={{marginTop: "13px"}} onClick={() => handleTagDelete(index)} className="btn btn-danger"><FontAwesomeIcon icon={faTrash}/></button>
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
            <button type="button" onClick={handleTagAdd} className="btn btn-primary mb-2">Add Tag</button>
        </div>
      </div>
    </div>
  );
};

export default TagsEdit;
