import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col, Tab } from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import UsernameReservationListView from './UsernameReservationListView';
import { UsernameReservation } from '../types/User';
import UsernameReservationServiceClient from '../services/username-reservation-service.client';
import NewReservationModal from './NewReservationModal';
import { BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs';

const UsernameReservationView = () => {

    const usernameReservationsClient = new UsernameReservationServiceClient();

    const [showNewReservationModal, setShowNewReservationModal] = useState<boolean>(false);
    const [reservations, setReservations] = useState<Array<UsernameReservation>>([]);

    const [page, setPage] = useState<number>(0);

    const nextPage = () => {
        setPage(page + 1);
    }

    const prevPage = () => {
        setPage(page - 1);
    }

    const pagedReservations = () => {
        return reservations?.slice(page * 15, (page * 15) + 15);
    }

    useEffect(() => {
        refreshReservations();
    }, []);

    const refreshReservations = () => {
        usernameReservationsClient.getAll().then((reservations) => {
            const alphabeticallyOrdered = reservations?.usernameReservations.sort((a, b) => a.username.localeCompare(b.username));
            setReservations(alphabeticallyOrdered);
        }).catch((error) => {
            console.error(error);
        });
    }

    return (
        <>
            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row>
                    <Col>
                        <h3>Username Reservations</h3>
                    </Col>
                </Row>
                <Row className="justify-content-end">
                    <Col xs="auto" className="justify-content-end">
                        <Button className="justify-content-end" variant="success" onClick={() => setShowNewReservationModal(true)}>Add</Button>
                    </Col>
                </Row>
                <Row>
                    {
                        reservations == void(0) || reservations?.length === 0 ?
                            <Col>
                                <h5>No username reservations configured</h5>
                            </Col>
                            :
                            <Col>
                                <UsernameReservationListView
                                    usernameReservations={pagedReservations()}
                                    requestRefresh={refreshReservations}
                                />
                            </Col>
                    }
                </Row>
                <Row>
                    <Navbar bg="dark" expand="lg" className="protest-search-bar">
                        <Container fluid>
                        <Navbar.Collapse id="navbarScroll">
                            <Button variant="outline-secondary"
                            hidden={page === 0}
                            onClick={prevPage} style={{ height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
                            <BsCaretLeftFill size="1em" color="lightgrey" />
                            </Button>
                        </Navbar.Collapse>
                        <Navbar.Collapse id="navbarScroll">
                            <span style={{ fontWeight: "bold" }}>Page {page}:&nbsp;&nbsp;</span>
                            <span style={{ fontWeight: "lighter" }}> {(page * 15) + 1} ... {(page * 15) + pagedReservations()?.length} / {reservations?.length}</span>
                        </Navbar.Collapse>
                        <Button variant="outline-secondary"
                            hidden={reservations == void(0) || reservations?.length < 15 || (page * 15) + pagedReservations()?.length === reservations?.length}
                            onClick={nextPage} style={{ height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
                            <BsCaretRightFill size="1em" color="lightgrey" />
                        </Button>
                        </Container>
                    </Navbar>
                </Row>
            </Container>
            <NewReservationModal
                usernameReservations={reservations}
                show={showNewReservationModal}
                onCancel={() => setShowNewReservationModal(false)}
                onConfirm={() => {
                    setShowNewReservationModal(false);
                    refreshReservations();
                }}
            />
        </>
    );
};

export default withAuthentication(UsernameReservationView);
