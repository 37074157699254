import "../../css/dark-mode.css";

import React, { useState } from 'react';
import { Modal, Button, Card, ListGroup, Col, Form, Row } from 'react-bootstrap';
import CarSelection from "./CarSelection";
import TrackSelection from "./TrackSelection";
import ImageSelection from './ImageSelection';
import { multiFormationLap } from '../../types/event/DailyRaceConfiguration';
import { DailyRacesContent } from '../../types/event/DailyRaceContent';
import { Item } from '../../types/event/Content';
import SessionModal from './SessionModal';
import { Session } from '../../types/event/Session';

interface ContentModalProps {
  config: DailyRacesContent[];
  game: string;
  setConfig: (config: DailyRacesContent[]) => void;
}

const ContentModal: React.FC<ContentModalProps> = ({ config, game, setConfig }) => {
  const [modalShow, setModalShow] = useState(false);
  const [content, setContent] = useState<DailyRacesContent>(new DailyRacesContent());
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const openModal = (index: number) => {
    setContent(config[index]);
    setCurrentIndex(index);
    setModalShow(true);
  };

  const closeModal = () => {
    if (content && currentIndex !== null) {
      const newContent = [...config];
      newContent[currentIndex] = content;
      setConfig(newContent);
    }
    setModalShow(false);
  };

  const handleCardImageSelected = (selected: string) => {
    if (content) {
      const newContent = { ...content, cardImage: selected };
      setContent(newContent);
    }
  }

  const handleHeroImageSelected = (selected: string) => {
    if (content) {
      const newContent = { ...content, heroImage: selected };
      setContent(newContent);
    }
  }

  const handleSmallImageSelected = (selected: string) => {
    if (content) {
      const newContent = { ...content, smallImage: selected };
      setContent(newContent);
    }
  }

  const handleAddSession = () => {
    if (content && content.sessions) {
      const newContent = { ...content, sessions: [...content.sessions, new Session()] };
      setContent(newContent);
    } else if (content) {
      const newContent = { ...content, sessions: [new Session()] };
      setContent(newContent);
    }
  }

  return (
    <>
        {config.map((content, index) => (
          <Col sm={4}>
            <Card className="mb-3">
              <Card.Header as="h5">{content.title ? content.title : `Content ${index + 1}`}</Card.Header>
              <Card.Subtitle>Selected Cars</Card.Subtitle>
              <Card.Body>
                <ListGroup variant="dark">
                  {content.cars.length > 0 ? (
                    <>
                      {content.cars.map((car, i) => <ListGroup.Item variant="dark" key={i}>{car?.friendly ?? car?.name}</ListGroup.Item>)}
                    </>
                  ) : (
                    "-"
                  )}
                </ListGroup>
                <Card.Subtitle className="text-dark">Selected Track</Card.Subtitle>
                <div className='card-text' style={{flex: 1}}>
                  {content.track?.friendly ?? content.track?.name}
                  - 
                  {content.track?.subItems[0]?.friendly ?? content.track?.subItems[0]?.name}
                </div>

                <div className="d-flex justify-content-end align-content-end flex-wrap">
                  <Button variant="primary" onClick={() => openModal(index)}>Edit</Button>
                  <Button variant="secondary btn-danger" onClick={() => setConfig(config.filter((_, i) => i !== index))} className="ml-2">Remove</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}

      <Modal show={modalShow} onHide={closeModal} className="modal-dark">
        <Modal.Header closeButton className='modal-header-dark'>
          <Modal.Title>Edit Content</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-dark'>
          <Row>
            <Col>
              <label htmlFor="title">Title:</label>
              <input id="title" name="title" type="text" value={content?.title} 
                onChange={(e) => {
                  if (content) {
                    setContent({ ...content, title: e.target.value });
                  }
                }} 
                className="form-control" />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="multiFormationLap">
                <Form.Label>Multi Formation Lap:</Form.Label>
                <Form.Control as="select" onChange={(e) => setContent({...content, multiFormationLap: Number(e.target.value)})} className="dark-select">
                  <option value="">Please Select</option>
                  {multiFormationLap.map((option) => (
                    <option value={option.key} selected={content?.multiFormationLap.toString() === option.key}>{option.value}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col>
            <label htmlFor="vehicleFilter">Vehicle Filter:</label>
              <input id="vehicleFilter" name="vehicleFilter" type="text" value={content?.vehicleFilter} 
                onChange={(e) => {
                  if (content) {
                    setContent({ ...content, vehicleFilter: e.target.value });
                  }
                }} 
                className="form-control" />
            </Col>

            <Col>
              <div className='col d-flex flex-column justify-content-start'>
                <label htmlFor="fixedSetup" className="mt-3">Fixed Setup:</label>
                <input id="title" name="fixedSetup" style={{alignSelf: "start"}} type="checkbox" checked={content?.fixedSetup} 
                  onChange={(e) => setContent({ ...content, fixedSetup: e.target.checked }) }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col><ImageSelection image={content?.smallImage} filter="small/" title="Small Card Image" onImageSelected={handleSmallImageSelected} /></Col>
            <Col><ImageSelection image={content?.cardImage} filter="card/" title="Large Card Image" onImageSelected={handleCardImageSelected} /></Col>
            <Col><ImageSelection image={content?.heroImage} filter="hero/" title="Hero Image" onImageSelected={handleHeroImageSelected} /></Col>
          </Row>

          <Row>
            <h4 style={{marginTop: "1rem"}}>Content</h4>
            <Col><CarSelection game={game} cars={content?.cars || []} onSelected={(cars) => setContent({...content, cars: cars})} /></Col>
            <Col><TrackSelection game={game} track={content?.track || new Item()} layout={content?.track?.subItems[0]} onSelected={(track) => setContent({...content, track: track})} /></Col>
          </Row>

          <Row>
            <h4 style={{marginTop: "1rem"}}>Sessions</h4>

            {content?.sessions?.map((session, index) => (
              <SessionModal session={session} index={index} sessions={content.sessions} setSessions={(sessions) => setContent({...content, sessions: sessions})} />
            ))}

            <Col>
              <Button variant="primary" onClick={() => handleAddSession()}>Add Session</Button>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Cancel</Button>
          <Button variant="primary" onClick={closeModal}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContentModal;
