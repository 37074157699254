import React, { useRef, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import ProtestServiceClient from '../services/protest-service.client';
import { AmendUserSafetyRatingRequest, IProtestSearchParameters, Protest, ProtestOutcome, ProtestStatus, ProtestType, SuspendUserRequest, StrikeRequest } from '../types/Protest';
import {Driver, PenaltiesApplied, Suspension, SafetyRankAdjustment, Strike} from '../types/event/Driver'

import { BsDownload } from 'react-icons/bs';
import RaceManagementClient from '../services/race-manaement.client';
import { S3Object } from '../types/S3Object';

interface IEvidenceModalComponentProps {
  show: boolean;
  setShowModal: (show: boolean) => void;
  protest: Protest;
}

const EvidenceModalComponent: React.FC<IEvidenceModalComponentProps> = (props) => {
  const rm = useRef<RaceManagementClient>(new RaceManagementClient());

  const [evidence, setEvidence] = useState<S3Object[]>([]);
  
  // on mount
  React.useEffect(() => {
    console.log("view evident", props.protest.eventType === "" ? "daily" : props.protest.eventType, props.protest.seriesId, props.protest.eventId, props.protest.split);

    rm.current.getS3BucketContent(process.env.REACT_APP_S3_RESULTS_BUCKET ?? "rf2-race-results", `${props.protest.eventType === "" ? "daily" : props.protest.eventType}/${props.protest.seriesId}/${props.protest.eventId}/${props.protest.split}`)
      .then((evidence) => {
        setEvidence(evidence);
      })
  }, [])

  const downloadItem = (file: S3Object) => {
    rm.current.downloadFromS3(process.env.REACT_APP_S3_RESULTS_BUCKET ?? "rf2-race-results", file.Key);
  }

  const createUrl = (file: S3Object) => {
    return `https://s3.console.aws.amazon.com/s3/buckets/${process.env.REACT_APP_S3_RESULTS_BUCKET}?region=${process.env.REACT_APP_AWS_REGION}&prefix=${file.Key}`;
  }

  return (
    <>
      <style>
      {`
          .protest-view-container {
              background-color: #222222;
              color: snow;
              height: 100% !important;
              padding: 2.5% !important;
          }

          .protest-view-text-input {
            background-color: #444444 !important;
            color: snow;
            border: 1px solid #333333 !important;
          }

          .protest-view-text-input:focus {
            color: snow !important;
          }

          .dropdown-menu[aria-labelledby = protest-view-ban-days-dropdown] {
            background-color: #bd2130 !important;
            color: snow !important;
          }

          .protest-view-ban-day-item {
            background-color: #bd2130 !important;
            color: snow !important;
          }
          `}
      </style>
      <div className="row">
        <div className="col-12">
          <Modal show={props?.show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

              <Modal.Header style={{backgroundColor: "#222222", borderColor: "#333333", color: "snow"}} closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                      <h4 style={{color: "snow"}}>Evidence</h4>
                  </Modal.Title>
              </Modal.Header>

              <Modal.Body style={{backgroundColor: "#222222"}}>
                  <div className="row">
                      <div className="col-12">
                        <ListGroup variant="flush">
                        {
                            Array.isArray(evidence) && evidence?.length > 0 ? evidence?.map((file, index) => (

                                <ListGroup.Item key={index} className="row evidence-list-item"
                                  style={{backgroundColor: "#222222", color: "snow", width: "100%"}}>
                                  <div className="row" style={{width: "100%"}}>
                                    <div className="col-2" style={{width: "fit-content"}}>
                                      <Button size="sm" variant="secondary" onClick={(e) => downloadItem(file)}>
                                        <BsDownload style={{marginRight: "5%",fontSize: "1em", width: "fit-content", display: "flex", alignItems: "center", strokeWidth: "0.75px"}}/>
                                      </Button>
                                    </div>
                                    <div className="col-10">
                                        <a href={createUrl(file)} target='_blank'>{S3Object.getfile(file)} ({S3Object.size(file)})</a>
                                    </div>
                                  </div>
                                </ListGroup.Item>

                            )) : <div style={{color: "snow"}}>No evidence found.</div>
                        }
                        </ListGroup>
                      </div>
                  </div>
              </Modal.Body>

              <Modal.Footer style={{backgroundColor: "#222222", borderColor: "#333333"}}>
                  <Button onClick={() => props?.setShowModal(false)}>Close</Button>
              </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default withAuthentication(EvidenceModalComponent);
