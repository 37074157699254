import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

import {Driver} from '../../types/event/Driver'

import DriverAccordionViewItemComponent from './DriverAccordionViewItemComponent';

export interface DriverAccordionViewComponentProps {
    drivers: Driver[];
    game: string;
    requestRefresh?: (indices: Array<number>) => void;
}

const DriverAccordionViewComponent = (props: DriverAccordionViewComponentProps) => {

    return (
        <Accordion flush defaultActiveKey={props?.drivers?.length === 1 ? ["0"] : []} alwaysOpen>
            {props?.drivers?.map((driver, index) => 
                <DriverAccordionViewItemComponent
                    driver={driver}
                    game={props?.game}
                    eventKey={index.toString()}
                    key={index}
                    requestRefresh={props?.requestRefresh}
                />
            )}
        </Accordion>
    );
}

export default DriverAccordionViewComponent;