import React, { useState, ChangeEvent } from 'react';
import { Modal, Button, Col, Card, Table, Row } from 'react-bootstrap';
import { Session } from "../../types/event/Session";

interface SessionModalProps {
  session: Session;
  index: number;
  setSessions: (sessions: Session[]) => void;
  sessions: Session[];
}

const SessionModal: React.FC<SessionModalProps> = ({ session, index, setSessions, sessions }) => {
  const [modalShow, setModalShow] = useState(false);

  const waterDepth = [[-0.01, "natural"], [0, "Dry"], [0.05, "Damp"], [0.15, "Slightly Wet"], [0.3, "Wet"], [0.6, "Very Wet"], [1.0, "Extremely Wet"]]
  const sessionTypes = ["Practice", "Qualifying", "Warmup", "Race"];
  const realRoadTypes = ["natural", "green", "preset:LIGHT.RRBIN", "preset:MEDIUM.RRBIN", "preset:HEAVY.RRBIN", "preset:SATURATED.RRBIN"];
  const skyOptions = [["0", "Clear"], ["1", "Light Clouds"], ["2", "Partially Cloudy"], ["3", "Mostly Cloudy"], ["4", "Overcast"], ["5", "Drizzle"], ["6", "Heavy Drizzle"], ["7", "Light Rain"], ["8", "Rain"], ["9", "Heavy Rain"], ["10", "Storm"]];
  //const windDirections = ["North", "North East", "East", "South East", "South", "South West", "West", "North West"];

  const handleSessionChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    const newSessions = [...sessions];
    if (id === "waterDepth" || id === "loadTempsFromFile" || id === "laps") {
      newSessions[index] = { ...newSessions[index], [id]: parseInt(value) };
    } else {
      newSessions[index] = { ...newSessions[index], [id]: value };
    }

    setSessions(newSessions);
  };

  const rainChance = (sky: string): string => {
    if (sky === "10") {
      return "100";
    }
    if (sky >= "5") {
      return "75";
    }
    return "0";
  }

  const handleWeatherChange = (weatherIndex: number, e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    const newSessions = [...sessions];

    if (id === 'sky') {
      newSessions[index].weather[weatherIndex] = { ...newSessions[index].weather[weatherIndex], [id]: value, rainChange: rainChance(value) };
    } else {
      newSessions[index].weather[weatherIndex] = { ...newSessions[index].weather[weatherIndex], [id]: value };
    }
    
    setSessions(newSessions);
  };

  const deleteSession = () => {
    const newSessions = sessions.filter((_, i) => i !== index);
    setSessions(newSessions);
    setModalShow(false);
  };

  return (
    <>
      <Col sm={4} key={index}>
        <Card className="mb-3" bg="dark" key={index}>
          <Card.Header as="h5">{session.type}</Card.Header>
          <Card.Body>
            <Card.Text>
              Duration: {session.duration}<br/>
              Time of Day: {session.timeOfDay}<br/>
              Real Road: {session.realRoad}<br/>
            </Card.Text>
            
            <div className="d-flex justify-content-end">
              <Button variant="primary" onClick={() => setModalShow(true)}>Edit</Button>
              <Button variant="danger" className="ml-2" onClick={deleteSession}>Remove</Button>
            </div>
          </Card.Body>
        </Card>

        
        <Modal className="session-modal" show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header className="session-modal-header" closeButton>
            <Modal.Title>Edit Session</Modal.Title>
          </Modal.Header>
          <Modal.Body className="session-modal-body">
            <div className="row">
              <div className="col">
                <label htmlFor="type">Type:</label>
                <select id="type" value={session.type} onChange={handleSessionChange} className="form-control">
                  {sessionTypes.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))}
                </select>
              </div>

              <div className="col">
                <label htmlFor="duration">Duration:</label>
                <input id="duration" type="text" value={session.duration} onChange={handleSessionChange} className="form-control" />
              </div>

              <div className="col">
                <label htmlFor="laps">Laps:</label>
                <input id="laps" type="number" value={session.laps?.toString() ?? 0} onChange={handleSessionChange} className="form-control" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="waitTime">Wait Time:</label>
                <input id="waitTime" type="text" value={session.waitTime} onChange={handleSessionChange} className="form-control" />
              </div>
              <div className="col">
                <label htmlFor="timeOfDay">Time of Day:</label>
                <input id="timeOfDay" type="text" value={session.timeOfDay} onChange={handleSessionChange} className="form-control" />
              </div>
              <div className="col">
                <label htmlFor="realRoad">Real Road:</label>
                <select id="realRoad" value={session.realRoad} onChange={handleSessionChange} className="form-control">
                  {realRoadTypes.map((road, index) => (
                    <option key={index} value={road} selected={session.realRoad === road}>{road}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor="waterDepth">Water Depth:</label>
                <select id="realRoad" value={session.realRoad} onChange={handleSessionChange} className="form-control">
                  {waterDepth.map(([value, label]) => (
                    <option key={index} value={value} selected={session.realRoad === value}>{label}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label htmlFor="loadTempsFromFile">Load Temps From File:</label>
                <input id="loadTempsFromFile" type="number" value={session.loadTempsFromFile} onChange={handleSessionChange} className="form-control" />
              </div>
              <div className="col"></div>
            </div>

            <br />
            <h3>Weather</h3>
            <hr />

            <Table striped hover>
              <thead className="session-modal-weather-table-header">
                <tr>
                  <th>Start Time</th>
                  <th>Duration</th>
                  <th>Sky</th>
                  <th>Rain Change</th>
                  <th>Temperature</th>
                </tr>
              </thead>
              <tbody>
                {session?.weather?.map((weather, index) => (
                  <tr key={index}>
                    <td className="snow">{weather.startTime}</td>
                    <td>
                      <input id="Duration" className="dark-input" type="text" value={weather?.Duration} onChange={(e) => handleWeatherChange(index, e)} />
                    </td>
                    <td>
                      <select className="dark-select" id="sky" value={weather.sky.toString()} onChange={(e) => handleWeatherChange(index, e)}>
                        {skyOptions.map(([value, label]) => (
                          <option key={value} value={value}>{label.toString()}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input id="rainChange" className="dark-input" type="text" value={weather.rainChange} onChange={(e) => handleWeatherChange(index, e)} />
                    </td>
                    <td>
                      <input id="temperature" className="dark-input" type="text" value={weather.temperature} onChange={(e) => handleWeatherChange(index, e)} />
                    </td>
                    {/*
                    <td>
                      <input id="humidity" type="text" value={weather.humidity} onChange={(e) => handleWeatherChange(index, e)} />
                    </td>
                    <td>
                      <input id="windSpeed" type="text" value={weather.windSpeed} onChange={(e) => handleWeatherChange(index, e)} />
                    </td>
                    <td>
                      <select id="windDirection" value={weather.windDirection} onChange={(e) => handleWeatherChange(index, e)}>
                        {windDirections.map((direction, index) => (
                          <option key={index} value={direction}>{direction}</option>
                        ))}
                      </select>
                    </td>
                    */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="session-modal-footer">
              <Row style={{ width: "100%" }}>
                  <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                      <Button variant="danger" onClick={() => setModalShow(false)}>Close</Button>
                  </Col>
                  <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{ display: "flex", justifyContent: "flex-end" }} >
                      <Button variant="success" onClick={() => setModalShow(false)}>Save Changes</Button>
                  </Col>
              </Row>
          </Modal.Footer>
        </Modal>
      </Col>
    </>
  );
}

export default SessionModal;
