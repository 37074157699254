import OAuthClient from "../../services/oauth.client";
import { CoopSession } from "./coop";
import { CoopEvent } from "./coopEvent";

class CoopClient {
  url = process.env.REACT_APP_RM_URL;
  oauth: OAuthClient;

  constructor() {
    this.oauth = new OAuthClient();
  }

  async sessions(driverId: string, page: number, take: number): Promise<CoopSession[]> {
    const response = await this.oauth.fetch(`${this.url}/coop/list/${driverId}?page=${page}&take=${take}`);
    return await response?.json() as CoopSession[];
  }

  async session(id: string): Promise<CoopSession> {
    const response = await this.oauth.fetch(`${this.url}/coop/${id}/inflate`);
    return await response?.json() as CoopSession;
  }

  async invites(driverId: string): Promise<CoopSession[]> {
    const response = await this.oauth.fetch(`${this.url}/coop/invites/${driverId}`);
    return await response?.json() as CoopSession[];
  }

  async historic(driverId: string, page: number, take: number): Promise<CoopSession[]> {
    const response = await this.oauth.fetch(`${this.url}/coop/historic/${driverId}`);
    return await response?.json() as CoopSession[];
  }

  async events(): Promise<CoopEvent[]> {
    const response = await this.oauth.fetch(`${this.url}/coop/event/active`);
    return await response?.json() as CoopEvent[];
  }
  
  async get(id: string): Promise<CoopEvent> {
    const response = await this.oauth.fetch(`${this.url}/coop/event/${id}`);
    return await response?.json() as CoopEvent;
  }

  async createEvent(event: CoopEvent) {
    return await this.oauth.fetch(`${this.url}/coop/event`, "PUT", JSON.stringify(event)) as Response;
  }

  async deleteEvent(id: string) {
    return await this.oauth.fetch(`${this.url}/coop/event/${id}`, "DELETE") as Response;
  }

  async updateEvent(id: string, event: CoopEvent) {
    return await this.oauth.fetch(`${this.url}/coop/event/${id}`, "POST", JSON.stringify(event)) as Response;
  }
}

export default CoopClient;
