import moment from "moment";
import { Event } from "../types/event/Event";
import { RaceControlStatusResponse } from "../types/RaceControlStatuResponse";
import OAuthClient from "./oauth.client";
import { ChampionshipsByIdResponse } from "../championships/responses/ChampionshipsByIdResponse";
import { EventSummary } from "../types/EventSummary";
import { Championship } from "../championships/models/Championship";

export class RaceControlClient {
  url = process.env.REACT_APP_RC_URL;
  oauth: OAuthClient;

  constructor() {
    this.oauth = new OAuthClient();
  }

  async events(signal: AbortSignal): Promise<EventSummary[]> {
    const response = await this.oauth.fetch(`${this.url}/ui/events`, "GET", "", signal);
    if (!response) {
      throw new Error('Failed to fetch events');
    }

    return await response.json() as EventSummary[];
  }

  async allEvents(game: string, type: string, tier: string, signal?: AbortSignal): Promise<EventSummary[]> {
    const response = await this.oauth.fetch(`${this.url}/ui/events/all?game=${game}&type=${type}&tier=${tier}`, "GET", "", signal);
    if (!response) {
      throw new Error('Failed to fetch events');
    }

    return await response.json() as EventSummary[];
  }

  async status(): Promise<RaceControlStatusResponse> {
    const response = await this.oauth.fetch(`${this.url}/status`);
    if (!response) {
      throw new Error('Failed to fetch status');
    }
    return await response.json() as RaceControlStatusResponse;
  }

  async getEvent(eventType: string | undefined, eventId: string | undefined): Promise<Event> {
    const response = await this.oauth.fetch(`${this.url}/event/${eventType}/${eventId}/unsafe`);
    if (!response) {
      throw new Error('Failed to fetch event');
    }
    return await response.json() as Event;
  }

  async getNextMaintenance(): Promise<moment.Moment> {
    const response = await this.oauth.fetch(`${this.url}/maintenance/next`);
    if (!response) {
      throw new Error('Failed to fetch next maintenance');
    } else if (response.status === 404) {
      return moment().add(365, 'days');
    }

    const jsn = await response.json();
    return moment(jsn.starts);
  }

  async getActiveChampionships(game: string, signal?: AbortSignal): Promise<Championship[]> {
    const response = await this.oauth.fetch(`${this.url}/championships/active?game=${game}`, "GET", "", signal)
      .then((response) => {
        if (!response) {
          throw new Error('Failed to fetch active championships');
        }
        return response.json();
      });

    const champs = response as ChampionshipsByIdResponse;
    return Object.values(champs.championships)
  }

  async getActiveChampionship(id: string): Promise<Championship> {
    return await this.oauth.fetch(`${this.url}/championships/${id}`)
      .then(async (response) => {
        if (!response) {
          throw new Error('Failed to fetch active championships');
        }
        const json = await response.json();
        return json as Championship;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }
}
