import React from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { HttpProtectedRole, HttpProtectedRouteViewModel, HttpProtectedUser, HttpProtectedUserViewModel } from '../../types/Security';
import withAuthentication from '../../withAuthentication';

import { FaCheck, FaUser, FaUserShield, FaUserSlash } from "react-icons/fa";
import HttpProtectedUserViewModal from './RolesViewModal';

interface IRolesListViewListViewItemProps {
    role: HttpProtectedRole;
    allUsers: Array<HttpProtectedUserViewModel>;
    allRoles: Array<HttpProtectedRole>;
    routes: Array<HttpProtectedRouteViewModel>;
    routesByService: Map<string, Array<HttpProtectedRouteViewModel>>;
    requestRefresh?: (indices: Array<number>) => void;
}

const RolesListViewItem: React.FC<IRolesListViewListViewItemProps> = (props) => {

    const [showModal, setShowModal] = React.useState(false);

    return (
        <>
            <style>
                {`
                .user-list-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .user-list-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid white
                }

                .user-list-item-row {
                    width: 100%;
                    height: 100%;
                }

                .user-list-item-icon-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .user-list-item-info-col {
                }

                .user-list-item-name-col {
                    font-weight: bold;
                    font-size: 1.0em;
                }

                .user-list-item-email-col {
                    font-weight: light;
                    font-size: 0.8em;
                    color: rgba(255, 255, 255, 0.5);
                }
            `}
            </style>
            <ListGroup.Item key={props?.role?.name}
                            as="li"
                            className="d-flex justify-content-between align-items-start user-list-item"
                            onClick={(e: React.MouseEvent<Element, MouseEvent>) => setShowModal(true)}>
                <Row className="user-list-item-row">
                    <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="user-list-item-icon-col">
                        <Row className="user-list-item-icon-col">
                            {
                                props?.role == void(0) ?
                                    <FaUserSlash size="1.5em" color='gray'/>
                                :
                                props?.role?.name === "SuperAdmin" ?
                                    <FaUserShield size="1.5em" color='red'/>
                                :
                                    <FaUser size="1.5em" color={props?.role?.colour}/>
                            }
                        </Row>
                    </Col>
                    <Col sm="8" md="8" lg="8" xl="8" xxl="8" className="user-list-item-info-col">
                        <Row>
                            <Col sm="12" md="12" lg="12" xl="12" xxl="12">
                                <Row>
                                    <Col sm="6" md="6" lg="6" xl="6" xxl="6" className="user-list-item-name-col">
                                        {props?.role?.name}
                                    </Col>
                                    <Col sm="6" md="6" lg="6" xl="6" xxl="6" className="user-list-item-name-col">
                                        {
                                            props?.role?.isDefault &&
                                            <FaCheck style={{marginLeft: "2rem"}}/>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12" xl="12" xxl="12" className="user-list-item-email-col">
                                <Row>
                                    <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ListGroup.Item>
            <HttpProtectedUserViewModal
                show={showModal}
                setShowModal={setShowModal}
                role={props?.role}
                allUsers={props?.allUsers}
                allRoles={props?.allRoles}
                routes={props?.routes}
                routesByService={props?.routesByService}
                requestRefresh={props?.requestRefresh}
            />
        </>
    );
};

export default withAuthentication(RolesListViewItem);