import React from 'react';
import { SubItem } from '../types/event/Content';

interface SubItemEditProps {
  subItem: SubItem; 
  handleChange: (field: keyof SubItem, value: string) => void;
}

const SubItemEdit = ({ subItem, handleChange }: SubItemEditProps) => {
  return (
    <div className="row sub-items">
      <div className="col-3">
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={subItem.name}
          onChange={(e) => handleChange('name', e.target.value)}
          className="form-control"
        />
      </div>
      <div className="col-3">
        <label>Friendly:</label>
        <input
          type="text"
          name="friendly"
          value={subItem.friendly}
          onChange={(e) => handleChange('friendly', e.target.value)}
          className="form-control"
        />
      </div>
      <div className="col-3">
        <label>Settings:</label>
        <input
          type="text"
          name="settings"
          value={subItem.settings}
          onChange={(e) => handleChange('settings', e.target.value)}
          className="form-control"
        />
      </div>
      <div className="col-3">
        <label>Scene:</label>
        <input
          type="text"
          name="settings"
          value={subItem.scn}
          onChange={(e) => handleChange('scn', e.target.value)}
          className="form-control"
        />
      </div>
    </div>
  );
};

export default SubItemEdit;
