import React from "react";
import withAuthentication from "../withAuthentication";
import RaceManagementClient from "../services/race-manaement.client";
import { Col, Form, Row } from "react-bootstrap";
import { dailyTiers, specialEventTiers } from "../types/event/DailyRaceConfiguration";
import { Event } from "../types/event/Event";
import moment from "moment";
import { eventTypes } from "../types/event/EventResults";

const ListEvents = () => {
  const [events, setEvents] = React.useState<Event[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  

  const [game, setGame] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [eventType, setEventType] = React.useState<string>("");
  const [tier, setTier] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);

  const nameTimeout = React.useRef<any>(null);
  const abort = React.useRef<AbortController | null>(null);

  const tiers = [...dailyTiers, ...specialEventTiers];

  const rm = React.useRef<RaceManagementClient>(new RaceManagementClient());
  const fetch = async (game: string, page: number, name: string, eventType: string, tier: string) => {
    abort.current?.abort();
    setLoading(true);
    
    abort.current = new AbortController();
    await rm.current.getEvents(game, page, 15, name, eventType, tier, abort.current.signal)
      .then((events: Event[]) => {
        setEvents(events);
        setLoading(false);
        setPage(page);
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }

  const view = async (e: any, event: Event) => {
    e.preventDefault();
    window.location.href = `/event/${event.type}/${event.id}`;
  }

  React.useEffect(() => {
    console.log("Initial event list");
    abort.current?.abort();
    fetch(game, page, name, eventType, tier);

    return () => {
      console.log("cleaning up event list");
      abort.current?.abort();
    }
  }, []);
  
  React.useEffect(() => { 
    console.info("[page] triggered", page);
    fetch(game, page, name, eventType, tier)
  }, [page]);

  React.useEffect(() => { 
    console.info("[eventType, tier, game] triggered", eventType, tier, game);
    fetch(game, 1, name, eventType, tier)
  }, [eventType, tier, game]);

  React.useEffect(() => {
    if (nameTimeout.current) clearTimeout(nameTimeout.current);
    nameTimeout.current = setTimeout(() => {
      if (page === 1)
        fetch(game, 1, name, eventType, tier)
      else {
        setPage(1);
      }
    }, 500);
  }, [name]);

  return (
    <div className='container'>
      <Row>
        <Col>
          <Form.Group controlId="tierName">
            <Form.Label>Game:</Form.Label>
            <Form.Control as="select" value={game} onChange={(e) => setGame(e.target.value)} style={{marginBottom: "1rem"}}>
              <option value="">Please Select</option> 
              <option value="rf2">RF2</option>
              <option value="lmu">LMU</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="tierName">
            <Form.Label>Name:</Form.Label>
            <input type="text" name="type" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="tierName">
            <Form.Label>Type:</Form.Label>
            <Form.Control as="select" value={eventType} onChange={(e) => setEventType(e.target.value)} style={{marginBottom: "1rem"}}>
              <option value="">Please Select</option> 
              {eventTypes.map((t) => (
                <option value={t} selected={eventType === t}>{t}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="tierName">
            <Form.Label>Tier:</Form.Label>
            <Form.Control as="select" value={tier} onChange={(e) => setTier(e.target.value)}>
              <option value="">Please Select</option> 
              {tiers.map((t) => (
                <option value={t} selected={tier === t}>{t}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col>
          <button className="btn btn-secondary mr2" style={{marginRight: "1rem", marginTop: "2rem"}} onClick={() => setPage(page - 1)} disabled={page === 1}>Previous</button>
          <span style={{marginTop: "2rem"}}>{page}</span>
          <button className="btn btn-secondary mr2" style={{marginLeft: "1rem", marginTop: "2rem"}} onClick={() => setPage(page + 1)} disabled={loading && events?.length < 20}>Next</button>
        </Col>

        <Col>
          <button className="btn btn-primary" style={{marginTop: "2rem"}} onClick={() => fetch(game, page, name, eventType, tier)}>Refresh</button>
        </Col>
      </Row>

      <Row>
        <div>
          {loading && <p>Loading...</p>}
          {!loading && (
            <table className="table table-dark">
              <thead>
                <tr>
                  <th scope="col">Game</th>
                  <th scope="col">ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Title</th>
                  <th scope="col">Status</th>
                  <th scope="col">Registrations</th>
                  <th scope="col">Splits</th>
                </tr>
              </thead>
              <tbody>
              {!events 
                ? (<p>No Events Found</p>) 
                : events.map((event: Event) => (
                  <tr key={event.id} onClick={(e) => view(e, event)}>
                    <td scope="row">{event.configuration.game}</td>
                    <td>{event.id}</td>
                    <td>{moment(event.configuration.starts).format("DD/MM HH:mm:ss")}</td>
                    <td>{event.configuration.title}</td>
                    <td>{event.status}</td>
                    <td>{event.registrations?.length}</td>
                    <td>{event.splits?.length}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Row>
    </div>
  )
}

export default withAuthentication(ListEvents);
