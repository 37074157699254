import React, { useRef, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Button, Col, Form, ListGroup, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { FaMinus, FaPlus, FaUsers } from 'react-icons/fa';
import { Activation, ActivationAddSeriesRequest, ActivationCreateRequest, ActivationRemoveSeriesRequest, generateToken } from '../../types/Activations';
import DarkDatepickerComponent from '../../components/generic/DarkDatepickerComponent';
import moment from 'moment';
import ActivationsServiceClient from '../../services/activations-service.client';

interface IHttpProtectedUserViewModalProps {
    show: boolean;
    setShowModal: (show: boolean) => void;
    requestRefresh?: (indices: Array<number>) => void;
}

const CreateActivationsViewModal: React.FC<IHttpProtectedUserViewModalProps> = (props) => {

    const activationServiceClient = new ActivationsServiceClient();

    const [activationPartner, setActivationPartner] = useState<string>("");
    const [activationToken, setActivationToken] = useState<string>("");
    const [activationExpires, setActivationExpires] = useState<Date>(new Date());
    const [activationError, setActivationError] = useState<string>("");
    React.useEffect(() => {
        if (!moment(activationExpires).isAfter(moment(Date.now()))) {
            setActivationError("Activation expiration must be in the future");
        }
        else {
            setActivationError("");
        }
    }, [activationExpires]); 

    const [newSeriesError, setNewSeriesError] = useState<string>("");
    const [activationSeries, setActivationSeries] = useState<Array<string>>([]);
    const [newSeriesId, setNewSeriesId] = useState<string>("");

    const generateNewToken = () => {

        const newToken = generateToken();

        setActivationToken(newToken);
    }

    const addSeries = (seriesId: string) => {
        if (seriesId === "") {
            setNewSeriesError("SeriesID cannot be empty");
            return;
        } else if (activationSeries.includes(seriesId)) {
            setNewSeriesError("SeriesID already exists");
            return;
        }
        setNewSeriesError("");
        setActivationSeries([...activationSeries, seriesId])
        setNewSeriesId("");
    }

    const removeSeries = (seriesId: string) => {
        setActivationSeries([...activationSeries.filter((id) => id !== seriesId)])
    }

    const [saveError, setSaveError] = useState<string>("");
    const onSave = () => {
        const creationRequest: ActivationCreateRequest = {
            activation: {
                id: "",
                partner: activationPartner,
                token: activationToken,
                expires: moment(activationExpires).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                series: activationSeries
            }
        };

        activationServiceClient.create(creationRequest).then(onClose).catch((err) => setSaveError(err?.message));
    }

    const onClose = () => {
        setActivationPartner("");
        setActivationToken("");
        setActivationExpires(new Date());
        setActivationSeries([]);
        setNewSeriesId("");
        setSaveError("");
        setNewSeriesError("");
        setActivationError("");
        props?.setShowModal(false);
        props?.requestRefresh?.([]);
    }

    return (
        <>
            <style>
                {`
                .activation-view-modal-body {
                    color: snow;
                    background-color: #222222;
                }

                .activation-select-list-group-header-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2em;
                }

                .activation-select-list-group-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .activation-select-list-group-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid #333333;
                }

                .activation-select-list-group-item-row {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                }

                .activation-select-list-group-item-icon-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .activation-select-btn-minus {
                    visibility: hidden;
                    border: 0;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }

                .activation-select-btn-plus {
                    border: 0;
                    background-color: #0d6efd !important;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }

                .activation-select-list-group-item:hover > * > * > .activation-select-btn-minus {
                    visibility: visible;
                    background-color: red;
                    border: 0;
                }

                .activation-select-btn-minus::focus, .activation-select-btn-plus::focus {
                    outline: none;
                }
                
                .activation-tab-style {
                    background-color: #222222;
                }
                
                .activation-tab-style .nav-link.active {
                    background-color: #222222;
                    color: snow;
                }
                
                .activation-tab-style .nav-link:hover {
                    background-color: #333333;
                    color: snow;
                }
            `}
            </style>
            <div className="row">
                <div className="col-12">
                    <Modal show={props?.show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

                        <Modal.Header style={{ backgroundColor: "#222222", borderColor: "#333333", color: "snow" }} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <h4 style={{ color: "snow" }}>New Activation</h4>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="activation-view-modal-body">
                            <Row>
                                <Tabs
                                    defaultActiveKey="identity-info"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 activation-tab-style"
                                >
                                    <Tab eventKey="identity-info" title="Activation Info" className="activation-tab-style">
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Form.Label>Partner</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Partner" onChange={(e) => setActivationPartner(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={10}>
                                                        <Form.Label>Token</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Token" value={activationToken} onChange={(e) => setActivationToken(e.target.value)} />
                                                    </Col>
                                                    <Col xs={2}>
                                                        <Button variant="primary" style={{ marginTop: "32px" }} onClick={generateNewToken}>New</Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Label>Expires</Form.Label>
                                                        <DarkDatepickerComponent
                                                            value={activationExpires}
                                                            onChange={(date: Date) => setActivationExpires(date)}
                                                            showTimeSelect={true}
                                                        />
                                                        {
                                                            activationError !== "" ?
                                                                <Form.Label style={{ color: "red" }}>{activationError}</Form.Label>
                                                                :
                                                                <Form.Label></Form.Label>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="activations" title="Whitelisted SeriesIDs">
                                        <Row>
                                            <Row>
                                                <Col>
                                                    <ListGroup>
                                                        {
                                                            activationSeries.map((seriesId, index) => {
                                                                return (
                                                                    <ListGroup.Item key={index} className="activation-select-list-group-item">
                                                                        <Row className="activation-select-list-group-item-row">
                                                                            <Col xs={11} className="activation-select-list-group-item-row">
                                                                                {seriesId}
                                                                            </Col>
                                                                            <Col xs={1} className="activation-select-list-group-item-icon-col">
                                                                                <Button variant="danger" className="activation-select-btn-minus" onClick={removeSeries.bind(this, seriesId)}>
                                                                                    <FaMinus />
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                );
                                                            })
                                                        }
                                                    </ListGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Label>New:</Form.Label>
                                                <Col xs={11}>
                                                    <Form.Control type="text" placeholder="Enter SeriesID" defaultValue={newSeriesId} value={newSeriesId} onChange={(e) => setNewSeriesId(e.target.value)} />
                                                </Col>

                                                <Col xs={1} className="">
                                                    <Button variant="primary" className="activation-select-btn-plus" onClick={addSeries.bind(this, newSeriesId)}>
                                                        <FaPlus />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row style={{ width: "100%" }}>
                                                <Col style={{ backgroundColor: "#222222", borderColor: "#333333", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                {
                                                    newSeriesError !== "" ?
                                                        <Form.Label style={{ color: "red" }}>{newSeriesError}</Form.Label>
                                                        :
                                                        <Form.Label></Form.Label>
                                                }   
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer style={{ backgroundColor: "#222222", borderColor: "#333333", display: "flex", justifyContent: "left", alignItems: "center" }}>
                            <Row style={{ width: "100%" }}>
                                <Col style={{ backgroundColor: "#222222", borderColor: "#333333", display: "flex", justifyContent: "left", alignItems: "center" }}>
                                    <Button variant="danger" onClick={() => onClose()}>Close</Button>
                                </Col>
                                <Col style={{ backgroundColor: "#222222", borderColor: "#333333", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                    <Button variant="success" onClick={() => onSave()}>Create</Button>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%" }}>
                                <Col style={{ backgroundColor: "#222222", borderColor: "#333333", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                {
                                    saveError !== "" ?
                                        <Form.Label style={{ color: "red" }}>{saveError}</Form.Label>
                                        :
                                        <Form.Label></Form.Label>
                                }   
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default withAuthentication(CreateActivationsViewModal);
