import React, { useEffect, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Badge, Button, ListGroup, Modal } from 'react-bootstrap';
import {GlobalNotification, Link} from './models/models';
import { useNavigate } from "react-router-dom";
import { BsFillChatLeftTextFill, BsGlobeAmericas, BsChatLeftHeart } from "react-icons/bs";
import moment from 'moment';

interface IGlobalNotificationListViewItemProps {
    notification: GlobalNotification;
    onClick: (id: string, e: React.MouseEvent<Element, MouseEvent>) => void;
}

const GlobalNotificationListViewItem: React.FC<IGlobalNotificationListViewItemProps> = (props) => {
    const navigate = useNavigate();

    const formatDateString = (timestamp: number | undefined) => {
      let result = "";
      if (timestamp != void(0)) {
        const date = new Date(timestamp * 1000);
        const dateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        result = date.toLocaleDateString("en-GB", dateOptions);
      }

      return result;
    }
    const notificationTextStringMinify = (fullText: string) => {

        let resultString = "";

        if (fullText.length > 100) {
            resultString = fullText.substring(0, 100) + "...";
        } else {
            resultString = fullText;
        }

        return resultString;

    }

  return (
    <>
        <style>
            {`
                .notification-list-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid  #333333
                }
                .notification-list-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid white
                }
                .notification-badge-right {
                    display: flex;
                    justify-content: flex-end;
                    padding: 2px;
                    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                }
            `}
        </style>
        <ListGroup.Item key={props.notification._id}
                        as="li"
                        className="d-flex justify-content-between align-items-start notification-list-item"
                        onClick={(e: React.MouseEvent<Element, MouseEvent>) => props.onClick(props.notification._id, e)}
                        onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => props.onClick(props.notification._id, e)}>

            <div className="row ms-1" style={{height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                {
                    props.notification.enotificationType ?
                        <BsFillChatLeftTextFill size="1.5em" color="orangered"/>
                        :
                        <div style={{display: "grid", position: "relative"}}>
                            <div style={{gridColumn: 1, gridRow: 1}}>
                                <BsGlobeAmericas size="1.5em" color="lightgrey"/>
                            </div>
                            <div style={{gridColumn: 1, gridRow: 1, position: "absolute", right: "-10%", top: "-50%"}}>
                                <BsChatLeftHeart size="1em" color="pink"/>
                            </div>
                        </div>
                }
            </div>
            <div className="row ms-2 me-auto">
                <div className="row ms-2 me-auto">
                    <div className="col-12 me-auto">
                        {notificationTextStringMinify(props.notification._id)}
                    </div>
                </div>
                <div className="row ms-2 me-auto">
                    <div className="col-6 fw-bold" style={{width: "fit-content"}}>{new GlobalNotification(props.notification).getEnglshTitle()}</div>
                    <div className="col-6 fw-lighter fst-italic" style={{width: "fit-content"}}>{moment(props.notification.dateFrom).toLocaleString()}</div>
                    <div className="col-6 fw-lighter fst-italic" style={{width: "fit-content"}}>{moment(props.notification.dateTo).toLocaleString()}</div>
                </div>
                <div className="row ms-2 me-auto">
                    <div className="col-12 me-auto">
                        {notificationTextStringMinify(new GlobalNotification(props.notification).getEnglishText())}
                    </div>
                </div>
            </div>
        </ListGroup.Item>
    </>
  );
};

export default (GlobalNotificationListViewItem);