import { Statistics } from "../types/Statistics";
import { Driver } from "../types/event/Driver";
import OAuthClient from "./oauth.client";

class NakamaClient {
  url = process.env.REACT_APP_RM_URL;
  oauth: OAuthClient;

  constructor() {
    this.oauth = new OAuthClient();
  }

  async playersList(game: string, search: string, requiresReview: boolean, page: number, take: number) : Promise<Driver[]> {
    return await this.oauth.fetch(`${this.url}/ui/players`, 'POST', JSON.stringify({ game, search, requiresReview, page, take}))
      .then(async res => {
        const data = await res.json();
        return data as Driver[];
      });
  }

  async playersGetByNakamaId(game: string, nakamaId: string) : Promise<Driver> {
    return await this.oauth.fetch(`${this.url}/ui/player/${game}/nakama/${nakamaId}`, 'GET', "")
      .then(async res => {
        const data = await res.json();
        return data as Driver;
      });
  }

  async playerGetBySteamId(game: string, steamId: string) : Promise<Driver> {
    return await this.oauth.fetch(`${this.url}/ui/player/${game}/steam/${steamId}`, 'GET', "")
      .then(async res => {
        const data = await res.json();
        return data as Driver;
      });
  }

  async playerStatistics(game:string, nakamaId: string) : Promise<any> {
    return await this.oauth.fetch(`${this.url}/ui/player/${game}/stats/${nakamaId}`, 'GET', "")
      .then(async res => {
        const data = await res.json();
        return data as Statistics;
      });
  }

  async statsQuery(game: string, query: string) : Promise<any> {
    return await this.oauth.fetch(`${this.url}/ui/stats/nakama/${game}/${query}`, 'GET', "")
      .then(async res => {
        const data = await res.json();
        return data;
      });
  }
}

export default NakamaClient;
