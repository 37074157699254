import { Col, Row, Form, Button, Container, Accordion } from 'react-bootstrap';
import moment from "moment";
import { Season } from "../models/Season";
import withAuthentication from "../../withAuthentication";
import DarkDatepickerComponent from "../../components/generic/DarkDatepickerComponent";
import React from 'react';
import { Championship, ChampionshipWeek } from '../models/Championship';
import ChampionshipServiceClient from '../../services/championship-service.client';
import ChampionshipCardViewComponent from './ChampionshipCardViewComponent';
import { ServerSettings, Tires } from '../../types/event/ServerSettings';
import { Ratings } from '../../types/event/Ratings';
import { Assists } from '../../types/event/Assists';

export interface EditSeasonFormComponentProps {
    season: Season;

    dateValidity: boolean;

    requestRefresh?: () => void;
    onUpdate: (season: Season) => void;
    saveButton: React.ReactNode;
}

const EditSeasonFormComponent = (props: EditSeasonFormComponentProps) => {

    const championshipService = new ChampionshipServiceClient();

    const [championships, setChampionships] = React.useState<Array<Championship>>([]);

    React.useEffect(() => {
        if (!props?.season) {
            return;
        }

        if (props?.season?.championships != void (0)) {
          championshipService?.getChampionshipsBySeasonId(props?.season?.id)
            .then(champs => {
              setChampionships(champs);
            })
            .catch(console.error);
        }
    }, [props?.season]);

    const addChampionship = () => {
        const newChampionship = new Championship();

        newChampionship.game = props?.season.game;
        newChampionship.name = "New Championship";
        newChampionship.seasonName = props?.season.name;
        newChampionship.dateFrom = props?.season.dateFrom;
        newChampionship.dateTo = props?.season.dateTo;
        newChampionship.seasonId = props?.season.id;

        newChampionship.serverSettings = defaultChampionshipServerSettings();
        newChampionship.ratings = defaultChampionshipRatings();

        setChampionships([...(championships ?? []), newChampionship]);
    }

    const defaultChampionshipServerSettings = (): ServerSettings => {
        return {
            maxPlayers: 38,
            fixedSetup: false,
            privateQualifying: false,
            trackLimitsRule: "1",
            trackLimitsPointsAllowed: 5,
            realRoadScale: 1,
            raceTimeScale: 1,
            fuelConsumptionMultiplier: 1,
            tyreWearMultiplier: 1,
            damageMultiplier: 100,
            raceClientWait: 120,
            closedRaceSessions: 0,
            maxDataPerClient: 0,
            downstreamRatedKbps: 0,
            upstreamRatedKbps: 0,
            assists: new Assists(),
            tires: new Tires()
        } as ServerSettings;
    }

    const defaultChampionshipRatings = (): Ratings => {
        return {
            enabled: true,
            maxSRGain: 24,
            drSettings: {
                k: 120,
                d: 800,
                base: 1,
                log: 10
            }
        } as Ratings;

    }

    const onDuplicateChampionship = (championship: Championship) => {
        const editedChampionship = championship;
        if (editedChampionship == void (0)) {
            console.error("Championship not found");
            return;
        }

        const newChampionship = JSON.parse(JSON.stringify(editedChampionship));
        newChampionship.name = `${editedChampionship.name} (Copy)`;
        newChampionship.weeks = editedChampionship.weeks.map((w: ChampionshipWeek) => {
            const newWeek = JSON.parse(JSON.stringify(w));
            newWeek.week = w.week;
            return newWeek;
        });
        newChampionship._id = "";

        setChampionships([...championships, newChampionship]);
    }

    const mongoFormat = (date: string) => {
        return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    }

    const seasonStarted = (season: Season): boolean => {
        const now = new Date();
        const dateFrom = new Date(season?.dateFrom);

        return dateFrom <= now;
    }

    const isNewSeason = (season: Season): boolean => {
        return !(season?.id != void (0) && season?.id?.length > 0);
    }

    return (
        <>
            <style>
                {`
                .add-championship-button {
                    width: 100%;
                    background: transparent;
                    border-style: dashed;
                    border-width: 4px;
                    height: 100%;
                    border-color: #4b5660;
                    color: #4b5660;
                    font-weight: 600;
                    font-size: 2.3rem;
                }

                .add-championship-button:hover, .add-championship-button:focus, .add-championship-button:active {
                    background: #4b5660 !important;
                    color: snow !important;
                    border-color: grey !important;
                }

                .championship-card-container-row {
                    width: fit-content;
                    min-width: 100%;
                    flex-wrap: nowrap;
                }

            `}
            </style>
            <Container>
                <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Season Config</Accordion.Header>
                        <Accordion.Body>
                            <Form>
                                <Row>
                                    <Col xs={1}>
                                        <Form.Group controlId="game">
                                            <Form.Label>Game:</Form.Label>
                                            <Form.Control as="select" value={props?.season.game} onChange={(e) => props?.onUpdate({ ...props?.season, game: e.target.value })} disabled={seasonStarted(props?.season)}>
                                                <option key="" value="">Please Select</option>
                                                {["rf2", "lmu"].map((game) => (
                                                    <option key={game} value={game}>{game}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group controlId="name">
                                            <Form.Label>Name:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={props?.season.name}
                                                onChange={(e) => props?.onUpdate({ ...props?.season, name: e.target.value })}
                                                disabled={seasonStarted(props?.season) && !isNewSeason(props?.season)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Group controlId="dateFrom">
                                          <Form.Label>
                                              Starts:
                                          </Form.Label>
                                          <DarkDatepickerComponent
                                              value={props?.season.dateFrom ? new Date(props?.season.dateFrom) : new Date()}
                                              onChange={(date: Date) => props?.onUpdate({ ...props?.season, dateFrom: mongoFormat(date.toISOString()) })}
                                              showTimeSelect={true}
                                              disabled={seasonStarted(props?.season) && !isNewSeason(props?.season)}
                                          />
                                          {!props?.dateValidity && (
                                              <span style={{ color: "red", display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "0.9rem" }}>
                                                  Season dates are invalid
                                              </span>
                                          )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Group controlId="dateTo">
                                          <Form.Label>
                                              Ends:
                                          </Form.Label>
                                          <DarkDatepickerComponent
                                              value={props?.season.dateTo ? new Date(props?.season.dateTo) : new Date()}
                                              onChange={(date: Date) => props?.onUpdate({ ...props?.season, dateTo: mongoFormat(date.toISOString()) })}
                                              showTimeSelect={true}
                                          />
                                          {!props?.dateValidity && (
                                            <span style={{ color: "red", display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "0.9rem" }}>
                                                Season dates are invalid
                                            </span>
                                          )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {props?.saveButton}
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                    {
                        props?.season?.id != void (0) && props?.season?.id?.length > 0 &&
                        <Accordion.Item eventKey="1" style={{ minHeight: "100vh" }}>
                            <Accordion.Header>Championships</Accordion.Header>
                            <Accordion.Body style={{ overflow: "none", minHeight: "85vh" }}>
                                <Row>
                                    <Col xs={12}>
                                        <Row className="championship-card-container-row">
                                            {
                                                championships != void (0) && championships?.length > 0 &&
                                                championships?.sort((a,b) => a?.dateFrom > b?.dateFrom ? 1 : -1).map(championship => (
                                                    <Col xs={3} key={championship?._id}>
                                                        <ChampionshipCardViewComponent
                                                          key={championship?._id}
                                                          championship={championship}
                                                          onDuplicate={onDuplicateChampionship}
                                                          requestRefresh={props?.requestRefresh}
                                                        />
                                                    </Col>
                                                ))
                                            }
                                            <Col xs={3}>
                                                <Button variant="primary"
                                                    className="add-championship-button"
                                                    onClick={addChampionship}>
                                                    Add Championship
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                </Accordion>
            </Container>
        </>
    )
}

export default withAuthentication(EditSeasonFormComponent);
