import { Server } from "./event/server"

export interface FleetAgent {
  ip: string
	status: string
  name: string
  version: string
	capacity: Capacity
	servers: Server[]
  cpu: CPU
  memory: Memory
  network: Network
}

export interface CPU {
  usage: number
  numCPUs: number
  threads: number
}

export interface Memory {
  used: number
  percent: number
  total: number
}

export interface Capacity {
  used: number
  total: number
}

export interface Network {
  totalBytesSent: number;
  totalBytesRecv: number;
  bytesSent: number;
  bytesRecv: number;

  totalPacketsSent: number;
  totalPacketsRecv: number;
  packetsSent: number;
  packetsRecv: number;
}

export function bytesToGB(bytes: number): number {
  return bytes / ( 1024 * 1024 * 1024);
}

export function bytesToMB(bytes: number): number {
  return bytes / ( 1024 * 1024);
}