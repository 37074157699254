export class Weather {
	startTime:     string = "540";
  Duration:      string = "30";
	sky:           string = "2";
	rainChange:    string = "0";
	temperature:   string = "22";
	humidity:      string = "55";
	windSpeed:     string = "7";
	windDirection: string = "2";

  constructor(startTime: string) {
    this.startTime = startTime;
  }

  static Default(startTime: string): Weather {
    return {
      startTime: startTime,
      Duration: "30",
      sky: "2",
      rainChange: "0",
      temperature: "22",
      humidity: "55",
      windSpeed: "7",
      windDirection: "1"
    };
  }
}