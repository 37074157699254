import { useEffect, useState } from "react";
import { DailyRaceConfiguration } from "../../../types/event/DailyRaceConfiguration";
import withAuthentication from "../../../withAuthentication";
import RaceManagementClient from "../../../services/race-manaement.client";
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { Button, Table } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faEdit, faAdd } from '@fortawesome/free-solid-svg-icons'

function CronToHuman({ cronString }: { cronString: string }) {
  const [minute, hour, , , ] = cronString.split(' ');

  let description = 'At ';

  // Minute
  const minutes = minute.split(',');
  if (minutes.length > 1) {
      description += `${minutes[0]} and ${minutes[1]} minutes`;
  } else {
      description += `${minutes[0]} minute${minutes[0] === '1' ? '' : 's'}`;
  }

  // Hour
  const [startHour, endHour] = hour.split('-');
  description += ` past every hour from ${startHour} AM to ${endHour} PM`;

  // Adding everyday as we know it's every day from the cron
  description += ' every day.';

  return <div>{description}</div>;
}


interface DailyRacesConfigTableProps {
  type: "current" | "future" | "past";
}


const DailyRacesConfigTable = (props: DailyRacesConfigTableProps) => {
  const client = new RaceManagementClient();
  const [dailyConfigurations, setDailyConfigurations] = useState<DailyRaceConfiguration[]>([]);
  const navigate = useNavigate();

  const fetch = () => {
    client.listDailyConfigurations()
      .then((configs) => {
        if (!configs) return;
        if (props.type === "current") {
          setDailyConfigurations(configs.filter(c => moment(c.starts).isBefore(moment.now()) && moment(c.ends).isAfter(moment.now())));
        } else if (props.type === "future") {
          setDailyConfigurations(configs.filter(c => moment(c.starts).isAfter(moment.now())));
        } else if (props.type === "past") {
          setDailyConfigurations(configs.filter(c => moment(c.ends).isBefore(moment.now())));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    fetch();
    const intervalId = setInterval(fetch, 10000);

    return () => clearInterval(intervalId);
  }, [])
  

  const editConfig = (seriesId: string) => async () => {
    console.log(`Edit config ${seriesId}`);
    navigate('/rm/configs/dailies/create?id=' + seriesId );
  }

  const copyConfig = (seriesId: string) => async () => {
    console.log(`Copy config ${seriesId}`);
    navigate('/rm/configs/dailies/create?id=' + seriesId + '&copy=true');
  }

  const deleteConfig = (seriesId: string) => async () => {
    console.log(`Delete config: ${seriesId}`);
    client.deleteDailyConfiguration(seriesId)
      .catch((err) => {
        console.error(err);
      });
  }

  const formatDate = (date: string) => {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  return (
    <div>
      {!dailyConfigurations 
        ? (
          <p>Loading Race Configurations, please wait</p>
        ) : (
          <Row>
            <div className="d-flex justify-content-center">
              <Col sm={12}>
                <div className="tableFixHead">
                  <Table dark>
                    <thead>
                      <tr>
                        <th>Game</th>
                        <th>Titles</th>
                        <th>Tier</th>
                        <th>Starts</th>
                        <th>Ends</th>
                        <th>Frequency</th>
                        <th style={{textAlign: "right", width: "11rem"}}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dailyConfigurations?.sort((a, b) => a.starts < b.starts ? 1 : -1).map((config, i) => (
                        <tr key={i}>
                          <td>{config.game}</td>
                          <td>{config.content.map(x => x.title).join(", ")}</td>
                          <td>{config.tier.name}</td>
                          <td>{formatDate(config.starts)}</td>
                          <td>{formatDate(config.ends)}</td>
                          <td>{config.frequency.map(f => <span>{f}<br/></span>)}</td>
                          <td style={{textAlign: "right", verticalAlign: "middle"}}>
                            <Button className="mr-2" color="primary" onClick={editConfig(config.seriesId)}><FontAwesomeIcon icon={faEdit} /></Button>
                            <Button className="mr-2" color="primary" onClick={copyConfig(config.seriesId)}><FontAwesomeIcon icon={faCopy} /></Button>
                            <Button className="mr-2" color="danger" onClick={deleteConfig(config.seriesId)}><FontAwesomeIcon icon={faTrash} /></Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </div>
          </Row>
        )
      }
    </div>
  )
}

export default withAuthentication(DailyRacesConfigTable);
