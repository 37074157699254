import { CreateRoleRequest, DeleteRoleRequest, HttpProtectedRole, HttpProtectedRoute, HttpProtectedRouteViewModel, HttpProtectedUser, HttpProtectedUserViewModel, ModifyProtectedRoleColourRequest, ModifyProtectedRoleRequest, ModifyProtectedRoleRouteRequest, ModifyProtectedRoleViewRequest, ModifyProtectedRouteRoleRequest, ModifyProtectedUserRoleRequest } from '../types/Security';
import OAuthClient from './oauth.client';

class SecurityServiceClient {
    url = process.env.REACT_APP_RM_URL;
    oauth: OAuthClient;

    constructor() {
      this.oauth = new OAuthClient();
    }
  
    async getAllProtectedUsers(page: Number, take: Number): Promise<Array<HttpProtectedUserViewModel>> {
      return this.oauth.fetch(`${this.url}/security/users/all?page=${page}&take=${take}`)
        .then(res => res.json());
    }

    async getSelf(): Promise<HttpProtectedUserViewModel> {
      return this.oauth.fetch(`${this.url}/security/users/self`)
        .then(res => res.json());
    }

    async addRoleToUser(id: string, roleName: string): Promise<void> {
      const request: ModifyProtectedUserRoleRequest = {
        id: id,
        roleName: roleName
      };

      return this.oauth.fetch(`${this.url}/security/users/addRole`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    async removeRoleFromUser(id: string, roleName: string): Promise<void> {
      const request: ModifyProtectedUserRoleRequest = {
        id: id,
        roleName: roleName
      };

      return this.oauth.fetch(`${this.url}/security/users/removeRole`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    async getAllRoutes(page: Number, take: Number): Promise<Array<HttpProtectedRouteViewModel>> {
      return this.oauth.fetch(`${this.url}/security/routes/all?page=${page}&take=${take}`)
        .then(res => res.json());
    }

    async getRouteById(id: string): Promise<HttpProtectedRoute> {
      return this.oauth.fetch(`${this.url}/security/routes/${id}`)
        .then(res => res.json());
    }

    async addRoleToRoute(id: string, roleName: string): Promise<void> {
      const request: ModifyProtectedRouteRoleRequest = {
        id: id,
        roleName: roleName
      };

      return this.oauth.fetch(`${this.url}/security/routes/addRole`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    async removeRoleFromRoute(id: string, roleName: string): Promise<void> {
      const request: ModifyProtectedRouteRoleRequest = {
        id: id,
        roleName: roleName
      };

      return this.oauth.fetch(`${this.url}/security/routes/removeRole`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    async getAllRoles(): Promise<Array<HttpProtectedRole>> {
      return this.oauth.fetch(`${this.url}/security/roles/all`)
        .then(res => res.json());
    }

    async setRoleColour(roleName: string, colour: string): Promise<Response> {
      const request: ModifyProtectedRoleColourRequest = {
        roleName: roleName,
        colour: colour
      };

      return this.oauth.fetch(`${this.url}/security/roles/setColour`, "POST", JSON.stringify(request));
    }

    async deleteRole(roleName: string): Promise<Response> {

      const request: DeleteRoleRequest = {
        roleName: roleName
      };

      return this.oauth.fetch(`${this.url}/security/roles/deleteRole`, "POST", JSON.stringify(request));
    }

    async createRole(roleName: string): Promise<Response> {
      const request: CreateRoleRequest = {
        roleName: roleName
      };

      return this.oauth.fetch(`${this.url}/security/roles/createRole`, "POST", JSON.stringify(request));
    }

    async addViewToRole(roleName: string, view: string): Promise<void> {
      const request: ModifyProtectedRoleViewRequest = {
        roleName: roleName,
        viewName: view
      };

      return this.oauth.fetch(`${this.url}/security/roles/addView`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    async removeViewFromRole(roleName: string, view: string): Promise<void> {
      const request: ModifyProtectedRoleViewRequest = {
        roleName: roleName,
        viewName: view
      };

      return this.oauth.fetch(`${this.url}/security/roles/removeView`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    async addRouteToRole(roleName: string, routeName: string): Promise<void> {
      const request: ModifyProtectedRoleRouteRequest = {
        roleName: roleName,
        routeName: routeName
      };

      return this.oauth.fetch(`${this.url}/security/roles/addRoute`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    async removeRouteFromRole(roleName: string, routeName: string): Promise<void> {
      const request: ModifyProtectedRoleRouteRequest = {
        roleName: roleName,
        routeName: routeName
      };

      return this.oauth.fetch(`${this.url}/security/roles/removeRoute`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }


    async setDefaultRole(roleName: string): Promise<Response> {
      const request: ModifyProtectedRoleRequest = {
        roleName: roleName
      };

      return this.oauth.fetch(`${this.url}/security/roles/setDefaultRole`, "POST", JSON.stringify(request))
    }

    async unsetDefaultRole(roleName: string): Promise<Response> {
      const request: ModifyProtectedRoleRequest = {
        roleName: roleName
      };

      return this.oauth.fetch(`${this.url}/security/roles/unsetDefaultRole`, "POST", JSON.stringify(request))
    }
}

export default SecurityServiceClient;
