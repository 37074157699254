import React, { ComponentType, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { HttpProtectedUserViewModel } from './types/Security';
import { useRoute } from './RouteContext';

type LoginMethod = 'redirect' | 'popup';

interface WithAuthenticationWrapperProps {
  loginMethod?: LoginMethod;
}

const forbiddenElement = (
  <div className="text-center mt-5">
    <h1>Forbidden</h1>
    <p>You do not have permission to view this page</p>
  </div>
);

const unauthElement = (
  <div className="text-center mt-5">
    <h1>Unauthorized</h1>
    <p>You must be signed in to view this page</p>
  </div>
);

function routeAllowed(user: HttpProtectedUserViewModel | null, path: string) {
  if (user == void (0) || user?.roles == void (0) || user?.roles?.length === 0) {
    return false;
  } else if (user?.roles?.map(r => r.name).includes("SuperAdmin")) {
    return true;
  }
  const allowedRoutes = user?.roles?.map(r => r.allowedRoutes).flat().filter(route => route !== '');
  return allowedRoutes?.some(route => route === '/' ? path === '/' : path.startsWith(route));
};

/** A HOC which only shows the given component if the user is signed in and
permitted to use the current route, as defined in the security service.
Otherwise, an error message is shown. */
const withAuthentication = <P extends object>(WrappedComponent: ComponentType<P>, loginMethod: LoginMethod = 'redirect'): React.FC<P & WithAuthenticationWrapperProps> => {
  const WithAuthenticationWrapper: React.FC<P> = (props) => {
    const auth = useAuth();
    const route = useRoute();

    // Stops the unauth message showing if it's just a slow load otherwise it flashes
    const [loadingTimerExpired, setLoadingTimerExpired] = useState<boolean>(false);
    useEffect(() => {
      setTimeout(() => {
        setLoadingTimerExpired(true);
      }, 5000);
    }, []);

    if (!auth.isAuthenticated) {
      return loadingTimerExpired ? unauthElement : null;
    }
    if (!routeAllowed(auth.self, route)) {
      return loadingTimerExpired ? forbiddenElement : null;
    }
    return <WrappedComponent {...props} />;
  };

  return WithAuthenticationWrapper;
};

export default withAuthentication;
