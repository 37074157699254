export interface Activation {
    id: string;
    partner: string;
    token: string;
    expires: string;
    series: Array<string>;
}

export interface ActivationCreateRequest {
    activation: Activation;
}

export interface ActivationAddSeriesRequest {
    id: string;
    seriesId: string;
}

export interface ActivationRemoveSeriesRequest {
    id: string;
    seriesId: string;
}

export interface ActivationUpdateTokenRequest {
    id: string;
    token: string;
}

export interface ActivationUpdateExpirationRequest {
    id: string;
    expires: string;
}

export interface ActivationDeleteRequest {
    id: string;
}

export function generateToken() {
    return btoa('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    }));
}