import React from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import withAuthentication from '../withAuthentication';

import { FaLock, FaLockOpen, FaUser, FaUserShield, FaUserSlash } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { UsernameReservation } from '../types/User';
import UnlinkAreYouSureModal from './UnlinkAreYouSureModal';
import LinkSteamIDModal from './LinkSteamIDModal';
import UsernameReservationServiceClient from '../services/username-reservation-service.client';
import NakamaClient from '../services/nakama.client';
import { get } from 'http';
import { Driver } from '../types/event/Driver';
import DriverAccordionViewComponent from '../components/driver/DriverAccordionViewComponent';
import ViewDriversModal from './ViewDriversModal';

interface IUsernameReservationListViewItemProps {
    reservation: UsernameReservation;
    requestRefresh?: (indices: Array<number>) => void;
}

const UsernameReservationListViewItem: React.FC<IUsernameReservationListViewItemProps> = (props) => {

    const usernameReservationsClient = new UsernameReservationServiceClient();

    const [showLinkModal, setShowLinkModal] = React.useState(false);
    const [showUnlinkModal, setShowUnlinkModal] = React.useState(false);
    const [showViewDriversModal, setShowViewDriversModal] = React.useState(false);

    const unlinkSteamID = () => {
        usernameReservationsClient.addUsernameReservation(props?.reservation?.username, "").then(() => {
            props?.requestRefresh?.([]);
        }).catch((error: Error) => {   
            console.error(error);
        });
    }

    const deleteReservation = () => {
        usernameReservationsClient.deleteUsernameReservation(props?.reservation?.username).then(() => {
            props?.requestRefresh?.([]);
        }).catch((error: Error) => {   
            console.error(error);
        });
    }

    return (
        <>
            <style>
                {`
                .username-reservation-list-item-row {
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                }

                .user-list-item-icon-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .username-reservation-list-item-col {
                }

                .username-reservation-details-col {
                    font-weight: bold;
                    font-size: 1.0em;
                }
            `}
            </style>
            <Row className="username-reservation-list-item-row">
                <Col xs={12} className="username-reservation-list-item-col">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={6} className="username-reservation-details-col">
                                    <Row>
                                        <Col>
                                            Username: {props?.reservation?.username}
                                        </Col>
                                    </Row>
                                    <Row>
                                        {
                                            props?.reservation?.steamId === null || props?.reservation?.steamId === "" ?
                                                <Col>
                                                    SteamID Not Specified
                                                </Col>
                                                :
                                                <Col>
                                                    SteamID: {props?.reservation?.steamId}
                                                </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <Row>
                                        {
                                            props?.reservation?.steamId == void(0) || props?.reservation?.steamId === "" ?
                                                <Col>
                                                    <Button variant="success" onClick={() => setShowLinkModal(true)}>Link SteamID</Button>
                                                </Col>
                                                :
                                                <Col>
                                                    <Button variant="danger" onClick={() => setShowUnlinkModal(true)}>Unlink SteamID</Button>
                                                </Col>
                                        }
                                        {
                                            props?.reservation?.steamId != void(0) && props?.reservation?.steamId === "" &&
                                            <Col>
                                                <Button variant="danger" onClick={deleteReservation}>Delete</Button>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <Row>
                                        {
                                            props?.reservation?.steamId != void(0) && props?.reservation?.steamId?.length > 0 &&
                                            <Col>
                                                <Button variant="success" onClick={() => setShowViewDriversModal(true)}>View Profile</Button>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <UnlinkAreYouSureModal
                show={showUnlinkModal}
                onCancel={() => setShowUnlinkModal(false)}
                onConfirm={() => {
                    unlinkSteamID();
                    setShowUnlinkModal(false);
                }}
            />
            <LinkSteamIDModal
                reservation={props?.reservation}
                show={showLinkModal}
                onCancel={() => setShowLinkModal(false)}
                onConfirm={() => {
                    setShowLinkModal(false);
                    props?.requestRefresh?.([]);
                }}
            />
            <ViewDriversModal
                reservation={props?.reservation}
                show={showViewDriversModal}
                onCancel={() => setShowViewDriversModal(false)}
                onConfirm={() => {
                    setShowViewDriversModal(false);
                    props?.requestRefresh?.([]);
                }}
            />
        </>
    );
};

export default withAuthentication(UsernameReservationListViewItem);