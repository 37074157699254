import { Accordion, Button, Col, Container, Dropdown, DropdownButton, Form, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Championship, ChampionshipType } from '../models/Championship';
import React, { useEffect } from 'react';
import ServerSettingsComponent from '../../rm/configs/ServerSettingsComponent';
import AssistsComponent from '../../rm/configs/Assists';
import CarSelection from '../../rm/configs/CarSelection';
import DarkDatepickerComponent from '../../components/generic/DarkDatepickerComponent';
import moment from "moment";
import ImageSelection from '../../rm/configs/ImageSelection';
import { serverSizes } from "../../types/event/SpecialEvent";
import { CiWarning } from 'react-icons/ci';
import Warning from '../../components/generic/Warning';
import Note from '../../components/generic/Note';
import _ from 'lodash';
import { dailyTiers } from '../../types/event/DailyRaceConfiguration';
import RatingsComponent from '../../rm/configs/Ratings';

export interface ChampionshipConfigurationModalProps {
  show: boolean;
  championship: Championship;
  isActive: boolean;
  onCancel: () => void;
  onConfirm: (championship: Championship) => void;
}

const ChampionshipConfigurationModal = (props: ChampionshipConfigurationModalProps) => {
  const [championship, setChampionship] = React.useState<Championship>(props?.championship);

  const games = ["lmu", "rf2"];
  const splitSettings = ["even", "filltop"];

  useEffect(() => {
    if (props?.show) {
      onShow();
    }
  }, [props?.show]);

  const onShow = () => {
    setChampionship({ ...props?.championship });
  }

  const onSave = () => {
    props?.onConfirm(championship);
  }

  const onCancel = () => {
    setChampionship({ ...props?.championship });
    props?.onCancel();
  }

  const mongoFormat = (date: string) => {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  }

  return (
    <>
      <style>
        {`
          .championship-configuration-modal-container {
            background-color: rgba(0, 0, 0, 0.25);
            color: snow;
          }

          .championship-configuration-modal-header {
            background-color: #333333;
            color: snow;
            border-bottom: 1px solid #454545;
            font-size: 1.2em;
            font-weight: bold;
            text-align: center;
          }

          .championship-configuration-modal-body {
            background-color: #333333;
            color: snow;
          }

          .championship-configuration-modal .modal-body-container {
            background-color: #333333;
            color: snow;
          }

          .championship-configuration-modal-footer {
            background-color: #333333;
            color: snow;
            border-top: 1px solid #454545;
          }

          .setting-popover {
            background-color: #333333 !important;
            color: snow !important;
          }

          .setting-popover .popover-header {
            background-color: #333333 !important;
            color: snow !important;
            border-bottom: 1px solid #454545;
          }

          .setting-popover .popover-body {
            background-color: #333333 !important;
            color: snow !important;
          }

          .dark-select {
            background-color: #333333 !important;
            color: snow;
            border: 1px solid #454545;
            outline: none !important;
          }

          .dark-select option {
            background-color: #333333 !important;
            color: snow;
            border: 1px solid #454545;
            outline: none !important;
          }

          .dark-select:focus, .dark-select:active, .dark-select:hover {
            background-color: #333333 !important;
            color: snow;
            border: 1px solid #454545;
            outline: none !important;
          }

          .center-image-preview {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}
      </style>

      <Modal className="championship-configuration-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header className="championship-configuration-modal-header">
          <Modal.Title id="contained-modal-title-vcenter">
            Editing Championship Configuration: {championship?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="championship-configuration-modal-body">
          <Container>
            {
              props?.isActive &&
              <Row style={{ margin: "0.1rem" }}>
                <Col>
                  <Note customText="This is an active championship; some options cannot be changed" />
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <Form>
                  <Accordion defaultActiveKey="-1">
                    <Accordion.Item eventKey="-1">
                      <Accordion.Header>Championship Config Settings</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col xs={6}>
                            <Form.Group controlId="name">
                              <Form.Label>Name:</Form.Label>
                              <Form.Control type="string" value={championship?.name} onChange={(e) => setChampionship({ ...championship, name: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <ImageSelection image={championship?.cardImage} filter="championship-cards/" title="Card Image" onImageSelected={(image) => setChampionship({ ...championship, cardImage: image })} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <Form.Group controlId="description">
                              <Form.Label>Description:</Form.Label>
                              <Form.Control
                                as="textarea" // Change type to as="textarea"
                                value={championship?.description}
                                onChange={(e) => setChampionship({ ...championship, description: e.target.value })}
                              />
                            </Form.Group>
                            <br />
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={2}>
                            <Form.Group controlId="tierName">
                              <Form.Label>Game:</Form.Label>
                              <Form.Control disabled={props?.isActive} className="dark-select" as="select" value={championship?.game} onChange={(e) => setChampionship({ ...championship, game: e.target.value })}>
                                <option value="">Please Select</option>
                                {games.map((game, index) => (
                                  <option key={index} value={game} selected={championship?.game === game}>{game}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={2}>
                            <Form.Group controlId="type">
                              <Form.Label>Type:</Form.Label>
                              <Form.Control disabled={props?.isActive} className="dark-select" as="select" value={championship?.type} onChange={(e) => setChampionship({ ...championship, type: (e.target.value as ChampionshipType) })}>
                                <option value="">Please Select</option>
                                <option value="solo">Solo Championship</option>
                                <option value="teamattack">Team Attack Championship</option>
                                <option value="endurance">Endurance Championship</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={2}>
                            <Form.Group controlId="entryRequirements">
                              <Form.Label>Tier:</Form.Label>
                              <Form.Control className="dark-select" as="select" value={championship?.tier?.name} onChange={(e) => setChampionship({ ...championship, tier: { ...championship?.tier, name: e?.target?.value } })}>
                                <option value="">Please Select</option>
                                {dailyTiers?.map((tier) => (
                                  <option value={tier} selected={championship?.tier?.name === tier}>{tier}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={2}>
                            <Form.Group controlId="roundLength">
                              <Form.Label>Week Length:</Form.Label>
                              <Form.Control type="number" value={championship?.weekLength} onChange={(e) => setChampionship({ ...championship, weekLength: Math.min(Math.max(1, Math.round(Number(e.target.value))), 28) })} />
                            </Form.Group>
                          </Col>
                          <Col xs={3}>
                            <Row>
                              <Form.Label>Date From:</Form.Label>
                              <DarkDatepickerComponent
                                value={championship?.dateFrom ? new Date(championship?.dateFrom) : new Date()}
                                onChange={(date: Date) => setChampionship({ ...championship, dateFrom: mongoFormat(date.toISOString()) })}
                                showTimeSelect={true}
                                disabled={props?.isActive}
                              />
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={2}>
                            <Form.Group controlId="maxSRGain">
                              <Form.Label>Drop Weeks:</Form.Label>
                              <Form.Control type="number" value={championship?.dropWeeks} onChange={(e) => setChampionship({ ...championship, dropWeeks: Number(e.target.value) })} />
                            </Form.Group>
                          </Col>
                          <Col xs={3}>
                            <Form.Group controlId="maxRegistrations">
                              <Form.Label>Max Registations Per Round:</Form.Label>
                              <Form.Control type="number" value={championship?.maxEventRegistrations} onChange={(e) => setChampionship({ ...championship, maxEventRegistrations: Number(e.target.value) })} />
                            </Form.Group>
                          </Col>
                          <Col xs={3}>
                            <Form.Group controlId="playersPerSkillBasedLeague">
                              <Form.Label>Players Per DR League:</Form.Label>
                              <Form.Control disabled={props?.isActive} type="number" value={championship?.playersPerSkillBasedLeague} onChange={(e) => setChampionship({ ...championship, playersPerSkillBasedLeague: Number(e.target.value) })} />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Row xs={2}>
                              <Form.Label>Split Setting:</Form.Label>
                              <Form.Control className="dark-select" as="select" onChange={(e) => setChampionship({ ...championship, splitSettings: e.target.value })}>
                                <option value="">Please Select</option>
                                {splitSettings.map((setting: string, index) => (
                                  <option key={index} value={setting} selected={championship.splitSettings === setting}>{setting.charAt(0).toUpperCase() + setting.slice(1)}</option>
                                ))}
                              </Form.Control>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={2}>
                            <Form.Group controlId="name">
                              <Form.Label>Server Size:</Form.Label>
                              <Form.Control className="dark-select" as="select" value={championship?.serverSize} onChange={(e) => setChampionship({ ...championship, serverSize: e.target.value })}>
                                <option value="">Please Select</option>
                                {serverSizes.map((instance, index) => (
                                  <option key={index} value={instance} selected={instance === championship?.serverSize}>{instance}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={3}>
                            <Form.Group controlId="entryRequirements">
                              <Form.Label>Entry Requirements Rank:</Form.Label>
                              <Form.Control className="dark-select" as="select" value={championship?.tier?.entryRequirement?.rank} onChange={(e) => setChampionship({ ...championship, tier: { ...championship?.tier, entryRequirement: { ...championship?.tier?.entryRequirement, rank: e.target.value } } })}>
                                <option value="">Please Select</option>
                                {
                                  ["Bronze", "Silver", "Gold", "Platinum", "Pro"].map((rank, index) => (
                                    <option key={index} value={rank} selected={championship?.tier?.entryRequirement?.rank === rank}>{rank}</option>
                                  ))
                                }
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={3}>
                            <Form.Group controlId="entryRequirements">
                              <Form.Label>Entry Requirements Tier:</Form.Label>
                              <Form.Control className="dark-select" as="select" value={championship?.tier?.entryRequirement?.tier} onChange={(e) => setChampionship({ ...championship, tier: { ...championship?.tier, entryRequirement: { ...championship?.tier?.entryRequirement, tier: Number(e.target.value) } } })}>
                                {[0, 1, 2, 3].map((tier) => (
                                  <option key={tier} value={tier}>{tier}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <br></br>
                        <Row>
                          {championship?.frequency.map((freq, i) => (
                            <Col xs={2}>
                              <Form.Group controlId="frequency">
                                <Form.Label>Frequency [{i + 1}]:</Form.Label>
                                <Form.Control type="string" value={freq} onChange={(e) => {
                                    if (championship == void (0)) return;

                                    championship.frequency[i] = e.target.value;
                                    setChampionship({ ...championship, frequency: championship?.frequency })
                                  }} />
                              </Form.Group>
                            </Col>
                          ))}

                          <Col>
                            <Button style={{ marginTop: "2rem", marginRight: "1rem" }} variant="primary" type="button" onClick={() => {
                              let freq = championship.frequency;
                              freq.pop();
                              setChampionship({ ...championship, frequency: freq })
                            }}
                            >Del</Button>

                            <Button style={{ marginTop: "2rem" }} variant="primary" type="button" onClick={() => {
                              let freq = championship.frequency;
                              freq.push("0 9-22/1 * * *");
                              setChampionship({ ...championship, frequency: freq })
                            }}
                            >Add</Button>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Server Settings</Accordion.Header>
                      <Accordion.Body>
                        <ServerSettingsComponent settings={championship?.serverSettings} onChanged={(settings) => setChampionship({ ...championship, serverSettings: settings })} />
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Assists</Accordion.Header>
                      <Accordion.Body>
                        <AssistsComponent assists={championship?.serverSettings?.assists} onChanged={(assists) => setChampionship({ ...championship, serverSettings: { ...championship?.serverSettings, assists: assists } })} />
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>SR/DR Ratings Configuration</Accordion.Header>
                      <Accordion.Body>
                        <RatingsComponent ratings={championship?.ratings} onChanged={(ratings) => setChampionship({ ...championship, ratings: ratings })} />
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Content Configuration</Accordion.Header>
                      <Accordion.Body>
                        {props?.isActive &&
                          <Warning customText="Removing cars is disabled for active championships - changing the vehicle filter is not recommended" />
                        }
                        <br></br>
                        <Row>
                          <Col>
                            <CarSelection game={championship.game} cars={championship.cars} onSelected={(cars) => setChampionship({ ...championship, cars: cars })} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group controlId="vehicleFilter">
                              <Form.Label>Vehicle Filter:</Form.Label>
                              <Form.Control value={championship.serverSettings.vehicleFilter} onChange={(e) => setChampionship({ ...championship, serverSettings: { ...championship.serverSettings, vehicleFilter: e.target.value } })} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="championship-configuration-modal-footer">
          <Row style={{ width: "100%" }}>
            <Col sm="6" md="6" lg="6" xl="6" xxl="6">
              <Button variant="danger" onClick={onCancel}>Cancel</Button>
            </Col>
            <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{ display: "flex", justifyContent: "flex-end" }} >
              <Button variant="success" onClick={onSave.bind(this)}>Confirm</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChampionshipConfigurationModal;