import { Col, Row, Button, Card } from 'react-bootstrap';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { CiWarning } from "react-icons/ci";
import withAuthentication from "../../withAuthentication";
import { Championship, ChampionshipWeek } from '../models/Championship';
import ChampionshipServiceClient from '../../services/championship-service.client';
import React from 'react';
import ChampionshipConfigurationModal from './ChampionshipConfigurationModal';
import ChampionshipWeekConfigurationModal from './ChampionshipWeekConfigurationModal';
import AreYouSureModal from '../../components/generic/AreYouSureModal';
import { Item } from '../../types/event/Content';
import { RaceControlClient } from '../../services/race-control.client';
import _, { set } from 'lodash';
import { Session } from '../../types/event/Session';

export interface ChampionshipCardViewComponentProps {
    championship: Championship;
    onDuplicate: (championship: Championship) => void;
    requestRefresh?: () => void;
}

const ChampionshipCardViewComponent = (props: ChampionshipCardViewComponentProps) => {
    const championshipService = new ChampionshipServiceClient();
    const raceControlClient = new RaceControlClient();

    const [modifiedChampionship, setModifiedChampionship] = React.useState<Championship>(props?.championship);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);

    const [showChampionshipConfigurationModal, setShowChampionshipConfigurationModal] = React.useState<boolean>(false);
    const [showChampionshipWeekConfigurationModal, setShowChampionshipWeekConfigurationModal] = React.useState<number | null | undefined>(null);

    const [showWeekDeletionAreYouSureModal, setShowWeekDeletionAreYouSureModal] = React.useState<boolean>(false);
    const [weekToDelete, setWeekToDelete] = React.useState<number | null | undefined>(null);

    const [showChampionshipDeletionAreYouSureModal, setShowChampionshipDeletionAreYouSureModal] = React.useState<boolean>(false);
    const [showChampionshipDeletionAreYouReallySureModal, setShowChampionshipDeletionAreYouReallySureModal] = React.useState<boolean>(false);

    const [showChampionshipEditActiveAreYouSureModal, setShowChampionshipEditActiveAreYouSureModal] = React.useState<boolean>(false);
    const [championshipActive, setChampionshipActive] = React.useState<boolean>(false);
    const [rcChampionship, setRcChampionship] = React.useState<Championship | null | undefined>(null);
    
    React.useEffect(() => {
        if (!props?.championship || !props?.championship?._id) {
            console.error("Championship not found");
            return;
        }
        raceControlClient.getActiveChampionships(props?.championship?.game).then((response) => {
            setChampionshipActive(response.findIndex(champ => champ?._id === props?.championship?._id) !== -1);
            setRcChampionship(response.find(champ => champ?._id === props?.championship?._id));
            setModifiedChampionship(props?.championship);
        }).catch(console.error);
    }, [props?.championship]);

    const removeChampionship = () => {
        championshipService?.removeChampionshipFromSeason(modifiedChampionship?.seasonId, modifiedChampionship?._id)?.then(props?.requestRefresh)?.catch(console.error)?.finally(props?.requestRefresh);
    }

    const onSaveChampionshipConfiguration = (editedChampionship: Championship) => {
      if(editedChampionship?._id == void (0) || editedChampionship?._id?.length === 0) {       
        championshipService?.addChampionshipToSeason(editedChampionship?.seasonId, editedChampionship).then(() => {
            championshipService?.getChampionshipsBySeasonId(editedChampionship?.seasonId).then(props?.requestRefresh).catch(console.error);
        }).catch(console.error);
      }

      championshipService
          ?.updateChampionship(editedChampionship?.seasonId, editedChampionship)
          ?.then(props?.requestRefresh)
          ?.then(setShowChampionshipConfigurationModal.bind(this, false))
          ?.then(setIsEdited.bind(this, false))
          ?.catch(console.error);
    }

    const onEditedChampionshipWeekConfiguration = (editedWeek: ChampionshipWeek) => {
      const index = modifiedChampionship?.weeks?.findIndex(week => week?.week === editedWeek?.week);

      const editedChampionship = modifiedChampionship;
      if (editedChampionship == void (0) || index === -1) {
          console.error("Championship or event not found");
          return;
      }

      editedChampionship.weeks[index] = editedWeek;
      setModifiedChampionship(editedChampionship);
      setIsEdited(true);
      setShowChampionshipWeekConfigurationModal(null);
    }

    const onEditedChampionshipConfiguration = (editedChampionship: Championship) => {
      setModifiedChampionship(editedChampionship);
      setIsEdited(true);
      setShowChampionshipConfigurationModal(false);
    };


    const formatDate = (date: string) => {
      return moment(date).format('DD/MM/YYYY HH:mm');
    }

    const isEventConfigured = (event: ChampionshipWeek) => {
        return !(event == void (0) || event?.track == void (0) || event?.track?.friendly?.length === 0 || event?.sessions == void (0) || event?.sessions?.length === 0);
    }

    const showDeleteWeekAreYouSureModal = (week: number) => {
        setWeekToDelete(week);
        setShowWeekDeletionAreYouSureModal(true);
    }

    const deleteWeek = (week: number) => {
        const index = modifiedChampionship?.weeks?.findIndex(w => w?.week === week);

        const editedChampionship = modifiedChampionship;
        if (editedChampionship == void (0) || index === -1) {
            console.error("Championship or event not found");
            return;
        }

        editedChampionship.weeks.splice(index, 1);

        // fix week numbers
        editedChampionship.weeks.forEach((w) => {
            if (w.week >= week) {
                w.week--;
            }
        });

        championshipService?.updateChampionship(editedChampionship?.seasonId, editedChampionship)?.then(props?.requestRefresh)?.catch(console.error);
    }

    const duplicateWeek = (week: number) => {
        const index = modifiedChampionship?.weeks?.findIndex(w => w?.week === week);

        const editedChampionship = modifiedChampionship;
        if (editedChampionship == void (0) || index === -1) {
            console.error("Championship or event not found");
            return;
        }

        // this is a deep copy of the event
        const newEvent: ChampionshipWeek = JSON.parse(JSON.stringify(editedChampionship.weeks[index]));

        const newWeekNumber = week + 1;

        editedChampionship?.weeks.forEach((w) => {
            if (w.week >= newWeekNumber) {
                w.week++;
            }
        });

        newEvent.week = newWeekNumber;
        newEvent.track = new Item();

        editedChampionship.weeks.push(newEvent);

        setModifiedChampionship({ ...editedChampionship });
    }

    const addWeek = () => {
        const editedChampionship = modifiedChampionship;
        if (editedChampionship == void (0)) {
            console.error("Championship not found");
            return;
        }

        const newWeekNumber = editedChampionship?.weeks?.length + 1;

        const newEvent = new ChampionshipWeek();
        newEvent.week = newWeekNumber;

        newEvent.sessions = [
            Session.Default("Practice"),
            Session.Default("Qualifying"),
            Session.Default("Race")
        ]

        editedChampionship.weeks.push(newEvent);
        
        setModifiedChampionship({ ...editedChampionship });
    }

    const moveWeekUp = (week: number) => {
        const editedChampionship = modifiedChampionship;
        if (!editedChampionship) {
            console.error("Championship not found");
            return;
        }

        const totalWeeks = editedChampionship?.weeks?.length;
        if (!totalWeeks || totalWeeks < 2) {
            console.error("Not enough weeks to perform the operation");
            return;
        } else if (week === 1) { // Assuming week numbering starts from 1
            console.error("Cannot move the first week up");
            return;
        }

        const prevWeekIndex = editedChampionship.weeks.findIndex(w => w.week === week - 1);
        const currentWeekIndex = editedChampionship.weeks.findIndex(w => w.week === week);
        if (prevWeekIndex === -1 || currentWeekIndex === -1) {
            console.error("Event not found");
            return;
        }

        // Swap the week numbers to reflect their new positions
        [editedChampionship.weeks[prevWeekIndex].week, editedChampionship.weeks[currentWeekIndex].week] =
            [editedChampionship.weeks[currentWeekIndex].week, editedChampionship.weeks[prevWeekIndex].week];

        championshipService.updateChampionship(editedChampionship.seasonId, editedChampionship)
            .then(props.requestRefresh)
            .catch(console.error);
    }

    const moveWeekDown = (week: number) => {
        const editedChampionship = modifiedChampionship;
        if (editedChampionship == void (0)) {
            console.error("Championship not found");
            return;
        }

        const totalWeeks = editedChampionship?.weeks?.length;
        if (!totalWeeks || totalWeeks < 2) {
            console.error("Not enough weeks to perform the operation");
            return;
        } else if (week === totalWeeks) {
            console.error("Cannot move the last week down");
            return;
        }

        const nextWeekIndex = editedChampionship.weeks.findIndex(w => w.week === week + 1);
        const currentWeekIndex = editedChampionship.weeks.findIndex(w => w.week === week);
        if (nextWeekIndex === -1 || currentWeekIndex === -1) {
            console.error("Event not found");
            return;
        }

        // Swap the week numbers to reflect their new positions
        [editedChampionship.weeks[nextWeekIndex].week, editedChampionship.weeks[currentWeekIndex].week] =
            [editedChampionship.weeks[currentWeekIndex].week, editedChampionship.weeks[nextWeekIndex].week];

        championshipService.updateChampionship(editedChampionship.seasonId, editedChampionship)
            .then(props.requestRefresh)
            .catch(console.error);
    }

    const weekAt = (dateTime: number): number => {
        if (!championshipActive) return -1;
        const dateFrom = new Date(modifiedChampionship?.dateFrom).getTime();
        const daysSinceStart = (new Date(dateTime).getTime() - dateFrom) / (1000 * 60 * 60 * 24);
        const weekLength = Math.max(modifiedChampionship?.weekLength || 1, 1); // Ensure weekLength is at least 1
        const weeksSinceStart = daysSinceStart / weekLength;
        // Ensure the week number is at least 1 and does not exceed modifiedChampionship?.weeks?.length
        const weekNumber = Math.max(1, Math.min(modifiedChampionship?.weeks?.length || 0, Math.ceil(weeksSinceStart)));
        return weekNumber;
    };

    return (
        <>
            <style>
            {`
                .championship-card {
                    background-color: #333333;
                    color: snow;
                    border: 1px solid #454545;
                    background-size: cover, contain;
                    background-position: center, top;
                    background-repeat: no-repeat;
                }

                .championship-card .card-header {
                    color: snow;
                    border-bottom: 0px solid #454545;
                    font-size: 1.2em;
                    font-weight: bold;
                    text-align: center;
                }

                .championship-card .card-header > .championship-card-header-title {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .championship-card .card-footer {
                    color: snow;
                    border-top: 0px solid #454545;
                }

                .championship-card-header-subtitle {
                    font-size: 0.8em;
                    display: flex !important;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .championship-btn {
                    background-color: rgba(0,0,0,0);
                    color: snow;
                    border: 0;
                    width: fit-content;
                }

                .championship-btn-container-col {
                    padding: 0;
                    margin: 0;
                }

                .championship-item-start {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center;
                }

                .championship-item-end {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end !important;
                    align-items: center;
                }

                .week-card {
                    background-color: #2A2A2A;
                    color: snow;
                    border: 1px solid #3f3f3f;
                    margin-left: 1rem;
                    width: calc(100% - 2rem);
                    box-sizing: border-box;
                }

                .week-card:hover {
                    background-color: #2f2f2f;
                }

                .week-card .card-header {
                    color: snow;
                    font-size: 1em;
                    font-weight: bold;
                    text-align: center;
                }

                .week-card .card-body {
                    color: snow;
                    border: 0;
                }

                .week-card .card-footer {
                    color: snow;
                    border: 0;
                }

                .week-card .card-footer .championship-item-start .championship-btn,
                .week-card .card-footer .championship-item-end .championship-btn {
                    visibility: hidden;
                }

                .week-card:hover .card-footer .championship-item-start .championship-btn,
                .week-card:hover .card-footer .championship-item-end .championship-btn {
                    visibility: visible;
                }

                .week-card-session-row {
                    font-size: 0.8em;
                    font-weight: 350;
                    line-height: 1rem;
                }

                .week-card-session-row > * {
                    width: fit-content;
                    min-width: 20%;
                }

                .week-card-error-row {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.8em;
                }

                .add-week-button {
                    width: 100%;
                    background: transparent;
                    border-style: dashed;
                    border-width: 4px;
                    height: 100%;
                    border-color: #4b5660;
                    color: #4b5660;
                    font-weight: 600;
                    font-size: 2.3rem;
                }

                .add-week-button:hover, .add-week-button:focus, .add-week-button:active {
                    background: #4b5660 !important;
                    color: snow !important;
                    border-color: grey !important;
                }
            `}
            </style>
            <Card className="championship-card" style={{ backgroundImage: modifiedChampionship?.cardImage?.length === 0 || modifiedChampionship?.cardImage?.endsWith("/card/") ? `` : `linear-gradient(to bottom, rgba(51,51,51,0.75), ${Array((modifiedChampionship?.weeks?.length ?? 0) + 4).fill("#333333").join(', ')}), url('${modifiedChampionship?.cardImage}')` }}>
                <Card.Header>                  
                    <Row className="championship-card-header-title">
                        {modifiedChampionship?.name}
                    </Row>
                    <Row className="championship-card-header-subtitle">
                        <Col xs={12} className="championship-card-header-subtitle">
                            {`${formatDate(modifiedChampionship?.dateFrom)}`} - {`${formatDate(moment(modifiedChampionship?.dateFrom).add(modifiedChampionship?.weeks?.length * modifiedChampionship?.weekLength, 'days').toDate().toISOString())}`}
                        </Col>
                    </Row>
                    <Row className="championship-card-header-subtitle">
                        <Col xs={12} className="championship-card-header-subtitle">
                            {championshipActive ? `Active (${rcChampionship?.registrations?.length} Players)` : "Inactive"}
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {
                        modifiedChampionship?.weeks?.sort((a, b) => a?.week - b?.week)?.map((w: ChampionshipWeek, index: number) => {

                            return (
                                <Row key={index}>
                                    {
                                        !isEventConfigured(w) &&
                                        <Card className="week-card">
                                            <Card.Body>
                                                <Row className="week-card-error-row">
                                                    <Col>
                                                        <CiWarning color="rgba(200,0,0,0.8)" size="1rem" />{` Not Configured`}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Row className="championship-item-end">
                                                    <Button className="championship-item-end championship-btn" variant="primary" onClick={setShowChampionshipWeekConfigurationModal.bind(this, w?.week)}><FontAwesomeIcon icon={faEdit} /></Button>
                                                    <Button className="championship-item-end championship-btn" variant="danger" onClick={deleteWeek.bind(this, w?.week)}><FontAwesomeIcon color="red" icon={faTrash} /></Button>
                                                </Row>
                                            </Card.Footer>
                                        </Card>
                                    }
                                    {
                                        isEventConfigured(w) &&
                                        <Card className="week-card">
                                            <Card.Header>
                                                <Row style={{fontSize: "0.8rem"}}>
                                                    {`Week ${w?.week}`}{weekAt(Date.now()) === w?.week ? " (Active)" : ""}
                                                </Row>
                                                {
                                                    w?.track?.friendly == void(0) || w?.track?.friendly?.length === 0 || w?.track?.subItems == void(0) || w?.track?.subItems?.length === 0 || w?.track?.subItems?.[0]?.name == void(0) || w?.track?.subItems?.[0]?.name?.length === 0 ?
                                                    <Row key={index}>
                                                        <Col style={{fontSize: "0.8rem", left: "0", display: "flex", flexDirection: "row", alignItems: "center", padding: 0}}>
                                                            <CiWarning color="rgba(200,125,0,1)" size="1rem" style={{marginRight: "1rem"}}/>
                                                            No track selected
                                                        </Col>
                                                    </Row>
                                                :
                                                    <Row style={{fontSize: "0.8rem"}}>
                                                        {w?.track?.friendly}: {w?.track?.subItems?.[0]?.name}
                                                    </Row>
                                                }
                                            </Card.Header>
                                            <Card.Footer>
                                                <Row>
                                                    <Col className="championship-item-start championship-btn-container-col">
                                                        <Row>
                                                            {
                                                                w?.week > weekAt(Date.now()) && weekAt(Date.now()) !== w?.week - 1 ?
                                                                <Button className="championship-item-start championship-btn" variant="primary" onClick={moveWeekUp.bind(this, w?.week)}><IoIosArrowUp /></Button>
                                                                : <></>
                                                            }
                                                            {
                                                                w?.week > weekAt(Date.now()) && weekAt(Date.now()) !== w?.week + 1 ?
                                                                <Button className="championship-item-start championship-btn" variant="primary" onClick={moveWeekDown.bind(this, w?.week)}><IoIosArrowDown /></Button>
                                                                : <></>
                                                            }
                                                        </Row>
                                                    </Col>
                                                    <Col className="championship-item-end championship-btn-container-col">
                                                        <Row>
                                                            <Button className="championship-item-end championship-btn" variant="primary" onClick={setShowChampionshipWeekConfigurationModal.bind(this, w?.week)}>
                                                            {
                                                                w?.week > weekAt(Date.now()) ?
                                                                <FontAwesomeIcon icon={faEdit} />
                                                                :
                                                                <FontAwesomeIcon icon={faEye} />
                                                            }
                                                            </Button>
                                                            <Button className="championship-item-end championship-btn" variant="primary" onClick={duplicateWeek.bind(this, w?.week)}><FontAwesomeIcon icon={faCopy} /></Button>
                                                            {
                                                                w?.week > weekAt(Date.now()) && 
                                                                <Button className="championship-item-end championship-btn" variant="danger" onClick={showDeleteWeekAreYouSureModal.bind(this, w?.week)}><FontAwesomeIcon color="red" icon={faTrash} /></Button>
                                                            }
                                                            </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Footer>
                                        </Card>
                                    }
                                    <ChampionshipWeekConfigurationModal
                                        show={showChampionshipWeekConfigurationModal === w?.week}
                                        championship={modifiedChampionship}
                                        week={w}
                                        editable={w?.week > weekAt(Date.now())}
                                        onCancel={setShowChampionshipWeekConfigurationModal.bind(this, null)}
                                        onConfirm={onEditedChampionshipWeekConfiguration}
                                    />
                                </Row>
                            )

                        })
                    }
                    <Row>
                        <Col style={{margin: "0.3rem", marginTop: "1rem"}}>
                            <Button variant="primary" className="add-week-button" onClick={addWeek}>
                                Add Week
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row className="d-flex justify-content-end">
                        <Col>
                            {
                                isEdited &&
                                <Button variant="success" onClick={() => onSaveChampionshipConfiguration(modifiedChampionship)} disabled={!isEdited}>Save</Button>
                            }
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button className="championship-btn" variant="primary" onClick={() =>championshipActive ? setShowChampionshipEditActiveAreYouSureModal(true) : setShowChampionshipConfigurationModal(true)}><FontAwesomeIcon icon={faEdit} /></Button>
                            <Button className="championship-btn" variant="primary" onClick={props?.onDuplicate?.bind(this, modifiedChampionship)}><FontAwesomeIcon icon={faCopy} /></Button>
                            <Button className="championship-btn" variant="danger" onClick={setShowChampionshipDeletionAreYouSureModal.bind(this, true)}><FontAwesomeIcon color="red" icon={faTrash} /></Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
            <ChampionshipConfigurationModal
                show={showChampionshipConfigurationModal}
                championship={modifiedChampionship}
                isActive={championshipActive}
                onCancel={setShowChampionshipConfigurationModal.bind(this, false)}
                onConfirm={onEditedChampionshipConfiguration}
            />
            <AreYouSureModal
                customTitle="Are you sure you want to edit this championship?"
                customText={`The championship ${modifiedChampionship?.name} is active. Editing it will affect the current championship. Are you sure you want to proceed?`}
                show={showChampionshipEditActiveAreYouSureModal}
                onCancel={() => setShowChampionshipEditActiveAreYouSureModal(false)}
                onConfirm={() => {
                    setShowChampionshipConfigurationModal(true);
                    setShowChampionshipEditActiveAreYouSureModal(false);
                }}
            />
            <AreYouSureModal
                customTitle="Are you sure you want to delete this week?"
                customText={`Press confirm to delete week ${weekToDelete}`}
                show={showWeekDeletionAreYouSureModal}
                onCancel={() => setShowWeekDeletionAreYouSureModal(false)}
                onConfirm={() => {
                    if (weekToDelete == void (0) || weekToDelete === null) {
                        console.error("Week to delete not found");
                        return;
                    }

                    deleteWeek(weekToDelete);
                    setShowWeekDeletionAreYouSureModal(false);
                }}
            />
            <AreYouSureModal
                customTitle="Are you sure you want to delete this championship?"
                customText={`Press confirm to delete championship ${modifiedChampionship?.name}`}
                show={showChampionshipDeletionAreYouSureModal}
                onCancel={() => setShowChampionshipDeletionAreYouSureModal(false)}
                onConfirm={() => {
                    if (championshipActive) {
                        setShowChampionshipDeletionAreYouSureModal(false);
                        setShowChampionshipDeletionAreYouReallySureModal(true);
                        return;
                    } else {
                        removeChampionship();
                        setShowChampionshipDeletionAreYouSureModal(false);
                        setShowChampionshipDeletionAreYouReallySureModal(false);
                    }
                }}
            />
            <AreYouSureModal
                customTitle="Are you REALLY sure you want to delete this active championship?"
                customText={`${modifiedChampionship?.name} is an ACTIVE CHAMPIONSHIP, with ${rcChampionship?.registrations?.length} players registered. Deleting it will have consequences and is not a normal intended utility for live championships. It's probably fine on dev though. Are you REALLY sure you want to proceed?`}
                show={showChampionshipDeletionAreYouReallySureModal}
                onCancel={() => setShowChampionshipDeletionAreYouReallySureModal(false)}
                onConfirm={() => {
                    removeChampionship();
                    setShowChampionshipDeletionAreYouSureModal(false);
                    setShowChampionshipDeletionAreYouReallySureModal(false);
                }}
            />
        </>
    )
}

export default withAuthentication(ChampionshipCardViewComponent);
