import React, { useState, useEffect } from 'react';
import { Col, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { KeyValue } from "../../types/keyValue";
import { Assists } from '../../types/event/Assists';
import { get } from 'http';
import { ServerSettings, Tires } from '../../types/event/ServerSettings';
import { closedRaceSessions, trackLimitsRule } from '../../types/event/DailyRaceConfiguration';
import S3ObjectSelection from './S3ObjectSelection';

interface ServerSettingsParameters {
  settings: ServerSettings | null;
  onChanged: (assists: ServerSettings) => void;
}

const ServerSettingsComponent: React.FC<ServerSettingsParameters> = (params: ServerSettingsParameters) => {
  const [settings, setSettings] = useState<ServerSettings>(params.settings ?? new ServerSettings());

  useEffect(() => {
    if (params.settings) setSettings(params.settings);
  }, [params.settings]);

  const handleUpdate = (key: string, value: any) => {
    const newSetting: Partial<ServerSettings> = { [key]: value };
    const updatedSettings = { ...settings, ...newSetting };
    
    setSettings(updatedSettings);
    params.onChanged(updatedSettings);
  }

  const handleTireChange = (key: string, value: any) => {
    const newSetting: Partial<Tires> = { [key]: value };
    const updatedTires = { ...settings.tires, ...newSetting };
    const updatedSettings = { ...settings, tires: updatedTires };
    
    setSettings(updatedSettings);
    params.onChanged(updatedSettings);
  }

  type PlayerCountDataRate = {
    minDownload: number;
    minUpload: number;
    minPerClient: number;
    recommendedUpload: number;
    recommendedPerClient: number;
  }

  // https://motorsportgames.atlassian.net/wiki/spaces/LM100/pages/3115581445/Server+Sizing
  const playerCountDataRates = new Map<number, PlayerCountDataRate>([
    [8, { minDownload: 1900, minUpload: 2354, minPerClient: 295, recommendedUpload: 4700, recommendedPerClient: 600 }],
    [10, { minDownload: 2375, minUpload: 3274, minPerClient: 328, recommendedUpload: 6500, recommendedPerClient: 660 }],
    [15, { minDownload: 3563, minUpload: 6155, minPerClient: 411, recommendedUpload: 12300, recommendedPerClient: 825 }],
    [20, { minDownload: 4750, minUpload: 9866, minPerClient: 494, recommendedUpload: 20000, recommendedPerClient: 1000 }],
    [25, { minDownload: 5938, minUpload: 14405, minPerClient: 577, recommendedUpload: 30000, recommendedPerClient: 1160 }],
    [30, { minDownload: 7125, minUpload: 19774, minPerClient: 660, recommendedUpload: 39500, recommendedPerClient: 1320 }],
    [35, { minDownload: 8313, minUpload: 25972, minPerClient: 743, recommendedUpload: 52000, recommendedPerClient: 1500 }],
    [40, { minDownload: 9500, minUpload: 33000, minPerClient: 825, recommendedUpload: 66000, recommendedPerClient: 1650 }],
    [45, { minDownload: 10688, minUpload: 40856, minPerClient: 908, recommendedUpload: 82000, recommendedPerClient: 1820 }],
    [50, { minDownload: 11875, minUpload: 49542, minPerClient: 991, recommendedUpload: 100000, recommendedPerClient: 2000 }],
    [55, { minDownload: 13063, minUpload: 59058, minPerClient: 1074, recommendedUpload: 120000, recommendedPerClient: 2150 }],
    [62, { minDownload: 14725, minUpload: 73772, minPerClient: 1190, recommendedUpload: 150000, recommendedPerClient: 2400 }],
  ]);

  useEffect(() => {
    const closestEntry = Array.from(playerCountDataRates.entries()).reduce((prev, curr) => {
      return (Math.abs(curr[0] - settings?.maxPlayers) < Math.abs(prev[0] - settings?.maxPlayers) ? curr : prev);
    });

    const updatedSettings = { ...settings, downstreamRatedKbps: closestEntry[1].recommendedUpload, upstreamRatedKbps: closestEntry[1].recommendedUpload, maxDataPerClient: closestEntry[1].recommendedPerClient };
    setSettings(updatedSettings);
    params.onChanged(updatedSettings);  

  }, [settings?.maxPlayers]);

  return (
    <>
    <Row>
      <Col>
        <Form.Group controlId="serverMaxPlayers">
          <Form.Label>Max Players:</Form.Label>
          <Form.Control type="number" value={settings.maxPlayers} onChange={(e) => handleUpdate("maxPlayers", Number(e.target.value))} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="serverFixedSetup">
          <Form.Label>Fixed Setup:</Form.Label>
          <Form.Check type="checkbox" checked={settings.fixedSetup} onChange={(e) => handleUpdate("fixedSetup", e.target.checked)} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="serverPrivateQualifying">
          <Form.Label>Private Qualifying:</Form.Label>
          <Form.Check type="checkbox" checked={settings.privateQualifying} onChange={(e) => handleUpdate("privateQualifying", e.target.checked)} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="serverRealRoadScale">
          <Form.Label>Track Limits Rule:</Form.Label>
          <Form.Control className="dark-select" as="select" onChange={(e) => handleUpdate("trackLimitsRule", e.target.value)}>
            <option value="">Please Select</option>
            {trackLimitsRule.map((option) => (
              <option value={option.key} selected={settings.trackLimitsRule.toString() === option.key}>{option.value}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="trackLimitsPointsAllowed">
          <Form.Label>Track Limit Points:</Form.Label>
          <Form.Control type="number" value={settings.trackLimitsPointsAllowed} onChange={(e) => handleUpdate("trackLimitsPointsAllowed", Number(e.target.value))} />
        </Form.Group>
      </Col>
      <Col>
        <S3ObjectSelection bucket={process.env.REACT_APP_S3_CONTENT_BUCKET ?? "rf2-content"} 
          prefix="mod/" 
          value={settings.baseRFM} 
          filter="" title="Base RFM"
          onSelected={(selected: string) => setSettings({...settings, baseRFM: selected })} />  
      </Col>
    </Row>

    <Row>
      <Col>
        <Form.Group controlId="serverRealRoadScale">
          <Form.Label>Real Road Scale:</Form.Label>
          <Form.Control type="number" value={settings.realRoadScale} onChange={(e) => handleUpdate("realRoadScale", Number(e.target.value))} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="raceTimeScale">
          <Form.Label>Race Time Scale:</Form.Label>
          <Form.Control type="number" value={settings.raceTimeScale} onChange={(e) => handleUpdate("raceTimeScale", Number(e.target.value))} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="serverFuelConsumptionMultiplier">
          <Form.Label>Fuel Consumption Multi:</Form.Label>
          <Form.Control type="number" value={settings.fuelConsumptionMultiplier} onChange={(e) => handleUpdate("fuelConsumptionMultiplier", Number(e.target.value))} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="serverTireWearMultiplier">
          <Form.Label>Tire Wear Multiplier:</Form.Label>
          <Form.Control type="number" value={settings.tyreWearMultiplier} onChange={(e) => handleUpdate("tyreWearMultiplier", Number(e.target.value))} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="damgeMultiplier">
          <Form.Label>Damage Multiplier:</Form.Label>
          <Form.Control type="number" value={settings.damageMultiplier} onChange={(e) => handleUpdate("damageMultiplier", Number(e.target.value))} />
        </Form.Group>
      </Col>
    </Row>

    <Row>
      <Col>
        <Form.Group controlId="preRaceWait">
          <Form.Label>Pre Race Wait (sec):</Form.Label>
          <Form.Control type="number" value={settings.raceClientWait} onChange={(e) => handleUpdate("raceClientWait", Number(e.target.value))} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="closeRaceSession">
        <Form.Label>Closed Race Session:</Form.Label>
          <Form.Control as="select" onChange={(e) => handleUpdate("closedRaceSessions", Number(e.target.value))}>
            <option value="">Please Select</option>
            {closedRaceSessions.map((option) => (
              <option value={option.key} selected={settings.closedRaceSessions === option.key}>{option.value}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="maxDataPerClient">
          <Form.Label>Max Data Per Client (kbps):</Form.Label>
          <Form.Control type="number" value={settings.maxDataPerClient} onChange={(e) => handleUpdate("maxDataPerClient", Number(e.target.value))} />
        </Form.Group>
      </Col>
      <Col>
        <OverlayTrigger overlay=
            {<Popover className="setting-popover">
                <Popover.Header as="h3">Download is what the clients send to the server</Popover.Header>
                <Popover.Body>
                  This is used in RF2 / old LMU versions, but the latest LMU versions don't take this into account
                </Popover.Body>
            </Popover>}>
            <Row>
              <Form.Group controlId="downstreamRatedKbps">
                <Form.Label>Downstream Rate (kbps):</Form.Label>
                <Form.Control type="number" value={settings.downstreamRatedKbps} onChange={(e) => handleUpdate("downstreamRatedKbps", Number(e.target.value))} />
              </Form.Group>
            </Row>
        </OverlayTrigger>
      </Col>
      <Col>
        <Form.Group controlId="upstreamRatedKbps">
          <Form.Label>Upstream Rate (kbps):</Form.Label>
          <Form.Control type="number" value={settings.upstreamRatedKbps} onChange={(e) => handleUpdate("upstreamRatedKbps", Number(e.target.value))} />
        </Form.Group>
      </Col>
    </Row>

    <Row>
      <Col>
        <Form.Group controlId="limitedTireSets">
          <Form.Label>Limited Tires:</Form.Label>
          <Form.Check type="checkbox" checked={settings.tires?.limitedTireSets} onChange={(e) => handleTireChange("limitedTireSets", e.target.checked) } />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="availableTires">
          <Form.Label>Available Tires:</Form.Label>
          <Form.Control type="number" value={settings.tires?.availableTires} onChange={(e) => handleTireChange("availableTires", parseInt(e.target.value))} />
        </Form.Group>
      </Col>
    </Row>
    </>
  );
}

export default ServerSettingsComponent;