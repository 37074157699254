import { Col, Row } from 'react-bootstrap';
import { CiWarning } from 'react-icons/ci';

export interface WarningProps {
    customTitle?: string;
    customText?: string;
}

const Warning = (props: WarningProps) => {

    return (
        <>
            <style>
                {`
                    .warning-row {
                        background: rgba(200,125,0,0.3);
                        padding: 0.2rem;
                        border: 1px solid rgba(200,125,0,0.5);
                        display: flex;
                        align-items: center;
                    }
                `}
            </style>
            
            <Row className="warning-row">
                <Col xs={1}>
                    <CiWarning color="rgba(200,125,0,1)" size="2.5rem" style={{marginRight: "1rem"}}/>
                </Col>
                <Col xs={11}>
                    <Row style={{fontSize: "1.1rem", fontWeight: "bold"}}>
                        {props?.customTitle ?? "Warning!"}
                    </Row>
                    <Row>
                        {props?.customText}
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default Warning;