import React from 'react';
import { HandleChangeFunction } from './HandledChangeFunction';
import { Item } from '../types/event/Content';

interface ItemEditProps {
  item: Item;
  handleChange: HandleChangeFunction;
}

const ItemEdit = ({ item, handleChange }: ItemEditProps) => {
  return (
    <div className="container">
      <h4>MOD Item</h4>
      <div className="row">
        <div className="col-4">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={item.name}
            onChange={(e) => handleChange('name', e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-4">
          <label>Friendly:</label>
          <input
            type="text"
            name="friendly"
            value={item.friendly}
            onChange={(e) => handleChange('friendly', e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-4">
          <label>Version:</label>
          <input
            type="text"
            name="version"
            value={item.version}
            onChange={(e) => handleChange('version', e.target.value)}
            className="form-control"
          />
        </div>
      </div>
    </div>
  );
};

export default ItemEdit;
