import { Item } from "./Content";
import { Session } from "./Session";

export class DailyRacesContent {
  session: number = 0;
  title: string = "";
  smallImage: string = "";
  cardImage: string = "";
	heroImage: string = "";
  cars: Item[] = [];
  track: Item = new Item();
  vehicleFilter: string = "";
  fixedSetup: boolean = false;
  multiFormationLap: number = 0;

  sessions: Session[] = [];
}