import { useEffect, useState } from "react";
import withAuthentication from "../../../withAuthentication";
import RaceManagementClient from "../../../services/race-manaement.client";
import { Button, Table } from 'reactstrap';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { SpecialEvent } from "../../../types/event/SpecialEvent";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faEdit, faAdd } from '@fortawesome/free-solid-svg-icons'
import SpecialEventTable from "./SpecialEventTable";

function CronToHuman({ cronString }: { cronString: string }) {
  const [minute, hour, , , ] = cronString.split(' ');

  let description = 'At ';

  // Minute
  const minutes = minute.split(',');
  if (minutes.length > 1) {
      description += `${minutes[0]} and ${minutes[1]} minutes`;
  } else {
      description += `${minutes[0]} minute${minutes[0] === '1' ? '' : 's'}`;
  }

  // Hour
  const [startHour, endHour] = hour.split('-');
  description += ` past every hour from ${startHour} AM to ${endHour} PM`;

  // Adding everyday as we know it's every day from the cron
  description += ' every day.';

  return <div>{description}</div>;
}

const ListSpecialEventConfigurations = () => {
  const client = new RaceManagementClient();
  const [configurations, setConfigurations] = useState<SpecialEvent[]>([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    client.listSpecialEventConfigurations()
      .then((configs) => {
        setConfigurations(configs);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const editConfig = (seriesId: string) => async () => {
    console.log(`Edit config ${seriesId}`);
    navigate('/rm/configs/specialEvents/create?id=' + seriesId );
  }

  const copyConfig = (seriesId: string) => async () => {
    console.log(`Copy config ${seriesId}`);
    navigate('/rm/configs/specialEvents/create?id=' + seriesId + '&copy=true');
  }

  const deleteConfig = (seriesId: string) => async () => {
    console.log(`Delete config: ${seriesId}`);
    client.deleteSpecialEventConfiguration(seriesId)
      .catch((err) => {
        console.error(err);
      });
  }

  const formatDate = (date: string) => {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  return (
    <div>
      <Col sm={12}>
        <h1 className="d-flex" style={{marginBottom: "2rem"}}><span style={{flex: 1}}>Special Events</span> <button className="btn btn-primary align-content-end" onClick={() => navigate('/rm/configs/specialEvents/create')}><FontAwesomeIcon icon={faAdd} /> Create New</button></h1>
      </Col>

      <Tab.Container defaultActiveKey="current">
        <Row>
          <div className="d-flex flex-column" style={{ padding: 0, marginLeft: "1rem" }}>
            <div className="d-flex flex" style={{ padding: 0 }}>
              <Col sm={6}>
                <div className="d-flex" style={{height: "100%"}}>
                  <div style={{ marginBottom: "0.5rem", alignSelf: "end"}}>
                    <Nav variant="pills" className="flex">
                      <Nav.Item>
                        <Nav.Link eventKey="current">Current Events</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="future">Future Events</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="past">Historic Events</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Col>
            </div>

            <Col sm={12}>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey="current">
                    <SpecialEventTable type="current" />
                  </Tab.Pane>

                  <Tab.Pane eventKey="future">
                    <SpecialEventTable type="future" />
                  </Tab.Pane>

                  <Tab.Pane eventKey="past">
                    <SpecialEventTable type="past" />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </div>
        </Row>
      </Tab.Container>
    </div>
  )
}

export default withAuthentication(ListSpecialEventConfigurations);
