import React, { useEffect, useState, } from 'react';
import { Accordion, Badge, Button, Card, Col, Container, Dropdown, DropdownButton, Form, Modal, Overlay, OverlayTrigger, Popover, Row } from 'react-bootstrap';

import { Driver } from '../types/event/Driver'

import moment from 'moment';
import { Protest } from '../types/Protest';

export interface ProtestPenaltySelectDriverComponentProps {
    driver: Driver | null | undefined;
    protest: Protest | null | undefined;

    onSelectPenaltyTarget: (target: "protestee" | "protester" | "") => void;

}

const ProtestPenaltySelectDriverComponent = (props: ProtestPenaltySelectDriverComponentProps) => {

    const driverName = (driver: Driver | null | undefined) => {
        return driver?.name ?? "";
    }

    const protestHasPenaltiesApplied = () => {
        return props?.protest?.suspensionApplied || props?.protest?.strikeApplied || props?.protest?.srReductionApplied;
    }

    return (
        <>
            <style>
                {`
                `}
            </style>
            <Col sm="2" md="2" lg="2" xl="2" xxl="2" style={{ width: "fit-content", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                {
                    props?.driver != void (0) ?

                        <OverlayTrigger
                            trigger="click"
                            placement="top"
                            overlay={
                                <Popover id="protest-view-ban-days-popover">
                                    <Popover.Header as="h3" style={{ backgroundColor: "#333333", color: "snow" }}>
                                        Deselect Driver
                                    </Popover.Header>
                                    <Popover.Body style={{ backgroundColor: "#333333", color: "snow" }}>
                                        <Form.Group className="mb-3" controlId="protest-view-ban-days-form" style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            {
                                                protestHasPenaltiesApplied() ?
                                                    <Form.Label>
                                                        You must remove all penalties before you can deselect a driver.
                                                    </Form.Label>
                                                    :
                                                    <Button onClick={props?.onSelectPenaltyTarget.bind(this, "")}>
                                                        Deselect
                                                    </Button>
                                            }
                                        </Form.Group>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <Badge pill bg="danger" style={{ cursor: "pointer" }}>
                                {`Penalties applied to: ${driverName(props?.driver)}`}
                            </Badge>
                        </OverlayTrigger>

                        :

                        <DropdownButton
                            id="protest-view-ban-days-dropdown"
                            title={`Apply penalties to: ${driverName(props?.driver)}`}
                            variant={"danger"}>
                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onSelectPenaltyTarget.bind(this, "protestee")}>Protestee</Dropdown.Item>
                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onSelectPenaltyTarget.bind(this, "protester")}>Protester</Dropdown.Item>
                        </DropdownButton>
                }
            </Col>
        </>
    );
}

export default ProtestPenaltySelectDriverComponent;