import { ChangeEvent } from 'react';
import { Badge, Button, Container, Form, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import Datetime from "react-datetime";
import moment from 'moment';
import { IProtestSearchParameters, ProtestOutcome, ProtestStatus, ProtestType } from '../../types/Protest';

export interface MainSearchBarComponentProps {
  protestSearchParameters: IProtestSearchParameters;
  setProtestSearchParameters: (protestSearchParameters: IProtestSearchParameters) => void;
  setProtestGameSearchParameter: (game: string) => void;
  setProtestTypeSearchParameter: (protestType: ProtestType) => void;
  setProtestTextSearchParameter: (event: ChangeEvent) => void;
  setProtestStatusSearchParameter: (status: ProtestStatus, reset: boolean) => void;
  setProtestOutcomeSearchParameter: (outcome: ProtestOutcome) => void;
  setProtestTimestampFromSearchParameter: (timestampFrom: string | moment.Moment) => void;
  setProtestTimestampToSearchParameter: (timestampFrom: string | moment.Moment) => void;
  resetSearchParameters: () => void;
}

const MainSearchBarComponent = (props: MainSearchBarComponentProps) => {

  return (
    <Navbar bg="dark" expand="lg" className="protest-search-bar">
      <Container fluid>
        <NavDropdown style={{ paddingRight: "1%" }}
          id="protest-search-bar-type-dropdown"
          title={
            props?.protestSearchParameters.protestType === ProtestType.Report ?
              "Reports"
              :
              props?.protestSearchParameters.protestType === ProtestType.Protest ?
                "Protests"
                : "All Games"
          }
          menuVariant="dark"
        >
          <NavDropdown.Item onClick={props?.setProtestGameSearchParameter.bind(this, "rf2")}>
            RF2
          </NavDropdown.Item>
          <NavDropdown.Item onClick={props?.setProtestGameSearchParameter.bind(this, "lmu")}>
            LMU
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={props?.setProtestGameSearchParameter.bind(this, "all")}>
            All Games
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown style={{ paddingRight: "1%" }}
          id="protest-search-bar-type-dropdown"
          title={
            props?.protestSearchParameters.protestType === ProtestType.Report ?
              "Reports"
              :
              props?.protestSearchParameters.protestType === ProtestType.Protest ?
                "Protests"
                : "Any Type"
          }
          menuVariant="dark"
        >
          <NavDropdown.Item onClick={props?.setProtestTypeSearchParameter.bind(this, ProtestType.Protest)}>
            Protests
          </NavDropdown.Item>
          <NavDropdown.Item onClick={props?.setProtestTypeSearchParameter.bind(this, ProtestType.Report)}>
            Reports
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={props?.setProtestTypeSearchParameter.bind(this, ProtestType.Unknown)}>
            Any Type
          </NavDropdown.Item>
        </NavDropdown>
        <Form className="d-flex align-items-center protest-search-bar-form-container">
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2 protest-search-bar-text-field"
            aria-label="Search"
            defaultValue={props?.protestSearchParameters.text}
            onChange={props?.setProtestTextSearchParameter.bind(this)}
          />
        </Form>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <NavDropdown
              id="protest-search-bar-status-dropdown"
              title={
                <Badge bg={props?.protestSearchParameters.status === ProtestStatus.Open ? "success" : props?.protestSearchParameters.status === ProtestStatus.Closed ? "danger" : "secondary"} pill>
                  {props?.protestSearchParameters.status === ProtestStatus.Open ?
                    "Open"
                    :
                    props?.protestSearchParameters.status === ProtestStatus.Closed ?
                      "Closed"
                      :
                      "Any Status"
                  }
                </Badge>
              }
              menuVariant="dark"
            >
              <NavDropdown.Item onClick={props?.setProtestStatusSearchParameter.bind(this, ProtestStatus.Open, false)}>
                <Badge bg="success" pill>
                  Open
                </Badge>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={props?.setProtestStatusSearchParameter.bind(this, ProtestStatus.Closed, false)}>
                <Badge bg="danger" pill>
                  Closed
                </Badge>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={props?.setProtestStatusSearchParameter.bind(this, ProtestStatus.Unknown, false)}>
                Any Status
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              id="protest-search-bar-outcome-dropdown"
              title={
                <Badge bg={props?.protestSearchParameters.outcome === ProtestOutcome.Accepted ? "success" : props?.protestSearchParameters.outcome === ProtestOutcome.Rejected ? "danger" : "secondary"} pill>
                  {props?.protestSearchParameters.outcome === ProtestOutcome.Accepted ?
                    "Accepted"
                    :
                    props?.protestSearchParameters.outcome === ProtestOutcome.Rejected ?
                      "Rejected"
                      :
                      props?.protestSearchParameters.outcome === ProtestOutcome.Dismissed ?
                        "Dismissed"
                        :
                        "Any Outcome"
                  }
                </Badge>
              }
              menuVariant="dark"
            >
              <NavDropdown.Item onClick={props?.setProtestOutcomeSearchParameter.bind(this, ProtestOutcome.Accepted)}>
                <Badge bg="success" pill>
                  Accepted
                </Badge>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={props?.setProtestOutcomeSearchParameter.bind(this, ProtestOutcome.Rejected)}>
                <Badge bg="danger" pill>
                  Rejected
                </Badge>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={props?.setProtestOutcomeSearchParameter.bind(this, ProtestOutcome.Dismissed)}>
                <Badge bg="secondary" pill>
                  Dismissed
                </Badge>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={props?.setProtestOutcomeSearchParameter.bind(this, ProtestOutcome.Unknown)}>
                Any Outcome
              </NavDropdown.Item>
            </NavDropdown>
            <div className="row me-auto" style={{ width: "fit-content", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
              <span>From:</span>
            </div>
            <div className="protest-timepicker-container">
              <Datetime
                key={props?.protestSearchParameters?.timestampTo}
                onChange={props?.setProtestTimestampFromSearchParameter.bind(this)}
                value={props?.protestSearchParameters?.timestampTo === 0 ? "" : moment.unix(props?.protestSearchParameters?.timestampFrom).toDate()}
              />
            </div>
            <div className="row me-auto" style={{ width: "fit-content", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
              <span>To:</span>
            </div>
            <div className="protest-timepicker-container">
              <Datetime
                key={props?.protestSearchParameters?.timestampFrom}
                onChange={props?.setProtestTimestampToSearchParameter.bind(this)}
                value={props?.protestSearchParameters?.timestampTo === 0 ? "" : moment.unix(props?.protestSearchParameters?.timestampTo).toDate()}
              />
            </div>
          </Nav>
        </Navbar.Collapse>
        <Button variant="outline-success" onClick={props?.resetSearchParameters}>Reset Filters</Button>
      </Container>
    </Navbar>
  );
}

export default MainSearchBarComponent;