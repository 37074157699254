export class Assists {
  antiLockBrakes: number = 2;
  autoBlip: number = 1;
  autoClutch: number = 1;
  autoLift: number = 1;
  autoPit: number = 0;
  autoShift: number = 3;
  brakeHelp: number = 2;
  drivingLine: number = 2;
  invulnerability: number = 0;
  oppositeLock: number = 1;
  spinRecovery: number = 2;
  stabilityControl: number = 2;
  steeringHelp: number = 3;
  tractionControl: number = 3;
  visFastLine: number = 3;

  static assistForDisplay = (assist: number) => {
    switch (assist) {
      case 0:
        return "Off";
      case 1:
        return "Low";
      case 2:
        return "Medium";
      case 3:
        return "High";
      default:
        return "";
    }
  }

  static onOffAssistForDisplay = (assist: number) => {
    switch (assist) {
      case 0:
        return "Off";
      case 1:
        return "On";
      default:
        return "";
    }
  } 
}