import { Col, Row } from 'react-bootstrap';
import { BsExclamationCircle } from 'react-icons/bs';

export interface NoteProps {
    customTitle?: string;
    customText?: string;
}

const Note = (props: NoteProps) => {

    return (
        <>
            <style>
                {`
                    .note-row {
                        background: rgba(42, 64, 175,0.3);
                        padding: 0.2rem;
                        border: 1px solid rgba(42, 64, 255,0.6);
                        display: flex;
                        align-items: center;
                    }
                `}
            </style>
            
            <Row className="note-row">
                <Col xs={1}>
                    <BsExclamationCircle color="rgba(42, 64, 255, 0.6)" size="2.5rem" style={{marginRight: "1rem"}}/>
                </Col>
                <Col xs={11}>
                    <Row style={{fontSize: "1.1rem", fontWeight: "bold"}}>
                        {props?.customTitle ?? "Note"}
                    </Row>
                    <Row>
                        {props?.customText}
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default Note;