import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Navbar, Row } from 'react-bootstrap';
import { BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs';

export interface PaginationComponentProps {
  curPage: number;
  total: number;
  viewTotal: number;
  pageSize: number;

  nextPage: () => void;
  prevPage: () => void;
}

const PaginationComponent = (props: PaginationComponentProps) => {

  return (
    <>
      <Navbar bg="dark" expand="lg" className="protest-search-bar">
        <Container fluid>
          <Navbar.Collapse id="navbarScroll">
            <Button variant="outline-secondary"
              hidden={props?.curPage === 0}
              onClick={props?.prevPage} style={{ height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
              <BsCaretLeftFill size="1em" color="lightgrey" />
            </Button>
          </Navbar.Collapse>
          <Navbar.Collapse id="navbarScroll">
            <span style={{ fontWeight: "bold" }}>Page {props?.curPage}:&nbsp;&nbsp;</span>
            {
              props?.total > 0 && props?.viewTotal > 0 ?
                <span style={{ fontWeight: "lighter" }}> {(props?.curPage * props?.pageSize) + 1} ... {(props?.curPage * props?.pageSize) + props?.viewTotal} / {props?.total}</span>
                :
                <span style={{ fontWeight: "lighter" }}>No results found</span>
            }
          </Navbar.Collapse>
          <Button variant="outline-secondary"
            hidden={props?.viewTotal < props?.pageSize || (props?.curPage * props?.pageSize) + props?.viewTotal === props?.total}
            onClick={props?.nextPage} style={{ height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
            <BsCaretRightFill size="1em" color="lightgrey" />
          </Button>
        </Container>
      </Navbar>
    </>
  );
}

export default PaginationComponent;