import React, { useEffect, useRef, useCallback, useState } from 'react';
import { InstalledContent } from '../types/InstalledContent';
import ContentManagerClient from '../services/content-manager.client';
import { Col, Form } from 'react-bootstrap';

interface SearchProps {
  onClick: (content: InstalledContent[]) => void;
}

const Search = ({ onClick }: SearchProps) => {
  const [search, setSearch] = useState<string>('');
  const [game, setGame] = useState<string>('lmu');
  const timeoutHandle = useRef<NodeJS.Timeout | null>(null);
  const cm = new ContentManagerClient();

  const fetchContent = useCallback(async (search: string, game: string) => {
    const result = await cm.getContent(game);
    const filtered = result.filter((item) => {
      try {
        return match(search.toLowerCase(), item.name?.toLowerCase()) || 
               match(search.toLowerCase(), item.installType?.toLowerCase()) ||
               match(search.toLowerCase(), item.type?.toLowerCase()) ||
               match(search.toLowerCase(), item.fileName?.toLowerCase()) || 
               match(search.toLowerCase(), item.content?.friendly.toLowerCase()) || 
               match(search.toLowerCase(), item.content?.name.toLowerCase()) ||
               item.tags?.some((tag) => match(search.toLowerCase(), tag.name?.toLowerCase())) ||
               item.content?.subItems?.some((subItem) => match(search.toLowerCase(), subItem?.name?.toLowerCase()) || 
                match(search.toLowerCase(), subItem?.friendly?.toLowerCase()));
      } catch (err) {
        console.log("error searching within: ", item, err);
        return true;
      }
    }).sort((a, b) => a.name.localeCompare(b.name));

    onClick(filtered);
  }, [onClick]);

  useEffect(() => {
    console.log('Search.tsx: useEffect ', search, game);
    fetchContent(search, game);    
  }, [search, game, fetchContent]);

  const match = (search: string, value: string): boolean => {
    if (!value) return false;
    return value.includes(search);
  }

  const handleSearch = (search: string) => {
    if (timeoutHandle.current) clearTimeout(timeoutHandle.current);

    const timeout = setTimeout(() => setSearch(search), 300);
    timeoutHandle.current = timeout;
  }

  return (
    <>
      <Col>
        <Form.Label>Search</Form.Label>
        <Form.Control className="dark-select" as="text" onChange={(e) => handleSearch(e.target.value)} />   
      </Col>
      <Col>
        <Form.Label>Game</Form.Label>
        <Form.Control className="dark-select" as="select" value={game} onChange={(e) => setGame(e.target.value)}> 
          <option value="">Select</option>       
          <option key="rf2" value="rf2">RF2</option>
          <option key="lmu" value="lmu">LMU</option>
        </Form.Control>          
      </Col>
    </>
  );
};

export default React.memo(Search);
