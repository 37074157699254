import { Session } from "../../types/event/Session";
import { Assists } from "../../types/event/Assists";
import { Content, Item } from "../../types/event/Content";
import { Ratings } from "../../types/event/Ratings";
import { ServerSettings } from "../../types/event/ServerSettings";
import { Registration } from "../../types/event/Event";
import { Driver } from "../../types/event/Driver";
import { Tier } from "../../types/event/Tier";

export type ChampionshipType = "Individual" | "Team" | "Endurance";
export type LeagueType = "SkillBased" | "Manufacturer" | "Class";

export class Championship {
    _id: string = "";
    seasonId: string = "";
    game: string = "";
    type: ChampionshipType = "Individual";
    name: string = "";
    seasonName: string = "";
    description: string = "";

    dateFrom: string = "";
    dateTo: string = "";

    weekLength: number = 7;

    frequency: string[] = [""];
    practiceFrequency: string = "";

    tier: Tier = {} as Tier;

    serverSize: string = "";

    dropWeeks: number = 0;

    splitSettings: string = "";

    cardImage: string = "";

    serverSettings: ServerSettings = {} as ServerSettings;

    ratings: Ratings = {} as Ratings;

    cars: Item[] = [];
    vehicleFilter: string = "";

    weeks: ChampionshipWeek[] = [];

    maxEventRegistrations: number = 0;
    playersPerSkillBasedLeague: number = 0;

    registrations: ChampionshipRegistration[] = [];
    leagues: League[] = [];
}

export class ChampionshipWeek {
    week: number = 0;

    freeEntry: boolean = false;
    multiFormationLap: number = 0;

    frequency: string[] = [""];

    track: Item = new Item();

    pointsMultiplier: number = 1;

    sessions: Session[] = [];
}


export class ChampionshipRegistration implements Registration {
    // Implement all properties from the Registration interface
    registeredAt: string = "";
    driver: Driver = {} as Driver;
    class: string = "";
    car: Item = {} as Item;
    manufacturer: string = "";

    registeredEvents: { [key: number]: string[] } = {};
}

export class League {
    type: LeagueType = "SkillBased";
    name: string = "";
    eloMin: number = 0;
    eloMax: number = 0;
    players: Registration[] = [];
}