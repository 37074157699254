import React, { useCallback, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Driver } from '../../../types/event/Driver'
import { useAuth } from '../../../AuthContext';

export interface DriverManualSafetyRatingActionModalProps {
    driver: Driver;
    game: string;
    show: boolean;
    onCancel: () => void;
    onSafetyRatingAdjusted: () => void;
}

const DriverManualSafetyRatingActionModal = (props: DriverManualSafetyRatingActionModalProps) => {

    const auth = useAuth();

    const [safetyRatingAdjustment, setSafetyRatingAdjustment] = useState<number>(0);
    const onSafetyRatingAdjustmentChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSafetyRatingAdjustment(-Math.abs(parseInt(event.target.value)));
    }

    const [reason, setReason] = useState<string>("");
    const onReasonChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReason(event.target.value);
    }

    const applySafetyRatingAdjustment = useCallback(() => {
        if (!auth.self) {
            return;
        }
        Driver.prototype.applySafetyRatingAdjustment
            .call(props?.driver, safetyRatingAdjustment, reason, auth.self.name, props?.game)
            .then(Driver.prototype.refresh.bind(props?.driver, props?.game))
            .catch(console.error)
            .finally(props?.onSafetyRatingAdjusted);
    }, [props.driver, safetyRatingAdjustment, reason, auth.self, props.game, props.onSafetyRatingAdjusted]);

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .sr-form-text-grey {
                        color: grey !important;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Apply Safety Rating Adjustment to {props.driver?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row style={{marginBottom: "10px"}}>
                            <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="number" onChange={onSafetyRatingAdjustmentChanged} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={onReasonChanged} />
                                <Form.Text className="sr-form-text-grey">
                                    Reason for the reduction which will be sent to the user
                                </Form.Text>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{width: "100%"}}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{display: "flex", justifyContent: "flex-end"}} >
                            <Button variant="warning" onClick={applySafetyRatingAdjustment}>Apply</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverManualSafetyRatingActionModal;