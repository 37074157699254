import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createPortal } from 'react-dom';

export interface DarkDatepickerComponentProps {
  value: Date;
  showTimeSelect?: boolean;
  onChange: (date: Date) => void;
  disabled?: boolean;
}

const DarkDatepickerComponent = (props: DarkDatepickerComponentProps) => {

  const portalId = `datepicker-portal-${Math.random().toString(36).substring(7)}`;

  return (
    <>
      <style>
        {` 
          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }

          .react-datepicker__input-container input {
            background-color: #40454b !important;
            color: snow;
            display: block;
            width: 100%;
            padding: .375rem .75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            background-clip: padding-box;
            border: 1px solid #72787f;
            appearance: none;
            border-radius: var(--bs-border-radius);
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            margin-bottom: 0;
          }

          .react-datepicker {
            background-color: #333 !important;
            color: snow;
          }

          .react-datepicker-popper {
            width: max-content;
          }

          .react-datepicker__header {
              background-color: #333 !important;
              color: snow;
          }

          .react-datepicker__current-month {
              background-color: #333 !important;
              color: snow;
          }

          .react-datepicker__day--selected {
              background-color: #333 !important;
              font-weight: bold;
              color: red !important;
          }

          .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
              background-color: #333 !important;
              color: snow;
          }

          .react-datepicker__time-container {   
              background-color: #333 !important;
              color: snow;
          }

          .react-datepicker__time {   
              background-color: #333 !important;
              color: snow;
          }

          .react-datepicker__time-container * {   
              background-color: #333 !important;
              color: snow;
          }

          .react-datepicker__time-container > * {   
              background-color: #333 !important;
              color: snow;
          }

          .react-datepicker__time-list-item--selected {
              background-color: #333 !important;
              color: red !important;
              font-weight: bold;
          }

          .datepicker-portal {
            position: absolute !important;
            z-index: 9999 !important;
            top: 0 !important;
            display: hidden !important;
          }
        `}
      </style>
      {/* The datepicker component can get stuck under other components when it pops out so this portal is necessary... */}
      {createPortal(<div id={portalId} className="datepicker-portal" />, document.body)}
      <DatePicker
        selected={props?.value}
        onChange={(date: Date) => props?.onChange(date)}
        showTimeSelect={props?.showTimeSelect}
        dateFormat="Pp"
        portalId={portalId}
        disabled={props?.disabled ?? false}
      />
    </>
  );
}

export default DarkDatepickerComponent;