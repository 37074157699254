import { useEffect, useState, useRef } from "react";
import withAuthentication from "../withAuthentication";
import { Button, Form, Container, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { games, multiFormationLap } from "../types/event/DailyRaceConfiguration";
import TrackSelection from "../rm/configs/TrackSelection";
import CarSelection from "../rm/configs/CarSelection";
import SessionModal from "../rm/configs/SessionModal";
import { ErrorResponse } from "../types/ErrorResponse";
import { RequestServerConfiguration } from "../types/RequestServerConfiguration";
import ServerManagerClient from "../services/server-manager.client";
import { Session } from "../types/event/Session";
import AssistsComponent from "../rm/configs/Assists";
import { serverSizes } from "../types/event/SpecialEvent";
import ServerSettingsComponent from "../rm/configs/ServerSettingsComponent";

const LaunchServer = () => {
  const client = useRef<ServerManagerClient>(new ServerManagerClient());

  const [config, setConfig] = useState<RequestServerConfiguration>(new RequestServerConfiguration());
  const [errors, setErrors] = useState<ErrorResponse[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  useEffect(() => {}, [config]);


  const launch = async () => {
    if (config) {
      await client.current.request(config)
        .then((response) => {
          console.log("response: "+response.statusText);
          if (response.status === 200) {
            navigate('/');
          } else if (response.status === 400) {
            response.json().then((errors) => {
              setErrors(errors);
            });
          } else {
            setErrors([{field: "", error: "An unknown error occurred"}]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  return (
    <Container>
      <h1>Launch a Server</h1>

      <Modal show={errors && errors.length > 0} onHide={() => { setErrors([]) }}>
        <Modal.Header closeButton>
          <Modal.Title>You've got issues, Get Good Scrub</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {errors.map((error) => (
              <li>{error.field}: {error.error}</li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>

      <Form>
        <h3 style={{marginTop: "2rem"}}>Server Information</h3>
        <hr/>
        <Row>
          <Col>
            <Form.Group controlId="game">
              <Form.Label>Game:</Form.Label>
              <Form.Control as="select" value={config.game} onChange={(e) => setConfig({...config, game: e.target.value})}>
                <option value="">Please Select</option> 
                {games.map((game) => (
                  <option value={game.toLowerCase()} selected={config.game === game}>{game}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="game">
              <Form.Label>Game:</Form.Label>
              <Form.Control as="select" value={config.serverSize} onChange={(e) => setConfig({...config, serverSize: e.target.value})}>
                <option value="">Please Select</option> 
                {serverSizes.map((game) => (
                  <option value={game.toLowerCase()} selected={config.game === game}>{game}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col sm={1}>
            <Form.Group controlId="count">
              <Form.Label>Count:</Form.Label>
              <Form.Control type="number" value={config.count} onChange={(e) => setConfig({...config, count: Number(e.target.value)})} />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="name">
              <Form.Label>Name:</Form.Label>
              <Form.Control value={config.name} onChange={(e) => setConfig({...config, name: e.target.value, config: { ...config.config, eventId: e.target.value } })} />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="password">
              <Form.Label>Password:</Form.Label>
              <Form.Control value={config.config.password} onChange={(e) => setConfig({...config, hasPassword: e.target.value !== "",  config: { ...config.config, password: e.target.value}})} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="generatePassword">
              <Form.Label>Generate Password:</Form.Label>
              <Form.Check type="checkbox" checked={config.generatePassword} onChange={(e) => setConfig({...config, generatePassword: e.target.checked})} />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="generateLobbyCode">
              <Form.Label>Lobby Code:</Form.Label>
              <Form.Check type="checkbox" checked={config.generateLobbyCode} onChange={(e) => setConfig({...config, generateLobbyCode: e.target.checked})} />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="offical">
              <Form.Label>Offical:</Form.Label>
              <Form.Check type="checkbox" checked={config.official} onChange={(e) => setConfig({...config, official: e.target.checked})} />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="public">
              <Form.Label>Public:</Form.Label>
              <Form.Check type="checkbox" checked={config.public} onChange={(e) => setConfig({...config, public: e.target.checked})} />
            </Form.Group>
          </Col>
        </Row>

        <h3 style={{marginTop: "2rem"}}>Server Settings</h3>
        <hr/>
        <Row>
          <Col>
            <Form.Group controlId="multiFormationLap">
              <Form.Label>Multi Formation Lap:</Form.Label>
              <Form.Control as="select" onChange={(e) => setConfig({...config, config: { ...config.config, settings: {...config.config.settings, multiFormationLap: Number(e.target.value)}}})}>
                <option value="">Please Select</option>
                {multiFormationLap.map((option) => (
                  <option value={option.key} selected={config.config.settings.multiFormationLap.toString() === option.key}>{option.value}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        
          <Col>
            <Form.Group controlId="vehicleFilter">
              <Form.Label>Vehicle Filter:</Form.Label>
              <Form.Control value={config.config.settings.vehicleFilter} 
                onChange={(e) => setConfig({...config,  config: { ...config.config, settings: {...config.config.settings, vehicleFilter: e.target.value}}})} />
            </Form.Group>
          </Col>
        </Row>

        <ServerSettingsComponent settings={config.config.settings} onChanged={(settings) => setConfig({...config, config: { ...config.config, settings: settings }})} />

        
        <h3 style={{marginTop: "2rem"}}>Track & Cars</h3>
        <hr />
        <Row>
          <Col>
            <TrackSelection game={config.game} track={config.config.track} layout={config.config.track.subItems[0]} onSelected={(track) => setConfig({...config, config: { ...config.config, track: track }})} />
          </Col>
          <Col>
            <CarSelection game={config.game} cars={config.config.cars} onSelected={(cars) => setConfig({...config, config: { ...config.config, cars: cars }})} />
          </Col>
        </Row>

        
        <h3 style={{marginTop: "2rem"}}>Session Configuration</h3> 
        <hr/>
        <Row>
          {config.config.sessions.map((session, index) => (
            <SessionModal session={session} index={index} sessions={config.config.sessions} setSessions={(newSessions) => setConfig({...config,  config: { ...config.config, sessions: newSessions}})} />
          ))}
          <Col sm={3}>
            <Button variant="secondary" onClick={() => setConfig({...config, config: {...config.config, sessions: [...config.config.sessions, new Session()]}})}>Add Session</Button> 
          </Col>
        </Row>

        <h3 style={{marginTop: "2rem"}}>Assists</h3>
        <hr />
        <Row>
          <AssistsComponent assists={config.config.settings.assists} onChanged={(assists) => { setConfig({ ...config, config: { ...config.config, settings: { ...config.config.settings, assists: assists } }}) } } />
        </Row>

        <Row>
          <Col>
            <Button style={{margin: "2rem 0rem 3rem"}} variant="primary" type="button" onClick={launch}>Request Server</Button>
          </Col>
        </Row>
      </Form>

      <footer></footer>
    </Container>
  )
}

export default withAuthentication(LaunchServer);
