import { ContentManagerStatus } from "../types/ContentManagerHealthResponse";
import { InstalledContent } from "../types/InstalledContent";
import OAuthClient from "./oauth.client";

class ContentManagerClient {
  url = process.env.REACT_APP_CM_URL;
  awsAuthSecretName = process.env.REACT_APP_AWS_CM_AUTH_SECRET_NAME;
  oauth: OAuthClient;

  constructor() {
    this.oauth = new OAuthClient();
  }

  async status(): Promise<ContentManagerStatus> {
    const response = await this.oauth.fetch(`${this.url}/health`);
    return response.json().then((data: any) => {
      return data as ContentManagerStatus;
    });
  }

  async getContentByType(type: string, game: string, name: string): Promise<InstalledContent[]> {
    const response = await this.oauth.fetch(`${this.url}/content/by/${game}/${type}/${name}`, "GET");
    return response?.json().then((data: any) => {
      if (!data?.InstalledContent) return [];
      return data["InstalledContent"] as InstalledContent[];
    }) || [];
  }

  async getContent(game: string): Promise<InstalledContent[]> {
    try {
      const response = await this.oauth.fetch(`${this.url}/content/${game}/.*`, "GET");
      if (!response?.ok) {
        throw new Error('Failed to get content');
      }
  
      const data = await response.json();
      return data["InstalledContent"] as InstalledContent[];
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async update(content: InstalledContent) : Promise<void> {
    try
    {
      console.log(`Updating content: ${content.Id}`, content);
      const response = await this.oauth.fetch(`${this.url}/content`, "PUT", JSON.stringify(content));
      if (!response?.ok) {
        throw new Error('Failed to update content');
      }

      console.log(`Updated content: ${content.Id}`);
    } catch (error) {
      console.log(error);
      //throw error;
    }
  }

  async delete(content: InstalledContent) : Promise<void> {
    const response = await this.oauth.fetch(`${this.url}/content/${content.Id}`, "DELETE");
    if (!response?.ok) {
      throw new Error('Failed to delete content');
    }
  }

  async sync(full: boolean) : Promise<void> {
    const response = await this.oauth.fetch(`${this.url}/content/sync?refresh=${full}`, "GET");
    if (!response?.ok) {
      throw new Error('Failed to sync content');
    }
  }

  async install(installType: string, workshopId: number, itemDef: string) : Promise<void> {
    const response = await this.oauth.fetch(
      `${this.url}/content/install`, 
      "POST", 
      JSON.stringify({ workshopId: workshopId, itemDefId: itemDef, installType: installType })
    );
    if (!response?.ok) {
      throw new Error('Failed to install content');
    }
  }
}

export default ContentManagerClient;
