import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col } from 'react-bootstrap';
import SecurityServiceClient from '../../services/security-service.client';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { HttpProtectedRole, HttpProtectedRouteViewModel, HttpProtectedUser, HttpProtectedUserViewModel } from '../../types/Security';
import RolesListView from './RolesListView';
import { FaUserPlus } from 'react-icons/fa';
import CreateRoleModal from './CreateRoleModal';

const RolesView = () => {

    const securityService = new SecurityServiceClient();

    const [showCreateRoleModal, setShowCreateRoleModal] = useState<boolean>(false);
    const [routes, setRoutes] = useState<Array<HttpProtectedRouteViewModel>>([]);
    const [routesByService, setRoutesByService] = useState<Map<string, Array<HttpProtectedRouteViewModel>>>(
        new Map<string, Array<HttpProtectedRouteViewModel>>()
    );
    const [allRoles, setAllRoles] = useState<Array<HttpProtectedRole>>([]);
    const [allUsers, setAllUsers] = useState<Array<HttpProtectedUserViewModel>>([]);
    useEffect(() => {
        securityService.getAllProtectedUsers(0, 100).then((users) => {
            setAllUsers(users);
        });

        securityService.getAllRoles().then((roles) => {
            setAllRoles(roles);
        });

        refreshRoutes();
    }, []);

    const refreshRoles = () => {
        securityService.getAllRoles().then((roles) => {
            setAllRoles(roles);
        });
    }

    const refreshUsers = () => {
        securityService.getAllProtectedUsers(0, 100).then((users) => {
            setAllUsers(users);
        });
    }

    const refreshRoutes = () => {
        securityService.getAllRoutes(0, 10000).then((routes) => {
            setRoutes(routes);
            sortRoutesByService(routes);
        });
    }

    const sortRoutesByService = (routes: Array<HttpProtectedRouteViewModel>) => {
        const routesByService = new Map<string, Array<HttpProtectedRouteViewModel>>();
        routes.forEach((route) => {
            if (!routesByService.has(route.service)) {
                routesByService.set(route.service, []);
            }
            routesByService.get(route.service)?.push(route);
        });
        
        setRoutesByService(routesByService);
    }

    return (
        <>
            <style>
            {`
            `}
            </style>
            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row>
                    <Col>
                        <h3>Roles</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end align-items-end">
                        <Button variant="success" onClick={()=>{setShowCreateRoleModal(true)}} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FaUserPlus style={{ marginRight: '0.4rem' }} />
                            Create Role
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RolesListView
                            allUsers={allUsers}
                            allRoles={allRoles}
                            routes={routes}
                            routesByService={routesByService}
                            requestRefresh={() => (refreshUsers(), refreshRoutes(), refreshRoles())}
                        />
                    </Col>
                </Row>
            </Container>
            <CreateRoleModal
                allRoles={allRoles}
                show={showCreateRoleModal}
                setShowModal={setShowCreateRoleModal}
                requestRefresh={() => (refreshRoles())}
            />
        </>
    );
};

export default withAuthentication(RolesView);
