import { useEffect, useRef, useState } from "react";
import RaceManagementClient from "../../services/race-manaement.client";
import { Leaderboard } from "./leaderboard";
import { Navigate, useNavigate } from "react-router-dom";
import LeaderboardsClient from "./leaderboards-service.client";
import withAuthentication from "../../withAuthentication";

const ListLeaderboards = () => {
  const client = useRef(new LeaderboardsClient());
  const timeout = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();


  const [leaderboards, setLeaderboards] = useState<Leaderboard[]>([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(20);
  const [search, setSearch] = useState('');

  const view = async (name: string) => {
    navigate('/rm/leaderboard/' + name );
  }

  useEffect(() => fetch(search, page, take), []);
  useEffect(() => fetch(search, page, take), [page, take]);
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      if (page === 1) {
        fetch(search, page, take);
      } else {
        setPage(1)
      }
    }, 300);
  }, [search]);

  const fetch = (search: string, page: number, take: number) => {
    client.current.list(search, page, take).then((leaderboards) => {
      setLeaderboards(leaderboards);
    });
  }

  const updatePage = (page: number) => {
    if (page < 1) return;
    
    setPage(page);
    client.current.list(search, page, take).then((leaderboards) => {
      setLeaderboards(leaderboards);
    });
  }

  return (
    <>
    <div style={{margin: "2rem"}}>
      <h1>Leaderboards</h1>
      <div style={{ margin: "1rem 0 1rem 0" }}>
        <input style={{ marginRight: "1rem" }}  value={search} onChange={(e) => setSearch(e.target.value)} />
        <button style={{ marginRight: "1rem" }} className="btn btn-secondary" onClick={() => updatePage(page - 1)}>Previous</button>
        <button className="btn btn-secondary" onClick={() => updatePage(page + 1)}>Next</button>
      </div>
      <table className="table table-dark">
        <thead>
          <th>Id</th>
          <th>Name</th>
          <th>Collection Name</th>
          <th># Entries</th>
        </thead>
        <tbody>  
          {leaderboards?.map((leaderboard) => (
            <tr onClick={() => view(leaderboard.name)}>
              <td>{leaderboard.id}</td>
              <td>{leaderboard.name}</td>
              <td>{leaderboard.collection}</td>
              <td>{leaderboard.entries}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default withAuthentication(ListLeaderboards);