import React from 'react';

import {Driver, Suspension} from '../../types/event/Driver'
import { Col, Row, Spinner } from 'react-bootstrap';
import moment from 'moment';

export interface DriverAccordionViewItemNullificationProps {
  driver: Driver;
}

const DriverAccordionViewItemNullification = (props: DriverAccordionViewItemNullificationProps) => {

    const accountedNullified = () => {
        return props?.driver?.piiNullification?.nullified;
    }

    return (
        accountedNullified() ?
            <Row style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                <Row title="User who requested the deletion (either the player or an admin">
                    <Col style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        By: {props?.driver?.piiNullification?.requestingUser}
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        Time: {moment(props?.driver?.piiNullification?.time).format("DD MMMM YYYY HH:mm")}
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        Notes: {props?.driver?.piiNullification?.notes}
                    </Col>
                </Row>
            </Row>
        :
            <></>
    );
}

export default DriverAccordionViewItemNullification;