import { Weather } from "./Weather";

export class Session {
	type:              string = "Practice";
	duration:          string = "10m";
	laps:              number = 0;
	waitTime:          string = "0m";
	timeOfDay:         string = "12h";
	realRoad:          string = "preset:MEDIUM.RRBIN";
	waterDepth:        number = -0.01;
	loadTempsFromFile: number = 0;
	weather:           Weather[] = [
    new Weather("540"),
    new Weather("570"),
    new Weather("600"),
    new Weather("630"),
    new Weather("660"),
  ];

  constructor(type?: string, duration?: string, waitTime?: string) {
    this.type = type ?? this.type;
    this.duration = duration ?? this.duration;
    this.waitTime = waitTime ?? this.waitTime;
  }

  public static Default(type: string): Session {
    return {
      type: type,
      duration: "10m",
      laps: 0,
      waitTime: type == "Race" ? "5m" : "0m",
      timeOfDay: "12h",
      realRoad: "preset:LIGHT.RRBIN",
      waterDepth: 0.0,
      loadTempsFromFile: 0,
      weather: Session.DefaultWeather()
    };
  }

  public static DefaultWeather(): Weather[] {
    return [
      Weather.Default("540"),
      Weather.Default("570"),
      Weather.Default("600"),
      Weather.Default("630"),
      Weather.Default("660")
    ];
  }

  public static realRoadForDisplay = (realRoad: string) => {
    switch (realRoad) {
      case "green":
        return "Green";
      case "natural":
        return "Progressing";
      case "preset:LIGHT.RRBIN":
        return "Light";
      case "preset:MEDIUM.RRBIN":
        return "Medium";
      case "preset:HEAVY.RRBIN":
        return "Heavy";
      case "preset:SATURATED.RRBIN":
        return "Saturated";
      default:
        return "";
    }
  }
}