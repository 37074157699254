import { useEffect, useState } from "react";
import withAuthentication from "../../../withAuthentication";
import RaceManagementClient from "../../../services/race-manaement.client";
import { Button, Table } from 'reactstrap';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { SpecialEvent } from "../../../types/event/SpecialEvent";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faEdit, faAdd } from '@fortawesome/free-solid-svg-icons'

function CronToHuman({ cronString }: { cronString: string }) {
  const [minute, hour, , , ] = cronString.split(' ');

  let description = 'At ';

  // Minute
  const minutes = minute.split(',');
  if (minutes.length > 1) {
      description += `${minutes[0]} and ${minutes[1]} minutes`;
  } else {
      description += `${minutes[0]} minute${minutes[0] === '1' ? '' : 's'}`;
  }

  // Hour
  const [startHour, endHour] = hour.split('-');
  description += ` past every hour from ${startHour} AM to ${endHour} PM`;

  // Adding everyday as we know it's every day from the cron
  description += ' every day.';

  return <div>{description}</div>;
}

interface SpecialEventTableProps {
  type: "current" | "future" | "past";
}

const SpecialEventTable = (props: SpecialEventTableProps) => {
  const client = new RaceManagementClient();
  const [configurations, setConfigurations] = useState<SpecialEvent[]>([]);
  const navigate = useNavigate();

  const fetch = () => {
    client.listSpecialEventConfigurations()
      .then((configs) => {
        if (!configs) return;
        if (props.type === "current") {
          setConfigurations(configs.filter(c => moment(c.starts).isBefore(moment.now()) && moment(c.ends).isAfter(moment.now())));
        } else if (props.type === "future") {
          setConfigurations(configs.filter(c => moment(c.starts).isAfter(moment.now())));
        } else if (props.type === "past") {
          setConfigurations(configs.filter(c => moment(c.ends).isBefore(moment.now())));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  
  useEffect(() => {
    fetch();
    const intervalId = setInterval(fetch, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const editConfig = (seriesId: string) => async () => {
    console.log(`Edit config ${seriesId}`);
    navigate('/rm/configs/specialEvents/create?id=' + seriesId );
  }

  const copyConfig = (seriesId: string) => async () => {
    console.log(`Copy config ${seriesId}`);
    navigate('/rm/configs/specialEvents/create?id=' + seriesId + '&copy=true');
  }

  const deleteConfig = (seriesId: string) => async () => {
    console.log(`Delete config: ${seriesId}`);
    client.deleteSpecialEventConfiguration(seriesId)
      .catch((err) => {
        console.error(err);
      });
  }

  const formatDate = (date: string) => {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  return !configurations 
  ? (
    <p>Loading Race Configurations, please wait</p>
  ) : (
    <Row>
      <div className="d-flex justify-content-center">
        <Col sm={12}>
          <div className="tableFixHead">
            <Table dark>
              <thead>
                <tr>
                  <th>Game</th>
                  <th>Title</th>
                  <th>Tier</th>
                  <th>Starts</th>
                  <th>Ends</th>
                  <th>Frequency</th>
                  <th style={{textAlign: "right", width: "11rem", verticalAlign: "middle"}}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {configurations?.map((config, i) => (
                  <tr key={config._id}>
                    <td>{config.game}</td>
                    <td>{config.title}</td>
                    <td>{config.tier.name}</td>
                    <td>{formatDate(config.starts)}</td>
                    <td>{formatDate(config.ends)}</td>
                    <td>
                      <span style={{fontSize: "0.8rem"}}><CronToHuman cronString={config.frequency} /></span>
                      <span style={{color: "#777"}}>({config.frequency})</span>
                    </td>
                    <td style={{textAlign: "right", verticalAlign: "middle"}}>
                      <Button className="mr-2" color="primary" onClick={editConfig(config._id ?? "")}><FontAwesomeIcon icon={faEdit} /></Button>
                      <Button className="mr-2" color="primary" onClick={copyConfig(config._id ?? "")}><FontAwesomeIcon icon={faCopy} /></Button>
                      <Button className="mr-2" color="danger" onClick={deleteConfig(config._id ?? "")}><FontAwesomeIcon icon={faTrash} /></Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </div>
    </Row>
  )
}

export default withAuthentication(SpecialEventTable);
