export class S3Object {
  ChecksumAlgorithm: string | null = null;
  ETag: string = "";
  Key: string = "";
  LastModified: Date = new Date();
  Owner: any = null;
  Size: number = 0;
  StorageClass: string = "";

  constructor() {}

  static getfile(obj: S3Object) : string {
    const key = obj.Key.split("/").pop() || "";
    return key.split("\\").pop() || ""; 
  }

  static size(o: S3Object) : string {
    let size = o.Size;
    const units = ["B", "KB", "MB", "GB", "TB"];
    let unit = 0;
    while (size > 1024) {
      size /= 1024;
      unit++;
    }
    return `${size.toFixed(2)} ${units[unit]}`;
  }
}