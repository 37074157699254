import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col, Accordion } from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import UsernameReservationListViewItem from './UsernameReservationListViewItem';
import { UsernameReservation } from '../types/User';
import { FaLock, FaLockOpen } from 'react-icons/fa';

interface IUsernameReservationListViewProps {
    usernameReservations: Array<UsernameReservation>;
    requestRefresh?: (indices: Array<number>) => void;
}

const UsernameReservationListView = (props: IUsernameReservationListViewProps) => {

    return (
        <Row>
            <style>
            {`
                .user-list-view-header-row {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                }

                .user-list-view-header-item {   
                    color: snow;
                    font-weight: bold;
                }
                
                .user-list-item-icon-col {
                    width: fit-content;
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center !important;
                    padding: 0;
                }
                
                .user-list-item-icon-col > * {
                    width: fit-content;
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center !important;
                    margin: 0;
                    margin-left: 0.1rem;
                    margin-right: 0.5rem;
                    padding: 0;
                }

                /* Style the Accordion */
                body .routes-accordion .accordion-button {
                    background-color: #222222 !important;
                }

                body .routes-accordion .accordion-button:not(.collapsed) {
                    background-color: #333333 !important;
                    border: 1px solid snow;
                }

                body .routes-accordion .accordion-button:hover {
                    background-color: #333333 !important;
                }

                /* Style the Accordion headers */
                body .routes-accordion .routes-accordion-header {
                    background-color: #222222 !important;
                }

                body .routes-accordion .accordion-button::after {
                    background-image: none;
                }

                body .routes-accordion .accordion-button::after {
                    content: '\\25BC';
                    color: snow !important;
                    transform: rotate(180deg);
                }
                
                body .routes-accordion .accordion-button:not(.collapsed)::after {
                    content: "\\25B2";
                    color: snow !important;
                }
            `}
            </style>
            <Col>
                <Row>
                    <Col>
                        <Row>
                            <Accordion defaultActiveKey="0" flush className="routes-accordion">
                            {
                                props?.usernameReservations?.map((reservation, index) => {
                                    return (
                                        <Accordion.Item eventKey={reservation?.username} key={reservation?.username}>
                                        <Accordion.Header className="routes-accordion-header">
                                            <Row style={{width: "100%"}}>
                                                <Col xs={1} className="user-list-item-icon-col">
                                                    <Row className="user-list-item-icon-col">
                                                        {
                                                            reservation?.steamId?.length === 0 ?
                                                                <FaLockOpen size="1.5em" color='grey' title="Unused"/>
                                                            :
                                                                <FaLock size="1.5em" color='white' title="Used"/>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col xs={11}>
                                                    {reservation?.username}
                                                </Col>
                                            </Row>
                                        </Accordion.Header>
                                        <Accordion.Body style={{padding: 0, overflowX: "hidden"}}>
                                            <Row>
                                                <Col>
                                                    <UsernameReservationListViewItem
                                                        reservation={reservation}
                                                        key={reservation?.username}
                                                        requestRefresh={props?.requestRefresh}
                                                    />
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                            </Accordion>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default withAuthentication(UsernameReservationListView);
