import { RaceDriver } from "./RaceDriver";

export const eventTypes = ["daily", "specialevent", "championship"];

export class EventResult {
  public game: string = "";
  public date: string = "";
  public tier: string = "";
  public seriesId: string = "";
  public eventId: string = "";
  public splitNo: number = 0;

  public eventType: string = "";
  public status: string = "";
  public official: boolean = false;
  public title: string = "";
  public track: string = "";
  
  public drivers: RaceDriver[] = [];

  public practice: RaceResults | null = null;
  public qualifying: RaceResults | null = null;
  public warmup: RaceResults | null = null;
  public race: RaceResults | null = null;
}


export function getSafetyRank(results: EventResult, name: string) : string {
  if (results.drivers) {
    const driver = results.drivers.find(x => x.name === name)
    if (driver) {
      if (driver.safetyRank.rank !== "") {
        return driver.safetyRank.rank+driver.safetyRank.tier;
      } else {
        return "Bronze0";
      }
    } else {
      return "Bronze0";
    }
  } else {
    return "Bronze0";
  }
}
export function getDriverRank(results: EventResult, name: string) : string {
  if (results.drivers) {
    const driver = results.drivers.find(x => x.name === name)
    if (driver) {
      if (driver.driverRank.rank !== "") {
        return driver.driverRank.rank+driver.driverRank.tier;
      } else {
        return "Bronze0";
      }
    } else {
      return "Bronze0";
    }
  } else {
    return "Bronze0";
  } 
}

export function getDriver(results: EventResult, name: string) : RaceDriver | undefined {
  if (results.drivers) {
    return results.drivers.find(x => x.name === name)
  } else {
    return new RaceDriver();
  }
}

export function getDriversResult(result: RaceResults | RaceResult, name: string): RaceResult | null {
  if (isRaceResults(result)) {
    const races = result.races ?? [];
    for (let i = 0; i < races.length; i++) {
      const race = races[i];
      const driverResult = race.results.find(x => x.name === name);
      if (driverResult) {
        return driverResult;
      }
    }
  } else if (result.name === name) {
    return result;
  }
  return null;
}

function isRaceResults(result: RaceResults | RaceResult): result is RaceResults {
  return 'races' in result;
}

export class RaceResults {
  sessionType: string;
  sessionNo: string;
  races: Session[];

  constructor() {
    this.sessionType = "";
    this.sessionNo = "";
    this.races = [];
  }
}

export class Session {
  mostLapsCompleted: number;
  streams: Stream[];
  results: RaceResult[];

  constructor() {
    this.mostLapsCompleted = 0;
    this.streams = [];
    this.results = [];
  }

  isEmpty(): boolean {
    return this.mostLapsCompleted === 0 && this.results.length <= 0 && this.streams.length <= 0;
  }
}

export class Stream {
  incidents: StreamEntry[];
  penalties: StreamEntry[];
  chats: StreamEntry[];
  driverChanges: StreamEntry[];

  constructor() {
    this.incidents = [];
    this.penalties = [];
    this.chats = [];
    this.driverChanges = [];
  }
}

export class StreamEntry {
  et: string;
  description: string;

  constructor() {
    this.et = "";
    this.description = "";
  }
}

export class RaceResult {
  name: string;
  steamId: string;
  vehName: string;
  category: string;
  carType: string;
  carClass: string;
  carNumber: string;
  teamName: string;
  gridPos: string;
  position: string;
  classGridPos: string;
  classPosition: string;
  lapRankIncludingDiscos: string;
  bestLapTime: string;
  finishTime: string;
  noLaps: number;
  finishStatus: string;
  laps: Lap[];
  controlAndAids: ControlAndAids[];
  points: number;

  constructor() {
    this.name = "";
    this.steamId = "";
    this.vehName = "";
    this.category = "";
    this.carType = "";
    this.carClass = "";
    this.carNumber = "";
    this.teamName = "";
    this.gridPos = "";
    this.position = "";
    this.classGridPos = "";
    this.classPosition = "";
    this.lapRankIncludingDiscos = "";
    this.bestLapTime = "";
    this.finishTime = "";
    this.noLaps = 0;
    this.finishStatus = "";
    this.laps = [];
    this.controlAndAids = [];
    this.points = 0;
  }

  getClassGridPosition(): number {
    return parseInt(this.classGridPos);
  }
  getClassPosition(): number {
    return parseInt(this.classPosition);
  }
  getGridPosition(): number {
    return parseInt(this.gridPos);
  }
  getPosition(): number {
    return parseInt(this.position);
  }

  static finishTimeStr(result: RaceResult): string {
    if (result.finishTime === "") {
      return "";
    }
    const time = parseFloat(result.finishTime);
    return RaceResult.formatTime(time);
  }

  static finishTimeDiff(a: RaceResult, b: RaceResult): string {
    if (!a || !b || a.finishTime === "" || b.finishTime === "") {
      return "";
    }

    const timeA = parseFloat(a.finishTime);
    const timeB = parseFloat(b.finishTime);
    const diff = timeB - timeA;
    return RaceResult.formatTime(diff);
  }

  static formatTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time % 1) * 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`;
  }
}

export class Lap {
  fuel: string;
  num: string;
  p: string;
  fcompound: string;
  rcompound: string;
  s1: string;
  s2: string;
  s3: string;
  twfl: string;
  twfr: string;
  twrl: string;
  twrr: string;

  constructor() {
    this.fuel = "";
    this.num = "";
    this.p = "";
    this.fcompound = "";
    this.rcompound = "";
    this.s1 = "";
    this.s2 = "";
    this.s3 = "";
    this.twfl = "";
    this.twfr = "";
    this.twrl = "";
    this.twrr = "";
  }
}

export class ControlAndAids {
  startLap: string;
  endLap: string;
  aids: string;

  constructor() {
    this.startLap = "";
    this.endLap = "";
    this.aids = "";
  }
}
