
import { loginMethods, msalInstance } from "../authConfig";

class OAuthClient {
  private accessToken: string | null = null;

  public async fetch(url: string, method: string = "GET", body: string = "", signal?: AbortSignal): Promise<Response> {
    if (!loginMethods.current) {
      throw new Error("User not logged in");
    }
    const accessToken = await loginMethods.current.getToken();

    const request: RequestInit = {
      method: method,
      headers: {
        "Authorization" : `Bearer ${accessToken}`,
        //"Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: body ? body : undefined,
      signal: signal
    };

    return fetch(url, request);
  }
}

export default OAuthClient;
