import { EventResult } from "../types/event/EventResults";
import OAuthClient from "./oauth.client";

class EventResultsClient {
  url = process.env.REACT_APP_RM_URL;
  oauth: OAuthClient;

  constructor() {
    this.oauth = new OAuthClient();
  }

  async getAll(page: number, take: number, type: string): Promise<EventResult[]> {
    const response = await this.oauth.fetch(`${this.url}/dailies/all/results?page=${page}&take=${take}&type=${type}`);
    return await response?.json() as EventResult[];
  }

  async get(seriesId: string | null, eventId: string | null, splitNo: string | null): Promise<EventResult> {
    const response = await this.oauth.fetch(`${this.url}/dailies/${seriesId}/results/${eventId}/${splitNo}`);
    return await response?.json() as EventResult;
  }
}

export default EventResultsClient;
