import React, { useRef, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Button, Col, Form, ListGroup, Modal, Nav, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { HttpProtectedRole, HttpProtectedRouteViewModel, HttpProtectedUser, HttpProtectedUserViewModel } from '../../types/Security';
import { FaMinus, FaPlus, FaUser, FaUserShield, FaUserSlash, FaUsers } from 'react-icons/fa';
import SecurityServiceClient from '../../services/security-service.client';
import RoutesListView from '../routes/RoutesListView';
import RoutesTabbedView from '../routes/RoutesTabbedView';

interface IRolesViewModalProps {
    allRoles: Array<HttpProtectedRole>;
    show: boolean;
    setShowModal: (show: boolean) => void;
    requestRefresh?: (indices: Array<number>) => void;
}

const CreateRoleModal: React.FC<IRolesViewModalProps> = (props) => {

    const securityService = new SecurityServiceClient();

    const [newRoleName, setNewRoleName] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [nameError, setNameError] = useState<boolean>(false);

    React.useEffect(() => {
        if(newRoleName?.replace(/\s/g, '')?.toLocaleLowerCase()?.includes(("SuperAdmin")?.toLocaleLowerCase())) {
            setErrorMessage("Role name cannot include SuperAdmin");
            setNameError(true);
        } else if(props?.allRoles?.find((role) => role?.name === newRoleName)) {
            setErrorMessage("Role name must be unique");
            setNameError(true);
        } else if(newRoleName?.length > 0 && (newRoleName?.length <= 3 || newRoleName?.length >= 26)) {
            setErrorMessage("Role name must be between 4 and 25 characters");
            setNameError(true);
        } else if(newRoleName?.length > 0 && !/^[A-Za-z\s]+$/.test(newRoleName)) {
            setErrorMessage("Role name can only contain letters from A to Z and whitespaces");
            setNameError(true);
        } else {
            setErrorMessage("");
            setNameError(false);
        }
    }, [newRoleName]);

    const create = () => {
        if(!nameError) {
            securityService.createRole(newRoleName).then((res) => {

                if(res?.status === 200) {
                    props?.setShowModal(false);
                    props?.requestRefresh?.([]);
                } else {
                    setErrorMessage("Failed to create role");
                    setNameError(true);
                }
            });
        }
    }

    return (
        <>
            <style>
                {`
                .protected-user-view-modal-body {
                    color: snow;
                    background-color: #222222;
                }

                .role-select-list-group-header-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2em;
                }

                .role-select-list-group-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .role-select-list-group-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid #333333;
                }

                .role-select-list-group-item-row {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .role-select-list-group-item-icon-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .role-select-btn-minus {
                    visibility: hidden;
                    border: 0;
                }

                .role-select-btn-plus {
                    visibility: hidden;
                    border: 0;
                }

                .role-select-list-group-item:hover > * > * > .role-select-btn-minus {
                    visibility: visible;
                    background-color: red;
                    border: 0;
                }

                .role-select-list-group-item:hover > * > * > .role-select-btn-plus {
                    visibility: visible;
                    background-color: #0d6efd;
                    border: 0;
                }

                .role-select-btn-minus::focus, .role-select-btn-plus::focus {
                    outline: none;
                }
                
                .user-role-tab-style {
                    background-color: #222222;
                }
                
                .user-role-tab-style .nav-link.active {
                    background-color: #222222;
                    color: snow;
                }
                
                .user-role-tab-style .nav-link:hover {
                    background-color: #333333;
                    color: snow;
                }
                
                .border-red {
                    border: 1px solid red !important;
                }
                
                .border-gray {
                    border: 1px solid gray;
                }
            `}
            </style>
            <div className="row">
                <div className="col-12">
                    <Modal show={props?.show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

                        <Modal.Header style={{ backgroundColor: "#222222", borderColor: "#333333", color: "snow" }} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row className="d-flex justify-content-between align-items-start">
                                    <Col xs="auto" className="d-flex justify-content-between align-items-start">
                                        <h4 style={{ color: "snow" }}>Create Role</h4>
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="protected-user-view-modal-body">
                            <Row className="align-items-center">
                                <Row className="align-items-center">
                                    <Col xs={4}>
                                        Enter a unique name for the new role:
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control
                                            className={nameError ? "border-red" : "border-gray"}
                                            type="text"
                                            value={newRoleName} 
                                            onChange={(e) => setNewRoleName(e.target.value)} 
                                        />
                                    </Col> 
                                </Row>
                                <Row className="align-items-center">
                                    <Col xs={4}>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Text className="text-danger">
                                            {errorMessage}
                                        </Form.Text>
                                    </Col> 
                                </Row>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer style={{ backgroundColor: "#222222", borderColor: "#333333" }} className="justify-content-between">
                            <Button onClick={() => props?.setShowModal(false)}>Close</Button>
                            <Button style={{visibility: nameError || newRoleName?.length === 0 ? "hidden" : "visible"}} variant="success" onClick={() => create()}>Create</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default withAuthentication(CreateRoleModal);
