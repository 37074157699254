import React from 'react';

import { Driver, SafetyRankAdjustment, Strike, Suspension, UsernameViolation } from '../../types/event/Driver'
import { Badge, Button, Col, ListGroup, Row } from 'react-bootstrap';

import moment from 'moment';
import DriverViewBanModal from './modals/DriverViewBanModal';
import DriverViewStrikeModal from './modals/DriverViewStrikeModal';
import DriverViewSafetyRatingModal from './modals/DriverViewSafetyRatingModal';
import DriverViewNameViolationModal from './modals/DriverViewNameViolationModal';

export interface DriverAccordionViewItemPenaltyHistoryProps {
    driver: Driver;
    game: string;
}

const DriverAccordionViewItemPenaltyHistory = (props: DriverAccordionViewItemPenaltyHistoryProps) => {

    const [showBanModal, setShowBanModal] = React.useState<boolean>(false);
    const [selectedBan, setSelectedBan] = React.useState<Suspension | null>(null);

    const [showStrikeModal, setShowStrikeModal] = React.useState<boolean>(false);
    const [selectedStrike, setSelectedStrike] = React.useState<Strike | null>(null);

    const [showSafetyModal, setShowSafetyModal] = React.useState<boolean>(false);
    const [selectedSafety, setSelectedSafety] = React.useState<SafetyRankAdjustment | null>(null);

    const [showUsernameViolationModal, setShowUsernameViolationModal] = React.useState<boolean>(false);
    const [selectedUsernameViolation, setSelectedUsernameViolation] = React.useState<UsernameViolation | null>(null);

    const allPenalties = () => {
        return [
            ...(props?.driver?.profile?.penaltiesApplied?.suspensions ? (props?.driver?.profile?.penaltiesApplied?.suspensions) : []),
            ...(props?.driver?.profile?.penaltiesApplied?.safetyRankAdjustments ? (props?.driver?.profile?.penaltiesApplied?.safetyRankAdjustments) : []),
            ...(props?.driver?.profile?.penaltiesApplied?.strikes ? (props?.driver?.profile?.penaltiesApplied?.strikes) : []),
            ...(props?.driver?.profile?.penaltiesApplied?.usernameViolations ? (props?.driver?.profile?.penaltiesApplied?.usernameViolations) : [])
        ];
    }

    const sortedPenalties = () => {
        return allPenalties().sort((a, b) => {
            const aTime = a.hasOwnProperty("from") ? (a as Suspension).from : (a as SafetyRankAdjustment).date;
            const bTime = b.hasOwnProperty("from") ? (b as Suspension).from : (b as SafetyRankAdjustment).date;

            return aTime - bTime;
        });
    }

    const formatDateString = (timestamp: number | undefined) => {
        let result = "";
        if (timestamp != void (0)) {
            const date = new Date(timestamp * 1000);
            const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            result = date.toLocaleDateString("en-GB", dateOptions);
        }

        return result;
    }

    const timeBetweenTwoUnixTimestamps = (timestamp1: number | undefined, timestamp2: number | undefined) => {
        let result = "Unknown";
        if (timestamp1 != void (0) && timestamp2 != void (0)) {
            const diff = moment(timestamp1 / 1000).diff(moment(timestamp2 / 1000));
            var mind = diff % (60 * 60);
            const hours = Math.floor(diff / (60 * 60));
            result = `${hours}h`;
        }

        return result;
    }

    const formatHourString = (hours: string) => {
        let result = "";
        switch (hours) {
            case "24h":
                result = "1 Day";
                break;
            case "48h":
                result = "2 Days";
                break;
            case "168h":
                result = "7 Days";
                break;
            case "720h":
                result = "30 Days";
                break;
            case "4320h":
                result = "180 Days";
                break;
            case "867240h":
                result = "Permanently";
                break;
            default:
                result = "Unknown";
                break;
        }

        return result;
    }


    const openInNewTab = (id: String) => {
        if (id != void (0) && id) {
            window.open(`${window.location.origin}/protest/${id}`, "_blank");
        }
    }

    const onClickBan = (ban: Suspension) => {
        setSelectedBan(ban);
        setShowBanModal(true);
    }

    const onClickStrike = (strike: Strike) => {
        setSelectedStrike(strike);
        setShowStrikeModal(true);
    }

    const onClickSafety = (safety: SafetyRankAdjustment) => {
        setSelectedSafety(safety);
        setShowSafetyModal(true);
    }

    const onClickUsernameViolation = (violation: UsernameViolation) => {
        setSelectedUsernameViolation(violation);
        setShowUsernameViolationModal(true);
    }

    const getRelatedUsernameViolation = (ban: Suspension | null) => {
        if (ban == void (0)) {
            return void (0);
        }
        
        return props?.driver?.profile?.penaltiesApplied?.usernameViolations?.find((violation) => violation?.reference === ban?.protestId);
    }

    const getBanViewText = (ban: Suspension) => {
        if (getRelatedUsernameViolation(ban) != void (0)) {
            return "Banned (Username)";
        } else {
            return `Ban (${formatHourString(timeBetweenTwoUnixTimestamps(ban?.to, ban?.from))})`;
        }
    }

    type PenaltyRowProps = {
        penalty: Suspension | Strike | SafetyRankAdjustment | UsernameViolation;
        onClick: () => void;
        badgeColor: string;
        badgeText: string;
        date: number;
    };
    
    const PenaltyRow: React.FC<PenaltyRowProps> = ({ penalty, onClick, badgeColor, badgeText, date }) => (
        <Row onClick={onClick} className="row protest-history-list-item-container" style={{ width: "100%", display: "flex", alignItems: "center", paddingBottom: "0.25%", paddingTop: "0.25%" }}>
            <Col sm="5" md="5" lg="5" xl="5" xxl="5" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <Badge pill bg={penalty?.redacted ? "secondary" : badgeColor} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", textShadow: badgeColor === "warning" ? "" : "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000", color: badgeColor === "warning" ? "black" : "snow", fontWeight: badgeColor === "warning" ? "700" : "500" }}>
                    {badgeText}
                </Badge>
            </Col>
            <Col sm="7" md="7" lg="7" xl="7" xxl="7" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Row style={{ fontSize: "0.75em", paddingLeft: 0, fontWeight: "bold", whiteSpace: "nowrap" }}>
                    {moment(new Date(date)).format("DD MMMM YYYY [at] HH:mm")}
                </Row>
            </Col>
        </Row>
    );

    return (
        <>
            <style>
                {`
              .protest-view-container {
                  background-color: #222222;
                  color: snow;
                  height: 100% !important;
                  padding: 2.5% !important;
              }
  
              .protest-user-details * {
                color: snow !important;
              }
    
              .protest-view-text-input {
                background-color: #444444 !important;
                color: snow;
                border: 1px solid #333333 !important;
              }
    
              .protest-view-text-input:focus {
                color: snow !important;
              }
    
              .dropdown-menu[aria-labelledby = protest-view-ban-days-dropdown] {
                background-color: #bd2130 !important;
                color: snow !important;
              }
    
              .protest-view-ban-day-item {
                background-color: #bd2130 !important;
                color: snow !important;
              }
  
              .accordion-protest-list button {
                background-color: #222222 !important;
                color: snow !important;
                box-shadow: none !important;
              }
              .accordion-protest-list button::after {
                background-color: #222222 !important;
                color: snow !important;
                box-shadow: none !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
              }

              .protest-history-list-item-container {
                cursor: pointer;
                padding-top: 2px !important;
                padding-bottom: 2px !important;
              }

              .protest-history-list-item-container:hover {
                background-color: #333333 !important;
              }
  
              .protest-history-undo-btn {
                display: none;
              }
              
              .protest-history-list-item-container:hover .protest-history-undo-btn {
                display: flex;
              }
  
              .white-text {
                color: snow !important;
              }
              `}
            </style>
            
            <Row>
                <Col>
                {
                    sortedPenalties().map((penalty, index) => {
                        if (penalty.hasOwnProperty("from")) {
                            return <PenaltyRow
                                penalty={penalty}
                                onClick={onClickBan.bind(this, penalty as Suspension)}
                                badgeColor="danger"
                                badgeText={getBanViewText(penalty as Suspension)}
                                date={(penalty as Suspension).from}
                                key={index}
                            />
                        } else if (penalty.hasOwnProperty("adjustment")) {
                            return <PenaltyRow penalty={penalty} onClick={onClickSafety.bind(this, penalty as SafetyRankAdjustment)} badgeColor="warning" badgeText={`Safety Rating ${(penalty as SafetyRankAdjustment).adjustment}`} date={(penalty as SafetyRankAdjustment).date} key={index} />
                        } else if (penalty?.hasOwnProperty("resetUsername")) {
                            return <PenaltyRow penalty={penalty} onClick={onClickUsernameViolation.bind(this, penalty as UsernameViolation)} badgeColor="primary" badgeText="Username Violation" date={(penalty as UsernameViolation).date} key={index} />
                        } else {
                            return <PenaltyRow penalty={penalty} onClick={onClickStrike.bind(this, penalty as Strike)} badgeColor="warning" badgeText="Strike" date={(penalty as Strike).date} key={index} />
                        }
                    })
                }
                </Col>
            </Row>
            <DriverViewBanModal
                show={showBanModal}
                driver={props?.driver}
                game={props?.game}
                ban={selectedBan}
                relatedUsernameViolation={getRelatedUsernameViolation(selectedBan)}
                onCancel={() => { setShowBanModal(false); }}
                onRedacted={() => { setShowBanModal(false); }}
            />
            <DriverViewStrikeModal
                show={showStrikeModal}
                driver={props?.driver}
                game={props?.game}
                strike={selectedStrike}
                onCancel={() => { setShowStrikeModal(false); }}
                onRedacted={() => { setShowStrikeModal(false); }}
            />
            <DriverViewSafetyRatingModal
                show={showSafetyModal}
                driver={props?.driver}
                game={props?.game}
                safety={selectedSafety}
                onCancel={() => { setShowSafetyModal(false); }}
                onRedacted={() => { setShowSafetyModal(false); }}
            />
            <DriverViewNameViolationModal
                show={showUsernameViolationModal}
                driver={props?.driver}
                game={props?.game}
                violation={selectedUsernameViolation}
                onCancel={() => { setShowUsernameViolationModal(false); }}
                onRedacted={() => { setShowUsernameViolationModal(false); }}
            />
        </>
    );
}

export default DriverAccordionViewItemPenaltyHistory;