import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';

import { Driver, Strike, Suspension } from '../../../types/event/Driver'
import { SuspendUserRequest } from '../../../types/Protest';

import UserServiceClient from '../../../services/user-service.client';
import moment from 'moment';

export interface DriverViewStrikeModalProps {
    driver: Driver;
    game: string;
    strike: Strike | null;
    show: boolean;
    onCancel: () => void;
    onRedacted: () => void;
}

const DriverViewStrikeModal = (props: DriverViewStrikeModalProps) => {

    const userService = new UserServiceClient();

    const [reason, setReason] = useState<string>("");
    const onReasonChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReason(event.target.value);
    }

    const formatDateString = (timestamp: number | undefined) => {
        let result = "";
        if (timestamp != void (0)) {
            const date = new Date(timestamp * 1000);
            const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            result = date.toLocaleDateString("en-GB", dateOptions);
        }

        return result;
    }
                                 
    const openProtestInNewTab = (id: String) => {
        if (id != void (0) && id) {
            window.open(`${window.location.origin}/protest/${id}`, "_blank");
        }
    }

    const undoStrike = (banReference: string) => {
        Driver.prototype.undoStrike
            .call(props?.driver, banReference, props?.game)
            .then(Driver.prototype.refresh.bind(props?.driver, props?.game))
            .catch(console.error)
            .finally(props?.onRedacted);
    }

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Strike Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row>
                            <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                                <Form.Label>Date: {moment(props?.strike?.date).format("DD MMMM YYYY HH:mm")}</Form.Label>
                            </Col>
                        </Row>
                        {
                            props?.strike?.protestId?.startsWith("Manual:") ?
                                <Row>
                                    <Row>
                                        <Col>
                                            <Form.Label>Manually applied by: {props?.strike?.protestId?.split(":")[1]}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label>Reason</Form.Label>
                                            <Form.Control as="textarea" rows={3} readOnly defaultValue={props?.strike?.notes} />
                                        </Col>
                                    </Row>
                                </Row>
                            :
                                <Row>
                                    <Col>
                                        <Button variant="primary" onClick={openProtestInNewTab.bind(this, props?.strike?.protestId || "invalid")}>View Protest</Button>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{width: "100%"}}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{display: "flex", justifyContent: "flex-end"}} >
                            <Button variant="secondary" onClick={undoStrike.bind(this, props?.strike?.protestId || "")}>Undo Strike</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverViewStrikeModal;