import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Badge, Container, ListGroup, Navbar, Spinner, Row, Col } from 'react-bootstrap';
import ProtestServiceClient from '../services/protest-service.client';
import { defaultProtestSearchParameters, GroupedProtests, IProtestSearchParameters, Protest, ProtestStatus } from '../types/Protest';
import ProtestListViewItem from './ProtestListViewItem';
import "react-datetime/css/react-datetime.css";
import { FaCog } from "react-icons/fa";
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import ProtestGroupedListViewItem from './ProtestGroupedListViewItem';
import PaginationComponent from '../components/generic/PaginationComponent';
import MainSearchBarComponent from './components/MainSearchBarComponent';
import PrioritySearchBarComponent from './components/PrioritySearchBarComponent';
import ProtestSettingsModalComponent from './components/ProtestSettingsModalComponent';

const ProtestListView = () => {

  const protestService = new ProtestServiceClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const [protestSearchParameters, setProtestSearchParameters] = useState<IProtestSearchParameters>(defaultProtestSearchParameters());
  const [loading, setLoading] = useState<boolean>(true);
  const [protests, setProtests] = useState<Protest[]>([]);
  const [isViewGroupedProtests, setIsViewGroupedProtests] = useState<boolean>(false);
  const [groupedProtests, setGroupedProtests] = useState<GroupedProtests[]>([]);
  const [groupedProtestsCount, setGroupedProtestsCount] = useState<number>(0);
  const [closedProtestCount, setClosedProtestCount] = useState<number>(0);
  const [priorityProtestCount, setPriorityProtestCount] = useState<number>(0);
  const [newProtestCount, setNewProtestCount] = useState<number>(0);
  const [openProtestCount, setOpenProtestCount] = useState<number>(0);
  const [allProtestsCount, setAllProtestsCount] = useState<number>(0);
  const [currentSearchCount, setCurrentSearchCount] = useState<number>(0);
  const [curPage, setCurPage] = useState<number>(0);
  
  const applySearchParams = (params: IProtestSearchParameters | null, page: string | null) => {
    const paramsString = JSON.stringify(params ?? protestSearchParameters);
    const encodedParams = btoa(paramsString);
    setSearchParams({ page: page ?? curPage.toString(), search: encodedParams });
  }

  useEffect(() => {
    const protestSettingsCookie = Cookies.get("protest-settings");
    const page = searchParams.get("page");
    const search = searchParams.get("search");
  
    const applySearchParamsFromUrl = (urlSearchParams: string) => {
      const decodedSearch = atob(urlSearchParams);
      protestSearchParameters = JSON.parse(decodedSearch) as IProtestSearchParameters;
      setProtestSearchParameters(protestSearchParameters);
      setIsViewGroupedProtests(protestSearchParameters.viewingGroupedProtests);
      priorityProtestsCount(protestSearchParameters.priorityParameters.numberProtests, protestSearchParameters.priorityParameters.numberPunishments);
      groupedPriorityProtestsCount(protestSearchParameters.priorityParameters.numberProtests, protestSearchParameters.priorityParameters.numberPunishments);
      if (protestSearchParameters?.viewingGroupedProtests === true) {
        searchGroupedPriorityProtests(protestSearchParameters, pageInt);
      } else {
        searchIndex(protestSearchParameters, pageInt);
      }
    };

    const applySearchParamsFromCookie = (cookie: string) => {
      const decodedSettings = atob(cookie);
      const settings = JSON.parse(decodedSettings);
      setSettingsModalFormData(settings);
      setProtestSearchPriorityParameters(settings.numberProtests, settings.numberPunishments);
      priorityProtestsCount(settings.numberProtests, settings.numberPunishments);
      groupedPriorityProtestsCount(settings.numberProtests, settings.numberPunishments);
    };

    let pageInt = page && page !== void(0) ? parseInt(page) : 0;
    if (!isNaN(pageInt)) setCurPage(pageInt);
  
    let protestSearchParameters = null;
    if (search && search !== void(0)) {
      applySearchParamsFromUrl(search);
    } else if (protestSettingsCookie && protestSettingsCookie !== void(0) && protestSettingsCookie.length > 0) {
      applySearchParamsFromCookie(protestSettingsCookie);
    } else {
      // if there's no seach params in the url or cookie then set the default search params
      applySearchParamsFromUrl(btoa(JSON.stringify(defaultProtestSearchParameters())));
      priorityProtestsCount();
      groupedPriorityProtestsCount();
    }
  
    [openProtests, closedProtests, newProtests, allProtests].forEach(func => func());
  }, []);

  const nextPage = () => {
    const protestsFound = protests.length;

    if (protestsFound == 15) {
      const nextPage = curPage + 1;
      setCurPage(nextPage);
      if (isViewGroupedProtests) {
        searchGroupedPriorityProtests(protestSearchParameters, nextPage);
      } else {
        searchIndex(protestSearchParameters, nextPage);
      }
      applySearchParams(null, nextPage.toString());
    }
  }

  const prevPage = () => {
    if (curPage > 0) {
      const prevPage = curPage - 1;
      setCurPage(prevPage);
      if (isViewGroupedProtests) {
        searchGroupedPriorityProtests(protestSearchParameters, prevPage);
      } else {
        searchIndex(protestSearchParameters, prevPage);
      }
      applySearchParams(null, prevPage.toString());
    }
  }

  const searchIndex = (params: IProtestSearchParameters | null, page: number | null) => {
    setLoading(true);
    protestService.getFilteredProtests(params ?? protestSearchParameters, page ?? curPage, 15)
      .then(p => setProtests(p))
      .then(() => protestService.getCountByFilter(params ?? protestSearchParameters))
      .then(count => setCurrentSearchCount(count))
      .then(() => setLoading(false))
      .catch(error => {
        console.error(error);
        setProtests([]);
      });
  }

  const searchGroupedPriorityProtests = (params: IProtestSearchParameters | null, page: number | null) => {
    setLoading(true);
    protestService.getGroupedPriorityProtests(params ?? protestSearchParameters, page ?? curPage, 15)
      .then(p => setGroupedProtests(p ?? []))
      .then(() => protestService.getGroupedPriorityProtestsCount(params ?? protestSearchParameters))
      .then(count => setGroupedProtestsCount(count))
      .then(() => setLoading(false))
      .catch(error => {
        console.error(error);
        setGroupedProtests([]);
      });
  }

  const viewGroupedPriorityProtests = () => {
    setIsViewGroupedProtests(true);
    setCurPage(0);
    setProtestSearchParameters({ ...protestSearchParameters, viewingGroupedProtests: true, priorityHigh: true, priorityRecent: false});
    searchGroupedPriorityProtests(protestSearchParameters, 0);
    applySearchParams({ ...protestSearchParameters, viewingGroupedProtests: true, priorityHigh: true, priorityRecent: false }, null);
  }

  const viewPriorityProtests = () => {
    setIsViewGroupedProtests(false);
    setCurPage(0);
    setProtestSearchParameters({ ...protestSearchParameters, viewingGroupedProtests: false, priorityHigh: true, priorityRecent: false });
    searchIndex({ ...protestSearchParameters, viewingGroupedProtests: false, priorityHigh: true, priorityRecent: false }, 0);
    applySearchParams({ ...protestSearchParameters, viewingGroupedProtests: false, priorityHigh: true, priorityRecent: false }, null);
  }

  const allProtests = () => {

    let search = defaultProtestSearchParameters();

    protestService.getCountByFilter(search)
      .then(p => setAllProtestsCount(p))
      .catch(error => {
        console.error(error);
      });
  }

  const openProtests = () => {

    let search = defaultProtestSearchParameters();
    search.status = ProtestStatus.Open;

    protestService.getCountByFilter(search)
      .then(p => setOpenProtestCount(p))
      .catch(error => {
        console.error(error);
      });
  }

  const closedProtests = () => {

    let search = defaultProtestSearchParameters();
    search.status = ProtestStatus.Closed;

    protestService.getCountByFilter(search)
      .then(p => setClosedProtestCount(p))
      .catch(error => {
        console.error(error);
      });
  }

  const newProtests = () => {

    const OneDayAgo = moment().subtract(1, 'days');
    const now = moment();

    let search = defaultProtestSearchParameters();

    search.status = ProtestStatus.Open;
    search.timestampFrom = OneDayAgo.unix();
    search.timestampTo = now.unix();

    protestService.getCountByFilter(search)
      .then(p => setNewProtestCount(p))
      .catch(error => {
        console.error(error);
      });
  }

  const priorityProtestsCount = (
    numerProtests: number = defaultProtestSearchParameters().priorityParameters.numberProtests,
    numberPunishments: number = defaultProtestSearchParameters().priorityParameters.numberPunishments
  ) => {

    let search = defaultProtestSearchParameters();
    search.priorityRecent = false;
    search.priorityHigh = true;
    search.priorityParameters.numberProtests = numerProtests;
    search.priorityParameters.numberPunishments = numberPunishments;

    protestService.getCountByFilter(search)
      .then(p => setPriorityProtestCount(p))
      .catch(error => {
        console.error(error);
      });
  }

  const groupedPriorityProtestsCount = (
    numerProtests: number = defaultProtestSearchParameters().priorityParameters.numberProtests,
    numberPunishments: number = defaultProtestSearchParameters().priorityParameters.numberPunishments
  ) => {

    let search = defaultProtestSearchParameters();
    search.priorityRecent = false;
    search.priorityHigh = true;
    search.priorityParameters.numberProtests = numerProtests;
    search.priorityParameters.numberPunishments = numberPunishments;

    protestService.getGroupedPriorityProtestsCount(search)
      .then(p => setGroupedProtestsCount(p))
      .catch(error => {
        console.error(error);
      });
  }

  const isViewingPriorityProtests = () => {
    return protestSearchParameters != void (0) && (protestSearchParameters.priorityHigh === true || protestSearchParameters.priorityRecent === true);
  }

  const setProtestPriorityHighSearchParameter = (priorityHigh: boolean) => {
    const currentSearchParameters = structuredClone(protestSearchParameters);
    currentSearchParameters.priorityHigh = priorityHigh;
    currentSearchParameters.priorityRecent = !priorityHigh;
    currentSearchParameters.viewingGroupedProtests = false;

    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    if(isViewGroupedProtests) {
      searchGroupedPriorityProtests(currentSearchParameters, 0);
    } else {
      searchIndex(currentSearchParameters, 0);
    }
  }

  const setProtestPriorityRecentSearchParameter = (priorityRecent: boolean) => {
    const currentSearchParameters = structuredClone(protestSearchParameters);
    currentSearchParameters.priorityRecent = priorityRecent;
    currentSearchParameters.priorityHigh = !priorityRecent;
    currentSearchParameters.viewingGroupedProtests = false;

    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    if(isViewGroupedProtests) {
      searchGroupedPriorityProtests(currentSearchParameters, 0);
    } else {
      searchIndex(currentSearchParameters, 0);
    }
  }

  const setProtestStatusSearchParameter = (status: number, reset: boolean) => {
    const currentSearchParameters = reset ? defaultProtestSearchParameters() : structuredClone(protestSearchParameters);
    currentSearchParameters.status = status;
    currentSearchParameters.viewingGroupedProtests = false;

    setIsViewGroupedProtests(false);
    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    searchIndex(currentSearchParameters, 0);
  }

  const setProtestGameSearchParameter = (game: string) => {
    const currentSearchParameters = structuredClone(protestSearchParameters);
    currentSearchParameters.game = game;
    currentSearchParameters.viewingGroupedProtests = false;

    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    if(isViewGroupedProtests) {
      searchGroupedPriorityProtests(currentSearchParameters, 0);
    } else {
      searchIndex(currentSearchParameters, 0);
    }
  }

  const setProtestTypeSearchParameter = (protestType: number) => {
    const currentSearchParameters = structuredClone(protestSearchParameters);
    currentSearchParameters.protestType = protestType;
    currentSearchParameters.viewingGroupedProtests = false;

    setIsViewGroupedProtests(false);
    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    searchIndex(currentSearchParameters, 0);
  }

  const setProtestOutcomeSearchParameter = (outcome: number) => {
    const currentSearchParameters = structuredClone(protestSearchParameters);
    currentSearchParameters.outcome = outcome;
    currentSearchParameters.viewingGroupedProtests = false;

    setIsViewGroupedProtests(false);
    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    searchIndex(currentSearchParameters, 0);
  }

  const setProtestTextSearchParameter = (event: ChangeEvent) => {
    const text = (event.target as HTMLInputElement).value;
    const currentSearchParameters = structuredClone(protestSearchParameters);

    currentSearchParameters.viewingGroupedProtests = false;
    currentSearchParameters.text = text;

    setIsViewGroupedProtests(false);
    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    searchIndex(currentSearchParameters, 0);
  }

  const setProtestTimestampFromSearchParameter = (timestampFrom: string | moment.Moment) => {
    const currentSearchParameters = structuredClone(protestSearchParameters);

    const isMomentTime = typeof (timestampFrom) === "object";
    if (isMomentTime) {
      const momentTime: moment.Moment = timestampFrom as moment.Moment;
      currentSearchParameters.timestampFrom = momentTime.unix();
    }
    currentSearchParameters.viewingGroupedProtests = false;

    setIsViewGroupedProtests(false);
    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    searchIndex(currentSearchParameters, null);
  }

  const setProtestTimestampToSearchParameter = (timestampTo: string | moment.Moment) => {
    const currentSearchParameters = structuredClone(protestSearchParameters);

    const isMomentTime = typeof (timestampTo) === "object";
    if (isMomentTime) {
      const momentTime: moment.Moment = timestampTo as moment.Moment;
      currentSearchParameters.timestampTo = momentTime.unix();
    }
    currentSearchParameters.viewingGroupedProtests = false;

    setIsViewGroupedProtests(false);
    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    searchIndex(currentSearchParameters, null);
  }

  const setBothTimestamps = (timestampFrom: string | moment.Moment, timestampTo: string | moment.Moment, reset: boolean) => {
    const currentSearchParameters = reset ? defaultProtestSearchParameters() : structuredClone(protestSearchParameters);

    const isMomentTime = typeof (timestampFrom) === "object";
    if (isMomentTime) {
      const momentTime: moment.Moment = timestampFrom as moment.Moment;
      currentSearchParameters.timestampFrom = momentTime.unix();
    }

    const isMomentTimeTo = typeof (timestampTo) === "object";
    if (isMomentTimeTo) {
      const momentTimeTo: moment.Moment = timestampTo as moment.Moment;
      currentSearchParameters.timestampTo = momentTimeTo.unix();
    }
    currentSearchParameters.viewingGroupedProtests = false;

    setIsViewGroupedProtests(false);
    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    searchIndex(currentSearchParameters, 0);
  }

  const setProtestSearchPriorityParameters = (numberProtests: number, numberPunishments: number) => {
    const currentSearchParameters = structuredClone(protestSearchParameters);
    currentSearchParameters.priorityParameters = {
      numberProtests: numberProtests,
      numberPunishments: numberPunishments
    };

    setCurPage(0);
    applySearchParams(currentSearchParameters, null);
    setProtestSearchParameters(currentSearchParameters);
    if(isViewGroupedProtests) {
      searchGroupedPriorityProtests(currentSearchParameters, 0);
    } else {
      searchIndex(currentSearchParameters, 0);
    }
  }

  const resetSearchParameters = () => {
    const defaultSearchParams = defaultProtestSearchParameters();

    setIsViewGroupedProtests(false);
    setCurPage(0);
    applySearchParams(defaultSearchParams, null);
    setProtestSearchParameters(defaultSearchParams);
    searchIndex(defaultSearchParams, null);
  }

  const openInNewTab = (id: String) => {
    if (id != void (0) && id) {
      window.location.href = `${window.location.origin}/protest/${id}`;
    }
  }

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [settingsModalFormData, setSettingsModalFormData] = useState({
    numberProtests: 5,
    numberPunishments: 1
  });

  const goto = (id: string) => {
    if (id != void (0) && id) {
    
        const search: IProtestSearchParameters = defaultProtestSearchParameters();
        search.text = id;
        search.status = ProtestStatus.Open;

        window.location.href = `${window.location.origin}/protest/list?page=0&search=${btoa(JSON.stringify(search))}`;
    }
  }

  return (
    <>
      <style>
        {`
          .protest-search-bar * {
            color: snow !important;
          }

          .protest-search-bar-text-field {
            background-color: #333333 !important;
            color: snow !important;
            border-color: #333333 !important;
            margin: auto 0;
          }

          .protest-search-bar-form-container  {
            color: snow !important;
          }

          .protest-timepicker-container {
            padding-right: 1%;
          }

          .protest-timepicker-container * {
            background-color: #333333 !important;
          }

          .protest-badge-header-container {
            padding: 2px;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
          }
          
          .protest-badge {
            margin: 0.25vw;
          }

          .protest-badge:hover {
            cursor: pointer;
            box-shadow: 0 0 5px rgba(255,255,255,0.5);
          }

          .protest-badge.priority {
            background-color: #c25a00 !important;
          }

          .protest-badge.toxic {
            background-color: #dfff00 !important;
            color: black !important;
            text-shadow: none;
          }

          .protest-badge.total {
            background-color: #636363 !important;
          }

          .protest-settings {
            padding: 1px;
          }

          .protest-settings:hover {
            cursor: pointer;
            padding: 0;
          }

          .protest-settings-modal {
            padding: 1vw;
          }

          .protest-settings-modal * {
            color: snow !important;
          }

          .protest-settings-close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            border: 0;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 0;
            font-size: 20px;
            color: snow;
            background-color: rgba(0,0,0,0);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 0 4px 0px;
          }
        `}
      </style>
      <Container style={{margin: 0, width: "100%", minWidth: "100%"}}>
        <ProtestSettingsModalComponent
          showSettingsModal={showSettingsModal}
          setShowSettingsModal={setShowSettingsModal}
          settingsModalFormData={settingsModalFormData}
          setSettingsModalFormData={setSettingsModalFormData}
          setProtestSearchPriorityParameters={setProtestSearchPriorityParameters}
          priorityProtestsCount={priorityProtestsCount}
          protestSearchParameters={protestSearchParameters}
        />
        <Row>
          <Col sm="1" md="1" lg="1" xl="1" xxl="1">
            <Row style={{margin: "0.25vw"}}>
              <Navbar bg="dark" expand="lg" className="protest-search-bar">
                <Container fluid>
                  <Row style={{ width: "100%" }}>
                    <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="protest-badge-header-container">
                      <Badge bg="success" pill className="protest-badge total" onClick={resetSearchParameters.bind(this)}>
                        Total: {allProtestsCount}
                      </Badge>
                      <Badge bg="success" pill className="protest-badge" onClick={setProtestStatusSearchParameter.bind(this, ProtestStatus.Open, true)}>
                        Open: {openProtestCount}
                      </Badge>
                      <Badge bg="danger" pill className="protest-badge" onClick={setProtestStatusSearchParameter.bind(this, ProtestStatus.Closed, true)}>
                        Closed: {closedProtestCount}
                      </Badge>
                      <Badge bg="primary" pill className="protest-badge" title="Protests raised in the past 24 hours"
                        onClick={setBothTimestamps.bind(this, moment().subtract(1, 'days'), moment(), true)}>
                        New: {newProtestCount}
                      </Badge>
                      <Badge bg="warning" pill className="protest-badge priority" title="Reports on toxic players"
                        onClick={viewPriorityProtests}>
                        Priority: {priorityProtestCount}
                      </Badge>
                      <Badge bg="warning" pill className="protest-badge toxic" title="Toxic users"
                        onClick={viewGroupedPriorityProtests}>
                        Toxic: {groupedProtestsCount}
                      </Badge>
                    </Col>
                  </Row>
                </Container>
              </Navbar>
            </Row>
          </Col>
          <Col sm="10" md="10" lg="10" xl="10" xxl="10">
            <Row>
              {
                !isViewingPriorityProtests() && !isViewGroupedProtests &&
                  <MainSearchBarComponent
                    protestSearchParameters={protestSearchParameters}
                    setProtestSearchParameters={setProtestSearchParameters}
                    setProtestTypeSearchParameter={setProtestTypeSearchParameter}
                    setProtestTextSearchParameter={setProtestTextSearchParameter}
                    setProtestStatusSearchParameter={setProtestStatusSearchParameter}
                    setProtestGameSearchParameter={setProtestGameSearchParameter}
                    setProtestOutcomeSearchParameter={setProtestOutcomeSearchParameter}
                    setProtestTimestampFromSearchParameter={setProtestTimestampFromSearchParameter}
                    setProtestTimestampToSearchParameter={setProtestTimestampToSearchParameter}
                    resetSearchParameters={resetSearchParameters}
                  />
              }
              {
                (isViewingPriorityProtests() || isViewGroupedProtests) &&
                <PrioritySearchBarComponent
                  protestSearchParameters={protestSearchParameters}
                  setProtestGameSearchParameter={setProtestGameSearchParameter}
                  setProtestPriorityHighSearchParameter={setProtestPriorityHighSearchParameter}
                  setProtestPriorityRecentSearchParameter={setProtestPriorityRecentSearchParameter}
                  resetSearchParameters={resetSearchParameters}
                />
              }
            </Row>
            <Row>
            {
              loading ?
                <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
                  <Row>
                    <Col>
                      <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem' }} />
                    </Col>
                  </Row>
                </div>
              : isViewGroupedProtests ?
              <ListGroup style={{ paddingRight: "0px" }}>
                {Array.isArray(groupedProtests) && groupedProtests.map(p => (
                  <ProtestGroupedListViewItem
                    key={p.protesteeId}
                    groupedProtests={p}
                    onClick={(id: string, e: React.MouseEvent<Element, MouseEvent>) => {
                      console.log(e);
                      switch (e?.button) {
                        // Left click
                        case 0:
                            goto(p?.protesteeId);
                          break;
                        // Middle click
                        case 1:
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                ))}
              </ListGroup>
              :
              <ListGroup style={{ paddingRight: "0px" }}>
                {protests != void (0) && protests.map != void (0) && protests?.map(p => (
                  <ProtestListViewItem
                    key={p._id}
                    protest={p}
                    onClick={(id: string, e: React.MouseEvent<Element, MouseEvent>) => {
                      console.log(e);
                      switch (e?.button) {
                        // Left click
                        case 0:
                          openInNewTab(id);
                          break;
                        // Middle click
                        case 1:
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                ))}
              </ListGroup>
            }
            </Row>
            <Row>
              {
                isViewGroupedProtests &&
                <PaginationComponent
                  curPage={curPage}
                  total={groupedProtestsCount}
                  viewTotal={groupedProtests?.length}
                  pageSize={15}

                  nextPage={nextPage}
                  prevPage={prevPage}
                />
              }
              {
                !isViewGroupedProtests &&
                <PaginationComponent
                  curPage={curPage}
                  total={currentSearchCount}
                  viewTotal={protests?.length}
                  pageSize={15}

                  nextPage={nextPage}
                  prevPage={prevPage}
                />
              }
            </Row>
          </Col>
          <Col sm="1" md="1" lg="1" xl="1" xxl="1">
            <Row style={{  }}>
              <Col>
                <FaCog size="2.5vw" color="lightgrey" title="Settings" style={{ margin: "20%" }}
                  className="protest-settings"
                  onClick={setShowSettingsModal.bind(this, true)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withAuthentication(ProtestListView);
