import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DailyRaceConfiguration } from "../../../types/event/DailyRaceConfiguration";
import withAuthentication from "../../../withAuthentication";
import RaceManagementClient from "../../../services/race-manaement.client";
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import DailyRacesConfigTable from "./DailyRacesConfigTable";
import { config } from "process";


const ListDailyConfigurations = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Col sm={12}>
        <h1 style={{marginBottom: "2rem"}} className="d-flex"><span style={{flex: 1}}>Daily Races</span> <button className="btn btn-primary align-content-end" onClick={() => navigate('/rm/configs/dailies/create')}><FontAwesomeIcon icon={faAdd} /> Create New</button></h1>
      </Col>

      <Tab.Container defaultActiveKey="current">
        <Row>
          <div className="d-flex flex-column" style={{ padding: 0, marginLeft: "1rem" }}>
            <div className="d-flex flex" style={{ padding: 0 }}>
              <Col sm={6}>
                <div className="d-flex" style={{height: "100%"}}>
                  <div style={{ marginBottom: "0.5rem", alignSelf: "end"}}>
                    <Nav variant="pills" className="flex">
                      <Nav.Item>
                        <Nav.Link eventKey="current">Current Events</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="future">Future Events</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="past">Historic Events</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Col>
            </div>

            <Col sm={12}>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey="current">
                    <DailyRacesConfigTable type="current" />
                  </Tab.Pane>

                  <Tab.Pane eventKey="future">
                    <DailyRacesConfigTable type="future" />
                  </Tab.Pane>

                  <Tab.Pane eventKey="past">
                    <DailyRacesConfigTable type="past" />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </div>
        </Row>
      </Tab.Container>
    </div>
  )
}

export default withAuthentication(ListDailyConfigurations);
