import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col } from 'react-bootstrap';
import SecurityServiceClient from '../../services/security-service.client';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { HttpProtectedRole, HttpProtectedUser, HttpProtectedUserViewModel } from '../../types/Security';
import ActivationsListView from './ActivationsListView';
import { Activation } from '../../types/Activations';
import ActivationsServiceClient from '../../services/activations-service.client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import ActivationsViewModal from './UpdateActivationsViewModal';
import CreateActivationsViewModal from './CreateActivationsViewModal';

const ActivationsView = () => {

    const activationService = new ActivationsServiceClient();

    const [showModal, setShowModal] = React.useState(false);
    const [activations, setActivations] = useState<Array<Activation>>([]);
    useEffect(() => {
        activationService.get(1, 100).then((result) => {
            setActivations(result);
        });
    }, []);

    const refreshActivations = () => {
        activationService.get(1, 100).then((result) => {
            setActivations(result);
        });
    }

    return (
        <>
            <style>
            {`
                .activation-create-new-button-col {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end !important;
                    align-items: center;
                }
            `}
            </style>
            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row>
                    <Col>
                        <h3>Activations</h3>
                    </Col>
                    <Col className="activation-create-new-button-col">
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                            <FontAwesomeIcon icon={faAdd} style={{paddingRight: "0.5rem"}}/>
                            Create New
                        </Button>
                    </Col>
                </Row>
                <Row>
                {
                    activations != void(0) && activations?.length > 0 ?
                    <Col>
                        <ActivationsListView
                            activations={activations}
                            requestRefresh={refreshActivations}
                        />
                    </Col>
                    :
                    <Col className="d-flex flex-column justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
                        <Spinner animation="border" style={{ width: '7vh', height: '7vh' }} />
                        <div className="text-center">
                            {
                                activations != void(0) && typeof(activations) === 'object' && (activations as any)?.message?.length > 0 ?
                                <p>{(activations as any)?.message}</p>
                                :
                                <p>Loading...</p> 
                            }
                        </div>
                    </Col>
                }
                </Row>
            </Container>
            <CreateActivationsViewModal
                show={showModal}
                setShowModal={setShowModal}
                requestRefresh={refreshActivations}
            />
        </>
    );
};

export default withAuthentication(ActivationsView);
