import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { UsernameReservation } from '../types/User';
import UsernameReservationServiceClient from '../services/username-reservation-service.client';

export interface LinkSteamIDModalProps {
    reservation: UsernameReservation;
    show: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const LinkSteamIDModal = (props: LinkSteamIDModalProps) => {

    const usernameReservationsClient = new UsernameReservationServiceClient();

    const [steamIdErrorMessage, setSteamIdErrorMessage] = useState<string>("");
    const [steamIdError, setSteamIdError] = useState<boolean>(false);

    const [steamId, setSteamId] = useState<string | null>(null);
    const onSteamIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        try
        {
            const newSteamID = event?.target?.value?.trim();
            validateSteamID(newSteamID);
            setSteamId(newSteamID);
        } catch (error) {
            setSteamIdErrorMessage("Invalid SteamID");
            setSteamIdError(true);
        }
    }
    
    const validateSteamID = (newSteamID: string) => {
        try
        {
            if (newSteamID?.length < 4 || newSteamID?.length > 30) {
                setSteamIdErrorMessage("Invalid SteamID: Not a valid SteamID64 Length");
                setSteamIdError(true);
            } else if(BigInt(newSteamID) === BigInt(0)) {
                setSteamIdErrorMessage("Invalid SteamID: 0 is not a valid SteamID64");
                setSteamIdError(true);
            } else {
                setSteamIdErrorMessage("");
                setSteamIdError(false);
            }
        } catch (error) {
            setSteamIdErrorMessage("Invalid SteamID: Not a number");
            setSteamIdError(true);
        }
    }

    const linkSteamID = () => {
        usernameReservationsClient.addUsernameReservation(props?.reservation?.username, steamId || "").then(() => {
            props?.onConfirm?.();
        }).catch((error: Error) => {   
            console.error(error);
        });
    }

    return (
        <>
            <style>
                {`
                    .link-steamid-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .link-steamid-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-steamid-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-steamid-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-steamid-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .link-steamid-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-form-text-grey {
                        color: grey !important;
                    }

                    .border-red {
                        border: 1px solid red !important;
                    }
                    
                    .border-gray {
                        border: 1px solid gray;
                    }
                `}
            </style>
            <Modal className="link-steamid-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="link-steamid-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Link SteamID to username {props?.reservation?.username ?? "Unknown"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="link-steamid-modal-body">
                    <Container className="link-steamid-modal-body-container">
                        <Row>
                            <Col>
                                <Form.Label>Enter SteamID to link</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={onSteamIdChanged}
                                    className={steamIdError ? "border-red" : "border-gray"}
                                    value={steamId ?? ""}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col xs={12}>
                                {
                                    steamIdError ?
                                    <Form.Text className="text-danger">
                                        {steamIdErrorMessage}
                                    </Form.Text>
                                    :
                                    <Form.Text className="link-form-text-grey">
                                        SteamID must be a valid SteamID64
                                    </Form.Text>
                                }
                            </Col> 
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="link-steamid-modal-footer">
                    <Row style={{width: "100%"}}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{display: "flex", justifyContent: "flex-end"}} >
                            <Button variant="success" onClick={linkSteamID}>Link</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LinkSteamIDModal;