import React from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { HttpProtectedRole, HttpProtectedRoute, HttpProtectedRouteViewModel, HttpProtectedUser } from '../../types/Security';
import withAuthentication from '../../withAuthentication';

import { FaLock, FaLockOpen, FaUser, FaUserShield, FaUserSlash } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import RoutesListViewModal from './RoutesListViewModal';

interface IRoutesListViewItemProps {
    route: HttpProtectedRouteViewModel;
    allRoles: Array<HttpProtectedRole>;
    requestRefresh?: (indices: Array<number>) => void;
}

const RoutesListViewItem: React.FC<IRoutesListViewItemProps> = (props) => {

    const [showModal, setShowModal] = React.useState(false);

    const isSuperAdminOnly = () => {
        return props?.route?.allowedRoles?.length === 1
            && props?.route?.allowedRoles?.[0]?.name === "SuperAdmin";
    }

    return (
        <>
            <style>
                {`
                .user-list-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .user-list-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid white
                }

                .user-list-item-row {
                    width: 100%;
                    height: 100%;
                }

                .user-list-item-icon-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .user-list-item-info-col {
                }

                .user-list-item-name-col {
                    font-weight: bold;
                    font-size: 1.0em;
                }

                .user-list-item-email-col {
                    font-weight: light;
                    font-size: 0.8em;
                    color: rgba(255, 255, 255, 0.5);
                }
            `}
            </style>
            <ListGroup.Item key={props?.route?.id}
                            as="li"
                            className="d-flex justify-content-between align-items-start user-list-item"
                            onClick={(e: React.MouseEvent<Element, MouseEvent>) => setShowModal(true)}>
                <Row className="user-list-item-row">
                    <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="user-list-item-icon-col">
                        <Row className="user-list-item-icon-col">
                            {
                                props?.route?.allowedRoles?.length === 0 ?
                                    <ImCross size="1.5em" color='gray' title="Not allowed"/>
                                : isSuperAdminOnly() ?
                                    <FaLock size="1.5em" color='red' title="Super Admin Only"/>
                                :
                                    <FaLockOpen size="1.5em" title="Open to other roles"/>

        
                            }
                        </Row>
                    </Col>
                    <Col sm="11" md="11" lg="11" xl="11" xxl="11" className="user-list-item-info-col">
                        <Row>
                            <Col sm="12" md="12" lg="12" xl="12" xxl="12">
                                <Row>
                                    <Col sm="7" md="7" lg="7" xl="7" xxl="7" className="user-list-item-name-col">
                                        {props?.route?.path}
                                    </Col>
                                    <Col sm="2" md="2" lg="2" xl="2" xxl="2">
                                        <span>
                                        {
                                            props?.route?.methods?.length === 0 ?
                                                "No Methods"
                                            : props?.route?.methods?.join(" ") 
                                        }
                                        </span>
                                    </Col>
                                    <Col sm="3" md="3" lg="3" xl="3" xxl="3">
                                        <span>
                                        {
                                            props?.route?.allowedRoles?.length === 0 ?
                                                "No Roles Assigned"
                                            : props?.route?.allowedRoles?.filter(r => r.name !== "SuperAdmin")?.length === 0 ?
                                                ""
                                            : props?.route?.allowedRoles?.filter(r => r.name !== "SuperAdmin")?.[0]?.name
                                        }
                                        </span>
                                        <span style={{color: "rgba(255, 255, 255, 0.5)"}}>
                                        {
                                            props?.route?.allowedRoles?.filter(r => r.name !== "SuperAdmin")?.length > 1 ?
                                                ` +${props?.route?.allowedRoles?.filter(r => r.name !== "SuperAdmin")?.length - 1} more`
                                            :
                                                ""
                                        }
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ListGroup.Item>
            <RoutesListViewModal
                show={showModal}
                setShowModal={setShowModal}
                route={props?.route}
                allRoles={props?.allRoles}
                requestRefresh={props?.requestRefresh}
            />
        </>
    );
};

export default withAuthentication(RoutesListViewItem);