import React from "react";
import { useParams } from "react-router-dom";
import withAuthentication from "../withAuthentication";
import { RaceControlClient } from "../services/race-control.client";
import { Col, Row, Table } from "react-bootstrap";
import { Championship, League } from "../championships/models/Championship";
import moment from "moment";
import { multiFormationLapValue } from "../types/event/DailyRaceConfiguration";
import DriverRankBadge from "../components/driverRank";
import SafetyRankBadge from "../components/safteyRank";


const AciveChampionshipView = () => {
  const { id } = useParams<{  id: string }>();
  const rc = new RaceControlClient();

  const [championship, setChampionship] = React.useState<Championship | null>(null);

  const objectPropsToList = (obj: any) => {
    if (!obj) {
      return null;
    }

    const listItems = Object.keys(obj).map((key, index) => (
      <li key={index}>{key}: {obj[key]}</li>
    ));

    return listItems;
  }

  const getCarRegisrationCount = (carName: string) => {
    if (!championship?.registrations) {
      return 0;
    }

    return championship.registrations.filter((reg) => reg.car.name === carName).length;
  }

  const viewLeaderboard = (league: League) => {
    window.location.href = `/rm/leaderboard/${championship?.seasonName}-${championship?.name}-${championship?.type}-${league.name}`;
  }

  const fetchChampionship = async () => {
    if (!id) return;

    await rc.getActiveChampionship(id)
      .then((response: Championship) => {
        setChampionship(response);
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }

  React.useEffect(() => {
    fetchChampionship();
  }, []);

  return (
    <div style={{ margin: "2rem" }}>
      <h1>Championship {championship?.name}</h1>
      {!championship 
        ? <div>loading...</div>
        : (
          <Row>
            <Col>
              <h3>General Setings</h3>
              <ul>
                <li><strong>Type:</strong> {championship.type}</li>
                <li><strong>Tier:</strong> {championship.tier.name} | {championship.tier.entryRequirement.rank}{championship.tier.entryRequirement.tier}</li>
                <li><strong>Frequency:</strong> {championship.frequency}</li>
                <li><strong>Starts:</strong> {moment(championship.dateFrom).format("dd/MM HH;:mm")}</li>
                <li><strong>Ends:</strong> {moment(championship.dateTo).format("dd/MM HH;:mm")}</li>
                <li><strong>Drop Weeks:</strong> {championship.dropWeeks}</li>
                <li><strong>Attempts:</strong> {championship.maxEventRegistrations}</li>
                <li><strong>Players Per League:</strong> {championship.playersPerSkillBasedLeague}</li>
                <li><strong>Season:</strong> {championship.seasonName}</li>
                <li><strong>Split settings:</strong> {championship.splitSettings}</li>
                <li><strong>Week Length:</strong> {championship.weekLength}</li>
                <li><strong># Weeks:</strong> {championship.weeks.length}</li>
              </ul>

              <h3>Cars:</h3>
              <ul>
                {championship.cars.map((car, index) => (
                  <li key={index}>(<strong>{getCarRegisrationCount(car.name)}</strong>) {car.friendly ?? car.name}</li>
                ))}
              </ul>

              <h3>Class Breakdown:</h3>
              <ul>
                <li><strong>HY:</strong> {championship.registrations?.filter((reg) => reg.class === "Hypercar")?.length ?? 0}</li>
                <li><strong>LMP2:</strong> {championship.registrations?.filter((reg) => reg.class === "LMP2")?.length ?? 0}</li>
                <li><strong>GT:</strong> {championship.registrations?.filter((reg) => reg.class === "GTE")?.length ?? 0}</li>
              </ul>

              <h3>Weeks</h3>
              <ul>
                {championship.weeks.map((week, index) => (
                  <>
                  <li key={index}>
                    <strong>Week {week.week}</strong> - {week.track.name} - PTS: {week.pointsMultiplier}x
                  </li>
                  <li>
                    <ul>
                      {week.sessions.map((session, index) => (
                        <li key={index}>
                          {session.type} - {session.timeOfDay} - {session.duration}m
                        </li>
                      ))}
                    </ul>
                  </li>
                  </>
                ))}
              </ul>

              <h3>Server Settings</h3>
              <ul>
                <li><strong>Players:</strong> {championship.serverSettings.maxPlayers}</li>
                <li><strong>Race Start:</strong> {multiFormationLapValue(championship.serverSettings.multiFormationLap)}</li>
                <li><strong>Fixed Setup:</strong> {championship.serverSettings.fixedSetup ? "true" : "false"}</li>
                <li><strong>Private Quali:</strong> {championship.serverSettings.privateQualifying ? "true" : "false"}</li>
                <li><strong>Real Road Scale:</strong> {championship.serverSettings.realRoadScale}x</li>
                <li><strong>Tyre Wear:</strong> {championship.serverSettings.tyreWearMultiplier}x</li>
                <li><strong>Track Limits:</strong> {championship.serverSettings.trackLimitsRule}</li>
                <li><strong>Track Limit Points:</strong> {championship.serverSettings.trackLimitsPointsAllowed}</li>
                <li><strong>Limited Tires:</strong> {championship.serverSettings.tires?.limitedTireSets}</li>
                <li><strong>Tires:</strong> {championship.serverSettings.tires?.availableTires}</li>
              </ul>

              <h3>Assists</h3>
              <ul>
                {objectPropsToList(championship.serverSettings.assists)}
              </ul>
            </Col>

            <Col>
              <h3>Registrations</h3>
              <table className="table table-dark">
                <thead>
                  <tr>
                    <th>Driver</th>
                    <th>Nationality</th>
                    <th>Class</th>
                    <th>Manufacturer</th>
                    <th>Car</th>
                    <th>DR</th>
                    <th>SR</th>
                  </tr>
                </thead>
                <tbody>
                  {championship.registrations?.sort((a,b) => b.driver.driverRank.elo - a.driver.driverRank.elo)?.map((registration, index) => (
                    <tr key={index} onClick={() => window.location.href = `/players/view/${registration.driver.id}/${championship.game}`}>
                      <td>{registration.driver.name}</td>
                      <td>{registration.driver.profile.nationality}</td>
                      <td>{registration.class}</td>
                      <td>{registration.manufacturer}</td>
                      <td>{registration.car.name}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <DriverRankBadge dr={registration.driver.driverRank} />
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <SafetyRankBadge sr={registration.driver.safetyRank} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>

            <Col>
              <h2>Leagues</h2>
              <table className="table table-dark">
                <thead>
                  <tr>
                    <th>type</th>
                    <th>League</th>
                    <th>Min Elo</th>
                    <th>Max Elo</th>
                    <th>Players</th>
                  </tr>
                </thead>
                <tbody>
                  {championship.leagues.sort((a, b) => a.type.localeCompare(b.type)).map((league, index) => (
                    <tr key={index} onClick={() => viewLeaderboard(league)}>
                      <td>{league.type}</td>
                      <td>{league.name}</td>
                      <td>{league.eloMin.toFixed(1)}</td>
                      <td>{league.eloMax.toFixed(1)}</td>
                      <td>{league.players?.length ?? 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        )}
    </div>

  );
}

export default withAuthentication(AciveChampionshipView);