import { DriverRank, SafetyRank } from "./Ranks";

export class RaceDriver {
  public name: string = "";
  public steamId: string = "";

  public safetyRank: SafetyRank = new SafetyRank();
  public driverRank: DriverRank = new DriverRank();

  public safetyRankAdjustment: number = 0;
  public driverRankAdjustment: number = 0;
}