import React from "react";
import { useParams } from 'react-router-dom';
import withAuthentication from "../withAuthentication";
import { Col, Row } from "react-bootstrap";
import { Event } from "../types/event/Event";
import moment from "moment";
import { RaceControlClient } from "../services/race-control.client";
import { Table } from "reactstrap";
import { multiFormationLapValue } from "../types/event/DailyRaceConfiguration";
import DriverRankBadge from "../components/driverRank";
import SafetyRankBadge from "../components/safteyRank";

const EventView = () => {
  const [event, setEvent] = React.useState<Event | null>(null);
  const { eventType, eventId } = useParams<{  eventType: string, eventId: string }>();
  const rc = React.useRef<RaceControlClient>(new RaceControlClient());
  
  React.useEffect(() => {
    const fetch = async () => {
      rc.current.getEvent(eventType, eventId)
        .then((event: Event) => {
          setEvent(event);

          if (event.status !== "Completed" && event.status !== "Cancelled") {
            setTimeout(() => fetch(), 5000);
          }
        })
        .catch((err: Error) => {
          console.error(err);
        });
    }

    fetch();
  }, [eventType, eventId]);

  const objectPropsToList = (obj: any) => {
    if (!obj) {
      return null;
    }

    const listItems = Object.keys(obj).map((key, index) => (
      <li key={index}>{key}: {obj[key]}</li>
    ));

    return listItems;
  }

  const getCarRegisrationCount = (carName: string) => {
    if (!event?.registrations) {
      return 0;
    }

    return event.registrations.filter((reg) => reg.car.name === carName).length;
  }

  return (
    <div style={{margin: "3rem"}}>
      {!event 
        ? (<p>No Event Found</p>)
        : (
          <>
            <h1 style={{marginBottom: "2rem"}}>{event.configuration.title} - {event.configuration.tier.name} - {event.id}</h1>
            <Row>
              <Col sm={2}>
                <h4>Event Details</h4>
                <ul>
                  <li><span>Status:</span> {event.status}</li>
                  <li><span>Event Starts At:</span> {moment(event.configuration.starts).format("DD/MM/YYYY HH:mm:ss")}</li>
                  <li><span>Race Starts At:</span> {moment(event.raceStartsAt).format("DD/MM/YYYY HH:mm:ss")}</li>
                  <li><span>Race Ends At:</span> {moment(event.raceEndsAt).format("DD/MM/YYYY HH:mm:ss")}</li>
                  <li><span>Track:</span> {event.configuration.content.track.friendly ?? event.configuration.content.track.name} - {event.configuration.content.track.subItems[0].friendly ?? event.configuration.content.track.subItems[0].name}</li>
                </ul>
                
                <h4>Cars ({event.configuration.content.cars.length})</h4>
                <ul>
                  {event.configuration.content.cars.map((car, index) => (
                    <li key={index}><span>{car.friendly ?? car.name}</span> {getCarRegisrationCount(car.name)}</li>
                  ))}
                </ul>
                
                <h4>Class Breakdown:</h4>
                <ul>
                  <li><span>HY:</span> {event.registrations?.filter((reg) => reg.class === "Hypercar")?.length ?? 0}</li>
                  <li><span>LMP2:</span> {event.registrations?.filter((reg) => reg.class === "LMP2")?.length ?? 0}</li>
                  <li><span>GT:</span> {event.registrations?.filter((reg) => reg.class === "GTE")?.length ?? 0}</li>
                </ul>
                
                <p>
                  <a className="btn btn-info" href={`https://s3.console.aws.amazon.com/s3/buckets/${process.env.REACT_APP_S3_RESULTS_BUCKET}?region=${process.env.REACT_APP_AWS_REGION}&prefix=${event.type}/${event.seriesId}/${event.id}/&showversions=false`}>
                    S3 File Location
                  </a>
                </p>

                <p>
                  <h3>Sessions</h3>
                  <ul>
                    {event.configuration.sessions.map((session, index) => (
                      <li key={index}><span>{session.type}</span> {session.duration} - {session.timeOfDay}</li>
                    ))}
                  </ul>
                </p>

                <p>
                  <h3>Settings</h3>
                    <ul>
                    <li><span>Players:</span> {event.configuration.settings.maxPlayers}</li>
                    <li><span>Race Start:</span> {multiFormationLapValue(event.configuration.settings.multiFormationLap)}</li>
                    <li><span>Fixed Setup:</span> {event.configuration.settings.fixedSetup ? "true" : "false"}</li>
                    <li><span>Private Quali:</span> {event.configuration.settings.privateQualifying ? "true" : "false"}</li>
                    <li><span>Real Road Scale:</span> {event.configuration.settings.realRoadScale}x</li>
                    <li><span>Tyre Wear:</span> {event.configuration.settings.tyreWearMultiplier}x</li>
                    <li><span>Track Limits:</span> {event.configuration.settings.trackLimitsRule}</li>
                    <li><span>Track Limit Points:</span> {event.configuration.settings.trackLimitsPointsAllowed}</li>
                    </ul>
                </p>

                <p>
                  <h3>Assists</h3>
                  <ul>
                    {objectPropsToList(event.configuration.settings.assists)}
                  </ul>
                </p>
              </Col>

              {event.splits == null || event.splits.length === 0 
                ? (
                  <Col>
                    <h4>
                      Registrations
                        <span style={{ fontSize: "1rem", marginLeft: "2rem" }}>
                          (HY: {event.registrations?.filter((reg) => reg.class === "Hypercar").length ?? 0} |
                          LMP2: {event.registrations?.filter((reg) => reg.class === "LMP2").length ?? 0} |
                          GT: {event.registrations?.filter((reg) => reg.class === "GTE").length ?? 0})
                        </span>
                    </h4>

                    <Table dark>
                      <thead>
                        <tr>
                          <th>Driver</th>
                          <th>Class</th>
                          <th>Car</th>
                          <th>DR</th>
                          <th>SR</th>
                        </tr>
                      </thead>
                      <tbody>
                        {event.registrations?.sort((a,b) => b.driver.driverRank.elo - a.driver.driverRank.elo)?.map((registration, index) => (
                          <tr key={index} onClick={() => window.location.href = `/players/view/${registration.driver.id}/${event.configuration.game}`}>
                            <td>{registration.driver.name}</td>
                            <td>{registration.class}</td>
                            <td>{registration.car.name}</td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              <DriverRankBadge dr={registration.driver.driverRank} />
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              <SafetyRankBadge sr={registration.driver.safetyRank} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>  
                ) : (
                  <Col>
                    <h2>Splits</h2>

                    {event.splits?.sort((a, b) => parseInt(a.splitNo) - parseInt(b.splitNo))?.map((split, index) => (
                      <>
                        <h3>Split #{split.splitNo}</h3>
                        <h4>
                          HY: {split.drivers?.filter((reg) => reg.class === "Hypercar").length ?? 0} |
                          LMP2: {split.drivers?.filter((reg) => reg.class === "LMP2").length ?? 0} |
                          GT: {split.drivers?.filter((reg) => reg.class === "GTE").length ?? 0}
                        </h4>
                        <Table dark>
                          <thead>
                            <tr>
                              <th>Driver</th>
                              <th>Class</th>
                              <th>Car</th>
                              <th>DR</th>
                              <th>SR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {split.drivers?.sort((a,b) => b.driver.driverRank.elo - a.driver.driverRank.elo)?.map((reg, index) => (
                              <tr key={index} onClick={() => window.location.href = `/players/view/${reg.driver.id}/${event.configuration.game}`}>
                                <td>{reg.driver.name}</td>
                                <td>{reg.class}</td>
                                <td>{reg.car?.name}</td>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  <DriverRankBadge dr={reg.driver.driverRank} />
                                </td>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  <SafetyRankBadge sr={reg.driver.safetyRank} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    ))}
                  </Col>
                )
              }

              <Col sm={3}>
                <h4>Splits</h4>
                {event.splits?.sort((a, b) => parseInt(a.splitNo) - parseInt(b.splitNo))?.map((split, index) => (
                  <div key={index}>
                    <p>
                      <span style={{marginRight: "2.5rem"}}><strong>Split:</strong> #{split.splitNo}</span>
                      <strong># Drivers:</strong> {split.drivers?.length}
                    </p>
                    <p>
                      HY: {split.drivers?.filter((reg) => reg.class === "Hypercar").length ?? 0} |
                      LMP2: {split.drivers?.filter((reg) => reg.class === "LMP2").length ?? 0} |
                      GT: {split.drivers?.filter((reg) => reg.class === "GTE").length ?? 0}
                    </p>
                    
                    <p><strong>Server Status:</strong>{split.server?.status}</p>
                    <p><strong>Server ARN:</strong>{split.server?.arn}</p>
                    <p>
                      <a className="btn btn-success" style={{marginRight: "0.5rem"}} href={`http://localhost:5397/rest/multiplayer/join?host=${split.server?.host}&port=64290&password=${split.server?.password}`}>JOIN</a>
                      <a className="btn btn-info" style={{marginRight: "0.5rem"}} href={`https://s3.console.aws.amazon.com/s3/buckets/${process.env.REACT_APP_S3_RESULTS_BUCKET}?region=${process.env.REACT_APP_AWS_REGION}&prefix=${event.type}/${event.seriesId}/${event.id}/${split.splitNo}/&showversions=false`}>
                        S3 Files
                      </a>
                      <a className="btn btn-info " href={`/rm/results/view?seriesId=${event.seriesId}&eventId=${event.id}&splitNo=${split.splitNo}`}>Results</a>
                    </p>
                  </div>
                ))}

                <h4>Servers</h4>
                {event.servers?.map((server, index) => (
                  <ul key={index}>
                    <li><span><strong>Server:</strong></span> {server.arn}</li>
                    <li><span><strong>Status:</strong></span> {server.status}</li>
                    <li><span><strong>Host:</strong></span> {server.host}</li>
                  </ul>
                ))}
              </Col>
            </Row>
          </>
        )
      }
    </div>
  )
}

export default withAuthentication(EventView);
