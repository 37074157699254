import React from "react";
import withAuthentication from "../../withAuthentication";
import RaceManagementClient from "../../services/race-manaement.client";
import { Col, Row } from "react-bootstrap";
import { FastestLapStat } from "../../types/Stats";
import ContentManagerClient from "../../services/content-manager.client";
import { InstalledContent } from "../../types/InstalledContent";

const StatsFatestLapsView = () => {
  const [fastestLaps, setFastestLaps] = React.useState<FastestLapStat[]>([]);
  const [tracks, setTracks] = React.useState<InstalledContent[]>([]);
  //const [cars, setCars] = React.useState<InstalledContent[]>([]);

  const [page, setPage] = React.useState<number>(0);
  const [take, setTake] = React.useState<number>(20);
  const [rank, setRank] = React.useState<string>('');
  const [track, setTrack] = React.useState<string>('');
  const [car, setCar] = React.useState<string>('');
  const [_class, setClass] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [bop, setBOP] = React.useState<string>('');

  const rm = React.useRef<RaceManagementClient>(new RaceManagementClient());
  const cm = React.useRef<ContentManagerClient>(new ContentManagerClient());

  const cars = [
    { name: 'Peugeot 9x8' },
    { name: 'Oreca 07' },
    { name: 'Glickenhaus SGC007' },
    { name: 'Cadillac V-lmdh' },
    { name: 'Porsche 963' },,
    { name: 'Porsche 911 RSR-19' },
    { name: 'Ferrari 499P' },
    { name: 'Toyota GR010' },
    { name: 'Corvette C8.R GTE' },
    { name: 'Vanwall 680' },
    { name: 'Aston Martin Vantage AMR' },
    { name: 'Ferrari 488 GTE EVO' }
  ];

  const bops = [
    { name: '1.0' },
    { name: '1.1' },
    { name: '1.2' },
    { name: '1.3' },
    { name: '1.4' },
    { name: '1.5' },
    { name: '1.6' },
    { name: '1.7' },
    { name: '1.8' },
    { name: '1.9' },
  ];
  
  const fetch = async (page: number, take: number, track: string, car: string, _class: string, rank: string, bop: string) => {
    setLoading(true);
    setFastestLaps([]);

    rm.current.fatestLaps(page, take, track, car, _class, rank, bop).then(data => {
      setFastestLaps(data);
      setLoading(false);
    }).catch(e => {
      console.error(e);
      setLoading(false);
    });
  }

  const fetchTracks = async () => {
    cm.current.getContentByType("track", "lmu", ".*").then(data => {
      setTracks(data);
    }).catch(e => {
      console.error(e);
    });
  }
  const fetchCars = async () => {
    cm.current.getContentByType("car", "lmu", ".*").then(data => {
      //setCars(data);
    }).catch(e => {
      console.error(e);
    });
  }

  const floatToTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes}:${seconds.toFixed(3)}`;
  }

  const downloadAsCsv = async () => {
    const laps = await rm.current.fatestLaps(0, 10000, track, car, _class, rank, bop);
    let csv = laps.map(lap => {
      return `${lap.rank[0]}${lap.tier},${lap.driver},${lap.track},${lap.vehName},${lap.carClass},${floatToTime(lap.lapTime)},${lap.s1},${lap.s2},${lap.s3},${(lap.fuel *100).toFixed(1)}%,${lap.bop}`;
    }).join("\n");

    csv = `Rank,Driver,Track,Car,Class,Time,S1,S2,S3,Fuel,BOP\n${csv}`;

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'fastest-laps.csv';
    a.click();
  }

  React.useEffect(() => { 
    fetchTracks();
    //fetchCars();
  }, []);

  React.useEffect(() => {}, [fastestLaps]);
  React.useEffect(() => {
    fetch(page, take, track, car, _class, rank, bop);
  }, [page, take, track, car, _class, rank, bop]);

  return (
    <div className='container'>
      <h1>Fastest Laps</h1>

      <Row>
        <Col xs={2}>
          <select onChange={(e) => setTrack(e.target.value)}>
            <option value=''>Select a Track</option>
            {tracks.map((track, idx) => {
              return (
                <option key={idx} value={track.name}>{track.name}</option>
              )
            })}
          </select>
        </Col>
        <Col xs={2}>
          <select onChange={(e) => setBOP(e.target.value)}>
            <option value=''>Select a BOP</option>
            {bops.map((bop, idx) => {
              return (
                <option key={idx} value={bop.name}>{bop.name}</option>
              )
            })}
          </select>
        </Col>
        <Col xs={2}>
          <select onChange={(e) => setRank(e.target.value)}>
            <option value=''>All Driver Ranks</option>
            <option value="Bronze">Bronze</option>
            <option value="Silver">Silver</option>
            <option value="Gold">Gold</option>
            <option value="Platinum">Platinum</option>
          </select>
        </Col>
        <Col xs={2}>
          <select onChange={(e) => setClass(e.target.value)}>
            <option value=''>All Car Class</option>
            <option value='Hyper'>HY</option>
            <option value='LMP2'>LMP2</option>
            <option value='GTE'>GTE</option>
          </select>
        </Col>
        <Col>
          <select onChange={(e) => setCar(e.target.value)}>
            <option value=''>All Cars</option>
            {cars.map((car, idx) => {
              return (
                <option key={idx} value={car?.name}>{car?.name}</option>
              )
            })}
          </select>
        </Col>
        <Col>
          <button className="btn btn-secondary mr2" style={{marginRight: "1rem"}} onClick={() => { if (page > 0) setPage(page-1) }}>Prev</button>
          <span style={{marginTop: "2rem", display: "inline"}}>{page}</span>
          <button className="btn btn-secondary mr2" style={{marginLeft: "1rem"}} onClick={() => { if (fastestLaps) setPage(page+1) }}>Next</button>
        </Col>

        <Col>
          <button className="btn btn-secondary mr2" style={{marginRight: "1rem"}} onClick={() => downloadAsCsv()}>Download as CSV</button>
        </Col>
      </Row>

      <table className="table table-dark">
        <thead>
          <tr>
            <th>BOP</th>
            <th>Rank</th>
            <th>Driver</th>
            <th>Track</th>
            <th>Car</th>
            <th>Class</th>
            <th>Time</th>
            <th>S1</th>
            <th>S2</th>
            <th>S3</th>
            <th>Fuel</th>
          </tr>
        </thead>
        <tbody>
          {loading && <tr><td colSpan={10}>Loading...</td></tr>}
          {(!fastestLaps || fastestLaps.length === 0) && !loading && <tr><td colSpan={10}>No Data</td></tr>}

          {fastestLaps && fastestLaps.map((lap, idx) => {
            return (
              <tr key={idx}>
                <td>{lap.bop}</td>
                <td>{lap.rank[0]}{lap.tier}</td>
                <td>{lap.driver}</td>
                <td>{lap.track}</td>
                <td>{lap.vehName}</td>
                <td>{lap.carClass}</td>
                <td>{floatToTime(lap.lapTime)}</td>
                <td>{lap.s1}</td>
                <td>{lap.s2}</td>
                <td>{lap.s3}</td>
                <td>{(lap.fuel *100).toFixed(1)}%</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default withAuthentication(StatsFatestLapsView);
