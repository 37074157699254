import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';

export interface UnlinkAreYouSureModalProps {
    show: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const UnlinkAreYouSureModal = (props: UnlinkAreYouSureModalProps) => {


    return (
        <>
            <style>
                {`
                    .unlink-steamid-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .unlink-steamid-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .unlink-steamid-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .unlink-steamid-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .unlink-steamid-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .unlink-steamid-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .strike-form-text-grey {
                        color: grey !important;
                    }
                `}
            </style>
            <Modal className="unlink-steamid-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="unlink-steamid-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Unlink SteamID Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="unlink-steamid-modal-body">
                    <Container className="unlink-steamid-modal-body-container">
                        <Row>
                            <Col>
                            You are about to unlink the SteamID from this username reservation. Are you sure you want to proceed?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="unlink-steamid-modal-footer">
                    <Row style={{width: "100%"}}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{display: "flex", justifyContent: "flex-end"}} >
                            <Button variant="danger" onClick={props?.onConfirm}>Unlink</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UnlinkAreYouSureModal;