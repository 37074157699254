import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import SecurityServiceClient from './services/security-service.client';
import { HttpProtectedUserViewModel } from './types/Security';
import { loginMethods } from "./authConfig";

interface AuthContextValue {
  isAuthenticated: boolean;
  setIsAuthenticated(authenticated: boolean): void;
  self: HttpProtectedUserViewModel | null;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  self: null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const securityService = new SecurityServiceClient();

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(loginMethods.current !== null);

  const [self, setSelf] = useState<HttpProtectedUserViewModel | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      securityService.getSelf().then((user) => {
        // The service client methods do not perform error handling or expose
        // response codes. On an error, they return the JSON error object from
        // the server (which also violates their type signature). So, we do a simple
        // check to see if the object really is a user, and if not, throw an
        // exception.
        if (!user.objectId) {
          throw new Error("didn't receive user");
        }
        setSelf(user);
      }).catch(err => {
        // If there's any issue getting user identity, e.g. API token expired, just log out.
        console.error(err);
        setIsAuthenticated(false);
        loginMethods.current?.logOut();
      });
    } else {
      setSelf(null);
    }
  }, [isAuthenticated]);

  const value: AuthContextValue = {
    isAuthenticated,
    setIsAuthenticated,
    self,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
