import { AddUsernameReservationRequest, AllUsernameReservationsResponse, DeleteUsernameReservationRequest} from '../types/User';

import OAuthClient from './oauth.client';

class UsernameReservationServiceClient {
    url = process.env.REACT_APP_RM_URL;
    oauth: OAuthClient;

    constructor() {
      this.oauth = new OAuthClient();
    }

    getAll(): Promise<AllUsernameReservationsResponse> {
        return this.oauth.fetch(`${this.url}/nameReservations/get/all`, "GET")
            .then(res => res.json());
    }

    addUsernameReservation(username: string, steamId: string): Promise<any> {
        const request: AddUsernameReservationRequest = {
            username: username,
            steamId: steamId
        };

        return this.oauth.fetch(`${this.url}/nameReservations/add`, "POST", JSON.stringify(request))
            .then(res => res.json());
    }

    deleteUsernameReservation(username: string): Promise<any> {
        const request: DeleteUsernameReservationRequest = {
            username: username
        };

        return this.oauth.fetch(`${this.url}/nameReservations/delete`, "POST", JSON.stringify(request))
            .then(res => res.json());
    }
}

export default UsernameReservationServiceClient;