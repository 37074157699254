export class DriverRank {
  elo: number = 0;
  rank: string = "Bronze";
  tier: number = 1;
  progress: number = 0;
}

export class SafetyRank {
  rating: number = 0;
  rank: string = "Bronze";
  tier: number = 0;
  progress: number = 0;
}