import { Accordion, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Championship, ChampionshipWeek } from '../models/Championship';
import React, { useEffect } from 'react';
import { FormCheck, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import TrackSelection from '../../rm/configs/TrackSelection';
import { Item } from '../../types/event/Content';
import SessionModal from '../../rm/configs/SessionModal';
import { Session } from "../../types/event/Session";
import { multiFormationLap } from "../../types/event/DailyRaceConfiguration";
import Note from '../../components/generic/Note';
import { set } from 'lodash';

export interface ChampionshipWeekConfigurationModalProps {
    show: boolean;
    championship: Championship;
    week: ChampionshipWeek;
    editable: boolean;
    onCancel: () => void;
    onConfirm: (championship: ChampionshipWeek) => void;
}

const ChampionshipWeekConfigurationModal = (props: ChampionshipWeekConfigurationModalProps) => {

    const [championshipWeek, setChampionshipWeek] = React.useState<ChampionshipWeek>({ ...props?.week });

    useEffect(() => {
        if (props?.show) {
            onShow();
        }
    }, [props?.show]);

    const onShow = () => {
        setChampionshipWeek({ ...props?.week });
    }

    const onSave = () => {
        props?.onConfirm(championshipWeek);
    }

    const onCancel = () => {
        setChampionshipWeek({ ...props?.week });
        props?.onCancel();
    }

    return (
        <>
            <style>
                {`
                .championship-configuration-modal-container {
                    background-color: rgba(0, 0, 0, 0.25);
                    color: snow;
                }

                .championship-configuration-modal-header {
                    background-color: #333333;
                    color: snow;
                    border-bottom: 1px solid #454545;
                    font-size: 1.2em;
                    font-weight: bold;
                    text-align: center;
                }

                .championship-configuration-modal-body {
                    background-color: #333333;
                    color: snow;
                }

                .championship-configuration-modal .modal-body-container {
                    background-color: #333333;
                    color: snow;
                }

                .championship-configuration-modal-footer {
                    background-color: #333333;
                    color: snow;
                    border-top: 1px solid #454545;
                }

                .dark-select {
                    background-color: #333333 !important;
                    color: snow;
                    border: 1px solid #454545;
                    outline: none !important;
                }

                .dark-select option {
                    background-color: #333333 !important;
                    color: snow;
                    border: 1px solid #454545;
                    outline: none !important;
                }

                .dark-select:focus, .dark-select:active, .dark-select:hover {
                    background-color: #333333 !important;
                    color: snow;
                    border: 1px solid #454545;
                    outline: none !important;
                }
            `}
            </style>
            <Modal className="championship-configuration-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="championship-configuration-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`Editing Championship Event: #${championshipWeek?.week}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="championship-configuration-modal-body">
                    <Container>
                        {
                            !props?.editable &&
                            <Row style={{ margin: "0.1rem" }}>
                                <Col>
                                    <Note customText="This is an active or past week and cannot be edited (any changes will not be saved)" />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <Form>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Championship Event Settings</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col xs={4}>
                                                        <FormGroup>
                                                            <FormLabel>Points Multiplier</FormLabel>
                                                            <FormControl
                                                                type="number"
                                                                value={championshipWeek.pointsMultiplier}
                                                                onChange={(e) => setChampionshipWeek({ ...championshipWeek, pointsMultiplier: parseFloat(e.target.value) })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <FormGroup>
                                                            <FormLabel>Free Entry</FormLabel>
                                                            <FormCheck
                                                                type="checkbox"
                                                                checked={championshipWeek.freeEntry}
                                                                onChange={(e) => setChampionshipWeek({ ...championshipWeek, freeEntry: e.target.checked })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Group controlId="multiFormationLap">
                                                            <Form.Label>Formation Lap:</Form.Label>
                                                            <Form.Control className="dark-select" as="select" onChange={(e) => setChampionshipWeek({ ...championshipWeek, multiFormationLap: Number(e?.target?.value) })}>
                                                                <option value="">Please Select</option>
                                                                {multiFormationLap?.map((option) => (
                                                                    <option value={option?.key} selected={championshipWeek?.multiFormationLap?.toString() === option?.key}>{option?.value}</option>
                                                                ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    {championshipWeek?.frequency?.map((freq, i) => (
                                                        <Col>
                                                            <Form.Group controlId="frequency">
                                                                <Form.Label>Frequency [{i + 1}]:</Form.Label>
                                                                <Form.Control type="string" value={freq}
                                                                    onChange={(e) => {
                                                                        if (championshipWeek == void (0)) return;

                                                                        championshipWeek.frequency[i] = e.target.value;
                                                                        setChampionshipWeek({ ...championshipWeek, frequency: championshipWeek?.frequency })
                                                                    }} />
                                                            </Form.Group>
                                                        </Col>
                                                    ))}
                                                    <Col>
                                                        <Button style={{ marginTop: "2rem", marginRight: "1rem" }} variant="primary" type="button" onClick={() => {
                                                            let freq = championshipWeek.frequency;
                                                            freq.pop();
                                                            setChampionshipWeek({ ...championshipWeek, frequency: freq })
                                                        }}
                                                        >Del</Button>

                                                        <Button style={{ marginTop: "2rem" }} variant="primary" type="button" onClick={() => {
                                                            let freq = championshipWeek.frequency;
                                                            freq.push("0 9-22/1 * * *");
                                                            setChampionshipWeek({ ...championshipWeek, frequency: freq })
                                                        }}
                                                        >Add</Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <TrackSelection game={props?.championship?.game} track={championshipWeek?.track || new Item()} layout={championshipWeek?.track.subItems[0]} onSelected={(track) => setChampionshipWeek({ ...championshipWeek, track: track })} />
                                                </Row>
                                                <Row>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Session Configuration </Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    {championshipWeek?.sessions.map((session, index) => (
                                                        <SessionModal key={index} session={session} index={index} sessions={championshipWeek?.sessions} setSessions={(newSessions) => setChampionshipWeek({ ...championshipWeek, sessions: newSessions })} />
                                                    ))}

                                                    <div className="col-sm-4">
                                                        <Button variant="primary"
                                                            style={{ height: "calc(100% - 15px)", width: "100%", background: "transparent", borderStyle: "dashed", borderWidth: "4px", minHeight: "201px", borderColor: "#4b5660", color: "#4b5660", fontWeight: 600, fontSize: "2.3rem" }}
                                                            onClick={() => setChampionshipWeek({ ...championshipWeek, sessions: [...championshipWeek?.sessions, new Session()] })}>Add Session</Button>
                                                    </div>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="championship-configuration-modal-footer">
                    <Row style={{ width: "100%" }}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="danger" onClick={onCancel}>Cancel</Button>
                        </Col>
                        {
                            props?.editable &&
                            <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{ display: "flex", justifyContent: "flex-end" }} >
                                <Button variant="success" onClick={onSave}>Confirm</Button>
                            </Col>
                        }
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ChampionshipWeekConfigurationModal;