import React from 'react';
import withAuthentication from '../withAuthentication';
import ListServers from './ListServers';
import RaceControlStatus from './RaceControlStatus';
import { Col, Row, Tab, Nav } from 'react-bootstrap';
import EventList from './EventList';
import LaunchServer from './LaunchServer';
import StatsView from '../stats/overall/StatsView';
import moment from 'moment';
import { RaceControlClient } from '../services/race-control.client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListFleetAgents from './ListFleetAgents';


const Home = () => {
  const [maintenance, setMaintenance] = React.useState(moment().add(365, 'days'));
  const timer = React.useRef<any>(null);
  const rc = React.useRef<RaceControlClient>(new RaceControlClient());

  const fetchMaintenance = async () => {
    const date = await rc.current.getNextMaintenance();
    setMaintenance(date);

    clearTimeout(timer.current);
    timer.current = setTimeout(() => fetchMaintenance(), 60000);
  }

  React.useEffect(() => {
    fetchMaintenance();
    timer.current = setTimeout(() => fetchMaintenance(), 60000);

    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  React.useEffect(() => {}, [maintenance]);


  return (
    <div className="container-dark">
      {maintenance.isBefore(moment().add(3, 'days')) && (
        <div className="alert alert-warning" role="alert">
          <FontAwesomeIcon icon="exclamation-triangle" />{' '}
          <strong>Upcoming Maintenance: {maintenance.format('Do MMMM YYYY, h:mm:ss a')}</strong>
        </div>
      )}

      <Tab.Container defaultActiveKey="servers">
        <Row>
          <div className="d-flex flex-column" style={{ padding: 0 }}>
            <div className="d-flex flex" style={{ padding: 0 }}>
              <Col sm={6}>
                <div className="d-flex" style={{height: "100%"}}>
                  <div style={{ marginBottom: "0.5rem", alignSelf: "end"}}>
                    <Nav variant="pills" className="flex">
                      <Nav.Item>
                        <Nav.Link eventKey="servers">Servers & Race Control Status</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fleet-agents">Fleet Agents</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="races">Events</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Col>
            </div>

            <Col sm={12}>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey="servers">
                    <h4 style={{marginBottom: "1rem"}}>Servers</h4>
                    <ListServers />

                    <h4 style={{marginTop: "2rem", marginBottom: "1rem"}}>Race Control Status</h4>
                    <RaceControlStatus />
                  </Tab.Pane>

                  <Tab.Pane eventKey="fleet-agents">
                    <h4 style={{marginBottom: "1rem"}}>Fleet Agents</h4>
                    <ListFleetAgents />
                  </Tab.Pane>

                  <Tab.Pane eventKey="races">
                    <EventList />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </div>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default withAuthentication(Home);
