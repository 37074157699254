import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col, Tab } from 'react-bootstrap';
import SecurityServiceClient from '../../services/security-service.client';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import RoutesListView from './RoutesListView';
import { HttpProtectedRole, HttpProtectedRoute, HttpProtectedRouteViewModel } from '../../types/Security';

interface IRoutesTabbedViewProps {
    routes: Array<HttpProtectedRouteViewModel>;
    routesByService: Map<string, Array<HttpProtectedRouteViewModel>>;
    allRoles: Array<HttpProtectedRole>;
    requestRefresh?: (indices: Array<number>) => void;
}

const RoutesTabbedView = (props: IRoutesTabbedViewProps) => {

    const [defaultServiceKey, setDefaultServiceKey] = useState<string>("");

    useEffect(() => {
        setDefaultServiceKey(props?.routesByService?.keys()?.next()?.value);
    }, []);
    
    return (
        <>
            <style>
                {`
            `}
            </style>
            <>
                {
                    defaultServiceKey !== "" &&
                    <Tab.Container id="left-tabs-example" defaultActiveKey={defaultServiceKey}>
                        <Row>
                            <Col sm="3" md="3" lg="3" xl="3" xxl="3">
                                <Nav variant="pills" className="flex-column">
                                    {
                                        Array.from(props?.routesByService?.keys())?.map((service) => {
                                            return (
                                                <Nav.Item key={service}>
                                                    <Nav.Link key={service} eventKey={service}>{service}</Nav.Link>
                                                </Nav.Item>
                                            );
                                        })
                                    }
                                </Nav>
                            </Col>
                            <Col sm="9" md="9" lg="9" xl="9" xxl="9">
                                <Tab.Content>
                                    {
                                        Array.from(props?.routesByService?.keys())?.map((service) => {
                                            return (
                                                <Tab.Pane key={service} eventKey={service}>
                                                    <RoutesListView
                                                        key={service}
                                                        routes={props?.routesByService?.get(service) || []}
                                                        allRoles={props?.allRoles}
                                                        requestRefresh={props?.requestRefresh}
                                                    />
                                                </Tab.Pane>
                                            );
                                        })
                                    }
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                }
            </>
        </>
    );
};

export default withAuthentication(RoutesTabbedView);
