import { AccountInfo, PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_OAUTH_ID ?? "",
    clientSecret: process.env.REACT_APP_OAUTH_SECRET,
    authority: "https://login.microsoftonline.com/20bc9139-adc7-4bce-802c-db01014de782/",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const scopes = process.env.REACT_APP_ENV === "development" 
  ? ["api://rc-api/dev/api-access"]
  : ["api://rc-api/dev/api-access"];

/** Data and methods specific to each login provider. */
type LoginMethods = {
  /** Get the login token for sending to the RC backend. */
  getToken(): Promise<string>;
  /** Trigger a log out of this provider. */
  logOut(): Promise<void>;
}

// Note: This login information must be globally accessible to be seen by OAuthClient;
// the rest is in the React AuthContext.
export const loginMethods: {current: LoginMethods | null} = {current: null};

export class MSLogin {
  private account: AccountInfo
  constructor(account: AccountInfo) {
    this.account = account;
  }
  async getToken() {
    const response = await msalInstance.acquireTokenSilent({
      account: this.account,
      scopes: scopes
    });
    return response.accessToken;
  }
  async logOut() {
    await msalInstance.logoutPopup();
  }
}

export class SteamLogin {
  private token: string
  constructor(token: string) {
    this.token = token;
  }
  async getToken() {
    return this.token;
  }
  async logOut() {
    window.localStorage.removeItem("steamUserToken");
  }
}

export let msalInstance: PublicClientApplication

/** Initialise login state, including setting loginMethods if the user is already logged in from a previous session. */
export async function initLogin() {
  msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  await msalInstance.handleRedirectPromise();

  const steamUserToken = window.localStorage.getItem("steamUserToken");
  if (steamUserToken) {
    loginMethods.current = new SteamLogin(steamUserToken);
  } else {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      loginMethods.current = new MSLogin(accounts[0]);
    }
  }
}
