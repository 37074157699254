import React, { useEffect, useState } from 'react';
import { Badge, Col, ListGroup } from 'react-bootstrap';
import { ProtestType, GroupedProtests } from '../types/Protest';
import {Driver} from '../types/event/Driver'
import { useNavigate } from "react-router-dom";
import { BsFillChatLeftTextFill, BsFillCarFrontFill, BsFillExclamationTriangleFill } from "react-icons/bs";
import ProtestServiceClient from '../services/protest-service.client';
import DriverAccordionViewItemBannedFlag from '../components/driver/DriverAccordionViewItemBannedFlag';

interface IProtestGroupedListViewItemProps {
    groupedProtests: GroupedProtests;
    onClick: (id: string, e: React.MouseEvent<Element, MouseEvent>) => void;
}

const ProtestGroupedListViewItem: React.FC<IProtestGroupedListViewItemProps> = (props) => {
    const protestService = new ProtestServiceClient();

    const [driver, setDriver] = useState<Driver | null>(null);

    useEffect(() => {
        if (props?.groupedProtests?.protests?.filter(p => p?.suspensionApplied === true)?.length > 0) {
            protestService.getUserById(props?.groupedProtests?.protesteeId, props?.groupedProtests?.protests?.find(p => p?.game != void(0) && p?.game?.length > 0)?.game || "")
            .then((user) => {
                setDriver(user);
            });
        }
    }, []);

    const formatDateString = (timestamp: number | undefined) => {
      let result = "";
      if (timestamp != void(0)) {
        const date = new Date(timestamp * 1000);
        const dateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        result = date.toLocaleDateString("en-GB", dateOptions);
      }
  
      return result;
    }

  return (
    <>
        <style>
            {`
                .protest-list-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .protest-list-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid white
                }

                .protest-badge-right {
                    display: flex;
                    justify-content: flex-end;
                    padding: 2px;
                    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                }
            `}
        </style>
        <ListGroup.Item key={"props.protest._id"}
                        as="li"
                        className="d-flex justify-content-between align-items-start protest-list-item"
                        onClick={(e: React.MouseEvent<Element, MouseEvent>) => props.onClick("props.protest._id", e)}
                        onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => props.onClick("props.protest._id", e)}>
                         
            <div className="row ms-1" style={{height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                <Col sm="1" md="1" lg="1" xl="1" xxl="1" style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
                {
                    // Note: the code is not game specific, but protest id's are because it's one per nakama instance
                    // So basically if any protests for this group have the field set, it will be the same for all of them
                    props?.groupedProtests?.protests?.find(p => p?.game != void(0) && p?.game?.length > 0)?.game?.toUpperCase()
                }
                </Col>
            </div>
            <div className="row ms-1" style={{height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                {
                    props?.groupedProtests?.protests?.filter(p => p?.protestType === ProtestType.Report)?.length > props?.groupedProtests?.protests?.filter(p => p?.protestType === ProtestType.Protest)?.length ?
                        <BsFillChatLeftTextFill size="1.5em" color="orangered"/>
                    :
                    <div style={{display: "grid", position: "relative"}}>
                        <div style={{gridColumn: 1, gridRow: 1}}>
                            <BsFillCarFrontFill size="1.5em" color="lightgrey"/>
                        </div>
                        <div style={{gridColumn: 1, gridRow: 1, position: "absolute", left: "-10%", bottom: "-10%"}}>
                            <BsFillExclamationTriangleFill size="1em" color="red"/>
                        </div>
                    </div>
                }
            </div>
            <div className="row ms-2 me-auto">
                <div className="row ms-2 me-auto">
                    <div className="col-6 fw-bold" style={{width: "fit-content"}}>{props.groupedProtests.protesteeUsername}</div>
                    <div className="col-6 fw-lighter fst-italic" style={{width: "fit-content"}}>
                        {formatDateString(props?.groupedProtests?.protests?.sort((a, b) => a.timestamp - b.timestamp)?.[0]?.timestamp)}
                        {" - "}
                        {formatDateString(props?.groupedProtests?.protests?.sort((a, b) => b.timestamp - a.timestamp)?.[0]?.timestamp)} 
                    </div>
                </div>
                <div className="row ms-2 me-auto">
                    <div className="col-12 me-auto">
                        {
                            driver &&
                            <DriverAccordionViewItemBannedFlag driver={driver}/>
                        }
                    </div>
                </div>
            </div>
            <div className="row ms-2 ms-auto">
                <div className="protest-badge-right">
                    <Badge bg="success" pill>
                        {props?.groupedProtests?.count}{" Open"}
                    </Badge>
                </div>
                <div className="protest-badge-right">
                    <Badge bg="danger" pill>
                        {props?.groupedProtests?.punishmentCount}{" Penalt"}{props?.groupedProtests?.punishmentCount === 1 ? "y" : "ies"}
                    </Badge>
                </div>
            </div>

        </ListGroup.Item>
    </>
  );
};

export default (ProtestGroupedListViewItem);
