import { Championship } from "../championships/models/Championship";
import { Season } from "../championships/models/Season";
import { SeasonDeletionRequest } from "../championships/requests/SeasonDeletionRequest";
import { SeasonsSearchRequest } from "../championships/requests/SeasonsSearchRequest";
import { SearchCountResponse } from "../championships/responses/SearchCountResponse";
import { SearchResponse } from "../championships/responses/SearchResponse";
import { SeasonDatesValidResponse } from "../championships/responses/SeasonDatesValidResponse";
import OAuthClient from './oauth.client';
import moment from "moment";

class ChampionshipServiceClient {
    url = process.env.REACT_APP_RM_URL;
    oauth: OAuthClient;

    constructor() {
      this.oauth = new OAuthClient();
    }

    async getSeason(seasonID: string): Promise<Season> {
      return this.oauth.fetch(`${this.url}/championships/seasons/${seasonID}`, "GET")
        .then(res => res.json());
    }

    async searchSeasons(game: string, text: string, from: Date, to: Date, page: Number, take: Number): Promise<SearchResponse> {

      const request: SeasonsSearchRequest = {
        game: game,
        text: text,
        timestampFrom: moment(from).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        timestampTo: moment(to).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        page: page,
        take: take
      };

      return this.oauth.fetch(`${this.url}/championships/seasons/search`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    async activeSeasons(): Promise<SearchResponse> {
      return this.oauth.fetch(`${this.url}/championships/seasons/active`, "GET")
        .then(res => res.json());
    }

    async createSeason(season: Season): Promise<string> {
      return this.oauth.fetch(`${this.url}/championships/seasons`, "PUT", JSON.stringify(season))
        .then(res => res.json());
    }

    async updateSeason(season: Season): Promise<Season> {
      return this.oauth.fetch(`${this.url}/championships/seasons`, "POST", JSON.stringify(season))
        .then(res => res.json());
    }

    async seasonDatesValid(season: Season): Promise<SeasonDatesValidResponse> {
      return this.oauth.fetch(`${this.url}/championships/seasons/seasonDatesValid`, "POST", JSON.stringify(season))
        .then(res => res.json());
    }

    async deleteSeason(seasonID: string): Promise<void> {
      const request: SeasonDeletionRequest = {
        seasonID: seasonID
      };
      
      return this.oauth.fetch(`${this.url}/championships/seasons`, "DELETE", JSON.stringify(request))
        .then(res => res.json());
    }

    async getChampionshipsBySeasonId(seasonID: string): Promise<Array<Championship>> {
      return this.oauth.fetch(`${this.url}/championships/seasons/${seasonID}/championships`, "GET")
        .then(res => res.json());
    }

    async addChampionshipToSeason(seasonID: string, championship: Championship): Promise<void> {
      return this.oauth.fetch(`${this.url}/championships/seasons/${seasonID}/addChampionship`, "PUT", JSON.stringify(championship))
        .then(res => res.json());
    }

    async removeChampionshipFromSeason(seasonID: string, championshipID: string): Promise<void> {
      return this.oauth.fetch(`${this.url}/championships/seasons/${seasonID}/deleteChampionship/${championshipID}`, "DELETE")
        .then(res => res.json());
    }

    async updateChampionship(seasonID: string, championship: Championship): Promise<void> {
      return this.oauth.fetch(`${this.url}/championships/seasons/${seasonID}/updateChampionship/${championship?._id}`, "POST", JSON.stringify(championship))
        .then(res => res.json());
    }
}

export default ChampionshipServiceClient;
