import React from "react";
import ServerManagerClient from "../services/server-manager.client";

import { bytesToGB, bytesToMB, FleetAgent } from "../types/FleetAgent";

const ListFleetAgents = () => {
  const sm = new ServerManagerClient();
  const interval = React.useRef<any>(null);
  const [agents, setAgents] = React.useState<FleetAgent[]>([]);

  const fetch = async () => {
    await sm.getFleetAgents()
      .then((agents: FleetAgent[]) => {
        setAgents(agents);
        interval.current = setTimeout(() => fetch(), 5000);
      })
      .catch((err: Error) => {
        console.error(err);
        interval.current = setTimeout(() => fetch(), 30000);
      });
  }

  React.useEffect(() => {}, [agents])
  React.useEffect(() => {
    fetch();
    return () => clearTimeout(interval.current);
  }, []);

  return (
    <>
      <table className="table table-dark">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">IP</th>
            <th scope="col">Status</th>
            <th scope="col">Version</th>
            <th scope="col">CPU</th>
            <th scope="col">Memory</th>
            <th scope="col">Traffic</th>
            <th scope="col">Packets</th>
            <th scope="col">Total Traffic</th>
            <th scope="col">Total Packets</th>
            <th scope="col">Capacity</th>
            <th scope="col"># Servers</th>
          </tr>
        </thead>
        <tbody>
        {!agents 
          ? (<p>No Fleet Agents Registered</p>) 
          : agents.map((agent: FleetAgent) => (
            <tr key={agent.ip}>
              <td scope="row">{agent.name}</td>
              <td scope="row">{agent.ip}</td>
              <td scope="row">{agent.status}</td>
              <td scope="row">{agent.version}</td>
              <td scope="row">{Math.floor(agent.cpu.usage)}% ({agent.cpu.numCPUs}/{agent.cpu.threads})</td>
              <td scope="row">{Math.floor(agent.memory.percent)}% {Math.floor(agent.memory.used)}/{Math.floor(agent.memory.total)} GB</td>
              <td scope="row">{bytesToMB(agent.network.bytesRecv).toFixed(1)}MB / {bytesToMB(agent.network.bytesSent).toFixed(1)}MB</td>
              <td scope="row">{agent.network.packetsRecv} / {agent.network.packetsSent}</td>
              <td scope="row">{bytesToGB(agent.network.totalBytesRecv).toFixed(1)}GB / {bytesToGB(agent.network.totalBytesSent).toFixed(1)}GB</td>
              <td scope="row">{(agent.network.totalPacketsRecv / 1000000).toFixed(1)}M / {(agent.network.totalPacketsRecv / 100000).toFixed(1)}M</td>
              <td scope="row">{agent.capacity.used} of {agent.capacity.total}</td>
              <td scope="row">{agent.servers?.length} ({agent.servers?.filter((server) => server.status === "error").length})</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default ListFleetAgents;
