import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { KeyValue } from "../../types/keyValue";
import RaceManagementClient from '../../services/race-manaement.client';

interface S3ObjectSelectionParameters {
  bucket: string;
  prefix: string;
  value: string | undefined;
  filter: string;
  title: string;
  onSelected: Function;
}

const S3ObjectSelection: React.FC<S3ObjectSelectionParameters> = ({ bucket, prefix, value, filter, title, onSelected }) => {
  const [objects, setObjects] = useState<KeyValue[]>([]);
  const [selected, setSelected] = useState<KeyValue | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const rm = useRef<RaceManagementClient>(new RaceManagementClient());

  useEffect(() => {
    setLoading(true);

    if (selected) 
    setSelected({ value: selected.value, key: selected.value });

    rm.current.listS3Bucket(bucket, prefix)
      .then((result) => {
        if (result) {
          result = result.filter(m => m.value.replace(prefix, "").length > 0);
        }
        setObjects(result);

        if (value) {
          const selected = result.find(i => i.value === value);
          if (selected) {
            setSelected(selected);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("error listing bucket content: ", err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (value) {
      const selected = objects.find(i => i.key === value);
      if (selected) {
        setSelected(selected);
      }
    }
  }, [value])

  const handleChange = (e: React.ChangeEvent) => {
    if(e == void(0)) return;

    const selected = objects?.find(i => i.value === (e.target as HTMLInputElement).value);
    if (selected) {
      setSelected(selected);
      onSelected(selected.value);
    } else {
      setSelected(null);
      onSelected("");
    }
  };

  return (
    <Form.Group controlId="s3">
      { (!objects || objects.length === 0) && loading === false 
          ? <Form.Label style={{color: "red"}}>{title} S3 Error: No content</Form.Label>
          : <Form.Label>{title}:</Form.Label>
      }
      <Form.Control className="dark-select" as="select" onChange={handleChange}>
        <option value="">Please Select</option>
        {objects?.map((option) => (
          <option key={option.key} value={option.value} selected={selected?.key === option.key}>{option.value}</option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default S3ObjectSelection;
