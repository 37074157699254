import {GlobalNotification, NotificationSearchParameters, Link} from '../notifications/models/models';
import OAuthClient from './oauth.client';

class NotificationServiceClient {
    url = process.env.REACT_APP_RM_URL;
    oauth: OAuthClient;

    constructor() {
      this.oauth = new OAuthClient();
    }
  

    public async GetAllGlobalNotifications(page: Number): Promise<GlobalNotification[]> {
      return this.oauth.fetch(`${this.url}/notifications/global/all?page=${page}`)
        .then(res => res.json())
    }

    public async GetLatestGlobalNotifications(page: Number): Promise<GlobalNotification[]> {
      return this.oauth.fetch(`${this.url}/notifications/global/latest?page=${page}`)
        .then(res => res.json())
    }

    public getNotificationById(notificationId: string): Promise<GlobalNotification> {
      return this.oauth.fetch(`${this.url}/notifications/global/${notificationId}`)
        .then(res => res.json())
    }

    public async addNewNotification(notification: GlobalNotification): Promise<Notification> {
      return this.oauth.fetch(`${this.url}/notifications/global/create`, 'PUT', JSON.stringify(notification))
        .then(res => res.json())
    }

    public updateNotification(notification: GlobalNotification): Promise<Notification> {
      return this.oauth.fetch(`${this.url}/notifications/global/edit/${notification._id}`, 'POST', JSON.stringify(notification))
        .then(res => res.json())
    }

    public getFilteredNotifications(search: NotificationSearchParameters, page: Number): Promise<Array<GlobalNotification>> {
      return this.oauth.fetch(`${this.url}/notifications/global/search?page=${page}`, 'POST', JSON.stringify(search))
        .then(res => res.json())
    }
}

export default NotificationServiceClient;