import { Col, Row, Button } from 'react-bootstrap';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Season } from "../models/Season";
import withAuthentication from "../../withAuthentication";

export interface SeasonViewItemComponentProps {
    season: Season;

    onEdit: (season: Season) => void;
    onCopy: (season: Season) => void;
    onDelete: (season: Season) => void;
  }
  
  const SeasonViewItemComponent = (props: SeasonViewItemComponentProps) => {

    const formatDate = (date: string) => {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    return (
        <>
            <style>
            {`
                .seasons-item {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .seasons-item-start {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center;
                }

                .seasons-item-end {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end !important;
                    align-items: center;
                }

                .seasons-row {
                    background-color: rgba(0,0,0,0);
                }

                .seasons-row:hover {
                    background-color: rgba(255,255,255,0.15);
                }

                .seasons-row > * > .seasons-btn {
                    visibility: hidden;
                }

                .seasons-row:hover > * > .seasons-btn {
                    visibility: visible;
                }

                .seasons-btn {
                    background-color: rgba(0,0,0,0);
                    color: snow;
                    border: 0;
                }
            `}
            </style>
            <Row className="seasons-row">
                <Col xs={1} className="seasons-item-start">{props?.season.game}</Col>
                <Col xs={5} className="seasons-item">{props?.season.name}</Col>
                <Col xs={2} className="seasons-item">{formatDate(props?.season.dateFrom)}</Col>
                <Col xs={2} className="seasons-item">{formatDate(props?.season.dateTo)}</Col>
                <Col xs={2} className="seasons-item-end">
                    <Button className="seasons-item-end seasons-btn" variant="primary" onClick={() => props?.onEdit(props?.season)}><FontAwesomeIcon icon={faEdit} /></Button>
                    <Button className="seasons-item-end seasons-btn" variant="primary" onClick={() => props?.onCopy(props?.season)}><FontAwesomeIcon icon={faCopy} /></Button>
                    <Button className="seasons-item-end seasons-btn" variant="danger" onClick={() => props?.onDelete(props?.season)}><FontAwesomeIcon color="red" icon={faTrash} /></Button>
                </Col>
            </Row>
        </>
    )
}

export default withAuthentication(SeasonViewItemComponent);
