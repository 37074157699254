import { BrowserRouter as Router } from 'react-router-dom';
import NavigationBar from './NavBar';
import AppRoutes from './AppRoutes';


function App() {
  return (
    <Router>
      <NavigationBar />
      <AppRoutes />
    </Router>
  );
}

export default App;
