import {AmendUserSafetyRatingRequest, GroupedProtests, IProtestSearchParameters, Protest, StrikeRequest, SuspendUserRequest} from '../types/Protest';
import {Driver} from '../types/event/Driver';
import {saveAs} from 'file-saver';
import OAuthClient from './oauth.client';

class ProtestServiceClient {
    url = process.env.REACT_APP_RM_URL;
    oauth: OAuthClient;

    constructor() {
      this.oauth = new OAuthClient();
    }
  
    async getAllProtests(page: Number): Promise<Array<Protest>> {
      return this.oauth.fetch(`${this.url}/protest/all?page=${page}`)
        .then(res => res.json());
    }

    async getCountByFilter(search: IProtestSearchParameters): Promise<number> {
      return this.oauth.fetch(`${this.url}/protest/count`, "POST", JSON.stringify(search))
        .then(res => res.json());
    }
    
    async getGroupedPriorityProtests(search: IProtestSearchParameters, page: Number, take: Number): Promise<Array<GroupedProtests>> {
      return this.oauth.fetch(`${this.url}/protest/groupedPriority?page=${page}&take=${take}`, "POST", JSON.stringify(search))
        .then(res => res.json());
    }

    async getGroupedPriorityProtestsCount(search: IProtestSearchParameters): Promise<number> {
      return this.oauth.fetch(`${this.url}/protest/groupedPriorityCount`, "POST", JSON.stringify(search))
        .then(res => res.json());
    }

    async getFilteredProtests(search: IProtestSearchParameters, page: Number, take: Number): Promise<Array<Protest>> {
      return this.oauth.fetch(`${this.url}/protest/search?page=${page}&take=${take}`, "POST", JSON.stringify(search))
        .then(res => res.json());
    }

    async getProtestById(protestId: string): Promise<Protest> {
      return this.oauth.fetch(`${this.url}/protest/${protestId}`)
        .then(res => res.json());
    }

    async getUserById(userId: string, game: string): Promise<Driver> {
      try {
        const response = await this.oauth.fetch(`${this.url}/protest/user/${userId}/profile?game=${game}`);
        if (response.status === 403) {
          // If the status is 403, try fetching the redacted profile
          const redactedResponse = await this.oauth.fetch(`${this.url}/protest/user/${userId}/profile/redacted?game=${game}`);
          return redactedResponse.json();
        }
        return response.json();
      } catch (error) {
        throw error;
      }
    }

    async getUserByIdRedacted(userId: string, game: string): Promise<Driver> {
      return this.oauth.fetch(`${this.url}/protest/user/${userId}/profile/redacted?game=${game}`)
        .then(res => res.json());
    }

    async updateProtest(protest: Protest, penaltyTarget: string | null | undefined = null): Promise<Protest> {
      const penaltyTargetQueryParameter = penaltyTarget != void(0) && penaltyTarget?.length > 0 ? `?userId=${penaltyTarget}` : '';
      return this.oauth.fetch(`${this.url}/protest/update/${protest._id}${penaltyTargetQueryParameter}`, "POST", JSON.stringify(protest))
        .then(res => res.json());
    }

    async closeAllOpenForProtestee(protest: Protest): Promise<any> {
      return this.oauth.fetch(`${this.url}/protest/closeAllOpenForProtestee`, "POST", JSON.stringify(protest))
        .then(res => res.json());
    }

    async suspendUser(protestId: string, suspension: SuspendUserRequest): Promise<any> {
      return this.oauth.fetch(`${this.url}/protest/${protestId}/suspend`, "POST", JSON.stringify(suspension))
        .then(res => res.json());
    }

    async undoSuspension(protestId: string, suspension: SuspendUserRequest): Promise<any> {
      return this.oauth.fetch(`${this.url}/protest/${protestId}/undosuspension`, "POST", JSON.stringify(suspension))
        .then(res => res.json());
    }

    async undoUserSafetyRatingAdjustment(protestId: string, adjustment: AmendUserSafetyRatingRequest): Promise<any> {
      return this.oauth.fetch(`${this.url}/protest/${protestId}/undoamendsafety`, "POST", JSON.stringify(adjustment))
        .then(res => res.json());
    }

    async amendUserSafetyRating(protestId: string, adjustment: AmendUserSafetyRatingRequest): Promise<any> {
      return this.oauth.fetch(`${this.url}/protest/${protestId}/amendsafety`, "POST", JSON.stringify(adjustment))
        .then(res => res.json());
    }

    async applyStrike(protestId: string, strike: StrikeRequest): Promise<any> {
      this.oauth.fetch(`${this.url}/protest/${protestId}/strike`, "POST", JSON.stringify(strike))
        .then(res => res.json());
    }

    async undoStrike(protestId: string, undostrike: StrikeRequest): Promise<any> {
      return this.oauth.fetch(`${this.url}/protest/${protestId}/undostrike`, "POST", JSON.stringify(undostrike))
        .then(res => res.json());
    }
}

export default ProtestServiceClient;
