import moment from 'moment';

export function dateDiff(from: Date, to: Date): string {
  const duration = moment.duration(moment(to).diff(moment(from)));

  if (duration.asDays() > 1) {
    return `${duration.days()}d ${duration.hours()}h`;
  }

  return `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
}

export function timeUntil(time: string | undefined): string {
    if (!time) return '';

    const now = moment();
    const date = moment(time);
    const duration = moment.duration(date.diff(now));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (hours > 0 && date.isSame(now, 'day')) {
        return date.format('HH:mm');
    } else if ((hours > 0) && date.isSame(now.clone().add(1, 'day'), 'day')) {
        return `TOMORROW AT ${date.format('HH:mm')}`;
    } else if (hours > 0) {
        return 'AT ' + date.format('dddd Do MMMM [at] HH:mm');
    } else if (minutes > 10) {
        return `IN ${minutes}m`;
    } else if (minutes > 0) {
        return `IN ${minutes}m ${seconds}s`;
    } else {
        return `IN ${seconds}s`;
    }
}
