import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';

import { Driver, NameChanges, Strike, Suspension } from '../../../types/event/Driver'
import { SuspendUserRequest } from '../../../types/Protest';

import UserServiceClient from '../../../services/user-service.client';
import moment from 'moment';

export interface DriverViewNameChangesModalProps {
    driver: Driver;
    game: string;
    nameChanges: NameChanges | null;
    show: boolean;
    onCancel: () => void;
}

const DriverViewNameChangesModal = (props: DriverViewNameChangesModalProps) => {

    const userService = new UserServiceClient();

    const getRelatedUsernameViolation = (ref: string) => {
        return props?.driver?.profile?.penaltiesApplied?.usernameViolations?.find((violation) => violation?.reference === ref);
    }

    return (
        <>
            <style>
                {`
                    .name-changes-view-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .name-changes-view-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .name-changes-view-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .name-changes-view-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                        max-height: 50vh;
                        overflow-y: auto;
                    }

                    .name-changes-view-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .name-changes-view-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }
                `}
            </style>
            <Modal className="name-changes-view-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="name-changes-view-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props?.driver?.username}'s Name Changes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="name-changes-view-modal-body">
                    <Container className="name-changes-view-modal-body-container">
                        <Row>
                            <Col>
                                {props?.nameChanges?.history?.sort((a,b) => { return a?.date.localeCompare(b?.date)})?.map((nameChange, index) => {
                                    return (
                                        <Row key={index}>
                                            <Col xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                ({moment(nameChange?.date).format("MM/DD/YYYY HH:mm")})
                                            </Col>
                                            <Col xs={4} style={{ display: "flex", justifyContent: "center" }}>
                                                {nameChange?.oldName} {"->"} {nameChange?.newName}
                                            </Col>
                                            <Col>
                                                <Row>
                                                    {
                                                        nameChange?.reference?.startsWith("Manual:") && getRelatedUsernameViolation(nameChange?.reference) ?
                                                            <Col>
                                                                <strong>Username Violation by:</strong> {nameChange?.reference?.split(":")[1]}
                                                            </Col>
                                                        : nameChange?.reference?.startsWith("Manual:") ?
                                                            <Col>
                                                                <strong>Manually changed by admin:</strong> {nameChange?.reference?.split(":")[1]}
                                                            </Col>
                                                        :
                                                            <Col>
                                                                <strong>Reference:</strong> {nameChange?.reference}
                                                            </Col>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong>Notes:</strong> {nameChange?.notes}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="name-changes-view-modal-footer">
                    <Row>
                        <Button variant="primary" onClick={props?.onCancel}>Close</Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverViewNameChangesModal;