export class Item {
  name: string = "";
  friendly: string = "";
  version: string = "";
  subItems: SubItem[] = [];
}

export class SubItem {
  name: string = "";
  friendly: string = "";
  settings: string = "";
  scn: string = "";
}

export class Content {
  track: Item = new Item();
  cars: Item[] = [];
}