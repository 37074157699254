import React, { useState, } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Driver, validateNewUsername } from '../../../types/event/Driver';

export interface DriverManualNameChangeActionModalProps {
    driver: Driver;
    game: string;
    show: boolean;
    onCancel: () => void;
    onChanged: () => void;
}

const DriverManualNameChangeActionModal = (props: DriverManualNameChangeActionModalProps) => {

    const [notes, setNotes] = useState<string>("");
    const onNotesChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
    }

    const [newUsername, setNewUsername] = useState<string>("");
    const onNewUsernameChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {

        const usernameInput = event?.target?.value?.trim();

        const usernameErr = validateNewUsername(usernameInput);
        if (usernameErr) {
            setError(usernameErr.message);
            return;
        } else {
            setError("");
        }
        
        setNewUsername(usernameInput);
    }

    const [error, setError] = useState<string>("");

    const changeName = () => {
        Driver.prototype.manualNameChange
            .call(props?.driver, newUsername, notes, props?.game)
            .then(Driver.prototype.refresh.bind(props?.driver, props?.game))
            .catch(console.error)
            .finally(props?.onChanged);
    }

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .new-username-form-text-grey {
                        color: grey !important;
                    }

                    .new-username-form-text-red {
                        color: red !important;
                    }

                    .border-red {
                        border: 1px solid red !important;
                    }
                    
                    .border-gray {
                        border: 1px solid gray;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Changing {props.driver?.name}'s Username
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row>
                            <Col>
                                <Form.Label>
                                    Use this form to manually change a players' username, display name and update result files with immediate effect.
                                    <br></br>
                                    There is no restriction on how often you can use this form.
                                    <br></br>
                                    This is intended to be used as a utility for general administration, and therefore doesn't apply punishments or warnings.
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Enter the new username</Form.Label>
                                <Form.Control as="textarea" rows={1} onChange={onNewUsernameChanged} className={error?.length > 0 ? "border-red" : "border-gray"}/>
                                <Form.Text  className={error?.length > 0 ? "new-username-form-text-red" : "new-username-form-text-grey"}>
                                    {error?.length > 0 ? error : `The new username (#0000 suffix is automatically applied if necessary)`}
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={onNotesChanged} />
                                <Form.Text className="strike-form-text-grey">
                                    Notes for future reference (not sent to the player)
                                </Form.Text>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{width: "100%"}}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{display: "flex", justifyContent: "flex-end"}} >
                            {
                                error?.length === 0 && newUsername?.length > 3 &&
                                <Button variant="success" onClick={changeName}>Change</Button>
                            }
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverManualNameChangeActionModal;