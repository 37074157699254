import React from "react";
import withAuthentication from "../../withAuthentication";
import { Col, Row } from "react-bootstrap";
import NakamaClient from "../../services/nakama.client";
import { Chart, registerables  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const NakamaStatsViewComponent = () => {
  Chart.register(...registerables);
  Chart.register(ChartDataLabels);

  const [playerChurnData, setPlayerChurnData] = React.useState<any>(null);
  const [marketableData, setMarketableData] = React.useState<any>(null);
  const [drData, setDrData] = React.useState<any>(null);
  const [srData, setSrData] = React.useState<any>(null);
  

  const nk = new NakamaClient();

  const fetchChurnData = async (game: string) => {
    const churn = await nk.statsQuery(game, "player-churn")
    const chart = {
      labels: ['Total Players', 'Active Last 90days', 'Active Last 30days', 'Active Last 14days', 'Active This Week', 'Active Today', 'Churned 14 days', 'Churned 30 days', 'Churned 60 days', 'Churned 90 days'],
      datasets: [
        {
          label: 'Player Count',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [
            churn[0].total_players,
            churn[0].active_last_90days,
            churn[0].active_last_30days,
            churn[0].active_last_14days,
            churn[0].active_this_week, 
            churn[0].active_today, 
            churn[0].churned_14_days_count, 
            churn[0].churned_30_days_count, 
            churn[0].churned_60_days_count, 
            churn[0].churned_90_days_count
          ]
        }
      ]
    };

    setPlayerChurnData(chart);
  }

  const fetchMarketableData = async (game: string) => {
    const data = await nk.statsQuery(game, "marketable-players")
    const chart = {
      labels: ['Verified Email', 'Not Verified', 'Opted In', 'Opted In & Verified', 'Opted Out'],
      datasets: [
        {
          label: 'Player Count',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [
            data[0].email_verified_users_count, 
            data[0].email_not_verified_users_count, 
            data[0].marketable_users_count, 
            data[0].marketable_email_not_verified_count, 
            data[0].not_marketable_users_count
          ]
        }
      ]
    };

    setMarketableData(chart);
  }

  const fetchPlayerBracketData = async (game: string, query: string) => {
    const brackets = await nk.statsQuery(game, query)
    const chart = {
      labels: ['Bronze 0', 'Bronze 1', 'Bronze 2', 'Bronze 3', 'Silver 1', 'Silver 2', 'Silver 3', 'Gold 1', 'Gold 2', 'Gold 3', 'Platinum 1', 'Platinum 2', 'Platinum 3'],
      datasets: [
        {
          label: 'Player Count',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [
            brackets.find((b: any) => b.rank === "Bronze" && b.tier === "0")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Bronze" && b.tier === "1")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Bronze" && b.tier === "2")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Bronze" && b.tier === "3")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Silver" && b.tier === "1")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Silver" && b.tier === "2")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Silver" && b.tier === "3")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Gold" && b.tier === "1")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Gold" && b.tier === "2")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Gold" && b.tier === "3")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Platinum" && b.tier === "1")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Platinum" && b.tier === "2")?.count ?? 0,
            brackets.find((b: any) => b.rank === "Platinum" && b.tier === "3")?.count ?? 0
          ]
        }
      ]
    };

    if (query === "players-by-dr") {
      setDrData(chart);
    } else {
      setSrData(chart);
    }
  }

  React.useEffect(() => {
    fetchChurnData("lmu");
    fetchMarketableData("lmu");
    fetchPlayerBracketData("lmu", "players-by-dr");
    fetchPlayerBracketData("lmu", "players-by-sr");
  }, []);

  return (
    <>
    <h1>Nakama Stats</h1>
    <br/>
    <div style={{ width: '90%' }}>
      <Row>
        <Col>
          <h3>Active and Churned Players</h3>
          {playerChurnData && (
            <Bar
              data={playerChurnData}
              options={{
                responsive: true,
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Churn',
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Players',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true, // To display the legend
                  }
                },
              }}
            />
          )}
        </Col>

        <Col>
          <h3>Marketable Players</h3>
          {marketableData && (
            <Bar data={marketableData} 
              options={{
                responsive: true,
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Marketing',
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Players',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true, // To display the legend
                  },
                },
              }}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Players in DR Brackets</h3>
          {drData && (
            <Bar
              data={drData}
              options={{
                responsive: true,
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Brackets',
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Players',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true, // To display the legend
                  },
                },
              }}
            />
          )}
        </Col>

        <Col>
          <h3>Players in SR Brackets</h3>
          {srData && (
            <Bar
              data={srData}
              options={{
                responsive: true,
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Brackets',
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Players',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true, // To display the legend
                  },
                },
              }}
            />
          )}
        </Col>
      </Row>
    </div>
    </>
  )
}

export default withAuthentication(NakamaStatsViewComponent);
