import React, { useEffect, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Badge, Button, Dropdown, DropdownButton, Form, ListGroup, Col, Row, ButtonGroup, Modal, Accordion } from 'react-bootstrap';
import NotificationServiceClient from '../services/notification-service.client';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { GlobalNotification, NotificationText, NotificationTranslationMap, Link, enotificationType, userType } from "./models/models";

import ImageSelection from "../rm/configs/ImageSelection";
import moment from "moment";
import { KeyValue } from '../types/keyValue';

interface INotificationViewComponentProps {
  notification: GlobalNotification | null | undefined;
  mode: "create" | "edit";
  onSaved: () => void;
  // onUpdated: (notification: GlobalNotification) => void;
  setShowModal: (show: boolean) => void;
}

const GlobalNotificationViewComponent: React.FC<INotificationViewComponentProps> = (props) => {
  const notificationService = new NotificationServiceClient();

  const [notification, setNotification] = useState<GlobalNotification>(props.notification ?? new GlobalNotification());
  const [notificationLocalisations, setNotificationLocalisations] = useState<NotificationTranslationMap>(props.notification?.text ?? {} as NotificationTranslationMap);
  const [dateFrom, setDateFrom] = useState<string>(props.notification ? moment(props.notification.dateFrom).toISOString() : new Date().toISOString());
  const [dateTo, setDateTo] = useState<string>(props.notification ? moment(props.notification.dateTo).toISOString() : new Date().toISOString());

  React.useEffect(() => {
    console.log(notificationLocalisations); // Logs the updated state
  }, [notificationLocalisations]);

  const addNewLocalisation = (lang: string) => {

    const updatedLocalisation: NotificationTranslationMap = notificationLocalisations ? { ...notificationLocalisations } : {} as NotificationTranslationMap;

    const newLocalisation: NotificationText = {
      title: "",
      text: ""
    }

    updatedLocalisation[lang] = newLocalisation;

    setNotificationLocalisations(updatedLocalisation);
    setNotification(new GlobalNotification({ ...notification, text: updatedLocalisation }));
  };

  const setNotificationLocalisationTitle = (lang: string, title: string) => {
    const updatedLocalisation: NotificationTranslationMap = notificationLocalisations ? { ...notificationLocalisations } : {} as NotificationTranslationMap;
    const localisation = updatedLocalisation[lang]
    if (localisation) {
      localisation.title = title;
      updatedLocalisation[lang] = localisation;
      setNotificationLocalisations(updatedLocalisation);
      setNotification(new GlobalNotification({ ...notification, text: updatedLocalisation }));
    }
  };

  const setNotificationLocalisationText = (lang: string, text: string) => {
    const updatedLocalisation: NotificationTranslationMap = notificationLocalisations ? { ...notificationLocalisations } : {} as NotificationTranslationMap;
    const localisation = updatedLocalisation[lang];
    if (localisation) {
      localisation.text = text;
      updatedLocalisation[lang] = localisation;
      setNotificationLocalisations(updatedLocalisation);
      setNotification(new GlobalNotification({ ...notification, text: updatedLocalisation }));
    }
  };

  const removeLocalisationText = (lang: string) => {
    const updatedLocalisation: NotificationTranslationMap = notificationLocalisations ? { ...notificationLocalisations } : {} as NotificationTranslationMap;
    delete updatedLocalisation[lang];
    setNotificationLocalisations(updatedLocalisation);
    setNotification(new GlobalNotification({ ...notification, text: updatedLocalisation }));
  };

  const saveNotificationText = () => {
    const note: GlobalNotification = new GlobalNotification({
      ...notification,
      dateFrom: moment(dateFrom).toDate(),
      dateTo: moment(dateTo).toDate(),
    });

    if (props.mode === "edit" && notification) {
      notificationService.updateNotification(note)
        .then(() => props.onSaved())
        .catch((err) => console.error(err));

    }
    else if (props.mode === "create" && notification) {
      notificationService.addNewNotification(note)
        .then(() => props.onSaved())
        .catch((err) => console.error(err));
    }
    else console.log("notification is null or unknown props.mode " + props.mode);
  };

  const games: KeyValue[] = [
    { key: "0", value: "all" },
    { key: "1", value: "rf2" },
    { key: "2", value: "lmu" },
  ];

  const notificationTypes: KeyValue[] = [
    { key: "0", value: "Marketing" },
    { key: "1", value: "Race Control" }
  ];

  const userTypes: KeyValue[] = [
    { key: "0", value: "All" },
    { key: "1", value: "Free" },
    { key: "2", value: "Subscribed" },
    { key: "3", value: "Premium" }
  ];

  const [languageFilter, setLanguageFilter] = useState<string>("");
  const languages = [
    { EnglishName: "Arabic", APIlanguageCode: "arabic" },
    { EnglishName: "Bulgarian", APIlanguageCode: "bulgarian" },
    { EnglishName: "Chinese (Simplified)", APIlanguageCode: "schinese" },
    { EnglishName: "Chinese (Traditional)", APIlanguageCode: "tchinese" },
    { EnglishName: "Czech", APIlanguageCode: "czech" },
    { EnglishName: "Danish", APIlanguageCode: "danish" },
    { EnglishName: "Dutch", APIlanguageCode: "dutch" },
    { EnglishName: "English", APIlanguageCode: "english" },
    { EnglishName: "Finnish", APIlanguageCode: "finnish" },
    { EnglishName: "French", APIlanguageCode: "french" },
    { EnglishName: "German", APIlanguageCode: "german" },
    { EnglishName: "Greek", APIlanguageCode: "greek" },
    { EnglishName: "Hungarian", APIlanguageCode: "hungarian" },
    { EnglishName: "Indonesian", APIlanguageCode: "indonesian" },
    { EnglishName: "Italian", APIlanguageCode: "italian" },
    { EnglishName: "Japanese", APIlanguageCode: "japanese" },
    { EnglishName: "Korean", APIlanguageCode: "koreana" },
    { EnglishName: "Norwegian", APIlanguageCode: "norwegian" },
    { EnglishName: "Polish", APIlanguageCode: "polish" },
    { EnglishName: "Portuguese", APIlanguageCode: "portuguese" },
    { EnglishName: "Portuguese-Brazil", APIlanguageCode: "brazilian" },
    { EnglishName: "Romanian", APIlanguageCode: "romanian" },
    { EnglishName: "Russian", APIlanguageCode: "russian" },
    { EnglishName: "Spanish-Spain", APIlanguageCode: "spanish" },
    { EnglishName: "Spanish-Latin America", APIlanguageCode: "latam" },
    { EnglishName: "Swedish", APIlanguageCode: "swedish" },
    { EnglishName: "Thai", APIlanguageCode: "thai" },
    { EnglishName: "Turkish", APIlanguageCode: "turkish" },
    { EnglishName: "Ukrainian", APIlanguageCode: "ukrainian" },
    { EnglishName: "Vietnamese", APIlanguageCode: "vietnamese" },
  ];

  return (
    <>
      <style>{`
          .notification-view-container {
              background-color: #222222;
              color: snow;
              height: 100% !important;
              padding: 2.5% !important;
          }

          .notification-view-container * {
              color: snow !important;
          }

          .notification-view-container option {
            background-color: #222222;
          }
             
          .notification-view-text-input:focus {
            background-color: #444444 !important;
            color: snow !important;
            border: 1px solid #333333 !important;
            
          .custom-list-item {
            background-color: #444444;
            /* Additional styling properties for the list item */
          }

          .custom-text-input {
            background-color: #333;
            color: snow !important;
            width: 100%;
          }
          
          .text-muted {
            background-color: #222222;
            color: snow !important;
            height: 100% !important;
          }
          
          .text-white {
            background-color: #222222;
            color: snow !important;
          }
        `}
      </style>

      <Modal.Header style={{ backgroundColor: "#222222", color: "snow" }}>
        <Modal.Title style={{ color: 'white' }}>
          <Col sm="12" md="12" lg="12" xl="12" xxl="12">
            <Row>Title: {new GlobalNotification(notification)?.getEnglshTitle()}</Row>
            <Row>ID: {notification?._id}</Row>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#222222" }}>
        <div className="row notification-view-container">
          <div>

            <Row>
              <Form.Group className="custom-text-input white-label" controlId="notificationText">
                <Col>
                  <Form.Text className="custom-text-input white-label" style={{ color: 'white' }}>
                    Notification ID: {props.notification?._id}
                  </Form.Text>
                </Col>
                <Row>
                  <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                    <Form.Text className="custom-text-input white-label">
                    <Col sm="12" md="12" lg="12" xl="12" xxl="12" style={{ width: "fit-content", display: "flex", alignItems: "center" }}>
                        <Form.Text className="custom-text-input white-label" style={{ color: 'white' }}>Game:</Form.Text>
                        <Form.Control as="select" value={notification.game} onChange={(e) => setNotification(new GlobalNotification({ ...notification, game: e.target.value }))}>
                          <option value="" key={"defaultGame"}>Please Select</option>
                          {games.map((item) => (
                            <option key={item.key} value={item.value}>{item.value}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Text>
                  </Col>
                  <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                    <Form.Text className="custom-text-input white-label">
                      <Col sm="12" md="12" lg="12" xl="12" xxl="12" style={{ width: "fit-content", display: "flex", alignItems: "center" }}>
                        <Form.Text className="custom-text-input white-label" style={{ color: 'white' }}>Notification Type:</Form.Text>
                        <Form.Control as="select" value={notification.enotificationType} onChange={(e) => setNotification(new GlobalNotification({ ...notification, enotificationType: parseInt(e.target.value) }))}>
                          <option value="" key={"defaultNotificationType"}>Please Select</option>
                          {notificationTypes.map((item) => (
                            <option key={item.key} value={item.key}>{item.value}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Text>
                  </Col>
                  <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                    <Form.Text className="custom-text-input white-label">
                    <Col sm="12" md="12" lg="12" xl="12" xxl="12" style={{ width: "fit-content", display: "flex", alignItems: "center" }}>
                        <Form.Text className="custom-text-input white-label" style={{ color: 'white' }}>User Type:</Form.Text>
                        <Form.Control as="select" value={notification.userType} onChange={(e) => setNotification(new GlobalNotification({ ...notification, userType: parseInt(e.target.value) }))}>
                          <option value="" key={"defaultUserType"}>Please Select</option>
                          {userTypes.map((item) => (
                            <option key={item.key} value={item.key}>{item.value}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Text>
                  </Col>
                </Row>


                <hr className="my-12" />
                <ImageSelection image={notification?.image}
                  filter="notifications/"
                  title="Notification image"
                  onImageSelected={(image) => setNotification(new GlobalNotification())/*{...notification, image: image })*/} />

                <hr className="my-12" />
                <Row>
                  <Col sm={6} className="mt-3 pl-4">
                    <Form.Label>Date From:</Form.Label>
                    <Form.Control as="input" name="notificationDateFrom" value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                      className="notification-view-text-input" />
                  </Col>

                  <Col sm={6} className="pr-4">
                    <Form.Label className="mt-3">Date To:</Form.Label>
                    <Form.Control as="input" name="notificationDateTo" value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                      className="notification-view-text-input" />

                  </Col>
                </Row>
                <hr className="my-12" />
                <Accordion defaultActiveKey="0">
                  {
                    notificationLocalisations && Object.entries(notificationLocalisations).map(([k, v], i) => {
                      return (
                        <Accordion.Item eventKey={i.toString()} key={i}>
                          <Accordion.Header>Language: {k}</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="mt-3">
                              <Form.Label>Title</Form.Label>
                              <Form.Control className="notification-view-text-input"
                                as="textarea"
                                type="text"
                                value={notificationLocalisations[k].title}
                                maxLength={126}
                                onChange={(event) => setNotificationLocalisationTitle(k, event.target.value)}
                              />
                            </Form.Group>
                            <Form.Group className="mt-3">
                              <Form.Label>Text</Form.Label>
                              <Form.Control className="notification-view-text-input"
                                as="textarea"
                                type="text"
                                value={notificationLocalisations[k].text}
                                maxLength={50000}
                                onChange={(event) => setNotificationLocalisationText(k, event.target.value)}
                              />
                            </Form.Group>
                            <Button variant="danger" className="mt-2" size="sm"
                              onClick={removeLocalisationText.bind(this, k)}>Remove
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                  }
                </Accordion>
                <Row style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>

                  <Col sm="2" md="2" lg="2" xl="2" xxl="2">
                    <DropdownButton
                      data-bs-theme="dark"
                      as={ButtonGroup}
                      id="account-links-dropdown-1"
                      title={"Add Localisation"}
                      variant={"outline-success"}
                      className="languages-dropdown">

                      <Form.Control
                        className="notification-view-text-input"
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => { setLanguageFilter(e.target.value) }}
                      />
                      <Dropdown.Header>Lanuages:</Dropdown.Header>
                      {
                        notificationLocalisations != void (0) &&
                        languages?.filter(lang =>
                          lang.EnglishName.toLowerCase().startsWith(languageFilter)
                        )?.map((lang, i) => {
                          return (
                            <Dropdown.Item key={i} onClick={() => addNewLocalisation(lang.APIlanguageCode)} hidden={
                              notificationLocalisations[lang.APIlanguageCode] != void (0)
                            }>
                              {lang.EnglishName}
                            </Dropdown.Item>
                          );
                        })
                      }

                    </DropdownButton>
                  </Col>

                </Row>
              </Form.Group>
            </Row>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ backgroundColor: "#222222", borderColor: "#333333" }}>
        <Button
          variant="danger"
          className="mt-2 me-auto"
          size="sm"
          onClick={() => props?.setShowModal(false)}
        >
          Exit
        </Button>
        <Button
          variant="primary"
          className="mt-2 ms-auto"
          size="sm"
          onClick={saveNotificationText}
        >
          Save & Exit
        </Button>
      </Modal.Footer>
    </>
  );
};

export default withAuthentication(GlobalNotificationViewComponent);