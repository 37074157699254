import React from "react";
import withAuthentication from "../../withAuthentication";
import RaceManagementClient from "../../services/race-manaement.client";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { Stat, Stats } from "../../types/Stats";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from "chart.js";

const StatsView = () => {
  Chart.register(...registerables);

  const [lapsLast30Days, setLapsLast30Days] = React.useState<any>(undefined);
  const [eventsLast30Days, setEventsLast30Days] = React.useState<any>(undefined);
  const [driversLast30Days, setDriversLast30Days] = React.useState<any>(undefined);

  const rm = React.useRef<RaceManagementClient>(new RaceManagementClient());
  
  const fetch = async () => {
    rm.current.getStats("lmu-laps-by-day")
      .then((stats: Stat) => {
        if (!stats.data || stats.data.length === 0) {
          return;
        }

        const data = stats.data.map((change) => ({
          x: moment(change._id).toDate(),
          y: change.totalLaps,
        }));

        const chartData = {
          datasets: [
            {
              label: "Laps Run by Day",
              data: data,
              borderColor: "blue",
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              fill: false,
            },
          ],
        };

        setLapsLast30Days(chartData);
      })
      .catch((err: Error) => {
        console.error(err);
      });

    rm.current.getStats("lmu-events-by-day")
      .then((stats: Stat) => {
        if (!stats.data || stats.data.length === 0) {
          return;
        }

        const data = stats.data.map((change) => ({
          x: moment(change._id).toDate(),
          y: change.count,
        }));

        const chartData = {
          datasets: [
            {
              label: "Event by Day",
              data: data,
              borderColor: "blue",
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              fill: false,
            },
          ],
        };

        setEventsLast30Days(chartData);
      })
      .catch((err: Error) => {
        console.error(err);
      });

    rm.current.getStats("lmu-drivers-by-day")
      .then((stats: Stat) => {
        if (!stats.data || stats.data.length === 0) {
          return;
        }

        const data = stats.data.map((change) => ({
          x: moment(change._id).toDate(),
          y: change.uniqueDrivers,
        }));

        const chartData = {
          datasets: [
            {
              label: "Laps Run by Day",
              data: data,
              borderColor: "blue",
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              fill: false,
            },
          ],
        };

        setDriversLast30Days(chartData);
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }

  React.useEffect(() => { 
    fetch();
  }, []);

  React.useEffect(() => {
  }, [lapsLast30Days, eventsLast30Days, driversLast30Days]);

  return (
    <div style={{ width: '90%' }}>
      <h1>Overall Stats</h1>
      <Row>
        <Col style={{height: "400px", width: "100%"}}>
          <h3>Laps Last 30days</h3>
          {lapsLast30Days && (
            <Line
              data={lapsLast30Days}
              options={{
                responsive: true,
                scales: {
                  x: {
                    type: "time",
                    time: {
                      parser: "YYYY-MM-DD", // Change the parser format here
                      tooltipFormat: "LLL",
                      unit: "month",
                    },
                    title: {
                      display: true,
                      text: 'Date',
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Laps',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true, // To display the legend
                  },
                },
              }}
            />
          )}
        </Col>

        <Col style={{height: "400px", width: "100%"}}>
          <h3>Events Last 30days</h3>
          {eventsLast30Days && (
            <Line
              data={eventsLast30Days}
              options={{
                responsive: true,
                scales: {
                  x: {
                    type: "time",
                    time: {
                      parser: "YYYY-MM-DD", // Change the parser format here
                      tooltipFormat: "LLL",
                      unit: "month",
                    },
                    title: {
                      display: true,
                      text: 'Date',
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Total Events',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true, // To display the legend
                  },
                },
              }}
            />
          )}
        </Col>

        <Col style={{height: "400px", width: "100%"}}>
          <h3>Drivers Last 30days</h3>
          {driversLast30Days && (
            <Line
              data={driversLast30Days}
              options={{
                responsive: true,
                scales: {
                  x: {
                    type: "time",
                    time: {
                      parser: "YYYY-MM-DD", // Change the parser format here
                      tooltipFormat: "LLL",
                      unit: "month",
                    },
                    title: {
                      display: true,
                      text: 'Date',
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Total Drivers',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true, // To display the legend
                  },
                },
              }}
            />
          )}
        </Col>
      </Row>      
    </div>
  )
}

export default withAuthentication(StatsView);
