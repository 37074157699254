import React from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { Activation } from '../../types/Activations';
import withAuthentication from '../../withAuthentication';
import { VscOrganization } from "react-icons/vsc";
import { FcExpired } from "react-icons/fc";
import { FaUser, FaUserShield, FaUserSlash } from "react-icons/fa";
import ActivationsViewModal from './UpdateActivationsViewModal';
import moment from 'moment';
import UpdateActivationsViewModal from './UpdateActivationsViewModal';

interface IPermissionsUserListViewItemProps {
    activation: Activation;
    requestRefresh?: (indices: Array<number>) => void;
}

const ActivationsListViewItem: React.FC<IPermissionsUserListViewItemProps> = (props) => {

    const [showModal, setShowModal] = React.useState(false);

    return (
        <>
            <style>
                {`
                .user-list-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .user-list-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid white
                }

                .user-list-item-row {
                    width: 100%;
                    height: 100%;
                }

                .user-list-item-icon-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .user-list-item-info-col {
                }

                .user-list-item-name-col {
                    font-weight: bold;
                    font-size: 1.0em;
                }

                .user-list-item-email-col {
                    font-weight: light;
                    font-size: 0.8em;
                    color: rgba(255, 255, 255, 0.5);
                }
            `}
            </style>
            <ListGroup.Item key={props?.activation?.id}
                            as="li"
                            className="d-flex justify-content-between align-items-start user-list-item"
                            onClick={(e: React.MouseEvent<Element, MouseEvent>) => setShowModal(true)}>
                <Row className="user-list-item-row">
                    <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="user-list-item-icon-col">
                        <Row className="user-list-item-icon-col">
                            <VscOrganization  size="1.5em"/>
                        </Row>
                    </Col>
                    <Col sm="8" md="8" lg="8" xl="8" xxl="8" className="user-list-item-info-col">
                        <Row>
                            <Col sm="12" md="12" lg="12" xl="12" xxl="12">
                                <Row>
                                    <Col sm="6" md="6" lg="6" xl="6" xxl="6" className="user-list-item-name-col">
                                        {props?.activation?.partner}
                                    </Col>
                                    <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                                        {
                                            moment(props?.activation?.expires).isBefore(moment()) ?
                                                <FcExpired size="1.5em" color='red'/>
                                            :
                                                <></>
                                        }
                                        {moment(props?.activation?.expires).format("YYYY-MM-DD HH:mm:ss")}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ListGroup.Item>
            <UpdateActivationsViewModal
                show={showModal}
                setShowModal={setShowModal}
                activation={props?.activation}
                requestRefresh={props?.requestRefresh}
            />
        </>
    );
};

export default withAuthentication(ActivationsListViewItem);