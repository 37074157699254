import { useLocation, Routes, Route } from 'react-router-dom';
import Home from './home/Home';
import ProtestListView from './protest/ProtestListView';
import ProtestView from './protest/ProtestView';
import ListDailyConfigurations from './rm/configs/dailies/ListDailyConfigurations';
import CreateDailyConfigurations from './rm/configs/dailies/CreateDailyConfigurations';
import GlobalNotificationListView from './notifications/GlobalNotificationListView';
import Health from './home/Health';
import ContentForm from './cm/ContentForm';
import ListSpecialEventsConfigurations from './rm/configs/specialEvents/ListSpecialEventsConfigurations';
import SpecialEventConfiguration from './rm/configs/specialEvents/SpecialEventConfiguration';
import ViewRaceResult from './rm/results/ViewRaceResult';
import EventList from './home/EventList';
import EventView from './home/EventView';
import PlayerList from './players/list';
import PlayerView from './players/view';
import PermissionsView from './security/permissions/PermissionsView';
import RoutesView from './security/routes/RoutesView';
import RolesView from './security/roles/RolesView';
import Stats from './stats/overall/StatsView';
import StatsFatestLapsView from './stats/fastest-laps/view';
import NakamaStatsView from './stats/nakama/view';
import React from 'react';
import LaunchServer from './home/LaunchServer';
import UsernameReservationView from './usernameReservation/UsernameReservationView';
import SeasonsView from './championships/configuration/SeasonsView';
import CreateSeasonView from './championships/configuration/CreateSeasonView';
import EditSeasonView from './championships/configuration/EditSeasonView';
import CopySeasonView from './championships/configuration/CopySeasonView';
import ActivationsView from './security/activations/ActivationsView';
import SteamLoginCallback from './steamLoginCallback';
import { RouteContext } from './RouteContext';
import ListLeaderboards from './rm/leaderboards/list';
import ViewLeaderboardEntries from './rm/leaderboards/view';
import CoopEventList from './rm/coop/events';
import CoopEventEdit from './rm/coop/event';
import ViewCoopSession from './rm/coop/session';
import ActiveChampionshipView from './home/ActiveChampionshipView';

function AppRoutes() {
  const location = useLocation();

  const routes = new Map<string, React.ElementType>([
    ["/", Home],
    ["/events", EventList],
    ["/event/:eventType/:eventId", EventView],
    ["/championship/:id", ActiveChampionshipView],
    ["/health", Health],
    ["/players/list", PlayerList],
    ["/players/view/:playerId/:game", PlayerView],
    ["/protest/:protestId", ProtestView],
    ["/protest/list/:page?/:search?", ProtestListView],
    ["/usernameReservations/list", UsernameReservationView],
    ["/cm/list", ContentForm],
    ["/notifications/global/list", GlobalNotificationListView],
    ["/rm/configs/dailies/list", ListDailyConfigurations],
    ["/rm/configs/dailies/create", CreateDailyConfigurations],
    ["/rm/configs/dailies/edit", CreateDailyConfigurations],
    ["/rm/configs/specialEvents/list", ListSpecialEventsConfigurations],
    ["/rm/configs/specialEvents/create", SpecialEventConfiguration],
    ["/rm/configs/specialEvents/edit", SpecialEventConfiguration],
    ["/rm/configs/seasons/list", SeasonsView],
    ["/rm/configs/seasons/create", CreateSeasonView],
    ["/rm/configs/seasons/edit/:seasonId", EditSeasonView],
    ["/rm/configs/seasons/copy/:seasonId", CopySeasonView],
    ["/sm/launchserver", LaunchServer],
    ["/rm/results/view", ViewRaceResult],
    ["/rm/leaderboards", ListLeaderboards],
    ["/rm/leaderboard/:name", ViewLeaderboardEntries],
    ["/rm/coop/events", CoopEventList],
    ["/rm/coop/event/:id?", CoopEventEdit],
    ["/rm/coop/session/:id?", ViewCoopSession],
    ["/permissions", PermissionsView],
    ["/roles", RolesView],
    ["/routes", RoutesView],
    ["/activations", ActivationsView],
    ["/stats/overall", Stats],
    ["/stats/nakama", NakamaStatsView],
    ["/stats/fastest-laps", StatsFatestLapsView],
    ["/steamLogin", SteamLoginCallback],
  ]);

  React.useEffect(() => {

    const handleEvent = () => {
      const routeKeys = Array.from(routes.keys());
      const event = new CustomEvent('nav-routes-event', { detail: { routeKeys } });

      window.dispatchEvent(event);
    };

    window.addEventListener('nav-routes-request', handleEvent);

    return () => {
      window.removeEventListener('nav-routes-request', handleEvent);
    };
  }, [routes]);

  const getActiveRouteClass = (path: string) => {
    if (location.pathname.startsWith('/cm/list')) {
      return 'content-management';
    }

    if (location.pathname.startsWith('/health')) {
      return 'health-route';
    }
  };

  return (
    <div className={`bg-dark page-content text-light ${getActiveRouteClass(location.pathname)}`}>
      <Routes>
        {Array.from(routes).map(([path, Component]) => (
          <Route
            key={path}
            path={path}
            element={<RouteContext.Provider value={path}><Component /></RouteContext.Provider>}
          />
        ))}
        <Route path="*" element={
          <div className="text-center mt-5">
            <h1>404 Not Found</h1>
            <p>The page you're looking for doesn't exist</p>
          </div>
        } />
      </Routes>
    </div>
  );
}

export default AppRoutes;