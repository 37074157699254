import React, { useState, useEffect } from 'react';
import { KeyValue } from "../../types/keyValue";
import Select from "react-select";
import RaceManagementClient from '../../services/race-manaement.client';
import { createPortal } from 'react-dom';

import '../../css/dark-mode.css';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#fff'
  }),
  singleValue: (provided: any) => {
    const opacity = 0.5;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition, color: 'black' };
  },
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    const color = 'black';
    return {
      ...styles, color, backgroundColor: isSelected ? '#f0f0f0' : isFocused ? '#e0e0e0' : null,
    };
  },
};


interface ImageSelectProps {
  image: string | undefined;
  filter: string;
  title: string;
  onImageSelected: (config: string) => void;
}

const ImageSelection: React.FC<ImageSelectProps> = ({ image, filter, title, onImageSelected }) => {
  const [cardImages, setCardImages] = useState<KeyValue[]>([]);
  const [selectedCardImage, setSelectedCardImage] = useState<KeyValue | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const aws = new RaceManagementClient();


  const portalId = `image-select-portal-${Math.random().toString(36).substring(7)}`;
  const selectedImageOption = selectedCardImage ? { value: selectedCardImage, label: selectedCardImage.value } : null;
  const imageOptions = cardImages.map(image => ({
    value: image,
    label: image.value
  }));

  useEffect(() => {
    setLoading(true);
    aws.listImagesInS3Bucket(process.env.REACT_APP_S3_IMAGES_BUCKET ?? "rf2-ui-images", filter)
      .then((result) => {

        const resultFilteredSorted = result?.filter((item) => item?.value !== filter).sort((a, b) => a?.value?.localeCompare(b?.value));

        setCardImages(resultFilteredSorted);
        if (image) {
          const selected = result?.find(i => i?.key === image);
          if (selected) {
            setSelectedCardImage(selected);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error listing bucket content: ", err);
      });
  }, []);

  useEffect(() => {
    if (image) {
      const selected = cardImages.find(i => i.key === image);
      if (selected) {
        setSelectedCardImage(selected);
      }
    }
  }, [image]);


  return (
    <>
      {/* The Select component can get stuck under other components when it pops out so this portal is necessary... */}
      {createPortal(<div id={portalId} className="image-select-portal" />, document.body)}
      <label htmlFor="title">{title}: {loading ? "loading..." : ""}</label>
      <Select className="dark-select"
        isMulti={false}
        options={imageOptions}
        onChange={(selected) => {
          if (selected) {
            setSelectedCardImage(selected.value);
            onImageSelected(selected.value.key);
          }
        }}
        value={selectedImageOption}
        styles={customStyles}
        menuPortalTarget={document.getElementById(portalId) as HTMLElement}
        menuPosition="fixed"
      />
    </>
  )
}

export default ImageSelection;
