import React, { useEffect, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Badge, Button, Col, ListGroup, Modal } from 'react-bootstrap';
import { AmendUserSafetyRatingRequest, IProtestSearchParameters, Protest, ProtestOutcome, ProtestStatus, ProtestType, SuspendUserRequest, StrikeRequest } from '../types/Protest';
import {Driver, PenaltiesApplied, Suspension, SafetyRankAdjustment, Strike} from '../types/event/Driver'
import { useNavigate } from "react-router-dom";
import { BsFillChatLeftTextFill, BsFillCarFrontFill, BsFillExclamationTriangleFill } from "react-icons/bs";

interface IProtestListViewItemProps {
    protest: Protest;
    onClick: (id: string, e: React.MouseEvent<Element, MouseEvent>) => void;
}

const ProtestListViewItem: React.FC<IProtestListViewItemProps> = (props) => {
    const navigate = useNavigate();

    const protestReasonStringMinify = (fullReason: string) => {
        
        let resultString = "";

        if (fullReason.length > 100) {
            resultString = fullReason.substring(0, 100) + "...";
        } else {
            resultString = fullReason;
        }

        return resultString;

    }

    const outcomeBadgeColor = (outcome: number) => {
        let result = "secondary";
        switch(outcome) {
            case ProtestOutcome.Accepted:
                result = "success";
                break;
            case ProtestOutcome.Rejected:
                result = "danger";
                break;
            case ProtestOutcome.Dismissed:
                result = "secondary";
                break;
            default:
                result = "secondary";
                break;
        }

        return result;
    }
    
    const outcomeString = (outcome: number) => {
        let result = "Unknown";
        switch(outcome) {
            case ProtestOutcome.Accepted:
                result = "Accepted";
                break;
            case ProtestOutcome.Rejected:
                result = "Rejected";
                break;
            case ProtestOutcome.Dismissed:
                result = "Dismissed";
                break;
            default:
                result = "Unknown";
                break;
        }

        return result;
    }

    const viewProtest = (id: string) => {
        navigate(`/protest/${id}`);
    }

    const formatDateString = (timestamp: number | undefined) => {
      let result = "";
      if (timestamp != void(0)) {
        const date = new Date(timestamp * 1000);
        const dateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        result = date.toLocaleDateString("en-GB", dateOptions);
      }
  
      return result;
    }

  return (
    <>
        <style>
            {`
                .protest-list-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .protest-list-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid white
                }

                .protest-badge-right {
                    display: flex;
                    justify-content: flex-end;
                    padding: 2px;
                    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                }
            `}
        </style>
        <ListGroup.Item key={props.protest._id}
                        as="li"
                        className="d-flex justify-content-between align-items-start protest-list-item"
                        onClick={(e: React.MouseEvent<Element, MouseEvent>) => props.onClick(props.protest._id, e)}
                        onMouseDown={(e: React.MouseEvent<Element, MouseEvent>) => props.onClick(props.protest._id, e)}>
                         
            <div className="row ms-1" style={{height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                <Col sm="1" md="1" lg="1" xl="1" xxl="1" style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
                {
                    props?.protest?.game?.toUpperCase()    
                }
                </Col>
            </div>
            <div className="row ms-1" style={{height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                {
                    props.protest.protestType === ProtestType.Report ?
                        <BsFillChatLeftTextFill size="1.5em" color="orangered"/>
                    :
                    <div style={{display: "grid", position: "relative"}}>
                        <div style={{gridColumn: 1, gridRow: 1}}>
                            <BsFillCarFrontFill size="1.5em" color="lightgrey"/>
                        </div>
                        <div style={{gridColumn: 1, gridRow: 1, position: "absolute", left: "-10%", bottom: "-10%"}}>
                            <BsFillExclamationTriangleFill size="1em" color="red"/>
                        </div>
                    </div>
                }
            </div>
            <div className="row ms-2 me-auto">
                <div className="row ms-2 me-auto">
                    <div className="col-6 fw-bold" style={{width: "fit-content"}}>{props.protest.protesteeUsername}</div>
                    <div className="col-6 fw-lighter fst-italic" style={{width: "fit-content"}}>{formatDateString(props.protest.timestamp)}</div>
                </div>
                <div className="row ms-2 me-auto">
                    <div className="col-12 me-auto">
                        {protestReasonStringMinify(props.protest.protestReason)}
                    </div>
                </div>
            </div>
            <div className="row ms-2 ms-auto">
                <div className="protest-badge-right">
                    <Badge bg={props.protest.status === ProtestStatus.Open ? "success" : props.protest.status === ProtestStatus.Closed ?  "danger" : "secondary"} pill>
                        {props.protest.status === ProtestStatus.Open ? "Open" : props.protest.status === ProtestStatus.Closed ? "Closed" : "Unknown"}
                    </Badge>
                </div>
                <div className="protest-badge-right">
                    <Badge bg={outcomeBadgeColor(props.protest.outcome)} pill>
                        {outcomeString(props.protest.outcome)}
                    </Badge>
                </div>
            </div>

        </ListGroup.Item>
    </>
  );
};

export default (ProtestListViewItem);
