import React, { useState, } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import UserServiceClient from '../../../services/user-service.client';
import { Driver } from '../../../types/event/Driver';
import moment from 'moment';

export interface DriverManualNameViolationActionModalProps {
    driver: Driver;
    game: string;
    show: boolean;
    onCancel: () => void;
    onApplied: () => void;
}

const DriverManualNameViolationActionModal = (props: DriverManualNameViolationActionModalProps) => {

    const userService = new UserServiceClient();

    const [resetUsername, setResetUsername] = useState<boolean>(true);
    const [suspend, setSuspend] = useState<boolean>(true);
    const [suspensionDelay, setSuspensionDelay] = useState<number>(10);
    const [error, setError] = useState<string>("");
    const [notes, setNotes] = useState<string>("");
    const onNotesChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
    }

    const validateAndSetSuspensionDelay = (e: any) => {
        const value = parseInt(e?.target?.value);
        if(value < 0 || value > 30) {
            setError("Suspension delay must be between 0 and 30 days");
            return;
        } else if(!e?.target?.validity?.valid) {
            setError(e?.target?.validationMessage);
            return;
        } else {
            setError("");
            setSuspensionDelay(value);
        }   
    }

    const applyViolation = () => {
        userService.usernameViolation(props?.driver?.id, resetUsername, suspend, suspensionDelay, notes, props?.game)
            .then(props?.onApplied)
            .catch(console.error);
    }

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .new-username-form-text-grey {
                        color: grey !important;
                    }

                    .new-username-form-text-red {
                        color: red !important;
                    }

                    .border-red {
                        border: 1px solid red !important;
                    }
                    
                    .border-gray {
                        border: 1px solid gray;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Apply username violation to {props.driver?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label> Issue a username change warning to the user. A penalty will be applied and a notification email will be sent. </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label> The email will remind the user of our guidelines and the need to update their username. </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label> Optionally, reset the username to "Driver Unknown" or suspend the account if the warning is ignored. </Form.Label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    label="Reset username to Driver Unknown"
                                    defaultChecked={resetUsername}
                                    onChange={(e) => setResetUsername(e.target.checked)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    label="Suspend user if warning ignored"
                                    defaultChecked={suspend}
                                    onChange={(e) => setSuspend(e.target.checked)}
                                />
                            </Col>
                        </Row>
                        {
                            suspend &&
                            <Row>
                                <Col xs={6}>
                                    <Form.Label>Warning Period (in days)</Form.Label>
                                    <Form.Control defaultValue={suspensionDelay} type="number" onChange={(e) => validateAndSetSuspensionDelay(e)} />
                                    <Form.Text  className={error?.length > 0 ? "new-username-form-text-red" : "new-username-form-text-grey"}>
                                        {error?.length > 0 ? error : `Warning period before suspension`}
                                    </Form.Text>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label>Suspension date</Form.Label>
                                    <Form.Control readOnly value={moment().add(suspensionDelay, 'days').format("DD MMMM YYYY HH:mm")} />
                                    <Form.Text className="new-username-form-text-grey">
                                        Date which the suspension is applied if they fail to change their name
                                    </Form.Text>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={onNotesChanged} />
                                <Form.Text className="strike-form-text-grey">
                                    Notes for future reference (not sent to the player)
                                </Form.Text>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{ width: "100%" }}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{ display: "flex", justifyContent: "flex-end" }}>
                            {
                                error?.length === 0 &&
                                <Button variant="primary" onClick={applyViolation}>Apply</Button>
                            }
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverManualNameViolationActionModal;