import { Activation, ActivationAddSeriesRequest, ActivationCreateRequest, ActivationDeleteRequest, ActivationRemoveSeriesRequest, ActivationUpdateExpirationRequest, ActivationUpdateTokenRequest } from '../types/Activations';
import OAuthClient from './oauth.client';

class ActivationsServiceClient {
    url = process.env.REACT_APP_RM_URL;
    oauth: OAuthClient;

    constructor() {
      this.oauth = new OAuthClient();
    }
  
    async get(page: Number, take: Number): Promise<Array<Activation>> {
      return this.oauth.fetch(`${this.url}/activations?page=${page}&take=${take}`)
        .then(res => res.json());
    }

    async getById(id: string): Promise<Activation> {
      return this.oauth.fetch(`${this.url}/activations/${id}`)
        .then(res => res.json());
    }

    async create(activation: ActivationCreateRequest): Promise<Response> {
      return this.oauth.fetch(`${this.url}/activations`, "PUT", JSON.stringify(activation))
        .then(res => res.json())
        .then((r) => {
          if(r.status === "OK") {
            return r;
          } else {
            throw new Error(`Failed: ${r.message}: ${r.errors.join(", ")}`);
          }
        });
    }

    async addSeries(activation: ActivationAddSeriesRequest): Promise<void> {
      return this.oauth.fetch(`${this.url}/activations/addSeries`, "POST", JSON.stringify(activation))
      .then(res => res.json())
      .then((r) => {
        if(r.status === "OK") {
          return r;
        } else {
          throw new Error(`Failed: ${r.message}: ${r.errors.join(", ")}`);
        }
      });
    }

    async removeSeries(activation: ActivationRemoveSeriesRequest): Promise<void> {
      return this.oauth.fetch(`${this.url}/activations/removeSeries`, "POST", JSON.stringify(activation))
        .then(res => res.json());
    }

    async updateToken(activation: ActivationUpdateTokenRequest): Promise<void> {
      return this.oauth.fetch(`${this.url}/activations/updateToken`, "POST", JSON.stringify(activation))
        .then(res => res.json());
    }

    async updateExpiration(activation: ActivationUpdateExpirationRequest): Promise<void> {
      return this.oauth.fetch(`${this.url}/activations/updateExpiration`, "POST", JSON.stringify(activation))
        .then(res => res.json());
    }

    async delete(activation: ActivationDeleteRequest): Promise<void> {
      return this.oauth.fetch(`${this.url}/activations`, "DELETE", JSON.stringify(activation))
        .then(res => res.json());
    }
}

export default ActivationsServiceClient;
