import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { UsernameReservation } from '../types/User';
import UsernameReservationServiceClient from '../services/username-reservation-service.client';

export interface NewReservationModalProps {
    usernameReservations: Array<UsernameReservation>;
    show: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const NewReservationModal = (props: NewReservationModalProps) => {

    const usernameReservationsClient = new UsernameReservationServiceClient();
    const [username, setUsername] = useState<string>("");

    const [usernameErrorMessage, setUsernameErrorMessage] = useState<string>("");
    const [usernameError, setUsernameError] = useState<boolean>(false);

    const onUsernameChanged = (newusername: string) => {
        validateusername(newusername);
        setUsername(newusername);
    }

    
    const validateusername = (newUsername: string) => {
        if (newUsername?.length <= 0) {
            setUsernameErrorMessage("Invalid username");
            setUsernameError(true);
        } else if (props?.usernameReservations?.find((reservation) => reservation?.username === newUsername)) {
            setUsernameErrorMessage("Username already reserved");
            setUsernameError(true);
        } else {
            setUsernameErrorMessage("");
            setUsernameError(false);
        }
    }

    const addReservation = () => {
        if (username?.trim() === "") {
            return;
        }

        usernameReservationsClient.addUsernameReservation(username, "").then(() => {
            props?.onConfirm();
        }).catch((error) => {
            console.error(error);
        });
    }


    return (
        <>
            <style>
                {`
                    .add-username-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .add-username-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .add-username-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .add-username-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .add-username-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .add-username-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-form-text-grey {
                        color: grey !important;
                    }

                    .border-red {
                        border: 1px solid red !important;
                    }
                    
                    .border-gray {
                        border: 1px solid gray;
                    }
                `}
            </style>
            <Modal className="add-username-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="add-username-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Username Reservation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-username-modal-body">
                    <Container className="add-username-modal-body-container">
                        <Row>
                            <Col>
                                <Form.Label>Enter Username to reserve</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={username}
                                    onChange={(e) => onUsernameChanged(e?.target?.value)}
                                    className={usernameError ? "border-red" : "border-gray"}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col xs={12}>
                                {
                                    usernameError ?
                                    <Form.Text className="text-danger">
                                        {usernameErrorMessage}
                                    </Form.Text>
                                    :
                                    <Form.Text className="link-form-text-grey">
                                        e.g. Max Verstappen (without the suffix)
                                    </Form.Text>
                                }
                            </Col> 
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="add-username-modal-footer">
                    <Row style={{ width: "100%" }}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        {
                            username?.trim()?.length > 0 && !usernameError &&
                            <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{ display: "flex", justifyContent: "flex-end" }} >
                                <Button variant="success" onClick={addReservation}>Add</Button>
                            </Col>
                        }
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NewReservationModal;