import React from 'react';
import { Tag } from '../types/InstalledContent';

interface TagEditProps {
  tag: Tag;
  handleTagChange: (field: keyof Tag, value: string) => void;
}

const TagEdit = ({ tag, handleTagChange }: TagEditProps) => {
  return (
    <>
      <div className="col-5 d-flex flex-column">
        <label>
          Type:
        </label>

        <input
          type="text"
          name="type"
          value={tag.type}
          className="form-control"
          onChange={(e) => handleTagChange('type', e.target.value)}
        />
      </div>
      <div className="col-6 p-0 d-flex flex-column">
        <label>
          Name:
        </label>

        <input
          type="text"
          name="name"
          value={tag.name}
          className="form-control"
          onChange={(e) => handleTagChange('name', e.target.value)}
        />
      </div>
    </>
  );
};

export default TagEdit;
