import { useEffect, useRef, useState } from "react";
import CoopClient from "./coop.client";
import { useNavigate, useParams } from "react-router-dom";
import { CoopEvent } from "./coopEvent";
import withAuthentication from "../../withAuthentication";

const CoopEventEdit = () => {
  const client = useRef(new CoopClient());
  const navigate = useNavigate();
  const { id } = useParams();

  const [coopEvent, setCoopEvent] = useState<CoopEvent>({} as CoopEvent);
  
  useEffect(() => fetch(), []);

  const fetch = () => {
    if (!id) {
      console.log('No event id provided');
      return;
    }

    client.current.get(id).then((event) => {
      setCoopEvent(event);
    });
  }

  const del = async () => {
    if (!id) {
      console.log('No event id provided');
      return;
    }

    await client.current.deleteEvent(id);
    navigate('/rm/coop/events');
  }

  const update = async () => {
    if (!id) {
      console.log('No event id provided');
      return;
    }

    await client.current.updateEvent(id, coopEvent);
    navigate('/rm/coop/events');
  }

  const create = async () => {
    await client.current.createEvent(coopEvent);
    navigate('/rm/coop/events');
  }


  return (
    <>
    <div style={{margin: "2rem"}}>
      {id ? <h1>Update COOP Event</h1> : <h1>Create COOP Event</h1>}

      <div style={{margin: "1rem"}}>
        <label style={{ width: "100px" }}>Name</label>
        <input type="text" value={coopEvent.name} onChange={(e) => setCoopEvent({...coopEvent, name: e.target.value})} />
      </div>

      <div style={{margin: "1rem"}}>
        <label style={{ width: "100px" }}>Track</label>
        <input type="text" value={coopEvent.track} onChange={(e) => setCoopEvent({...coopEvent, track: e.target.value})} />
      </div>

      <div style={{margin: "1rem"}}>
        <label style={{ width: "100px" }}>Length</label>
        <input type="text" value={coopEvent.length} onChange={(e) => setCoopEvent({...coopEvent, length: e.target.value})} />
      </div>

      <div style={{margin: "1rem"}}>
        <label style={{ width: "100px" }}>DLC App Ids</label>
        <input type="text" value={coopEvent.dlcAppIds?.join(', ')} onChange={(e) => setCoopEvent({...coopEvent, dlcAppIds: e.target.value.split(', ').map(Number)})} />
      </div>

      <div style={{margin: "1rem"}}>
        <label style={{ width: "100px" }}>Starts</label>
        <input type="datetime" value={coopEvent.starts} onChange={(e) => setCoopEvent({...coopEvent, starts: e.target.value})} />
      </div>

      <div style={{margin: "1rem"}}>
        <label style={{ width: "100px" }}>Ends</label>
        <input type="datetime" value={coopEvent.ends} onChange={(e) => setCoopEvent({...coopEvent, ends: e.target.value})} />
      </div>

      <div style={{margin: "1rem"}}>
        <label style={{ width: "100px" }}>Save File</label>
        <div>
          <textarea style={{ width: "80%", height: "60vh" }} value={JSON.stringify(coopEvent.saveFile)} onChange={(e) => setCoopEvent({...coopEvent, saveFile: JSON.parse(e.target.value)})} />
        </div>
      </div>

      <div style={{margin: "1rem"}}>
        {id && (<button style={{marginRight: "1rem"}} className="btn btn-danger" onClick={() => del()}>Delete</button>)}
        
        <button style={{marginRight: "1rem"}} className="btn btn-secondary" onClick={() => navigate('/rm/coop/events')}>Close</button>
        
        {id ? <button className="btn btn-primary" onClick={update}>Save</button> 
            : <button className="btn btn-primary" onClick={create}>Create</button>}
      </div>
      
    </div>
    </>
  )
}

export default withAuthentication(CoopEventEdit);