import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';

import { Driver, Strike, Suspension, UsernameViolation } from '../../../types/event/Driver'
import { SuspendUserRequest } from '../../../types/Protest';

import UserServiceClient from '../../../services/user-service.client';
import moment from 'moment';
import { FramePrefix } from 'msal/lib-commonjs/utils/Constants';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';

export interface DriverViewNameViolationModalProps {
    driver: Driver;
    game: string;
    violation: UsernameViolation | null;
    show: boolean;
    onCancel: () => void;
    onRedacted: () => void;
}

const DriverViewNameViolationModal = (props: DriverViewNameViolationModalProps) => {

    const openProtestInNewTab = (id: String) => {
        if (id != void (0) && id) {
            window.open(`${window.location.origin}/protest/${id}`, "_blank");
        }
    }

    const suspensionApplied = () => {
        return props?.violation?.suspend && Date.now() >= moment(props?.violation?.date).add(props?.violation?.suspensionDelay, "days").valueOf();
    }

    const suspenionAppliedOn = () => {
        return moment(props?.violation?.date).add(props?.violation?.suspensionDelay, "days").format("DD MMMM YYYY HH:mm");
    }

    const undoViolation = () => {
        Driver.prototype.undoUsernameViolation
            .call(props?.driver, props?.violation?.reference || "", props?.game)
            .then(Driver.prototype.refresh.bind(props?.driver, props?.game))
            .catch(console.error)
            .finally(props?.onRedacted);
    }

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .no-margin-bottom {
                        margin-bottom: 0 !important;
                    }

                    .no-margin-top {
                        margin-top: 0 !important;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Username Violation Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row>
                            <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                                <Form.Label>Date: {moment(props?.violation?.date).format("DD MMMM YYYY HH:mm")}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            {
                                props?.violation?.reference?.startsWith("Manual:") ?
                                    <Row>
                                        <Col>
                                            <Form.Label>Manually applied by: {props?.violation?.reference?.split(":")[1]}</Form.Label>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col>
                                            <Button variant="primary" onClick={openProtestInNewTab.bind(this, props?.violation?.reference || "invalid")}>View Protest</Button>
                                        </Col>
                                    </Row>
                            }
                            <Row>
                                <Col>
                                    <Form.Label className="no-margin-bottom">
                                        Resolved:
                                        {
                                            props?.violation?.resolved ?
                                                <FaCheck className="lime" size="0.75rem" style={{marginLeft: "0.5rem"}}/>
                                                :
                                                <ImCross className="red" size="0.75rem" style={{marginLeft: "0.5rem"}}/>
                                        }
                                    </Form.Label>
                                    <Form.Text className="new-username-form-text-grey no-margin-top">
                                    {
                                        props?.violation?.resolved ?
                                            "The violation has been resolved"
                                            :
                                            "The violation has not been resolved: The user has not changed their name yet"
                                    }
                                    </Form.Text>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr className="my-12" style={{marginTop: "1rem"}}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type="checkbox"
                                        label={props?.violation?.resetUsername ? "Username was reset" : "Username was not reset"}
                                        checked={props?.violation?.resetUsername}
                                        readOnly
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type="checkbox"
                                        label={props?.violation?.suspend ? "Automatic suspension was enabled" : "Automatic suspension was not enabled"}
                                        checked={props?.violation?.suspend}
                                        readOnly
                                    />
                                </Col>
                            </Row>
                            {
                                props?.violation?.suspend &&
                                <Row>
                                    <Col xs={6}>
                                        <Form.Label>Suspension delay (in days)</Form.Label>
                                        <Form.Control defaultValue={props?.violation?.suspensionDelay} type="number" />
                                        <Form.Text className="new-username-form-text-grey">
                                            Warning period before suspension is applied
                                        </Form.Text>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Label>{suspensionApplied() ? "Suspension applied on" : "Suspension set to apply on"}</Form.Label>
                                        <Form.Control readOnly defaultValue={suspenionAppliedOn()} />
                                        <Form.Text className="new-username-form-text-grey">
                                            {
                                                suspensionApplied() ?
                                                    "The user was suspended due to failing to change their name"
                                                    :
                                                    "The date the suspension is set to apply on"
                                            }
                                        </Form.Text>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col>
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control as="textarea" rows={3} readOnly defaultValue={props?.violation?.notes} />
                                    <Form.Text className="strike-form-text-grey">
                                        Notes for future reference (not sent to the player)
                                    </Form.Text>
                                </Col>
                            </Row>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{ width: "100%" }}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{ display: "flex", justifyContent: "flex-end" }} >
                            <Button variant="secondary" onClick={undoViolation.bind(this)}>Undo</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverViewNameViolationModal;