import React, { useState } from "react";
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { Season } from "../models/Season";
import withAuthentication from "../../withAuthentication";
import ChampionshipServiceClient from "../../services/championship-service.client";
import { SeasonDatesValidResponse } from "../responses/SeasonDatesValidResponse";
import EditSeasonFormComponent from "./SeasonEditFormComponent";

const EditSeasonView = () => {

    const championshipService = new ChampionshipServiceClient();
    const navigate = useNavigate();

    const { seasonId } = useParams<{ seasonId: string }>();
    const [seasonLoaded, setSeasonLoaded] = useState<boolean>(false);

    const [seasonShowUpdateButton, setSeasonShowUpdateButton] = useState<boolean>(true);
    const [seasonNameChanged, setSeasonNameChanged] = useState<boolean>(false);
    const [seasonSaveError, setSeasonSaveError] = useState<string>("");
    const [seasonDatesValid, setSeasonDatesValid] = useState<boolean>(true);
    const [season, setSeason] = useState<Season>({
        id: "",
        game: "",
        name: "",
        dateFrom: "",
        dateTo: "",
        championships: []
    });

    React.useEffect(() => {
        if (seasonId == void (0)) return;

        if (seasonId?.length > 0 && !seasonLoaded) {
            refreshSeason();
        }
    }, [seasonId, seasonLoaded]);

    React.useEffect(() => {
        if (!isValid(season)) {
            return;
        }

        championshipService.seasonDatesValid(season)
            .then((res: SeasonDatesValidResponse) => {
                setSeasonDatesValid(res?.valid);
            });

    }, [season]);

    const refreshSeason = () => {
        if (seasonId == void (0)) return;

        championshipService.getSeason(seasonId).then((season: Season) => {
            setSeason(season);
            setSeasonLoaded(true);
            setSeasonShowUpdateButton(true);
        });
    }

    const save = () => {
        if (!isValid(season)) {
            return;
        }

        setSeasonShowUpdateButton(false);
        setSeasonSaveError("");
        setSeasonLoaded(false);

        championshipService.updateSeason(season)
            .then((updatedSeason: Season) => {
                setSeason(updatedSeason);
                if (seasonNameChanged) {
                    championshipService?.getChampionshipsBySeasonId(updatedSeason?.id).then((championships) => {
                        championships.forEach((championship) => {
                            championship.seasonName = updatedSeason.name;
                            championshipService.updateChampionship(updatedSeason?.id, championship)
                            .then(() => {
                                refreshSeason();
                            })
                            .catch((err) => {
                                setSeasonSaveError("Failed to update championships");
                                setSeasonLoaded(true);
                            });
                        });
                    });
                } else {
                    setSeasonLoaded(true);
                }
            }).catch((err) => {
                setSeasonSaveError("Failed to save season");
                setSeasonLoaded(true);
            });
    }

    const onUpdateSeason = (editedSeason: Season) => {
        const nameChanged = editedSeason.name !== season.name;
        setSeasonNameChanged(nameChanged);
        setSeason(editedSeason);
        setSeasonShowUpdateButton(true);
    }

    const isValid = (season: Season) => {
        return season.game.length > 0 && season.name.length > 0 && season.dateFrom.length > 0 && season.dateTo.length > 0
    }

    return (
        <>
            <style>
            {`
                .seasons-header {
                    background-color: #f8f9fa;
                    color: black;
                    border-bottom: 1px solid #dee2e6;
                    font-weight: bold;
                    padding: 0.5rem 0;
                    margin-bottom: 1rem;
                }
                
                .seasons-header-item {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .seasons-header-item-start {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center;
                }

                .seasons-header-item-end {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end !important;
                    align-items: center;
                }
            `}
            </style>
            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row>
                    <Col className="seasons-header-item-start">
                        <h3>Editing Season: {season.name}</h3>
                    </Col>
                </Row>
                {
                    seasonLoaded ? (
                        <Row>
                            <Col>
                                <Row>
                                    <EditSeasonFormComponent
                                        season={season}
                                        dateValidity={seasonDatesValid}
                                        requestRefresh={refreshSeason}
                                        onUpdate={onUpdateSeason}
                                        saveButton={
                                          seasonShowUpdateButton &&
                                            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button variant="success" onClick={save}>Update</Button>
                                            </Col>
                                        }
                                    />
                                </Row>
                                <Row>
                                    <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {
                                            seasonSaveError != void (0) && seasonSaveError?.length > 0 ? (
                                                <p style={{ color: "red" }}>{seasonSaveError}</p>
                                            ) : <></>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className="d-flex flex-column justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
                                <Spinner animation="border" style={{ width: '7vh', height: '7vh' }} />
                                <div className="text-center">
                                    <p>Loading...</p>
                                </div>
                            </Col>
                        </Row>
                    )
                }
            </Container>
        </>
    )
}

export default withAuthentication(EditSeasonView);
