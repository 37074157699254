import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginMethods, SteamLogin } from "./authConfig";
import { useAuth } from "./AuthContext";


export default function() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const openIDData: {[key: string]: string} = {};
    query.forEach((v, k) => {
      openIDData[k] = v;
    });
    fetch(`${process.env.REACT_APP_RM_URL}/newTokenFromSteamOpenID`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        openIDData,
      }),
    })
    .then(async (resp) => {
      const body = await resp.json();
      if (resp.status !== 200) {
        setErrorMessage(body.message ?? body.toString());
      } else {
        if (body.token.length === 0) {
          throw new Error("received unexpected API token from server");
        }
        window.localStorage.setItem("steamUserToken", body.token);
        loginMethods.current = new SteamLogin(body.token);
        auth.setIsAuthenticated(true);
        navigate("/");
      };
    }).catch((err) => {
      console.error(err);
      setErrorMessage(err.message);
    });
  }, [auth]);

  return errorMessage === null ? (
    <div className="text-center mt-5">
      <h1>Logging in...</h1>
    </div>
  ) : (
    <div className="text-center mt-5">
      <h1>Error logging in</h1>
      <div>{errorMessage}</div>
    </div>
  );
}
