import { Assists } from "./Assists";

export class ServerSettings {
  maxDataPerClient: number = 1000;
  downstreamRatedKbps: number = 100000;
  upstreamRatedKbps: number = 50000;

  allowPassengers: boolean = false;
  raceClientWait: number = 122;
  closedRaceSessions: number = 1;

  maxPlayers: number = 24;
	fixedSetup: boolean = false;
	multiFormationLap: number = 0;
	privateQualifying: boolean = false;
  damageMultiplier: number = 100;
  raceTimeScale: number = 1;
	realRoadScale: number = 1;
	fuelConsumptionMultiplier: number = 1; 
	tyreWearMultiplier: number = 1;
	trackLimitsRule: string = "1";
	trackLimitsPointsAllowed: number = 5;
  hotswaps: boolean = false;
  baseRFM: string = "";
  vehicleFilter: string = "";
  setups: string[] = [];
  favouriteAndFixedSetups: string = "";
  assists: Assists | null = null;
  tires: Tires | null = null;
}

export class Tires {
  limitedTireSets?: boolean = false;
  availableTires?: number = 64;
}
