import { Item } from "./event/Content";
import { ServerSettings } from "./event/ServerSettings";
import { Session } from "./event/Session";

export class RequestServerConfiguration {
  count: number = 1;
  type: string = "hosted";
  serverSize: string = "small";
  game: string = "RF2";
  public: boolean = true;
  name: string = "";
  
  generatePassword: boolean = false;
  generateLobbyCode: boolean = false;
  official: boolean = false;
  hasPassword: boolean = false;
  autoconfig: boolean = true;

  config: ServerConfiguration = new ServerConfiguration();
}

export class ServerConfiguration {
  eventType: string = "rc";
  seriesId: string = "server";
  eventId: string = "";

  password: string = "";
  track: Item = new Item();
  cars: Item[] = [];
  sessions: Session[] = [
    new Session("Practice", "5m", "0m"),
    new Session("Qualifying", "10m", "0m"),
    new Session("Race", "15m", "5m")
  ];
  settings: ServerSettings = new ServerSettings();
}