import React, { useEffect, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Accordion, Badge, Button, Col, Dropdown, DropdownButton, Form, ListGroup, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap';
import ProtestServiceClient from '../services/protest-service.client';
import { useParams } from 'react-router-dom';
import { defaultProtestSearchParameters, AmendUserSafetyRatingRequest, IProtestSearchParameters, Protest, ProtestOutcome, ProtestStatus, ProtestType, SuspendUserRequest, StrikeRequest } from '../types/Protest';
import { Driver } from '../types/event/Driver'
import { BsFillCarFrontFill, BsFillChatLeftTextFill, BsFillExclamationTriangleFill, BsLink } from 'react-icons/bs';
import { AiFillFolderOpen } from "react-icons/ai";
import ProtestListViewItem from './ProtestListViewItem';
import EvidenceModalComponent from './EvidenceModalComponent';
import DriverAccordionViewComponent from '../components/driver/DriverAccordionViewComponent';
import ProtestPenaltyOptionsComponent from './ProtestPenaltyOptionsComponent';

const ProtestView = () => {

  const { protestId } = useParams<{ protestId: string }>();

  const protestService = new ProtestServiceClient();

  const [protest, setProtest] = useState<Protest>();

  const [protestee, setProtestee] = useState<Driver>();
  const [protester, setProtester] = useState<Driver>();

  useEffect(() => {
    if (protest == void (0)) return;
    if (protestee == void (0)) return;
    if (protester == void (0)) return;

    const penaltiesAlreadyApplied = protest?.suspensionApplied || protest?.strikeApplied || protest?.srReductionApplied;

    if (!penaltiesAlreadyApplied) return;

    const hasPenaltiesForThisProtest = (driver: Driver): Boolean => [
      ...driver?.profile?.penaltiesApplied?.suspensions || [],
      ...driver?.profile?.penaltiesApplied?.safetyRankAdjustments || [],
      ...driver?.profile?.penaltiesApplied?.strikes || []
    ].filter(p => !p.redacted).filter(p => p?.protestId === protest?._id)?.length > 0;

    const protesteeHasPenaltiesForThisProtest = hasPenaltiesForThisProtest(protestee);
    const protesterHasPenaltiesForThisProtest = hasPenaltiesForThisProtest(protester);

    if (!protesteeHasPenaltiesForThisProtest && !protesterHasPenaltiesForThisProtest) return;

    if (protesteeHasPenaltiesForThisProtest && protesterHasPenaltiesForThisProtest) {
      //throw new Error("Both drivers have penalties for this protest...? This is not meant to be possible.");
    }

    if (protesteeHasPenaltiesForThisProtest) {
      setPenaltyTarget(protestee);
    }
    else if (protesterHasPenaltiesForThisProtest) {
      setPenaltyTarget(protester);
    }
    else {
      setPenaltyTarget(null);
    }
  }, [protestee, protester]);

  const [penaltyTarget, setPenaltyTarget] = useState<Driver | null | undefined>(null);
  const selectPenaltyTarget = (driver: string) => {
    if (driver === "protestee") {
      setPenaltyTarget(protestee);

      if (protest?.outcome !== ProtestOutcome.Accepted) {
        actionProtest(acceptProtest);
      }
    }
    else if (driver === "protester") {
      setPenaltyTarget(protester);

      if (protest?.outcome === ProtestOutcome.Accepted) {
        protest != void (0) ? resetOutcome(protest) : void (0);
      }
    } else {
      setPenaltyTarget(null);

      if (protest?.outcome !== ProtestOutcome.Unknown) {
        protest != void (0) ? resetOutcome(protest) : void (0);
      }
    }
  }

  const [protesteeUserError, setProtesteeUserError] = useState<boolean>(false);
  const [protesterUserError, setProtesterUserError] = useState<boolean>(false);

  const [protests, setProtests] = useState<Protest[]>([]);

  const searchIndex = (search: IProtestSearchParameters) => {
    console.table(search)
    protestService.getFilteredProtests(search, 0, 500)
      .then(p => setProtests(p))
      .catch(error => {
        console.error(error);
        setProtests([]);
      });
  }

  const refreshProtestee = (currentProtest: Protest) => {
    return new Promise<Driver>((resolve, reject) => {
      protestService.getUserById(currentProtest.protesteeId, currentProtest.game)
        .then((user) => {
          try {
            setProtestee(user);
            setProtesteeUserError(false);

            searchIndex({
              game: "all",
              text: currentProtest.protesteeId,
              timestampFrom: 0,
              timestampTo: 0,
              status: 1,
              protestType: 0,
              outcome: 0,
              priorityRecent: false,
              priorityHigh: false,
              priorityParameters: {
                numberProtests: 0,
                numberPunishments: 0
              },
              viewingGroupedProtests: false
            });

            resolve(user);
          }
          catch (e) {
            console.error(e);
            setProtesteeUserError(true);
            reject(e);
          }
        })
        .catch((error) => {
          console.error(error);
          setProtesteeUserError(true);
          reject(error);
        });
    });
  }

  const refreshProtester = (currentProtest: Protest) => {
    return new Promise<Driver>((resolve, reject) => {
      protestService.getUserById(currentProtest.protesterId, currentProtest.game)
        .then((user) => {
          try {
            setProtester(user);
            setProtesterUserError(false);

            resolve(user);
          }
          catch (e) {
            console.error(e);
            setProtesterUserError(true);
            reject(e);
          }
        })
        .catch((error) => {
          console.error(error);
          setProtesterUserError(true);
          reject(error);
        });
    });
  }

  // on mount
  React.useEffect(() => {
    protestService.getProtestById(protestId || "")
      .then((p) => {
        setProtest(p);
        const currentProtest = p;
        console.log(p);
        const protestValid = currentProtest != void (0);

        if (protestValid) {
          setProtest(currentProtest);

          refreshProtestee(currentProtest).then(refreshProtester.bind(this, currentProtest));
        }
        else {
          console.error("Protest is not valid");
        }
      })
      .catch(console.error)
  }, [])

  const getProtest = (): Protest | undefined => {

    let result: Protest | undefined = undefined;

    const propsNull = protest == void (0);
    const propsProtestNull = protest == void (0);
    const propsProtestIdNull = (p: Protest | undefined) => p?._id == void (0);

    const haveProtest = !propsNull && !propsProtestNull && !propsProtestIdNull(protest);

    if (haveProtest) {
      result = protest;
    }

    return result;
  }

  const applySuspension = (duration: string) => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {

      const protestId = currentProtest._id;

      const suspension: SuspendUserRequest = {
        targetUserId: penaltyTarget?.id || "",
        protestType: currentProtest.protestType,
        duration: duration,
        notes: ""
      };

      protestService.suspendUser(protestId, suspension)
        .then(() => {
          currentProtest.suspensionApplied = true;
          currentProtest.suspensionLength = duration;
          setProtest(structuredClone(currentProtest));
          refreshProtestee(currentProtest);
          refreshProtester(currentProtest);
        })
    }
  }

  const undoSuspension = () => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {

      const protestId = currentProtest._id;

      const suspension: SuspendUserRequest = {
        targetUserId: penaltyTarget?.id || "",
        protestType: currentProtest.protestType,
        duration: "",
        notes: ""
      };

      protestService.undoSuspension(protestId, suspension)
        .then(() => {
          currentProtest.suspensionApplied = false;
          currentProtest.suspensionLength = "";
          setProtest(structuredClone(currentProtest));
          refreshProtestee(currentProtest);
          refreshProtester(currentProtest);
        })
    }
  }

  const amendUserSafetyRating = (amount: number) => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {

      const protestId = currentProtest._id;

      const adjustment: AmendUserSafetyRatingRequest = {
        targetUserId: penaltyTarget?.id || "",
        adjustment: amount
      }

      protestService.amendUserSafetyRating(protestId, adjustment)
        .then(() => {
          currentProtest.srReductionApplied = true;
          currentProtest.srReductionAmount = amount;
          setProtest(structuredClone(currentProtest));
          refreshProtestee(currentProtest);
          refreshProtester(currentProtest);
        }).catch(console.error)
    }
  }

  const undoUserSafetyRatingAdjustment = () => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {

      const protestId = currentProtest._id;

      const adjustment: AmendUserSafetyRatingRequest = {
        targetUserId: penaltyTarget?.id || "",
        adjustment: 0
      }

      protestService.undoUserSafetyRatingAdjustment(protestId, adjustment)
        .then(() => {
          currentProtest.srReductionApplied = false;
          currentProtest.srReductionAmount = 0;
          setProtest(structuredClone(currentProtest));
          refreshProtestee(currentProtest);
          refreshProtester(currentProtest);
        })
    }
  }

  const applyStrike = () => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {

      const protestId = currentProtest._id;
      const request: StrikeRequest = {
        targetUserId: penaltyTarget?.id || "",
        notes: ""
      }

      protestService.applyStrike(protestId || "", request)
        .then(() => {
          currentProtest.strikeApplied = true;
          setProtest(structuredClone(currentProtest));
          refreshProtestee(currentProtest);
          refreshProtester(currentProtest);
        }).catch(console.error)
    }
  }

  const undoStrike = () => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {

      const protestId = currentProtest._id;
      const request: StrikeRequest = {
        targetUserId: penaltyTarget?.id || "",
        notes: ""
      }

      protestService.undoStrike(protestId || "", request)
        .then(() => {
          currentProtest.strikeApplied = false;
          setProtest(structuredClone(currentProtest));
          refreshProtestee(currentProtest);
          refreshProtester(currentProtest);
        })
    }
  }

  const closeAllProtests = (protest: Protest) => {

    return new Promise<Protest>((resolve, reject) => {

      const currentProtest = getProtest();
      const protestValid = currentProtest != void (0);

      if (protestValid && currentProtest.status === ProtestStatus.Open) {
        currentProtest.status = ProtestStatus.Closed;

        protestService.closeAllOpenForProtestee(currentProtest)
          .then(() => {
            setProtest(structuredClone(currentProtest));
          })
          .catch(console.error);

        resolve(currentProtest);
      } else {
        reject(new Error("bad protest state"));
      }

    });

  }

  type ProtestModifier = (protest: Protest) => Promise<Protest>;
  const actionProtest = (action: ProtestModifier) => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {
      action(currentProtest)
        .then(p => protestService.updateProtest.call(protestService, p, penaltyTarget?.id))
        .then(() => {
          setProtest(structuredClone(currentProtest));
        })
        .catch(console.error);

    }
  }

  const closeProtest: ProtestModifier = (currentProtest: Protest) => {
    return new Promise<Protest>((resolve, reject) => {
      if (currentProtest.status === ProtestStatus.Open) {
        currentProtest.status = ProtestStatus.Closed;
        resolve(currentProtest);
      } else {
        reject(new Error("Protest is not open"));
      }
    });
  }

  const openProtest: ProtestModifier = (currentProtest: Protest) => {
    return new Promise<Protest>((resolve, reject) => {
      if (currentProtest.status !== ProtestStatus.Open) {
        currentProtest.status = ProtestStatus.Open;
        resolve(currentProtest);
      } else {
        reject(new Error("Protest is not open"));
      }
    });
  }

  const acceptProtest: ProtestModifier = (currentProtest: Protest) => {
    return new Promise<Protest>((resolve, reject) => {
      if (currentProtest.outcome !== ProtestOutcome.Accepted) {
        currentProtest.outcome = ProtestOutcome.Accepted;
        let copyProtest = structuredClone(currentProtest);
        copyProtest.outcome = ProtestOutcome.Accepted;
        resolve(copyProtest);
      } else {
        reject(new Error("Protest is already Accepted"));
      }
    });
  }

  const rejectProtest: ProtestModifier = (currentProtest: Protest) => {
    return new Promise<Protest>((resolve, reject) => {
      if (currentProtest.outcome !== ProtestOutcome.Rejected) {
        currentProtest.outcome = ProtestOutcome.Rejected;
        let copyProtest = structuredClone(currentProtest);
        copyProtest.outcome = ProtestOutcome.Rejected;
        resolve(copyProtest);
      } else {
        reject(new Error("Protest is already Rejected"));
      }
    });
  }

  const dismissProtest: ProtestModifier = (currentProtest: Protest) => {
    return new Promise<Protest>((resolve, reject) => {
      if (currentProtest.outcome !== ProtestOutcome.Dismissed) {
        currentProtest.outcome = ProtestOutcome.Dismissed;
        let copyProtest = structuredClone(currentProtest);
        copyProtest.outcome = ProtestOutcome.Dismissed;
        resolve(copyProtest);
      } else {
        reject(new Error("Protest is already Dismissed"));
      }
    });
  }

  const resetOutcome: ProtestModifier = (currentProtest: Protest) => {
    return new Promise<Protest>((resolve, reject) => {
      if (currentProtest.outcome !== ProtestOutcome.Unknown) {
        currentProtest.outcome = ProtestOutcome.Unknown;
        let copyProtest = structuredClone(currentProtest);
        copyProtest.outcome = ProtestOutcome.Unknown;
        resolve(copyProtest);
      } else {
        reject(new Error("Protest is already Unknown"));
      }
    });
  }

  const [protestNotes, setProtestNotes] = useState<string>("");
  const onProtestNotesChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    if (protest != void (0)) {
      protest.notes = event.target.value.toString();
    }

    setProtestNotes(event.target.value);
  }

  const saveNotes = () => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {
      currentProtest.notes = protestNotes;
      protestService.updateProtest(currentProtest)
        .then(() => {
          setProtest(structuredClone(currentProtest));
        })

    }
  }

  const [protestJudgement, setProtestJudgement] = useState<string>("");
  const onProtestJudgementChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    if (protest != void (0)) {
      protest.judgement = event.target.value.toString();
    }

    setProtestJudgement(event.target.value);
  }

  const saveJudgement = () => {
    const currentProtest = getProtest();
    const protestValid = currentProtest != void (0);

    if (protestValid) {
      currentProtest.judgement = protestJudgement;
      protestService.updateProtest(currentProtest)
        .then(() => {
          setProtest(structuredClone(currentProtest));
        })

    }
  }

  const outcomeToVariant = (outcome: number | undefined) => {
    let result = "secondary";
    switch (outcome) {
      case ProtestOutcome.Accepted:
        result = "success";
        break;
      case ProtestOutcome.Rejected:
        result = "danger";
        break;
      case ProtestOutcome.Dismissed:
        result = "secondary";
        break;
      default:
        result = "secondary";
        break;
    }

    return result;
  }

  const protestShareLinkToClipboard = () => {
    const currentProtest = getProtest();
    if (currentProtest != void (0)) {
      navigator.clipboard.writeText(`${window.location.origin}/protest/${currentProtest._id}`);
    }
  }

  const openInNewTab = (id: String) => {
    if (id != void (0) && id) {
      window.open(`${window.location.origin}/protest/${id}`, "_blank");
    }
  }

  const formatDateString = (timestamp: number | undefined) => {
    let result = "";
    if (timestamp != void (0)) {
      const date = new Date(timestamp * 1000);
      const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      result = date.toLocaleDateString("en-GB", dateOptions);
    }

    return result;
  }

  const [showEvidenceModal, setShowEvidenceModal] = useState<boolean>();
  const openEvidenceModal = () => {
    return new Promise<void>((resolve, reject) => {
      setShowEvidenceModal(true);
      resolve();
    });
  }

  return (
    <>
      <style>
        {`
          .protest-form-text-grey {
            color: grey !important;
          }

          .protest-form-text-snow {
            color: snow !important;
          }

          .protest-form-text-snow .bold {
            font-weight: bold;
          }

          .accordion-protest-list-open-cases {
            line-height: 0 !important;
          }

          .accordion-body-protest-open-cases {
            padding: 0 !important;
          }
        `}
      </style>
      <div className="row protest-view-container">
        {
          protest != void (0) && protest?.protesteeUsername != void (0) ?
            <div className="row">
              <div className="row">
                <div className="col-2" style={{ width: "fit-content", height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center" }}>
                  {
                    protest?.protestType === ProtestType.Report ?
                      <BsFillChatLeftTextFill size="1.5em" color="orangered" />
                      :
                      <div style={{ display: "grid", position: "relative" }}>
                        <div style={{ gridColumn: 1, gridRow: 1 }}>
                          <BsFillCarFrontFill size="1.5em" color="lightgrey" />
                        </div>
                        <div style={{ gridColumn: 1, gridRow: 1, position: "absolute", left: "-10%", bottom: "-10%" }}>
                          <BsFillExclamationTriangleFill size="1em" color="red" />
                        </div>
                      </div>
                  }
                </div>
                <div className="col-10">
                  <h2>{protest?.game?.toLocaleUpperCase()} {protest?.protestType === ProtestType.Report ? "Report:" : "Protest:"} {protest?._id}</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <Row>
                      <Col xs={4} style={{display: "flex", justifyContent: "flex-start", alignItems: "center", fontWeight: "bold"}}>
                        <Row>
                          <h1>Protestee</h1>
                        </Row>
                      </Col>
                      <Col xs={4} style={{display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold"}}>
                        <Row>
                          <h1>Protester</h1>
                        </Row>
                      </Col>
                      <Col xs={4} style={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"}}>
                        <Row>
                          <h2>Details</h2>
                        </Row>
                      </Col>
                    </Row>
                    <Form>

                      <div className="row">
                        <div className="col-4">
                          <Row>
                            {
                              protestee != void (0) &&
                              <DriverAccordionViewComponent
                                drivers={[protestee]}
                                game={protest?.game}
                                requestRefresh={(indices: Array<number>) => {
                                  refreshProtestee(protest);
                                }}
                              />
                            }
                          </Row>
                        </div>
                        <div className="col-4">
                          <Row>
                            {
                              protester != void (0) &&
                              <DriverAccordionViewComponent
                                drivers={[protester]}
                                game={protest?.game}
                                requestRefresh={(indices: Array<number>) => {
                                  refreshProtester(protest);
                                }}
                              />
                            }
                          </Row>
                        </div>
                        <div className="col-4">
                          <div className="row">
                            <Form.Group className="mb-3 protest-user-details protest-form-text-snow" controlId="protesterUsername">
                              <Form.Text className="text-muted protest-form-text-snow">
                                Event ID: {protest?.eventId}
                              </Form.Text>
                              <Form.Text className="text-muted protest-form-text-snow">
                                Series ID: {protest?.seriesId}
                              </Form.Text>
                              <Form.Text className="text-muted protest-form-text-snow">
                                Split: {protest?.split}
                              </Form.Text>
                              <Form.Text className="text-muted protest-form-text-snow">
                                Incident Time: {protest?.incidentTimestamp}
                              </Form.Text>
                              <Form.Text className="text-muted protest-form-text-snow">
                                Submission Time: {formatDateString(protest?.timestamp)}
                              </Form.Text>
                              <Button variant="primary" style={{ marginTop: "2%", backgroundColor: "darkblue", borderColor: "darkblue", marginRight: "2%" }}
                                onClick={protestShareLinkToClipboard}>
                                <div className="row" style={{ fontSize: "20px", width: "fit-content", display: "flex", alignItems: "center" }}>
                                  <BsLink style={{ fontSize: "0.75em", width: "fit-content", display: "flex", alignItems: "center", strokeWidth: "0.75px" }} />
                                  <div style={{ fontSize: "0.5em", width: "fit-content", paddingLeft: 0, fontWeight: "bold" }}>Copy Link</div>
                                </div>
                              </Button>
                              {
                                protest?.seriesId != void (0) && protest?.seriesId.length > 0 && protest?.eventId != void (0) && protest?.eventId.length > 0 && protest?.split != void (0) && protest?.split.length > 0
                                  ? <>
                                    <Button onClick={openEvidenceModal} variant="primary" style={{ marginTop: "2%", backgroundColor: "darkblue", borderColor: "darkblue" }}>
                                      <div className="row" style={{ fontSize: "20px", width: "fit-content", display: "flex", alignItems: "center" }}>
                                        <AiFillFolderOpen style={{ fontSize: "0.75em", width: "fit-content", display: "flex", alignItems: "center", strokeWidth: "0.75px" }} />
                                        <div style={{ fontSize: "0.5em", width: "fit-content", paddingLeft: 0, fontWeight: "bold" }}>View Evidence</div>
                                      </div>
                                    </Button>

                                    <a href={`/rm/results/view?seriesId=${protest.seriesId}&eventId=${protest.eventId}&splitNo=${protest.split}`} className="btn" style={{ marginTop: "2%", backgroundColor: "darkblue", borderColor: "darkblue" }}>
                                      <div className="row" style={{ fontSize: "20px", width: "fit-content", display: "flex", alignItems: "center" }}>
                                        <AiFillFolderOpen style={{ fontSize: "0.75em", width: "fit-content", display: "flex", alignItems: "center", strokeWidth: "0.75px" }} />
                                        <div style={{ fontSize: "0.5em", width: "fit-content", paddingLeft: 0, fontWeight: "bold" }}>View Results</div>
                                      </div>
                                    </a>
                                  </>
                                  : <></>
                              }
                              <EvidenceModalComponent
                                show={showEvidenceModal || false}
                                setShowModal={setShowEvidenceModal}
                                protest={protest}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <hr className="my-12" />
                      {
                        protests?.length > 1 &&
                        <Row>
                          <Col xs={12} className="accordion-protest-list accordion-protest-list-open-cases">
                            <Accordion flush style={{ backgroundColor: "#222222", color: "snow", border: "1px solid grey", lineHeight: "1.5rem" }}>
                              <Accordion.Item eventKey="0" style={{ backgroundColor: "#222222", color: "snow" }}>
                                <Accordion.Header style={{ backgroundColor: "#222222", color: "snow" }}>
                                  {protests?.length} open cases for {protest?.protesteeUsername}
                                </Accordion.Header>
                                <Accordion.Body className="accordion-body-protest-open-cases">
                                  <ListGroup style={{ paddingRight: "0px" }}>
                                    {protests.filter(p => protest == void (0) ? true : p._id != protest?._id).map(p => (
                                      <ProtestListViewItem
                                        key={p._id}
                                        protest={p}
                                        onClick={(id: string) => openInNewTab(id)}
                                      />
                                    ))}
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <br></br>
                          </Col>
                        </Row>
                      }
                      {
                        protests?.length > 1 &&
                        <hr className="my-12" />
                      }
                      <div className="row">
                        <Form.Group className="mb-3" controlId="protestReason">
                          <Form.Label>Reason</Form.Label>
                          <Form.Control className="protest-view-text-input" as="textarea" rows={3} readOnly value={protest?.protestReason} />
                          <Form.Text className="protest-form-text-grey">
                            Reason for the protest by the protester
                          </Form.Text>
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group className="mb-3" controlId="protestNotes">
                          <Form.Label>Notes</Form.Label>
                          <Form.Control className="protest-view-text-input" as="textarea" rows={3} defaultValue={protest?.notes} onChange={onProtestNotesChange} />
                          <Form.Text className="protest-form-text-grey">
                            Notes for protest committee / future reference
                            <Button variant="primary" size="sm" style={{ fontSize: "small", display: "flex", justifyContent: "flex-end", alignItems: "center", float: "right" }}
                              onClick={saveNotes}>
                              Save
                            </Button>
                          </Form.Text>
                        </Form.Group>
                      </div>

                      <Row>
                        <Form.Group className="mb-3" controlId="protestNotes">
                          <Form.Label>Judgement</Form.Label>
                          <Form.Control className="protest-view-text-input" as="textarea" rows={3} defaultValue={protest?.judgement} onChange={onProtestJudgementChange} />
                          <Form.Text className="protest-form-text-grey">
                            Judgement field which is sent to both parties
                            <Button variant="primary" size="sm" style={{ fontSize: "small", display: "flex", justifyContent: "flex-end", alignItems: "center", float: "right" }}
                              onClick={saveJudgement}>
                              Save
                            </Button>
                          </Form.Text>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col xs={12} style={{width: "fit-content"}} className="ms-auto">
                          {
                            protest?.updatedBy != void (0) && protest?.updatedBy.length > 0 &&
                              `Last Updated By: ${protest?.updatedBy}`
                          }
                        </Col>
                      </Row>
                      <hr className="my-12" />
                      <Row style={{ display: "flex" }}>
                        <Col xs={7}>
                          <ProtestPenaltyOptionsComponent
                            driver={penaltyTarget}
                            protest={protest}

                            onSelectPenaltyTarget={selectPenaltyTarget}

                            onApplySuspension={applySuspension}
                            onUndoSuspension={undoSuspension}

                            onAmendUserSafetyRating={amendUserSafetyRating}
                            onUndoUserSafetyRatingAdjustment={undoUserSafetyRatingAdjustment}

                            onApplyStrike={applyStrike}
                            onUndoStrike={undoStrike}
                          />
                        </Col>
                        <Col xs={5} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                          <Row style={{ width: "100%" }}>
                            <Col xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                              {
                                protest?.outcome !== ProtestOutcome.Unknown ?
                                  <>
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="top"
                                      overlay={
                                        <Popover>
                                          <Popover.Header as="h3" style={{ backgroundColor: "#333333", color: "snow" }}>
                                            {ProtestOutcome[protest?.outcome]}
                                          </Popover.Header>
                                          <Popover.Body style={{ backgroundColor: "#333333", color: "snow" }}>
                                            <div className="row">
                                              <DropdownButton
                                                id="dropdown-basic-button"
                                                title="Re-Action"
                                                variant="secondary">
                                                {
                                                  penaltyTarget?.id != protester?.id &&
                                                  <Dropdown.Item onClick={actionProtest.bind(this, acceptProtest)}>Accepted</Dropdown.Item>
                                                }
                                                {
                                                  penaltyTarget?.id != protestee?.id &&
                                                  <Dropdown.Item onClick={actionProtest.bind(this, rejectProtest)}>Rejected</Dropdown.Item>
                                                }
                                                {
                                                  penaltyTarget?.id != protestee?.id &&
                                                  <Dropdown.Item onClick={actionProtest.bind(this, dismissProtest)}>Dismissed</Dropdown.Item>
                                                }
                                              </DropdownButton>
                                            </div>
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <Badge pill bg={outcomeToVariant(protest?.outcome)} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000" }}>{ProtestOutcome[protest?.outcome]}</Badge>
                                    </OverlayTrigger>
                                  </>
                                  :
                                  <Row>
                                    <Col>
                                      <DropdownButton
                                        id="dropdown-basic-button"
                                        title="Action"
                                        variant="secondary">
                                        {
                                          penaltyTarget?.id != protester?.id &&
                                          <Dropdown.Item onClick={actionProtest.bind(this, acceptProtest)}>Accepted</Dropdown.Item>
                                        }
                                        {
                                          penaltyTarget?.id != protestee?.id &&
                                          <Dropdown.Item onClick={actionProtest.bind(this, rejectProtest)}>Rejected</Dropdown.Item>
                                        }
                                        {
                                          penaltyTarget?.id != protestee?.id &&
                                          <Dropdown.Item onClick={actionProtest.bind(this, dismissProtest)}>Dismissed</Dropdown.Item>
                                        }
                                      </DropdownButton></Col>
                                  </Row>
                              }
                            </Col>
                            <Col xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                              {
                                protest?.outcome !== ProtestOutcome.Unknown ?
                                  protest?.status === ProtestStatus.Open ?
                                    <Row style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%", whiteSpace: "nowrap"  }}>
                                      <Col xs={4}>
                                        <Button variant="danger"
                                          onClick={actionProtest.bind(this, closeProtest)}>
                                          Close
                                        </Button></Col>
                                      <Col xs={8} title="Action all open protests for this Protestee on this game">
                                        {
                                          protests?.length > 1 &&
                                            <Button variant="danger"
                                              onClick={closeAllProtests.bind(this, protest)}>
                                              Close All ({protest?.game?.toLocaleUpperCase()})
                                            </Button>
                                        }       
                                        </Col>
                                    </Row>
                                    :
                                    protest?.status === ProtestStatus.Closed ?
                                      <>
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="top"
                                          overlay={
                                            <Popover>
                                              <Popover.Header as="h3" style={{ backgroundColor: "#333333", color: "snow" }}>
                                                Closed
                                              </Popover.Header>
                                              <Popover.Body style={{ backgroundColor: "#333333", color: "snow" }}>
                                                <div className="row">
                                                  <Button variant="success" size="sm" onClick={actionProtest.bind(this, openProtest)}>
                                                    Re-Open
                                                  </Button>
                                                </div>
                                              </Popover.Body>
                                            </Popover>
                                          }
                                        >
                                          <Badge bg="danger" pill style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000" }}>
                                            Closed
                                          </Badge>
                                        </OverlayTrigger>
                                      </>
                                      :
                                      <Button variant="primary"
                                        onClick={actionProtest.bind(this, openProtest)}>
                                        Open
                                      </Button>
                                  :
                                  <></>
                              }
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="row">
              <h1>Protest {protestId} Details Not Found</h1>
            </div>
        }
      </div>
    </>
  );
};

export default withAuthentication(ProtestView);
