import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Button, Form, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faEdit, faAdd } from '@fortawesome/free-solid-svg-icons'
import withAuthentication from "../../withAuthentication";
import ChampionshipServiceClient from "../../services/championship-service.client";
import AreYouSureModal from "../../components/generic/AreYouSureModal";
import SeasonViewItemComponent from "./SeasonViewItemComponent";
import { Season } from "../models/Season";
import SeasonListViewComponent from "./SeasonListViewComponent";

const SeasonsView = () => {

    const championshipService = new ChampionshipServiceClient();
    const navigate = useNavigate();

    const [seasonsCount, setSeasonsCount] = useState<number>(0);
    const [seasonsPage, setSeasonsPage] = useState<number>(0);
    const [seasonsTextSearch, setSeasonsTextSearch] = useState<string>("");
    const [seasons, setSeasons] = useState<Season[]>([]);

    const [futureSeasonsCount, setFutureSeasonCount] = useState<number>(0);
    const [futureSeasonsPage, setFutureSeasonsPage] = useState<number>(0);
    const [futureSeasonsTextSearch, setFutureSeasonsTextSearch] = useState<string>("");
    const [futureSeasons, setFutureSeasons] = useState<Season[]>([]);

    const [activeSeasons, setActiveSeasons] = useState<Season[]>([]);

    const [pastSeasonsCount, setPastSeasonsCount] = useState<number>(0);
    const [pastSeasonsPage, setPastSeasonsPage] = useState<number>(0);
    const [pastSeasonsTextSearch, setPastSeasonsTextSearch] = useState<string>("");
    const [pastSeasons, setPastSeasons] = useState<Season[]>([]);

    const pageSize = 25;
    const fullPageSize = 25;

    const [seasonToDelete, setSeasonToDelete] = useState<Season>({} as Season)
    const [showDeletionAreYouSureModal, setShowDeletionAreYouSureModal] = useState(false);
    const [showDeletionAreYouReallySureModal, setShowDeletionAreYouReallySureModal] = useState(false);

    useEffect(() => {

        resetPages();
        refreshSeasons(0);
        refreshGroupedSeasons();
        
    }, []);

    useEffect(() => {

        refreshFutureSeasons(futureSeasonsPage);

    }, [futureSeasonsPage]);

    useEffect(() => {

        refreshPastSeasons(pastSeasonsPage);

    }, [pastSeasonsPage]);

    useEffect(() => {

        refreshSeasons(seasonsPage);

    }, [seasonsPage]);

    useEffect(() => {

        setFutureSeasonsPage(0);
        refreshFutureSeasons(0);

    }, [futureSeasonsTextSearch]);

    useEffect(() => {

        setPastSeasonsPage(0);
        refreshPastSeasons(0);

    }, [pastSeasonsTextSearch]);

    useEffect(() => {

        setSeasonsPage(0);
        refreshSeasons(0);

    }, [seasonsTextSearch]);

    const resetPages = () => {
        setSeasonsPage(0);
        setFutureSeasonsPage(0);
        setPastSeasonsPage(0);
    }

    const refreshGroupedSeasons = () => {
        refreshFutureSeasons(0);

        championshipService.activeSeasons()
        .then((res) => setActiveSeasons(res.seasons))
        .catch(() => setActiveSeasons([]));

        refreshPastSeasons(0);
    }

    const refreshFutureSeasons = (page: number) => {
        championshipService.searchSeasons("", futureSeasonsTextSearch, new Date(Date.now() + 10), new Date(9999999999999), page, pageSize)
        .then((res) => {
            setFutureSeasons(res.seasons);
            setFutureSeasonCount(res.count);
        })
        .catch(() => setFutureSeasons([]));
    }

    const refreshSeasons = (page: number) => {
        championshipService.searchSeasons("", seasonsTextSearch, new Date(0), new Date(9999999999999), page, fullPageSize)
        .then((res) => {
            setSeasons(res.seasons);
            setSeasonsCount(res.count);
        })
        .catch(() => setSeasons([]));
    }

    const refreshPastSeasons = (page: number) => {
        championshipService.searchSeasons("", pastSeasonsTextSearch, new Date(0), new Date(Date.now()), page, pageSize)
        .then((res) => {
            setPastSeasons(res.seasons);
            setPastSeasonsCount(res.count);
        })
        .catch(() => setPastSeasons([]));
    }

    const deleteSeason = (seasonID: string) => {
        championshipService.deleteSeason(seasonID)
            .then(() => {
                resetPages();
                refreshGroupedSeasons();
                refreshSeasons(0);
                refreshGroupedSeasons();
            });
    }

    const tryDeleteSeason = (season: Season) => {
        setSeasonToDelete(season);
        setShowDeletionAreYouSureModal(true);
    }

    const isSeasonActive = (season: Season) => {
        return season.dateFrom <= new Date().toISOString() && season.dateTo >= new Date().toISOString();
    }

    return (
        <>
            <style>
            {`
                .seasons-top-bar {
                    background-color: rgba(0,0,0,0);
                    color: snow;
                    font-weight: bold;
                    padding: 0.5rem 0;
                }
                
                .seasons-top-bar-item {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .seasons-top-bar-item-start {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center;
                }

                .seasons-top-bar-item-end {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end !important;
                    align-items: center;
                }

                .seasons-tabs {
                    margin-bottom: 0 !important;
                }

                .seasons-tabs .nav-link.active {
                    background-color: #222222;
                    color: snow;
                }
                
                .seasons-tabs .nav-link:hover {
                    background-color: #333333;
                    color: snow;
                }

                .seasons-tab {
                    background-color: #222222;
                    color: snow;
                }

            `}
            </style>

            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row className="seasons-top-bar">
                    <Col className="seasons-top-bar-item-start">
                        <h3>Seasons Configuration</h3>
                    </Col>
                    <Col className="seasons-top-bar-item-end">
                        <Button variant="primary" className="seasons-header-item" onClick={() => navigate('/rm/configs/seasons/create')}>
                            <FontAwesomeIcon icon={faAdd} style={{ paddingRight: "0.5rem" }} />
                            Create New
                        </Button>
                    </Col>
                </Row>
                <Tabs
                    defaultActiveKey="active-seasons"
                    id="uncontrolled-tab-example"
                    className="mb-3 seasons-tabs"
                >
                    <Tab eventKey="active-seasons" title="Active" className="seasons-tab">
                        <Row>
                            <Col xs={12}>
                                <SeasonListViewComponent
                                    customTitle="Active Seasons"
                                    seasons={activeSeasons}
                                    onEdit={(season) => navigate(`/rm/configs/seasons/edit/${season.id}`)}
                                    onCopy={(season) => navigate(`/rm/configs/seasons/copy/${season.id}`)}
                                    onDelete={tryDeleteSeason}
                                />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="future-seasons" title="Future" className="seasons-tab">
                        <Row>
                            <Col xs={12}>
                                <SeasonListViewComponent
                                    customTitle="Future Seasons"
                                    seasons={futureSeasons}
                                    textSearch={futureSeasonsTextSearch}
                                    textSearchChange={setFutureSeasonsTextSearch}
                                    curPage={futureSeasonsPage}
                                    total={futureSeasonsCount}
                                    pageSize={pageSize}
                                    nextPage={() => setFutureSeasonsPage(futureSeasonsPage + 1)}
                                    prevPage={() => setFutureSeasonsPage(futureSeasonsPage - 1)}
                                    onEdit={(season) => navigate(`/rm/configs/seasons/edit/${season.id}`)}
                                    onCopy={(season) => navigate(`/rm/configs/seasons/copy/${season.id}`)}
                                    onDelete={tryDeleteSeason}
                                />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="past-seasons" title="Expired" className="seasons-tab">
                        <Row>
                            <Col xs={12}>
                                <SeasonListViewComponent
                                    customTitle="Expired Seasons"
                                    seasons={pastSeasons}
                                    textSearch={pastSeasonsTextSearch}
                                    textSearchChange={setPastSeasonsTextSearch}
                                    curPage={pastSeasonsPage}
                                    total={pastSeasonsCount}
                                    pageSize={pageSize}
                                    nextPage={() => setPastSeasonsPage(pastSeasonsPage + 1)}
                                    prevPage={() => setPastSeasonsPage(pastSeasonsPage - 1)}
                                    onEdit={(season) => navigate(`/rm/configs/seasons/edit/${season.id}`)}
                                    onCopy={(season) => navigate(`/rm/configs/seasons/copy/${season.id}`)}
                                    onDelete={tryDeleteSeason}
                                />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="all-seasons" title="All" className="seasons-tab">
                        <Row>
                            <Col xs={12}>
                                <SeasonListViewComponent
                                    customTitle="All Seasons"
                                    seasons={seasons}
                                    textSearch={seasonsTextSearch}
                                    textSearchChange={setSeasonsTextSearch}
                                    curPage={seasonsPage}
                                    total={seasonsCount}
                                    pageSize={fullPageSize}
                                    nextPage={() => setSeasonsPage(seasonsPage + 1)}
                                    prevPage={() => setSeasonsPage(seasonsPage - 1)}
                                    onEdit={(season) => navigate(`/rm/configs/seasons/edit/${season.id}`)}
                                    onCopy={(season) => navigate(`/rm/configs/seasons/copy/${season.id}`)}
                                    onDelete={tryDeleteSeason}
                                />
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Container>
            <AreYouSureModal
                customTitle="Are you sure you want to delete this season?"
                customText={`Press confirm to delete the season ${seasonToDelete.name}`}
                show={showDeletionAreYouSureModal}
                onCancel={() => setShowDeletionAreYouSureModal(false)}
                onConfirm={() => {
                    if (isSeasonActive(seasonToDelete)) {
                        setShowDeletionAreYouReallySureModal(true);
                        setShowDeletionAreYouSureModal(false);
                    } else {
                        deleteSeason(seasonToDelete.id);
                        setShowDeletionAreYouSureModal(false);
                    }
                }}
            />
            <AreYouSureModal
                customTitle="Are you REALLY sure you want to delete this season?"
                customText={`This is an active season and has not yet ended. Deleting it will remove all associated championships, weeks and events. Are you sure you want to proceed?`}
                show={showDeletionAreYouReallySureModal}
                onCancel={() => (setShowDeletionAreYouReallySureModal(false), setShowDeletionAreYouSureModal(false))}
                onConfirm={() => {
                    deleteSeason(seasonToDelete.id);
                    setShowDeletionAreYouReallySureModal(false);
                    setShowDeletionAreYouSureModal(false);
                }}
            />
        </>
    )
}

export default withAuthentication(SeasonsView);
