import { useEffect, useRef, useState } from "react";
import { EventResult, eventTypes } from "../types/event/EventResults";
import { Col, Form, Row } from "react-bootstrap";
import RaceManagementClient from "../services/race-manaement.client";
import { Driver } from "../types/event/Driver";
import DriverRankBadge from "../components/driverRank";
import SafetyRankBadge from "../components/safteyRank";

interface PlayerEventsProps {
  steamId?: string
}

const ViewEvents = (props: PlayerEventsProps) => {
  const rm = new RaceManagementClient();

  const [eventResults, setEventResults] = useState<EventResult[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [game, setGame] = useState("");
  const [eventType, setEventType] = useState("");

  const fetch = (page: number, game: string, eventType: string) => {
    if (!props.steamId) return;

    setLoading(true);
    rm.resultsBySteamId(props.steamId, page, 15, eventType, game)
      .then((results: EventResult[]) => {
        setEventResults(results);
        setLoading(false);
      })
      .catch((err: Error) => {
        setLoading(false);
        console.error(err);
      });
  }

  const view = (seriesId: string, eventId: string, splitNo: number) => {
    window.location.href = `/rm/results/view?seriesId=${seriesId}&eventId=${eventId}&splitNo=${splitNo}`;
  }

  useEffect(() => fetch(1, "", ""), [props.steamId, page]);
  useEffect(() => fetch(page, game, eventType), [page]);
  useEffect(() => fetch(1, game, eventType), [eventType, game]);

  return (
    <>
    <Row>
      <Col>
        <Form.Group controlId="tierName">
          <Form.Label>Game:</Form.Label>
          <Form.Control as="select" value={game} onChange={(e) => setGame(e.target.value)} style={{marginBottom: "1rem"}}>
            <option value="">Please Select</option> 
            <option value="RF2">RF2</option>
            <option value="LMU">LMU</option>
          </Form.Control>
        </Form.Group>
      </Col>

      <Col>
        <Form.Group controlId="tierName">
          <Form.Label>Type:</Form.Label>
          <Form.Control as="select" value={eventType} onChange={(e) => setEventType(e.target.value)} style={{marginBottom: "1rem"}}>
            <option value="">Please Select</option> 
            {eventTypes.map((t) => (
              <option value={t} selected={eventType === t}>{t}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>

      <Col>
        <button className="btn btn-secondary mr2" style={{marginRight: "1rem", marginTop: "2rem"}} onClick={() => setPage(page - 1)} disabled={page === 1 || loading}>Previous</button>
        <span style={{marginTop: "2rem"}}>{page}</span>
        <button className="btn btn-secondary mr2" style={{marginLeft: "1rem", marginTop: "2rem"}} onClick={() => setPage(page + 1)} disabled={loading && eventResults?.length < 20}>Next</button>
      </Col>

      <Col>
        <button className="btn btn-primary" style={{marginTop: "2rem"}} onClick={() => fetch(page, game, eventType)}>Refresh</button>
      </Col>
    </Row>

    <div className="small">
      <table className="table table-dark">
        <thead>
          <tr>
            <th scope="col">Game</th>
            <th scope="col">Event</th>
            <th scope="col">Date</th>
            <th scope="col">Type</th>
            <th scope="col">Official</th>
            <th scope="col">Tier</th>
            <th scope="col">Qualified</th>
            <th scope="col">Finished</th>
            <th scope="col">DR</th>
            <th scope="col">SR</th>
          </tr>
        </thead>

        <tbody>
          {eventResults?.map((result, index) => (
            <tr key={index} onClick={() => view(result.seriesId, result.eventId, result.splitNo)}>
              <td scope="col">{result.game}</td>
              <td scope="col">{result.title}</td>
              <td scope="col">{result.date}</td>
              <td scope="col">{result.eventType}</td>
              <td scope="col">{result.official ? "yes" : "no"}</td>
              <td scope="col">{result.tier}</td>
              <td scope="col">{result.race?.races[0].results.find(x => x.steamId == props.steamId)?.classGridPos}</td>
              <td scope="col">{result.race?.races[0].results.find(x => x.steamId == props.steamId)?.classPosition}</td>
              <td scope="col">
                <DriverRankBadge dr={result.drivers.find(x => x.steamId == props.steamId)?.driverRank} />
                {result.drivers.find(x => x.steamId == props.steamId)?.driverRankAdjustment}
              </td>
              <td scope="col">
                <SafetyRankBadge sr={result.drivers.find(x => x.steamId == props.steamId)?.safetyRank} />
                {result.drivers.find(x => x.steamId == props.steamId)?.safetyRankAdjustment}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default ViewEvents;
