import React, { useState, useEffect } from 'react';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { KeyValue } from "../../types/keyValue";
import { Assists } from '../../types/event/Assists';
import { get } from 'http';

interface AssistsParameters {
  assists: Assists | null;
  onChanged: (assists: Assists) => void;
}

interface AssistOption {
  key: number;
  value: string;
}

const AssistsComponent: React.FC<AssistsParameters> = (params: AssistsParameters) => {
  const [assists, setAssists] = useState<Assists>(new Assists());

  const onOffOptions : AssistOption[] = [
    { key: 0, value: "Off" },
    { key: 1, value: "On" }
  ];

  const lowHighOptions : AssistOption[] = [
    { key: 0, value: "Off" },
    { key: 1, value: "Low" },
    { key: 2, value: "High" }
  ];

  const lowMedHighOptions : AssistOption[] = [
    { key: 0, value: "Off" },
    { key: 1, value: "Low" },
    { key: 2, value: "Medium" },
    { key: 3, value: "High" }
  ];

  const defaultOptionNames: string[] = ["Beginner", "Intermediate", "Advanced"];

  const defaultOptions: Map<string, Assists> = new Map<string, Assists>([
    ["Beginner", {
      antiLockBrakes: 1,
      autoBlip: 1,
      autoClutch: 1,
      autoLift: 1,
      autoPit: 0,
      autoShift: 3,
      brakeHelp: 2,
      drivingLine: 3,
      invulnerability: 0,
      oppositeLock: 1,
      spinRecovery: 0,
      stabilityControl: 0,
      steeringHelp: 3,
      tractionControl: 0,
      visFastLine: 3
    } as Assists],
    ["Intermediate", {
      antiLockBrakes: 0,
      autoBlip: 1,
      autoClutch: 1,
      autoLift: 1,
      autoPit: 0,
      autoShift: 3,
      brakeHelp: 0,
      drivingLine: 3,
      invulnerability: 0,
      oppositeLock: 0,
      spinRecovery: 0,
      stabilityControl: 0,
      steeringHelp: 0,
      tractionControl: 0,
      visFastLine: 3
    } as Assists],
    ["Advanced", {
      antiLockBrakes: 0,
      autoBlip: 1,
      autoClutch: 1,
      autoLift: 1,
      autoPit: 0,
      autoShift: 0,
      brakeHelp: 0,
      drivingLine: 0,
      invulnerability: 0,
      oppositeLock: 0,
      spinRecovery: 0,
      stabilityControl: 0,
      steeringHelp: 0,
      tractionControl: 0,
      visFastLine: 0
    } as Assists]
  ]);

  const handleDefaultAssistChange = (value: string) => {
    const newAssists = defaultOptions.get(value);
    if (newAssists) {
      setAssists(newAssists);
      params.onChanged(newAssists);
    }
  }

  const getKey = (value: string, options: AssistOption[]) : number => {
    const option = options.find((option) => option.value === value);
    if (option) {
      return option.key;
    }
    return 0;
  }

  const getValue = (key: number, options: AssistOption[]): string => {
    const option = options.find(option => option.key === key);
    return option ? option.value : options[0].value; // Return the first option as default if not found
  }

  const handleAssistChange = (key: string, value: string, options: AssistOption[]) => {
    const newSetting: Partial<Assists> = { [key]: getKey(value, options)};
    const updatedAssists = { ...assists, ...newSetting };
    setAssists(updatedAssists);
    params.onChanged(updatedAssists);
  };

  useEffect(() => {
    if (params.assists) setAssists(params.assists);
  }, [params.assists]);

  return (
    <>
      <style>
        {`

          .dark-dropdown > * {
              background-color: #333333 !important;
              color: snow !important;
              border: 1px solid #454545;
              outline: none !important;
          }

          .dark-dropdown > * > *, .dark-dropdown > * > * > *, .dark-dropdown > * > * > * > * {
              background-color: #333333 !important;
              color: snow !important;
              outline: none !important;
          }
      `}
      </style>
    <Form.Group controlId="assists">
      <Row>
        <Col xs={2} className="ms-auto">
          <DropdownButton
            id="defaults-dropdown"
            title="Defaults"
            className="dark-dropdown"
            onSelect={(eventKey: string | null) => {
              if (eventKey) handleDefaultAssistChange(eventKey);
            }}
          >
            {defaultOptionNames.map((option) => (
              <Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Form.Label>Antilock Brakes</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.antiLockBrakes, lowHighOptions)} onChange={(e) => handleAssistChange("antiLockBrakes", e.target.value, lowHighOptions)}> 
            {lowHighOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Auto Blip</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.autoBlip, onOffOptions)} onChange={(e) => handleAssistChange("autoBlip", e.target.value, onOffOptions)}> 
            {onOffOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Auto Clutch</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.autoClutch, onOffOptions)} onChange={(e) => handleAssistChange("autoClutch", e.target.value, onOffOptions)}> 
            {onOffOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Auto Lift</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.autoLift, onOffOptions)} onChange={(e) => handleAssistChange("autoLift", e.target.value, onOffOptions)}> 
            {onOffOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Auto Pit</Form.Label>
          <Form.Control className="dark-select" as="select"  value={getValue(assists?.autoPit, onOffOptions)} onChange={(e) => handleAssistChange("autoPit", e.target.value, onOffOptions)}> 
            {onOffOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Auto Shift</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.autoShift, lowMedHighOptions)} onChange={(e) => handleAssistChange("autoShift", e.target.value, lowMedHighOptions) }> 
            {lowMedHighOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>
      
        <Col>
          <Form.Label>Brake Help</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.brakeHelp, lowHighOptions)} onChange={(e) => handleAssistChange("brakeHelp", e.target.value, lowHighOptions) }> 
            {lowHighOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Driving Line</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.drivingLine, lowMedHighOptions)} onChange={(e) => handleAssistChange("drivingLine", e.target.value, lowMedHighOptions) }> 
            {lowMedHighOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>
      </Row>

      <Row style={{ marginTop: "1rem" }}>
        <Col>
          <Form.Label>Invulnerability</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.invulnerability, onOffOptions)} onChange={(e) => handleAssistChange("invulnerability", e.target.value, onOffOptions) }> 
            {onOffOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Opposite Lock</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.oppositeLock, onOffOptions)} onChange={(e) => handleAssistChange("oppositeLock", e.target.value, onOffOptions) }> 
            {onOffOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Spin Recovery</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.spinRecovery, onOffOptions)} onChange={(e) => handleAssistChange("spinRecovery", e.target.value, onOffOptions) }> 
            {onOffOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Stability Control</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.stabilityControl, lowHighOptions)} onChange={(e) => handleAssistChange("stabilityControl", e.target.value, lowHighOptions) }> 
            {lowHighOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Steering Help</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.steeringHelp, lowMedHighOptions)} onChange={(e) => handleAssistChange("steeringHelp", e.target.value, lowMedHighOptions) }> 
            {lowMedHighOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Traction Control</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.tractionControl, lowMedHighOptions)} onChange={(e) => handleAssistChange("tractionControl", e.target.value, lowMedHighOptions) }> 
            {lowMedHighOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>

        <Col>
          <Form.Label>Vis Fast Line</Form.Label>
          <Form.Control className="dark-select" as="select" value={getValue(assists?.visFastLine, lowMedHighOptions)} onChange={(e) => handleAssistChange("visFastLine", e.target.value, lowMedHighOptions) }> 
            {lowMedHighOptions.map((option) => (
              <option key={option.key} value={option.value}>{option.value}</option>
            ))}
          </Form.Control>
        </Col>
        <Col/>
      </Row>
    </Form.Group>
    </>
  )
}

export default AssistsComponent;
