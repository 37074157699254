import React from "react";
import EventResultsClient from "../services/event-results.client";
import { EventResult } from "../types/event/EventResults";
import moment from "moment";
import withAuthentication from "../withAuthentication";
import { Driver } from "../types/event/Driver";
import { Col, Form, Row } from "react-bootstrap";
import NakamaClient from "../services/nakama.client";
import SafetyRankBadge from "../components/safteyRank";
import DriverRankBadge from "../components/driverRank";

const PlayersList = () => {
  const [players, setPlayers] = React.useState<Driver[]>([]);
  const [game, setGame] = React.useState<string>("lmu");
  const [search, setSearch] = React.useState<string>("");
  const [requiresReview, setRequiresReview] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [take, setTake] = React.useState<number>(20);
  const [loading, setLoading] = React.useState<boolean>(true);

  const client = new NakamaClient();
  const interval = React.useRef<any>(null);

  const fetch = async (game: string, search: string, requiresReview: boolean, page: number, take: number) => {
    await client.playersList(game, search, requiresReview, page, take)
      .then((results: Driver[]) => {
        setPlayers(results);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error(err);
      })
  }


  React.useEffect(() => {
    console.log("search updated");
    if (interval.current) clearInterval(interval.current);

    interval.current = setTimeout(async () => {
      if (page === 1) {
        fetch(game, search, requiresReview, 1, take);
      } else {
        setPage(1);
      }
    }, 500);

    return () => clearTimeout(interval.current);
  }, [search])

  React.useEffect(() => {
    console.log("game requires review, take updated");
    setPage(1);
  }, [game, requiresReview, take]);

  React.useEffect(() => { 
    console.log("page updated");
    fetch(game, search, requiresReview, page, take) 
  }, [page]);

  React.useEffect(() => { 
    console.log("page load");
    fetch("lmu", "", false, page, take) 
  }, []);


  return (
    <>
    <h1>Players</h1>
    <div className='container'>
      <Row>
        <Col>
          <Form.Group controlId="tierName">
            <Form.Label>Game:</Form.Label>
            <Form.Control as="select" value={game} onChange={(e) => setGame(e.target.value)} style={{marginBottom: "1rem"}}>
              <option value="">Please Select</option> 
              <option value="rf2" selected={game === "rf2"}>RF2</option>
              <option value="lmu" selected={game === "lmu"}>LMU</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="tierName">
            <Form.Label>Search:</Form.Label>
            <input type="text" name="type" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="tierName">
            <Form.Label>Needs Review:</Form.Label>
            <Form.Control as="select" value={requiresReview ? "true": "false"} onChange={(e) => setRequiresReview(e.target.value === "true")} style={{marginBottom: "1rem"}}>
              <option value="false">No</option>
              <option value="true">Yes</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col>
          <button className="btn btn-secondary mr2" style={{marginRight: "1rem", marginTop: "2rem"}} onClick={() => setPage(page - 1)} disabled={page === 1}>Previous</button>
          <span style={{marginTop: "2rem"}}>{page}</span>
          <button className="btn btn-secondary mr2" style={{marginLeft: "1rem", marginTop: "2rem"}} onClick={() => setPage(page + 1)} disabled={loading && players?.length < 20}>Next</button>
        </Col>
      </Row>
    </div>

    {loading && <p>Loading...</p>}

    {players?.length === 0 && <p>No players found</p>}
    {players?.length > 0 && (
      <table className="table table-dark">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Requires Review</th>
            <th scope="col">DR</th>
            <th scope="col">SR</th>
          </tr>
        </thead>
        <tbody>
        {players?.map((driver: Driver) => (
          <tr key={driver.id} onClick={() => window.location.href = `/players/view/${driver.id}/${game}`} style={{cursor: "pointer"}}>
            <td>
              <img style={{marginRight: "0.2rem"}} src={driver.avatar} height="23px" width="23px" />
              {driver.name}
            </td>
            
            <td scope="row">{driver.profile.email}</td>
            <td scope="row">{driver.profile.requiresReview ? "Yes" : "Nope"}</td>

            <td scope="row">
              <DriverRankBadge dr={driver.driverRank} />
            </td>

            <td>
              <SafetyRankBadge sr={driver.safetyRank} />
            </td>

          </tr>
        ))}
        </tbody>
      </table>
    )}
    </>
  );
};

export default withAuthentication(PlayersList);
