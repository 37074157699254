import { SafetyRank } from '../types/event/Ranks';

export interface SafetyRankProps {
  sr: SafetyRank | null | undefined;
}

const SafetyRankBadge = (props: SafetyRankProps) => {
  return !props.sr
    ? <></>
    :(<>
      <img alt="SR Badge" 
          style={{margin: "-10px 0.2rem", height: "50px", width: "50px"}} 
          src={`https://rf2-ui-images-prod.s3.eu-west-1.amazonaws.com/ranks/sr-lmu/${props.sr.rank}${props.sr.tier}-small.svg`} />

      {props.sr.rating.toFixed(1)}
      </>
    )
}

export default SafetyRankBadge;
