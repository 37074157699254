import { useNavigate, useParams } from "react-router-dom";
import { Entry } from "./entry";
import { useEffect, useRef, useState } from "react";
import LeaderboardsClient from "./leaderboards-service.client";
import { set } from "lodash";
import withAuthentication from "../../withAuthentication";
import { Modal } from "react-bootstrap";

const ViewLeaderboardEntries = () => {
  const client = useRef(new LeaderboardsClient());
  const { name } = useParams();

  const [entries, setEntries] = useState<Entry[]>([]);
  const [total, setTotal] = useState(0);
  const [entry, setEntry] = useState<Entry | undefined>();
  
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(20);

  useEffect(() => fetch(), [page, take]);
  useEffect(() => fetch(), []);

  const deleteEntry = async (id: string, entryId: string) => {
    await client.current.deleteEntry(id, entryId);
    const updatedEntries = entries.filter((entry) => entry.id !== entryId);
    fetch();
  }

  const deleteLeaderboard = async () => {
    if (!name) return;

    if (!window.confirm("Are you sure you want to delete this leaderboard?")) {
      return;
    }

    await client.current.delete(name);
    window.location.href = '/rm/leaderboards';
  }

  const edit = async (entry: Entry) => {
    await client.current.updateEntry(entry.leaderboard, entry);
  }

  const view = (entry: Entry) => {
    setEntry(entry);
  }

  const updatePage = (page: number) => {
    if (page < 1) return;
    if (page > Math.ceil(total / take)) return;
    
    setPage(page);
  }

  const fetch = () => {
    if (!name) {
      console.log('No leaderboard name provided');
      return;
    }

    client.current.entries(name, page, take).then((entries) => {
      setEntries(entries.entries);
      setTotal(entries.count);
    });
  }


  return (
    <>
    <div style={{margin: "2rem"}}>
      <h1>{name} ({total})</h1>
      <Modal show={entry != null} onHide={() => setEntry(undefined)} size="xl" className='modal-dark'>
        <Modal.Header closeButton className='modal-header-dark'>
          <Modal.Title>Editing: {entry?.name} </Modal.Title>
        </Modal.Header>

        <Modal.Body className='modal-body-dark'>
          <textarea style={{ width: "80%", height: "80vh" }} value={JSON.stringify(entry, null, 2)} onChange={(e) => setEntry(JSON.parse(e.target.value))} />
        </Modal.Body>

        <Modal.Footer>
          {entry && (
            <>
            <button style={{ marginRight: "20rem" }} className="btn btn-danger" onClick={() => deleteEntry(entry?.leaderboard, entry?.id)}>Delete</button>
            <button style={{ marginRight: "1rem" }} className="btn btn-secondary" onClick={() => setEntry(undefined)}>Close</button>
            <button className="btn btn-primary" onClick={() => edit(entry)}>Save</button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <div style={{ margin: "1rem 0 1rem 0" }}>
        <button style={{ marginRight: "1rem" }} className="btn btn-secondary" onClick={() => updatePage(page - 1)}>Previous</button>
        <button className="btn btn-secondary" onClick={() => updatePage(page + 1)}>Next</button>
      </div>

      <div style={{ margin: "1rem 0 1rem 0" }}>
        <button style={{ marginRight: "1rem" }} className="btn btn-danger" onClick={() => deleteLeaderboard()}>Delete Leaderboard</button>
      </div>

      <table className="table table-dark">
        <thead>
          <th>Position</th>
          <th>Id</th>
          <th>Time</th>
          <th>Name</th>
          <th>Score</th>
        </thead>
        <tbody>  
          {entries?.map((entry) => (
            <tr onClick={() => view(entry)}>
              <td>{entry.position}</td>
              <td>{entry.id}</td>
              <td>{entry.time.toString()}</td>
              <td>{entry.name}</td>
              <td>{entry.score.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default withAuthentication(ViewLeaderboardEntries);
