import { AgentStatusResponse } from '../types/AgentStatusResponse';
import { FleetAgent } from '../types/FleetAgent';
import { RequestServerConfiguration } from '../types/RequestServerConfiguration';
import { Server } from '../types/event/server';
import OAuthClient from './oauth.client';

class ServerManagerClient {
  url = process.env.REACT_APP_SM_URL;
  oauth: OAuthClient;

  constructor() {
    this.oauth = new OAuthClient();
  }

  async getServers(): Promise<Server[]> {
    const response = await this.oauth.fetch(`${this.url}/servers`)
      .then((response) => {
        if (!response.ok)
          throw new Error("Failed to fetch servers");

        return response;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });

    if (!response) return [];
    return await response?.json() as Server[];
  }

  async getFleetAgents(): Promise<FleetAgent[]> {
    const response = await this.oauth.fetch(`${this.url}/fleet`)
      .then((response) => {
        if (!response.ok)
          throw new Error("Failed to fetch fleet agents");

        return response;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
    
    if (!response) return [];
    return await response?.json() as FleetAgent[];
  }

  async stop(arn: string) : Promise<void> {
    await this.oauth.fetch(`${this.url}/server/stop`, "POST", JSON.stringify({ taskArn: arn }));
  }

  async agentStatus(server: Server) : Promise<AgentStatusResponse> {
    const response = await this.oauth.fetch(`${this.url}/agent/status/${server.taskArn}`, "GET");

    const responseJson = await response?.json();
    return responseJson.Message as AgentStatusResponse;
  }

  async request(config: RequestServerConfiguration) : Promise<Response> {
    return await this.oauth.fetch(`${this.url}/request`, "POST", JSON.stringify(config));
  }
}

export default ServerManagerClient;
