import { useEffect, useRef, useState } from "react";
import CoopClient from "./coop.client";
import { useNavigate } from "react-router-dom";
import { CoopSession } from "./coop";
import moment from "moment";

interface CoopSessionsProps {
  driverId?: string
  type: string
}

const ViewCoopSessions = (props: CoopSessionsProps) => {
  const client = useRef(new CoopClient());
  const navigate = useNavigate();

  const [sessions, setSessions] = useState<CoopSession[]>([]);

  useEffect(() => fetch(), [props.driverId]);
  useEffect(() => {}, [sessions]);

  const fetch = () => {
    if (!props.driverId) return;

    if (props.type === "historic") {
      client.current.historic(props.driverId, 1, 3).then((sessions) => {
        setSessions(sessions);
      });
    } else if (props.type === "invites") {
      client.current.invites(props.driverId).then((sessions) => {
        setSessions(sessions);
      });
    } else {
      client.current.sessions(props.driverId, 1, 3).then((sessions) => {
        setSessions(sessions);
      });
    }
  }

  const viewSession = (id:string) => {
    navigate('/rm/coop/session/' + id);
  }

  return (
    <>
    <table className="table table-dark">
      <thead>
        <tr>
          <th>Id</th>
          <th>Event Name</th>
          <th>Session Name</th>
          <th>Status</th>
          <th>Created</th>
          <th>Updated</th>
          <th>Drivers</th>
        </tr>
      </thead>
      <tbody>  
        {sessions && sessions?.length > 0 && sessions?.map((session) => (
          <tr onClick={() => viewSession(session.id)}>
            <td>{session.id}</td>
            <td>{session.eventName}</td>
            <td>{session.name}</td>
            <td>{session.status}</td>
            <td>{moment(session.created).fromNow()}</td>
            <td>{moment(session.updated).fromNow()}</td>
            <td>{session.drivers?.map((driver) => {
              return (
                <div style={{ marginBottom: "0.25rem" }}>
                  <img src={driver.avatar} alt={driver.name} style={{width: "25px", height: "25px"}} />
                  <span> {driver.name}</span>
                </div>
              )
            })}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </>
  )
}

export default ViewCoopSessions;