import React, { useEffect, useState, } from 'react';
import { Accordion, Badge, Button, Card, Col, Container, Dropdown, DropdownButton, Form, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap';

import { Driver } from '../types/event/Driver'

import moment from 'moment';
import { Protest } from '../types/Protest';
import ProtestPenaltySelectDriverComponent from './ProtestPenaltySelectDriverComponent';

export interface ProtestPenaltyOptionsComponentProps {
    driver: Driver | null | undefined;
    protest: Protest | null | undefined;

    onSelectPenaltyTarget: (target: "protestee" | "protester" | "") => void;

    onApplyStrike: () => void;
    onUndoStrike: () => void;

    onApplySuspension: (length: string) => void;
    onUndoSuspension: () => void;

    onAmendUserSafetyRating: (amount: number) => void;
    onUndoUserSafetyRatingAdjustment: () => void;
}

const ProtestPenaltyOptionsComponent = (props: ProtestPenaltyOptionsComponentProps) => {

    const formatHourString = (hours: string) => {
        let result = "";
        switch (hours) {
            case "24h":
                result = "1 Day";
                break;
            case "48h":
                result = "2 Days";
                break;
            case "168h":
                result = "7 Days";
                break;
            case "720h":
                result = "30 Days";
                break;
            case "4320h":
                result = "180 Days";
                break;
            case "867240h":
                result = "Permanently";
                break;
            default:
                result = "Unknown";
                break;
        }

        return result;
    }

    return (
        <>
            <style>
                {`
                `}
            </style>
            <Row>
                <ProtestPenaltySelectDriverComponent
                    driver={props?.driver}
                    protest={props?.protest}
                    onSelectPenaltyTarget={props?.onSelectPenaltyTarget}
                />
                {
                    props?.driver == void (0) ?
                        <Col sm="2" md="2" lg="2" xl="2" xxl="2" style={{ width: "fit-content", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            Select a driver to apply penalties
                        </Col>
                        :
                        <>
                            <Col sm="2" md="2" lg="2" xl="2" xxl="2" style={{ width: "fit-content", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {
                                    props?.protest?.suspensionApplied === true ?
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="top"
                                            overlay={
                                                <Popover>
                                                    <Popover.Header as="h3" style={{ backgroundColor: "#333333", color: "snow" }}>
                                                        Banned {formatHourString(props?.protest?.suspensionLength)}
                                                    </Popover.Header>
                                                    <Popover.Body style={{ backgroundColor: "#333333", color: "snow" }}>
                                                        <div className="row">
                                                            <Button variant="danger" size="sm" onClick={props?.onUndoSuspension}>
                                                                Undo ban
                                                            </Button>
                                                        </div>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Badge pill bg="danger" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000" }}>
                                                Banned {formatHourString(props?.protest?.suspensionLength)}
                                            </Badge>
                                        </OverlayTrigger>
                                        :
                                        <DropdownButton
                                            id="protest-view-ban-days-dropdown"
                                            title={"Ban"}
                                            variant={"danger"}>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onApplySuspension.bind(this, "24h")}>1 Day</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onApplySuspension.bind(this, "48h")}>2 Days</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onApplySuspension.bind(this, "168h")}>7 Days</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onApplySuspension.bind(this, "720h")}>30 Days</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onApplySuspension.bind(this, "4320h")}>180 Days</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onApplySuspension.bind(this, "867240h")}>Permanent</Dropdown.Item>
                                        </DropdownButton>
                                }
                            </Col>
                            <Col sm="2" md="2" lg="2" xl="2" xxl="2" style={{ width: "fit-content", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {
                                    props?.protest?.srReductionApplied === true ?
                                        <OverlayTrigger trigger="click" placement="top" overlay={
                                            <Popover>
                                                <Popover.Header as="h3" style={{ backgroundColor: "#333333", color: "snow" }}>
                                                    Safety Rating {props?.protest?.srReductionAmount}
                                                </Popover.Header>
                                                <Popover.Body style={{ backgroundColor: "#333333", color: "snow" }}>
                                                    <div className="row">
                                                        <Button variant="warning" size="sm" onClick={props?.onUndoUserSafetyRatingAdjustment}>
                                                            Undo SR Adjustment
                                                        </Button>
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                        >
                                            <Badge pill bg="warning" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", color: "black" }}>
                                                Safety Rating {props?.protest?.srReductionAmount}
                                            </Badge>
                                        </OverlayTrigger>
                                        :
                                        <DropdownButton id="protest-view-ban-days-dropdown" title={"SR Adjustment"} variant={"warning"}>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -5)}>-5</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -10)}>-10</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -15)}>-15</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -25)}>-25</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -50)}>-50</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -75)}>-75</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -100)}>-100</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -250)}>-250</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -500)}>-500</Dropdown.Item>
                                            <Dropdown.Item className="protest-view-ban-day-item" onClick={props?.onAmendUserSafetyRating.bind(this, -2000)}>-2000</Dropdown.Item>
                                        </DropdownButton>
                                }
                            </Col>
                            <Col sm="2" md="2" lg="2" xl="2" xxl="2" style={{ width: "fit-content", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {
                                    props?.protest?.strikeApplied === true ?
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="top"
                                            overlay={
                                                <Popover>
                                                    <Popover.Header as="h3" style={{ backgroundColor: "#333333", color: "snow" }}>
                                                        Strike Applied
                                                    </Popover.Header>
                                                    <Popover.Body style={{ backgroundColor: "#333333", color: "snow" }}>
                                                        <div className="row">
                                                            <Button variant="danger" size="sm" onClick={props?.onUndoStrike}>
                                                                Undo Strike
                                                            </Button>
                                                        </div>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Badge pill bg="warning" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", color: "black" }}>
                                                Strike Applied
                                            </Badge>
                                        </OverlayTrigger>
                                        :
                                        <Button variant="warning" size="sm" onClick={props?.onApplyStrike}>
                                            Apply Strike
                                        </Button>
                                }
                            </Col>
                        </>
                }
            </Row>
        </>
    );
}

export default ProtestPenaltyOptionsComponent;