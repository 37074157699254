import React from "react";
import { RaceControlClient } from "../services/race-control.client";
import moment from "moment";
import { EventSummary } from "../types/EventSummary";
import { Button, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { Championship } from "../championships/models/Championship";
import { ChampionshipsByIdResponse } from "../championships/responses/ChampionshipsByIdResponse";
import { eventTypes } from "../types/event/EventResults";
import { dailyTiers } from "../types/event/DailyRaceConfiguration";

const RaceControlStatus = () => {
  const rc = new RaceControlClient();
  const interval = React.useRef<any>(null);
  const upcomingAbort = React.useRef<AbortController | null>(null);
  const allAbort = React.useRef<AbortController | null>(null);
  const champAbort = React.useRef<AbortController | null>(null);

  const [events, setEvents] = React.useState<EventSummary[]>([]);
  const [championships, setChampionships] = React.useState<Championship[]>([]);
  
  const [allEvents, setAllEvents] = React.useState<EventSummary[]>([]);
  const [game, setGame] = React.useState<string>("");
  const [eventType, setEventType] = React.useState<string>("");
  const [eventTier, setEventTier] = React.useState<string>("");
  

  const fetchUpcomingEvents = async () => {
    if (upcomingAbort.current) {
      upcomingAbort.current.abort();
    }

    upcomingAbort.current = new AbortController();
    await rc.events(upcomingAbort.current.signal)
      .then((response: EventSummary[]) => {
        setEvents(response);
        interval.current = setTimeout(() => fetchUpcomingEvents(), 5000);
      })
      .catch((err: Error) => {
        console.error(err);
        interval.current = setTimeout(() => fetchUpcomingEvents(), 30000);
      });
  }

  const fecthAllEvents = async (game: string, type: string, tier: string) => {
    allAbort.current?.abort();

    upcomingAbort.current = new AbortController();
    await rc.allEvents(game, type, tier, upcomingAbort.current.signal)
      .then((response: EventSummary[]) => {
        setAllEvents(response);
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }

  const fetchActiveChampionships = async () => {
    champAbort.current?.abort();

    champAbort.current = new AbortController();
    await rc.getActiveChampionships("lmu", champAbort.current.signal)
      .then((response: Championship[]) => {
        setChampionships(response);
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }

  const viewChamp = (champId: string) => {
    window.location.href = `/championship/${champId}`;
  }

  React.useEffect(() => {}, [events])
  React.useEffect(() => {}, [allEvents])
  React.useEffect(() => {}, [championships])

  React.useEffect(() => {
    fecthAllEvents(game, eventType, eventTier);
  }, [game, eventType, eventTier]);

  React.useEffect(() => { 
    fetchUpcomingEvents(); 
    fecthAllEvents("", "", "");
    fetchActiveChampionships();

    return () => {
      clearTimeout(interval.current);
      
      upcomingAbort.current?.abort();
      allAbort.current?.abort();
      champAbort.current?.abort();
    }
  }, []);

  return (
    <>
      <Tab.Container defaultActiveKey="upcoming-events">
        <Nav variant="pills" className="flex">
          <Nav.Item>
            <Nav.Link eventKey="upcoming-events">Upcoming Events</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="all-events">All Events</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="active-championships">Active Championships</Nav.Link>
          </Nav.Item>
        </Nav>
      
        <Tab.Content>
          <Tab.Pane eventKey="upcoming-events">
            {renderEventsTable(events)}
          </Tab.Pane>

          <Tab.Pane eventKey="all-events">
            <Row>
              <Col sm={3}>
                <Form.Group controlId="game">
                  <Form.Label>Game:</Form.Label>
                  <Form.Control as="select" value={game} onChange={(e) => setGame(e.target.value)} style={{marginBottom: "1rem"}}>
                    <option value="">Please Select</option> 
                    <option value="rf2">RF2</option>
                    <option value="lmu">LMU</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group controlId="type">
                  <Form.Label>Type:</Form.Label>
                  <Form.Control as="select" value={eventType} onChange={(e) => setEventType(e.target.value)} style={{marginBottom: "1rem"}}>
                    <option value="">Please Select</option> 
                    {eventTypes.map((t) => (
                      <option value={t} selected={eventType === t}>{t}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group controlId="tier">
                  <Form.Label>Tier:</Form.Label>
                  <Form.Control as="select" value={eventTier} onChange={(e) => setEventTier(e.target.value)} style={{marginBottom: "1rem"}}>
                    <option value="">Please Select</option> 
                    {dailyTiers.map((t) => (
                      <option value={t} selected={eventTier === t}>{t}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group controlId="tier">
                  <Button className="btn btn-primary" onClick={() => fecthAllEvents(game, eventType, eventTier)} style={{marginTop: "2rem"}}>Refresh</Button>
                </Form.Group>
              </Col>

              <Col sm={3}></Col>
            </Row>
            {renderEventsTable(allEvents)}
          </Tab.Pane>

          <Tab.Pane eventKey="active-championships">
            <button className="btn btn-primary" onClick={() => fetchActiveChampionships()} style={{marginTop: "1rem", marginBottom: "1rem"}}>Refresh</button>

            <div className="tableFixHead small">
              <table className="table table-dark">
                <thead>
                  <tr>
                    <th scope="col">Game</th>
                    <th scope="col">Type</th>
                    <th scope="col">Tier</th>
                    <th scope="col">Name</th>
                    <th scope="col">Season</th>
                    <th scope="col">Starts</th>
                    <th scope="col">Ends</th>
                    <th scope="col">Weeks</th>
                    <th scope="col">Registrations</th>
                  </tr>
                </thead>
                <tbody>
                  {championships != undefined && championships.length > 0 
                    ? championships.map((champ) => (
                        <tr key={champ._id} onClick={(e) => viewChamp(champ._id)}>
                          <td>{champ.game}</td>
                          <td>{champ.type}</td>
                          <td>{champ.tier?.name}</td>
                          <td>{champ.name}</td>
                          <td>{champ.seasonName}</td>
                          <td>{moment(champ.dateFrom).format("DD/MM HH:mm")}</td>
                          <td>{moment(champ.dateTo).format("DD/MM HH:mm")}</td>
                          <td>{champ.weeks?.length}</td>
                          <td>{champ.registrations?.length}</td>
                        </tr>
                      ))
                    :
                      <tr>
                        <td colSpan={10}>No active championships</td>
                      </tr>
                  }
                </tbody>
              </table>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

const renderEventsTable = (events: EventSummary[]) => {
  const view = async (eventType: string, eventId: string) => {
    window.location.href = `/event/${eventType}/${eventId}`;
  }

  return (
    <div className="tableFixHead small">
      <table className="table table-dark">
        <thead>
          <tr>
            <th scope="col">Game</th>
            <th scope="col">Title</th>
            <th scope="col">Type</th>
            <th scope="col">Tier</th>
            <th scope="col">Track</th>
            <th scope="col">Starts</th>
            <th scope="col">Status</th>
            <th scope="col">Registrations</th>
            <th scope="col">Servers</th>
          </tr>
        </thead>
        <tbody>
          {
            events != undefined && events.length > 0 
              ? events.map((event) => (
                  <tr key={event.id} onClick={(e) => view(event.type, event.id)}>
                    <td>{event.game}</td>
                    <td>{event.title}</td>
                    <td>{event.type}</td>
                    <td>{event.tier}</td>
                    <td>{event.track}</td>
                    <td>{moment(event.startsAt).format("DD/MM HH:mm")}</td>
                    <td>{event.status}</td>
                    <td>{event.registrations}</td>
                    <td>{event.servers}</td>
                  </tr>
                ))
              :
                <tr>
                  <td colSpan={10}>No active events</td>
                </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

export default RaceControlStatus;
