import React, { useCallback } from 'react';
import { Navbar, Nav, Button, NavDropdown, Row } from 'react-bootstrap';
import { loginMethods, msalInstance } from './authConfig';
import { FaUser, FaUserShield, FaUserSlash } from 'react-icons/fa';
import { useAuth } from './AuthContext';

const identifierSelect = encodeURIComponent("http://specs.openid.net/auth/2.0/identifier_select");
const steamLoginURL = `https://steamcommunity.com/openid/login?openid.mode=checkid_setup&openid.ns=${encodeURIComponent("http://specs.openid.net/auth/2.0")}&openid.claimed_id=${identifierSelect}&openid.identity=${identifierSelect}&openid.return_to=${encodeURIComponent(`${process.env.REACT_APP_URL}/steamLogin`)}`;

const NavigationBar: React.FC = () => {
  const auth = useAuth();
  const { self } = auth;

  const views = Array<string>();
  React.useEffect(() => {

    const handleEvent = () => {
      const event = new CustomEvent('nav-views-event', { detail: { views } });
  
      window.dispatchEvent(event);
    };
  
    window.addEventListener('nav-views-request', handleEvent);
  
    return () => {
      window.removeEventListener('nav-views-request', handleEvent);
    };
  }, [views]);

  const doLogOut = useCallback(async () => {
    if (loginMethods.current === null) {
      return;
    }
    await loginMethods.current.logOut();
    auth.setIsAuthenticated(false);
  }, [auth.setIsAuthenticated]);

  const doMSLogIn = useCallback(async () => {
    await msalInstance.loginRedirect({
      scopes: ["User.Read"],
    });
  }, []);

  const linkStyle = (paths: string | string[]) => {
    if (self == void(0) || self?.roles == void(0) || self?.roles?.length === 0) {
      return {display: "none"};
    } else if (self?.roles?.map(r => r.name).includes("SuperAdmin")) {
      return {display: "block"};
    }
  
    const allowedViews = self?.roles?.map(r => r.allowedViews).flat();
    const display = Array.isArray(paths) 
      ? allowedViews?.some(v => paths.includes(v)) ? "block" : "none"
      : allowedViews?.includes(paths) ? "block" : "none";
  
    return {display: display};
  };

  const createNavLink = (title: string, path: string) => {

    views.push(path);

    return (
      <Nav.Link href={path} style={linkStyle(path)}>{title}</Nav.Link>
    );
  };
  
  const createNavDropdown = (title: string, hrefs: {path: string, name: string, divider?: boolean}[]) => {

    views.push(...hrefs.map(h => h.path));
    
    return (
      <NavDropdown
        style={linkStyle(hrefs.map(h => h.path))}
        title={title}
        menuVariant="dark"
        id="basic-nav-dropdown">
        {hrefs.map(h => (
          <>
            <NavDropdown.Item key={h.path} href={h.path} style={linkStyle(h.path)}>{h.name}</NavDropdown.Item>
            {h.divider && <NavDropdown.Divider />}
          </>
        ))}
      </NavDropdown>
    );
  };

  return (
    <>
      <style>
      {`
        .self-user-dropdown {
          color: snow;
          margin-right: 1rem;
        }

        .self-user-dropdown .dropdown-toggle::before {
          display: none;
        }

        .dropdown-title {
          display: flex;
          align-items: center;
        }

        .main-navbar {
          border-bottom: 1px solid rgba(255, 255, 255, 0.75);
          margin-bottom: 1rem;
        }

        .navbar-title {
          position: relative;
          padding-left: 1rem;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.8) !important;
        }

        .navbar-title:hover {
          position: relative;
          padding-left: 1rem;
          font-weight: bold;
          color: snow !important;
        }
      `}
      </style>
      <Navbar bg="dark" variant="dark" expand="lg" className="main-navbar">
        <Navbar.Brand className="navbar-title" title="Home" href="/">
          <img src={`${process.env.PUBLIC_URL}/racecontrol-logo.svg`} height="40px" width="150px" alt="Race Control Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {auth.isAuthenticated && (
              <>
                {createNavDropdown("Configurations", [
                  {path: "/rm/configs/dailies/list", name: "Daily Races"},
                  {path: "/rm/configs/specialEvents/list", name: "Special Events", divider: true},
                  {path: "/rm/configs/seasons/list", name: "Seasons | Champs"},
                  {path: "/rm/coop/events", name: "Coop Events"},
                  {path: "/rm/leaderboards", name: "Leaderboards", divider: true},
                  {path: "/cm/list", name: "Content Management"},
                  {path: "/notifications/global/list", name: "Global Notifications"},
                  {path: "/sm/launchserver", name: "Launch Server"}
                ])}
                
                {createNavDropdown("Player Management", [
                  {path: "/players/list", name: "Players"},
                  {path: "/protest/list", name: "Protests"},
                  {path: "/usernameReservations/list", name: "Username Reservations"}
                ])}

                {createNavDropdown("Statistics", [
                  {path: "/stats/overall", name: "Overall Stats"},
                  {path: "/stats/nakama", name: "Nakama Stats"},
                  {path: "/stats/fastest-laps", name: "Fastest Laps"}
                ])}
                {createNavDropdown("Security & Permissions", [
                  {path: "/permissions", name: "Permissions"},
                  {path: "/roles", name: "Roles"},
                  {path: "/routes", name: "Routes"},
                  {path: "/activations", name: "Activations"}
                ])}
              </>
            )}
          </Nav>
          {auth.isAuthenticated ? (
            <div className="ml-auto">
              <NavDropdown
              id="nav-dropdown-dark-example"
              title={
                self?.roles == void(0) || self?.roles?.length === 0 ?
                  <div className="dropdown-title">
                    <FaUserSlash size="1.5em" color='gray' style={{marginRight: "0.4rem"}}/>
                    {self?.name}
                  </div>
                :
                self?.roles?.map(r => r.name)?.includes("SuperAdmin") ?
                  <div className="dropdown-title">
                    <FaUserShield size="1.5em" color='red' style={{marginRight: "0.4rem"}}/>
                    {self?.name}
                  </div>
                :
                  <div className="dropdown-title">
                    <FaUser size="1.5em" color={self?.roles?.[0]?.colour} style={{marginRight: "0.4rem"}}/>
                    {self?.name}
                  </div>
              }
              menuVariant="dark"
              className="self-user-dropdown"
              drop={"start"}
              >
                <NavDropdown.Item onClick={doLogOut}>
                  Log out
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          ) : (
            <>
              <Button variant="outline-light" onClick={doMSLogIn}>
                Log in with MSG
              </Button>
              <Button variant="outline-light" href={steamLoginURL}>
                Log in with Steam
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavigationBar;
