export interface Protest {
    _id: string;

    game: string;

    timestamp: number;
    status: number; // Open, Closed

    eventType: string;
    eventId: string;
    seriesId: string;
    split: string;
    incidentTimestamp: number;

    protesterId: string;
    protesteeId: string;
    protesterUsername: string;
    protesteeUsername: string;

    protestType: number; // Report (behaviour), Protest (on track incident)
    protestReason: string; // Reason for protest

    outcome: number; // Accepted, Rejected, Dismissed

    suspensionApplied: boolean; // Ban (N days), SR Reduction (N points), Time Penalty (N seconds)
    suspensionLength: string; // Length of penalty (days, points, seconds)

    srReductionApplied: boolean;
    srReductionAmount: number;

    timePenaltyApplied: boolean;
    timePenaltyAmount: number;

    strikeApplied: boolean;

    notes: string; // Notes from protest committee
    judgement: string;
    updatedBy: string;
}

export interface GroupedProtests {
    protesteeId: string;
    protesteeUsername: string;

    protests: Protest[];
    count: number;
    punishmentCount: number;
    totalCount: number;

    newestProtestTimestamp: number;
}

// type for search parameters for Protests
export interface IProtestSearchParameters {
    game: string;
    
    text: string;

    timestampFrom: number;
    timestampTo: number;

    status: number; // Open, Closed
    protestType: number; // Report (behaviour), Protest (on track incident)
    outcome: number; // Accepted, Rejected, Dismissed

    priorityRecent: boolean;
    priorityHigh: boolean;

    priorityParameters: PrioritParameters;
    viewingGroupedProtests: boolean;
}

export interface PrioritParameters {
    numberProtests: number;
    numberPunishments: number;
}

export function defaultProtestSearchParameters(): IProtestSearchParameters {
    return {
        game: "all",

        text: "",

        timestampFrom: 0,
        timestampTo: 0,

        status: 0,
        protestType: 0,
        outcome: 0,

        priorityRecent: false,
        priorityHigh: false,

        priorityParameters: {
            numberProtests: 5,
            numberPunishments: 1
        },
        viewingGroupedProtests: false
    };
}

export enum ProtestStatus {
    Unknown = 0,
    Open = 1,
    Closed = 2
}

export enum ProtestType {
    Unknown = 0,
    Report = 1,
    Protest = 2
}

export enum ProtestOutcome {
    Unknown = 0,
    Accepted = 1,
    Rejected = 2,
    Dismissed = 3
}

export interface ManualBanUserRequest {
    banReference: string;
    targetUserId: string;
    protestType: number;
    duration: string;
    notes: string;
}

export interface SuspendUserRequest {
    targetUserId: string;
    protestType: number;
    duration: string;
    notes: string;
}

export interface ManualAmendUserSafetyRatingRequest {
    banReference: string;
    targetUserId: string;
    adjustment: number;
    notes: string;
}

export interface AmendUserSafetyRatingRequest {
    targetUserId: string;
    adjustment: number;
}

export interface ManualStrikeRequest {
    banReference: string;
    targetUserId: string;
    notes: string;
}

export interface StrikeRequest {
    targetUserId: string;
    notes: string;
}