import moment from "moment";

export class GlobalNotification {
    _id: string = "";
    enotificationType: number = 0;
    userType: number = 0;
    game: string = "";
    text: NotificationTranslationMap = {};
    image: string = "";
    link: Link[] = [];
    dateFrom: Date = moment().toDate();
    dateTo: Date = moment().add(1, "days").toDate();

    constructor(notification?: Partial<GlobalNotification>) {
        Object.assign(this, notification);
    }

    public getEnglshTitle(): string {
        // try "english" first and then just take the first one
        return this.text["english"]?.title || this.text[Object.keys(this.text)[0]]?.title || "No Title";
    }

    public getEnglishText(): string {
        // try "english" first and then just take the first one
        return this.text["english"]?.text || this.text[Object.keys(this.text)[0]]?.text || "No Text";
    }
}

export type NotificationTranslationMap = { [key: string]: NotificationText };

export interface NotificationText {
    title: string;
    text: string;
}

export interface Link {
    [key: string]: string;
    type: string;
    name: string;
    destination: string;
}


export class NotificationSearchParameters {
    text: string = "";
    dateFrom: Date = moment().toDate();
    dateTo: Date = moment().toDate();
    enotificationType: number = 0;
    userType: number = 0;
    game: string = "";
    title: string = "";
}

export enum enotificationType {
    Unknown = 0,
    Marketing = 1,
    RaceControl = 2
}

export enum userType {
    Unknown = 0,
    Free = 1,
    Subscribed = 2,
    Premium = 3,
    All = 4
}