import {AmendUserSafetyRatingRequest, IProtestSearchParameters, ManualAmendUserSafetyRatingRequest, ManualBanUserRequest, ManualStrikeRequest, Protest, StrikeRequest, SuspendUserRequest} from '../types/Protest';
import { AddUsernameReservationRequest, ChangeUsernameRequest, NullifyPIIRequest, UndoUsernameViolationRequest, UsernameViolationRequest} from '../types/User';
import {Driver} from '../types/event/Driver';
import OAuthClient from './oauth.client';

class UserServiceClient {
    url = process.env.REACT_APP_RM_URL;
    oauth: OAuthClient;

    constructor() {
      this.oauth = new OAuthClient();
    }

    get(id: string, game: string): Promise<Driver> {
      return new Promise<any>((resolve, reject) => {
        this.oauth.fetch(`${this.url}/user/${id}?game=${game}`, "GET")
        .then(res => res.json())
        .then(res => resolve(new Driver(res)))
        .catch(reject);
      });
    }

    applySafetyRatingAdjustment(safetyRatingAdjustment: ManualAmendUserSafetyRatingRequest, game: string): Promise<any> {
      return this.oauth.fetch(`${this.url}/user/amendSafetyRating?game=${game}`, "POST", JSON.stringify(safetyRatingAdjustment))
        .then(res => res.json());
    }

    undoSafetyRatingAdjustment(driverId: string, banReference: string, game: string): Promise<any> {
      return new Promise<any>((resolve, reject) => {
        this.oauth.fetch(`${this.url}/user/undoAmendSafetyRating/${driverId}/${banReference}?game=${game}`)
        .then(res => resolve(res.json()))
        .catch(reject);
      });
    }

    applyStrike(strike: ManualStrikeRequest, game: string): Promise<any> {
      return this.oauth.fetch(`${this.url}/user/strike?game=${game}`, "POST", JSON.stringify(strike))
        .then(res => res.json());
    }

    undoStrike(driverId: string, banReference: string, game: string): Promise<any> {
      return this.oauth.fetch(`${this.url}/user/undoStrike/${driverId}/${banReference}?game=${game}`)
        .then(res => res.json());
    }

    banUser(suspension: ManualBanUserRequest, game: string): Promise<any> {
      return this.oauth.fetch(`${this.url}/user/ban?game=${game}`, "POST", JSON.stringify(suspension))
        .then(res => res.json());
    }

    undoBan(driverId: string, banReference: string, game: string): Promise<any> {
      return this.oauth.fetch(`${this.url}/user/undoBan/${driverId}/${banReference}?game=${game}`)
        .then(res => res.json());
    }

    nullifyAccount(nullifyPII: NullifyPIIRequest, game: string): Promise<any> {
      return this.oauth.fetch(`${this.url}/user/nullify_pii?game=${game}`, "POST", JSON.stringify(nullifyPII))
        .then(res => res.json());
    }

    manualNameChange(driverId: string, newUsername: string, reason: string, game: string): Promise<any> {

      const request: ChangeUsernameRequest = {
        userId: driverId,
        newUsername: newUsername,
        notes: reason,
        reference: ""
      };

      return this.oauth.fetch(`${this.url}/user/changeName?game=${game}`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    usernameViolation(driverId: string, resetUsername: boolean, suspend: boolean, suspensionDelay: number, reason: string, game: string): Promise<any> {
      const request: UsernameViolationRequest = {
        userId: driverId,
        resetUsername: resetUsername,
        suspend: suspend,
        suspensionDelay: suspensionDelay,
        notes: reason,
        reference: ""
      };

      return this.oauth.fetch(`${this.url}/user/usernameViolation?game=${game}`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }

    undoUsernameViolation(driverId: string, reference: string, game: string): Promise<any> {
      const request: UndoUsernameViolationRequest = {
        userId: driverId,
        reference: reference
      };

      return this.oauth.fetch(`${this.url}/user/undoUsernameViolation?game=${game}`, "POST", JSON.stringify(request))
        .then(res => res.json());
    }
}

export default UserServiceClient;