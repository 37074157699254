import React, { useEffect, useState } from 'react';
import withAuthentication from '../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import NotificationServiceClient from '../services/notification-service.client';
import { GlobalNotification, NotificationSearchParameters, enotificationType, userType } from './models/models';
import GlobalNotificationListViewItem from './GlobalNotificationListViewItem';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs';
import { Moment } from 'moment';
import GlobalNotificationViewComponent from "./GlobalNotificationViewComponent";
import moment from 'moment';

const GlobalNotificationListView = () => {
  const [page, setPage] = useState<number>(0);
  const notificationService = new NotificationServiceClient();
  const [notification, setNotification] = useState<GlobalNotification | null>();
  const [showModal, setShowModal] = useState<boolean>();
  const [globalNotifications, setNotifications] = useState<GlobalNotification[]>([]);
  const [searchParams, setSearchParams] = useState<NotificationSearchParameters | null>(null);

  useEffect(() => {
    resetSearchParameters();
  }, []);
  
  const openModal = (notificationId: string) => {
    return new Promise((resolve, reject) => {
      notificationService.getNotificationById(notificationId || "")
        .then(p => {
          setNotification(p);
          setShowModal(true);
        })
        .catch(error => {
          console.error(error);
          setShowModal(false);
          reject(error);
        });
    });
  }

  const openModalNew = () => {
    setNotification(null);
    setShowModal(true);
  }

  const closeModal = () => {
    setNotification(null);
    setShowModal(false);
    fetch();
  }

  useEffect(() => {
    fetch();
  }, [page, searchParams]);

  const nextPage = () => {
    const notificationsFound = globalNotifications?.length ?? 0;
    if (notificationsFound == 15) {
      setPage(page + 1);
    }
  }

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  }

  let initalRequestCompleted = false;
  const fetch = () => {
    initalRequestCompleted = true;
    if (searchParams)
      notificationService.getFilteredNotifications(searchParams, page)
          .then(p => setNotifications(p))
          .catch(error => {
            console.error(error);
            setNotifications([]);
          });
    else
      notificationService.GetAllGlobalNotifications(page)
          .then(p => setNotifications(p))
          .catch(error => {
            // handle any error state, rejected promises, etc..
          });
  }

  const getLatest = () => {
    initalRequestCompleted = true;
    notificationService.GetLatestGlobalNotifications(page)
        .then(p => setNotifications(p))
        .catch(error => {
          // handle any error state, rejected promises, etc..
        });
  }

  const setNotificationTypeSearchParameter = (notificationType: number) => {
    let params = searchParams ? {...searchParams} : new NotificationSearchParameters();
    params.enotificationType = notificationType;
    setSearchParams(params);
  }

  const setNotificationGameSearchParameter = (game: string) => {
    let params = searchParams ? {...searchParams} : new NotificationSearchParameters();
    params.game = game;
    setSearchParams(params);
  }

  const setNotificationUserTypeSearchParameter = (notificationUserType: number) => {
    let params = searchParams ? {...searchParams} : new NotificationSearchParameters();
    params.userType = notificationUserType;
    setSearchParams(params);
  }

  const setNotificationTextSearchParameter = (text: string) => {
    let params = searchParams ? {...searchParams} : new NotificationSearchParameters();
    params.text = text;
    setSearchParams(params);
  }

  const setNotificationDateFromSearchParameter = (dateFrom: string | Moment) => {
    let params = searchParams ? {...searchParams} : new NotificationSearchParameters();
    if (typeof(dateFrom) === "object") {
      params.dateFrom = (dateFrom as Moment).toDate();
    }

    setSearchParams(params);
  }

  const setNotificationDateToSearchParameter = (dateTo: string | Moment) => {
    let params = searchParams ? {...searchParams} : new NotificationSearchParameters();
    if (typeof (dateTo) === "object") {
      params.dateTo = (dateTo as Moment).toDate();
    }

    setSearchParams(params);
  }

  const resetSearchParameters = () => {
    setSearchParams({
      text: "",
      dateFrom: new Date("0001-01-01T00:00:00Z"),
      dateTo: new Date("0001-01-01T00:00:00Z"),
      enotificationType: 0,
      userType: 0,
      game: "",
      title: ""
    });

    // Clear the date picker values
    const dateFromPicker = document.getElementById("notification-date-from") as HTMLInputElement;
    const dateToPicker = document.getElementById("notification-date-to") as HTMLInputElement;
    if (dateFromPicker) {
      dateFromPicker.value = "";
    }
    if (dateToPicker) {
      dateToPicker.value = "";
    }

    // Clear the text search boxes
    const textSearchBox = document.getElementById("notification-text-search") as HTMLInputElement;
    const titleSearchBox = document.getElementById("notification-title-search") as HTMLInputElement;
    if (textSearchBox) {
      textSearchBox.value = "";
    }
    if (titleSearchBox) {
      titleSearchBox.value = "";
    }
  };

  const openInNewTab = (id: String) => {
    if (id != void (0) && id) {
      window.open(`${window.location.origin}/notifications/global/${id}`, "_blank");
    }
  }

  return (
      <>
        <style>
          {`
          .notification-search-bar * {
            color: snow !important;
          }

          .notification-search-bar-text-field {
            background-color: #333333 !important;
            color: snow !important;
            border-color: #333333 !important;
          }

          
          .notification-timepicker-container * {
            padding-right: 1%;
            color: snow !important;
            background-color: #333333 !important;
            border-color: #333333 !important;
     
            }
        `}
        </style>
        <div className="container">
          <div className="row">
            <h1></h1>
          </div>

          <Modal show={showModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

              <GlobalNotificationViewComponent
                notification={notification}
                mode={notification ? "edit" : "create"}
                onSaved={closeModal}
                setShowModal={setShowModal.bind(this, false)}
              />

          </Modal>


          <div className="row">
            <Navbar bg="dark" expand="lg" className="protest-search-bar">
              <Container fluid>
                <NavDropdown style={{paddingRight: "1%"}}
                             id="protest-search-bar-type-dropdown"
                             title={
                               searchParams?.game === "rf2" ?
                                   "RF2"
                                   :
                                   searchParams?.game === "lmu" ?
                                       "LMU"
                                       : "Game"
                             }
                             menuVariant="dark"
                >
                  <NavDropdown.Item onClick={setNotificationGameSearchParameter.bind(this, "rf2")}>
                    RF2
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={setNotificationGameSearchParameter.bind(this, "lmu")}>
                    LMU
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={setNotificationGameSearchParameter.bind(this, "all")}>
                    All
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown style={{paddingRight: "1%"}}
                             id="protest-search-bar-type-dropdown"
                             title={
                               searchParams?.enotificationType === enotificationType.Marketing ?
                                   "Marketing"
                                   :
                                   searchParams?.enotificationType === enotificationType.RaceControl ?
                                       "RaceControl"
                                       : "Notif. Type"
                             }
                             menuVariant="dark"
                >
                  <NavDropdown.Item onClick={setNotificationTypeSearchParameter.bind(this, enotificationType.Marketing)}>
                    Marketing
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={setNotificationTypeSearchParameter.bind(this, enotificationType.RaceControl)}>
                    RaceControl
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={setNotificationTypeSearchParameter.bind(this, enotificationType.Unknown)}>
                    Any Type
                  </NavDropdown.Item>
                </NavDropdown>

                <Form className="d-flex">
                  <Form.Control
                      type="search"
                      placeholder="Notification Text"
                      className="me-2 notification-search-bar-text-field"
                      aria-label="Search"
                      id="notification-text-search"
                      onChange={(e) => setNotificationTextSearchParameter(e.target.value)}
                  />
                </Form>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                      className="me-auto my-2 my-lg-0"
                      style={{ maxHeight: '100px' }}
                      navbarScroll
                  >
                    <NavDropdown
                        id="notification-search-bar-usertype-dropdown"
                        title={
                          <Badge bg={searchParams?.userType === userType.Subscribed ? "Subscribed" : searchParams?.userType === userType.Free ? "danger" : "secondary"} pill>
                            {searchParams?.userType === userType.Subscribed ?
                                "Subscribed"
                                :
                                searchParams?.userType === userType.Free ?
                                    "Free"
                                    :
                                    searchParams?.userType === userType.Premium ?
                                        "Premium"
                                        :
                                    "Any User Type"
                            }
                          </Badge>
                        }
                        menuVariant="dark"
                    >
                      <NavDropdown.Item onClick={setNotificationUserTypeSearchParameter.bind(this, userType.Free)}>
                          Free
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={setNotificationUserTypeSearchParameter.bind(this, userType.Subscribed)}>
                        Subscribed
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={setNotificationUserTypeSearchParameter.bind(this, userType.Premium)}>
                        Premium
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={setNotificationUserTypeSearchParameter.bind(this, userType.Unknown)}>
                        Any User Type
                      </NavDropdown.Item>
                    </NavDropdown>

                    <div className="row me-auto" style={{width: "fit-content", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                      <span>From:</span>
                    </div>
                    <div className="notification-timepicker-container">
                      <Datetime
                          onChange={setNotificationDateFromSearchParameter.bind(this)}
                          inputProps={{ id: "notification-date-to" }} // Pass the id as inputProps
                      />
                    </div>
                    <div className="row me-auto" style={{width: "fit-content", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                      <span>To:</span>
                    </div>
                    <div className="notification-timepicker-container">
                      <Datetime
                          onChange={setNotificationDateToSearchParameter.bind(this)}
                          inputProps={{ id: "notification-date-from" }} // Pass the id as inputProps
                      />
                    </div>
                  </Nav>
                </Navbar.Collapse>
                <Button variant="outline-success" onClick={resetSearchParameters}>Reset Filters</Button>
                <Button variant="primary" onClick={() => openModalNew()}>New</Button>
                <Button variant="primary" onClick={getLatest}>Recent</Button>


              </Container>
            </Navbar>
          </div>
          <div className="row">
            <ListGroup style={{paddingRight: "0px"}}>
              {globalNotifications?.map(p => (
                  <GlobalNotificationListViewItem
                      notification={p}
                      onClick={(id: string, e: React.MouseEvent<Element, MouseEvent>) => {
                        console.log(e);
                        switch(e?.button)
                        {
                            // Left click
                          case 0:
                            openModal(id);
                            break;
                            // Middle click
                          case 1:
                            //openInNewTab(id);
                            break;
                          default:
                            break;
                        }
                      }}
                  />
              ))}
            </ListGroup>
          </div>
          <div className="row">
            <Navbar bg="dark" expand="lg" className="protest-search-bar">
              <Container fluid>
                <Navbar.Collapse id="navbarScroll">
                  <Button variant="outline-secondary"
                          hidden={page === 0}
                          onClick={prevPage} style={{height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                    <BsCaretLeftFill size="1em" color="lightgrey"/>
                  </Button>
                </Navbar.Collapse>
                <Button variant="outline-secondary"
                        hidden={globalNotifications?.length < 15}
                        onClick={nextPage} style={{height: "100%", display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
                  <BsCaretRightFill size="1em" color="lightgrey"/>
                </Button>
              </Container>
            </Navbar>
          </div>
        </div>

      </>
  );
};

export default withAuthentication(GlobalNotificationListView);