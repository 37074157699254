import React, { useRef, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Button, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import { HttpProtectedRole, HttpProtectedRoute, HttpProtectedRouteViewModel, HttpProtectedUser } from '../../types/Security';
import SecurityServiceClient from '../../services/security-service.client';
import { FaMinus, FaPlus, FaUsers } from "react-icons/fa";

interface IRoutesListViewModalProps {
    show: boolean;
    setShowModal: (show: boolean) => void;
    route: HttpProtectedRouteViewModel;
    allRoles: Array<HttpProtectedRole>;
    requestRefresh?: (indices: Array<number>) => void;
}

const RoutesListViewModal: React.FC<IRoutesListViewModalProps> = (props) => {

    const securityService = new SecurityServiceClient();

    // on mount
    React.useEffect(() => {
    }, [])

    const removeRole = (roleName: string) => {
        if (roleName === "SuperAdmin") {
            return;
        }

        securityService.removeRoleFromRoute(props?.route?.id, roleName).then(() => props?.requestRefresh?.([]));
    }

    const addRole = (roleName: string) => {
        securityService.addRoleToRoute(props?.route?.id, roleName).then(() => props?.requestRefresh?.([]));
    }

    return (
        <>
            <style>
            {`
                .protected-user-view-modal-body {
                    color: snow;
                    background-color: #222222;
                }

                .role-select-list-group-header-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2em;
                }

                .role-select-list-group-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .role-select-list-group-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid #333333;
                }

                .role-select-list-group-item-row {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .role-select-list-group-item-icon-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .role-select-btn-minus {
                    visibility: hidden;
                    border: 0;
                }

                .role-select-btn-plus {
                    visibility: hidden;
                    border: 0;
                }

                .role-select-list-group-item:hover > * > * > .role-select-btn-minus {
                    visibility: visible;
                    background-color: red;
                    border: 0;
                }

                .role-select-list-group-item:hover > * > * > .role-select-btn-plus {
                    visibility: visible;
                    background-color: #0d6efd;
                    border: 0;
                }

                .role-select-btn-minus::focus, .role-select-btn-plus::focus {
                    outline: none;
                }
            `}
            </style>
            <div className="row">
                <div className="col-12">
                    <Modal show={props?.show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

                        <Modal.Header style={{ backgroundColor: "#222222", borderColor: "#333333", color: "snow" }} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row>
                                    <Col>
                                    <h4 style={{ color: "snow" }}>{props?.route?.path}</h4>
                                    <h5 style={{ color: "rgba(255, 255, 255, 0.5)" }}>
                                        {props?.route?.methods?.join(", ")}
                                    </h5>
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="protected-user-view-modal-body">
                            <Row>
                                <Col className="role-select-list-group-header-col">
                                    Available Roles
                                </Col>
                                <Col className="role-select-list-group-header-col">
                                    Authorised Roles
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ListGroup>
                                        {
                                            props?.allRoles?.filter(r => !props?.route?.allowedRoles?.map(ar => ar.name)?.includes(r.name))?.map((role, index) => {
                                                return (
                                                    <ListGroup.Item className="role-select-list-group-item" key={index}>
                                                        <Row className="role-select-list-group-item-row">
                                                            <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="role-select-list-group-item-icon-col">
                                                                <FaUsers color={role?.colour}/> 
                                                            </Col>
                                                            <Col sm="10" md="10" lg="10" xl="10" xxl="10">
                                                                {role?.name}
                                                            </Col>
                                                            <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="role-select-list-group-item-icon-col">
                                                                <Button onClick={() => addRole(role?.name)} className="role-select-btn-plus" variant="primary">
                                                                    <FaPlus color="snow"/>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                );
                                            })
                                        }
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <ListGroup>
                                        {
                                            props?.route?.allowedRoles?.map((role, index) => {
                                                return (
                                                    <ListGroup.Item className="role-select-list-group-item" key={index}>
                                                        <Row className="role-select-list-group-item-row">
                                                            <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="role-select-list-group-item-icon-col">
                                                                <FaUsers color={role?.colour}/> 
                                                            </Col>
                                                            <Col sm="10" md="10" lg="10" xl="10" xxl="10">
                                                                {role?.name}
                                                            </Col>
                                                            <Col sm="1" md="1" lg="1" xl="1" xxl="1" className="role-select-list-group-item-icon-col">
                                                                {
                                                                    role?.name !== "SuperAdmin" &&
                                                                        <Button onClick={() => removeRole(role?.name)} className="role-select-btn-minus" variant="danger">
                                                                            <FaMinus color="snow"/>
                                                                        </Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                );
                                            })
                                        }
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer style={{ backgroundColor: "#222222", borderColor: "#333333" }}>
                            <Button onClick={() => props?.setShowModal(false)}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default withAuthentication(RoutesListViewModal);
