import { useEffect, useRef, useState } from "react";
import { EventResult, RaceResult, RaceResults, Session } from "../../types/event/EventResults";
import withAuthentication from "../../withAuthentication";
import EventResultsClient from "../../services/event-results.client";
import { Container, Nav, Tab } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import RaceManagementClient from "../../services/race-manaement.client";
import { S3Object } from "../../types/S3Object";
import { RankHistory } from "../../types/event/RankChange";
import ResultTable from "./ResultTable";

const ViewRaceResult = () => {
  const eventService = useRef(new EventResultsClient());
  const rm = useRef(new RaceManagementClient());
 
  const [eventResult, setEventResult] = useState<EventResult>();
  const [files, setFiles] = useState<S3Object[]>([]);
  const [rankChanges, setRankChanges] = useState<RankHistory>({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    eventService.current.get(params.get('seriesId'), params.get('eventId'), params.get('splitNo'))
      .then(async (result) => {
        setEventResult(result);
        
        const files = await rm.current.getS3BucketContent(process.env.REACT_APP_S3_RESULTS_BUCKET ?? "rf2-race-results", `${result.eventType}/${result.seriesId}/${result.eventId}/${result.splitNo}`);
        setFiles(files);
      })
      .then(async () => rm.current.rankChangesForEvent(params.get('eventId') ?? ""))
      .then((rankChanges) => {
        console.log(rankChanges);
        setRankChanges(rankChanges);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return !eventResult ? (
    <h1>Loading...</h1>
  ) : (
    <div style={{margin: "2rem"}}>
    <Tab.Container defaultActiveKey="race">
      <h1>{eventResult.eventType}: {eventResult.title} [#{eventResult.splitNo}]</h1>
      <h3>{eventResult.track}</h3>
      <h4>{eventResult.seriesId}/{eventResult.eventId}/{eventResult.splitNo}</h4>

      <Row style={{marginTop: "2rem"}}>
        <Col lg={9}>
          <Nav variant="pills" className="flex">
            <Nav.Item>
              <Nav.Link eventKey="practice">Practice</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="qualifying">Qualifying</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="race">Race</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col lg={3}>
          
        </Col>
      </Row>

      
      <Tab.Content>
        <Tab.Pane eventKey="practice">
          {renderRaceResults(eventResult?.practice, files, rankChanges)}
        </Tab.Pane>

        <Tab.Pane eventKey="qualifying">
          {renderRaceResults(eventResult?.qualifying, files, rankChanges)}
        </Tab.Pane>

        <Tab.Pane eventKey="race">
          {renderRaceResults(eventResult?.race, files, rankChanges)}
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
    </div>
  );
}

function renderRaceResults(result: RaceResults | null, files: S3Object[], rankChanges: RankHistory) {
  const rm = new RaceManagementClient();

  const download = (file: S3Object) => {
    rm.downloadFromS3(process.env.REACT_APP_S3_RESULTS_BUCKET ?? "rf2-race-results", file.Key);
  }

  const replay = (et: string) => {
    fetch("http://localhost:5397/rest/replay/replaytime", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: et,
    })
      .then((response) => {
        if (!response.ok) {
          console.error("There was a problem with the fetch operation:", response);
        }
        console.log("replay moved?")
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  return (
    <Row>
      <Col lg={5}>
        <ResultTable results={result?.races[0]?.results} rankChanges={rankChanges} />
      </Col>
      <Col lg={2}>
        <h3>Chat</h3>
        <ul>
          {result?.races[0]?.streams[0]?.chats?.map((c, index) => (<li key={index}>{c.description}</li>))}
        </ul>
      </Col>
      <Col lg={3}>
        <h3>Incidents</h3>
        <ul>
          {result?.races[0]?.streams[0]?.incidents?.map((c, index) => (<li key={index} onClick={() => replay(c.et)}>{c.et} {c.description}</li>))}
        </ul>
      </Col>
      <Col lg={2}>
        <h4>Files</h4>
        {files.map((f, index) => (
          <div key={index}>
            <a href="#" onClick={() => download(f)}>{f.Key.split('/').pop()}</a>
          </div>
        ))}
      </Col>
    </Row>
  )
}


export default withAuthentication(ViewRaceResult);