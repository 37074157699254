import React, { useState } from "react";
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { Season } from "../models/Season";
import withAuthentication from "../../withAuthentication";
import ChampionshipServiceClient from "../../services/championship-service.client";
import { SeasonDatesValidResponse } from "../responses/SeasonDatesValidResponse";
import EditSeasonFormComponent from "./SeasonEditFormComponent";
import { Championship } from "../models/Championship";

const CopySeasonView = () => {

    const championshipService = new ChampionshipServiceClient();
    const navigate = useNavigate();

    const { seasonId } = useParams<{ seasonId: string }>();
    const [seasonLoaded, setSeasonLoaded] = useState<boolean>(false);

    const [seasonSaveError, setSeasonSaveError] = useState<string>("");
    const [seasonDatesValid, setSeasonDatesValid] = useState<boolean>(true);
    const [season, setSeason] = useState<Season>({
        id: "",
        game: "",
        name: "",
        dateFrom: "",
        dateTo: "",
        championships: []
    });

    React.useEffect(() => {
        if (seasonId == void (0)) return;

        if (seasonId?.length > 0 && !seasonLoaded) {
            championshipService.getSeason(seasonId)
                .then((season: Season) => {
                    setSeason({ ...season, id: "" });
                    setSeasonLoaded(true);
                });
        }
    }, [seasonId, seasonLoaded]);


    React.useEffect(() => {
        if (!isValid(season)) {
            return;
        }

        championshipService.seasonDatesValid(season)
            .then((res: SeasonDatesValidResponse) => {
                setSeasonDatesValid(res?.valid);
            });

    }, [season]);

    const save = async () => {
        if (!isValid(season) || seasonId == void(0) || seasonId?.length === 0) {
            return;
        }

        season.championships = [];
        const championships = await championshipService?.getChampionshipsBySeasonId(seasonId).then((championships: Array<Championship>) => {
            return championships?.map((championship: Championship) => {
                return { ...championship, id: "" };
            });
        }).catch((err) => console.error(err));


        setSeasonSaveError("");
        championshipService.createSeason(season)
            .then((duplicatedSeasonId: string) => {
                if(duplicatedSeasonId == void(0) || duplicatedSeasonId.length == 0) {
                    setSeasonSaveError("Failed to save season");
                    return;
                }

                championships?.forEach((championship: Championship) => {
                    championshipService.addChampionshipToSeason(duplicatedSeasonId, championship).catch((err) => console.error(err));
                });

                navigate(`/rm/configs/seasons/list`);
            }).catch((err) => {
                setSeasonSaveError("Failed to save season");
            });
    }

    const isValid = (season: Season) => {
        return season.game.length > 0 && season.name.length > 0 && season.dateFrom.length > 0 && season.dateTo.length > 0
    }

    return (
        <>
            <style>
            {`
                .seasons-header {
                    background-color: #f8f9fa;
                    color: black;
                    border-bottom: 1px solid #dee2e6;
                    font-weight: bold;
                    padding: 0.5rem 0;
                    margin-bottom: 1rem;
                }
                
                .seasons-header-item {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .seasons-header-item-start {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center;
                }

                .seasons-header-item-end {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end !important;
                    align-items: center;
                }
            `}
            </style>
            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row>
                    <Col className="seasons-header-item-start">
                        <h3>Create New Season</h3>
                    </Col>
                </Row>
                {
                    seasonLoaded ? (
                        <Row>
                            <Col>
                                <Row>
                                    <EditSeasonFormComponent
                                        season={season}
                                        dateValidity={seasonDatesValid}
                                        onUpdate={setSeason}
                                        saveButton={
                                            <Row>
                                                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button variant="success" onClick={save}>Save</Button>
                                                </Col>
                                            </Row>
                                        }
                                    />
                                </Row>
                                <Row>
                                    <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {
                                            seasonSaveError != void (0) && seasonSaveError?.length > 0 ? (
                                                <p style={{ color: "red" }}>{seasonSaveError}</p>
                                            ) : <></>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className="d-flex flex-column justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
                                <Spinner animation="border" style={{ width: '7vh', height: '7vh' }} />
                                <div className="text-center">
                                    <p>Loading...</p>
                                </div>
                            </Col>
                        </Row>
                    )
                }
            </Container>
        </>
    )
}

export default withAuthentication(CopySeasonView);
