import { useEffect, useRef, useState } from "react";
import CoopClient from "./coop.client";
import { useNavigate, useParams } from "react-router-dom";
import { CoopSession, TeamMember } from "./coop";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

interface CoopSessionsProps {
  driverId?: string
  type: string
}

const ViewCoopSession = (props: CoopSessionsProps) => {
  const client = useRef(new CoopClient());
  const { id } = useParams();
  const navigate = useNavigate();

  const [session, setSession] = useState<CoopSession | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => fetch(id), []);
  useEffect(() => {}, [session]);

  const fetch = (id: string | undefined) => {
    if (!id) return;

    client.current.session(id)
      .then((session) => {
        setSession(session);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const viewDriver = (driverId: string) => {
    navigate('/players/view/' + driverId + "/lmu");
  }

  const download = () => {
    if (!session) return;

    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(session?.gameState.saveJson, null, 2)], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = session?.id + ".json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const getDriver = (driverId: string) : TeamMember | undefined => {
    return session?.drivers?.find((driver) => driver.driverId === driverId);
  }

  return (
    <div className="full-screen-container">
      {loading 
        ? <h1>Loading...</h1>
        : <>
          <h1>Session: {session?.name}</h1>
          <Row>
            <Col>
              <h2>Session Details</h2>
              <ul>
                <li>Id: {session?.id}</li>
                <li>Event Name: {session?.eventName}</li>
                <li>Session Name: {session?.name}</li>
                <li>Status: {session?.status}</li>
                <li>Created: {moment(session?.created).fromNow()}</li>
                <li>Updated: {moment(session?.updated).fromNow()}</li>
              </ul>
            </Col>

            <Col>
              <h2>Drivers</h2>
              <ul>
                {session?.drivers?.map((driver) => {
                  return (
                    <li onClick={() => viewDriver(driver.driverId)}>
                      <img src={driver.avatar} alt={driver.name} style={{width: "25px", height: "25px"}} />
                      {driver.isOwner && <span>👑</span>}
                      <span> {driver.name} ({driver.state})</span>
                    </li>
                  )
                })}
              </ul>
            </Col>

            <Col>
                <h2>Game State</h2>
                <ul>
                  <li>Car Class: {session?.gameState.carClassId}</li>
                  <li>Car: {session?.gameState.car.name}</li>
                  <li>Track: {session?.gameState.track.sceneDesc}</li>
                  <li>
                    <img src={session?.gameState.currentDriver.avatar} alt={session?.gameState.currentDriver.name} style={{width: "25px", height: "25px"}} />
                    <span> {session?.gameState.currentDriver.name} ({session?.gameState.currentDriver.state})</span>
                  </li>
                </ul>
            </Col>

            <Col>
              <h2>Chat</h2>
              <ul>
                {session?.gameState.chat?.map((chat) => {
                  return (
                    <li>
                      <img src={getDriver(chat.driverId)?.avatar} alt={chat.driverId} style={{width: "25px", height: "25px"}} />
                      <span> {chat.dateTime}</span>
                      <span> {chat.lap}</span>
                      <span> {getDriver(chat.driverId)?.name}</span>
                      <span> {chat.message}</span>
                    </li>
                  )
                })}
              </ul>
            </Col>
          </Row>

          <Row>
            <Col>
              <h2>Save JSON</h2>
            </Col>
            <Col>
              <button onClick={() => download()} className="btn btn-primary">Download</button>
            </Col>
          </Row>

          <Row>
            <Col>
              <textarea style={{ width: "100%", height: "800px" }} value={JSON.stringify(session?.gameState.saveJson, null, 2)}></textarea>
            </Col>
          </Row>
        </>
      }
    </div>
  )
}

export default ViewCoopSession;