import { ManualAmendUserSafetyRatingRequest, ManualBanUserRequest, ManualStrikeRequest, SuspendUserRequest } from "../Protest";
import UserServiceClient from '../../services/user-service.client';

export class Driver {
  id: string = "";
  steamId: string = "";
  name: string = "";
  username: string = "";
  avatar: string = "";

  lastNameChange: string = "";
  lastAvatarChange: string = "";

  driverRank: DriverRank = new DriverRank();
  safetyRank: SafetyRank = new SafetyRank();

  profile: Profile = new Profile();

  piiNullification: PIINullification = new PIINullification();

  constructor(driver: Driver) {
    Object.assign(this, driver);
  }

  public isStaff(): boolean {
    return this?.profile.verified.msg || this?.profile.verified.s397;
  }

  public isVerified(): boolean {
    return this?.profile.verified.account;
  }

  public acceptedRules(): boolean {
    return this?.profile.optins.competitionRules && this?.profile.optins.privacyPolicy;
  }

  public applySafetyRatingAdjustment(adjustment: number, reason: string, banReference: string, game: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const safetyRatingAdjustment: ManualAmendUserSafetyRatingRequest = {
        banReference: `Manual:${banReference}:${Date.now()}`,
        targetUserId: this?.id,
        adjustment: adjustment,
        notes: reason
      };

      new UserServiceClient().applySafetyRatingAdjustment(safetyRatingAdjustment, game).then(resolve).catch(reject);

    });
  }

  public undoSafetyRatingAdjustment(banReference: string, game: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      new UserServiceClient().undoSafetyRatingAdjustment(this?.id, banReference, game).then(resolve).catch(reject);
    });
  }

  public applyStrike(reason: string, banReference: string, game: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const strike: ManualStrikeRequest = {
        banReference: `Manual:${banReference}:${Date.now()}`,
        targetUserId: this?.id,
        notes: reason
      };

      new UserServiceClient().applyStrike(strike, game).then(resolve).catch(reject);

    });
  }

  public undoStrike(banReference: string, game: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      new UserServiceClient().undoStrike(this?.id, banReference, game).then(resolve).catch(reject);
    });
  }

  public applyBan(duration: string, reason: string, banReference: string, game: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const suspension: ManualBanUserRequest = {
        banReference: `Manual:${banReference}:${Date.now()}`,
        targetUserId: this?.id,
        protestType: 3,
        duration: duration,
        notes: reason
      };

      new UserServiceClient().banUser(suspension, game).then(resolve).catch(reject);

    });
  }

  public undoBan(banReference: string, game: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      new UserServiceClient().undoBan(this?.id, banReference, game).then(resolve).catch(reject);
    });
  }

  public refresh(game: string): Promise<Driver> {
    return new Promise<Driver>((resolve, reject) => {
      new UserServiceClient().get(this.id, game).then((driver) => {
        Object.assign(this, driver);
        resolve(driver);
      }).catch(reject);
    });
  }

  public manualNameChange(newUsername: string, notes: string, game: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      new UserServiceClient().manualNameChange(this.id, newUsername, notes, game).then(resolve).catch(reject);
    });
  }

  public undoUsernameViolation(banReference: string, game: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      new UserServiceClient().undoUsernameViolation(this.id, banReference, game).then(resolve).catch(reject);
    });
  }
}


export class Profile {
  email: string = "";
  nationality: string = "";
  badge: string = "";
  verified: Verified = new Verified();
  optins: Optins = new Optins();
  penaltiesApplied: PenaltiesApplied | null = null;
  nameChanges: NameChanges | null = null;

  lastLogin: string = "";
  requiresReview: boolean = false;
}

export class PIINullification {
  nullified: boolean = false;
  requestingUser: string = "";
  notes: string = "";
  time: number = 0;
}

export class Verified {
  email: boolean = false;
  account: boolean = false;
  msg: boolean = false;
  s397: boolean = false;
  alpha: boolean = false;
  verified: boolean = false;

  code: string = "";
}

export class Optins {
  emailMarketing: boolean = false;
  competitionRules: boolean = false;
  privacyPolicy: boolean = false;
  termsOfService: boolean = false;
}

export class DriverRank {
  elo: number = 0;
  rank: string = "Bronze";
  tier: number = 1;
  progress: number = 0;
}

export class SafetyRank {
  rating: number = 0;
  rank: string = "Bronze";
  tier: number = 0;
  progress: number = 0;
}

export interface PenaltiesApplied {
  suspensions: Suspension[];
  safetyRankAdjustments: SafetyRankAdjustment[];
  strikes: Strike[];
  usernameViolations: UsernameViolation[];
}

export interface Suspension {
  protestId: string;
  type: number;
  notes: string;
  from: number;
  to: number;
  redacted: boolean;
}

export interface SafetyRankAdjustment {
  protestId: string;
  adjustment: number;
  date: number;
  redacted: boolean;
  notes: string;
}

export interface Strike {
  protestId: string;
  date: number;
  redacted: boolean;
  notes: string;
}

export interface UsernameViolation {
  reference: string;
  date: number;
  resetUsername: boolean;
  suspend: boolean;
  suspensionDelay: number;
  resolved: boolean;
  redacted: boolean;
  notes: string;
}

export interface NameChanges {
  history: NameChange[];
}

export interface NameChange {
  date: string;
  reference: string;
  initiatedBy: string;
  oldName: string;
  newName: string;
  notes: string;
}

export const usernameRegex = /^[a-zA-Z0-9][a-zA-Z0-9_'-. ]*[a-zA-Z0-9]$/;
export const emptySpacesRegex = /^\s*$/;
export const suffixRegex = /#(\d+)$/;
export const usernameMaxLen = 26;

export function validateNewUsername(username: string): Error | null {
  if (username.match(suffixRegex)) {
    return new Error("there is no need to include a suffix in the username. The system will automatically add one if necessary.");
  } else if (!usernameRegex.test(username) || emptySpacesRegex.test(username)) {
    return new Error(`username contains invalid characters`);
  } else if (username.length > usernameMaxLen) {
    return new Error(`username is too long: ${username.length} exceeds ${usernameMaxLen} characters.`);
  }

  return null;
}