import React, { useEffect, useState } from "react";
import Select from "react-select";
import ContentManagerClient from "../../services/content-manager.client";
import { InstalledContent, Tag } from "../../types/InstalledContent";
import { Col } from 'react-bootstrap';
import { Item } from "../../types/event/Content";
import { createPortal } from 'react-dom';

import '../../css/dark-mode.css';

interface CarSelectionProps {
  cars: Item[],
  game: string,
  onSelected: (c: Item[]) => void,
}

const CarSelection = (props: CarSelectionProps) => {
  const [cars, setCars] = useState<InstalledContent[]>([]);
  const [selectedCars, setSelectedCars] = useState<Item[]>(props.cars ?? []);
  const portalId = `car-select-portal-${Math.random().toString(36).substring(7)}`;

  let client = new ContentManagerClient();

  useEffect(() => {
    client.getContentByType("car", props.game, ".*").then((content) => {
      setCars(content);
    });
  }, [props.game])

  useEffect(() => {
    if (props.cars) setSelectedCars(props.cars);
  }, [props.cars])

  const handleSelectChange = (selected: any) => {
    const selection = selected.map((car: any) => car.value);
    props.onSelected(selection);
    setSelectedCars(selection);
  }

  // convert cars to options for react-select
  const options = cars.map(car => ({
    value: car.content,
    label: car.content.friendly ?? car.content.name
  }));

  // convert selected cars to the format expected by react-select
  const selectedOptions = selectedCars.map(car => ({
    value: car,
    label: car.friendly ?? car.name
  }));

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#fff'
    }),
    singleValue: (provided: any) => {
      const opacity = 0.5;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition, color: 'black' };
    },
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      const color = 'black';
      return {
        ...styles, color, backgroundColor: isSelected ? '#f0f0f0' : isFocused ? '#e0e0e0' : null,
      };
    },
  };

  const handleAddAllCars = (e: React.MouseEvent) => {
    e.preventDefault();

    const selectedCars = cars.map(car => car.content);
    props.onSelected(selectedCars);
    setSelectedCars(selectedCars);
  }

  const handleClearCars = (e: React.MouseEvent) => {
    e.preventDefault();

    props.onSelected([]);
    setSelectedCars([]);
  }

  const handleAddByClass = (carclass: string) => {
    const toAdd = cars.filter(car => car.tags?.some(t => t.type === "carClass" && t.name === carclass)).map(car => car.content);
    const mergedCars = Array.from(new Set([...selectedCars, ...toAdd]));
    
    props.onSelected(mergedCars);
    setSelectedCars(mergedCars);
  }

  const distinctCarClasses = (cars: InstalledContent[]) => {
    const classes = cars.flatMap(car => car.tags?.filter(t => t.type === "carClass").map(t => t.name) ?? []);
    return Array.from(new Set(classes));
  }

  return (
    <>
      {/* The Select component can get stuck under other components when it pops out so this portal is necessary... */}
      {createPortal(<div id={portalId} className="image-select-portal" />, document.body)}

      <Col>
        <h5>Car Select</h5>
        <select className="dark-select" onChange={(e) => handleAddByClass(e.target.value)} style={{marginRight: "1rem"}}>
          <option value="">Add By Class</option>
          {distinctCarClasses(cars).map(c => <option key={c} value={c}>{c}</option>)}
        </select>

        <a href="" onClick={(e) => handleClearCars(e)}>Clear All</a>
        <br />
        <Select className="dark-select"
          options={options}
          isMulti
          onChange={handleSelectChange}
          value={selectedOptions}
          styles={customStyles}
          menuPortalTarget={document.getElementById(portalId) as HTMLElement}
          menuPosition="fixed"
        />
      </Col>
    </>
  );
}

export default CarSelection;
