import React, { useRef, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Button, Col, ListGroup, Modal, Nav, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { HttpProtectedRole, HttpProtectedRouteViewModel, HttpProtectedUser, HttpProtectedUserViewModel } from '../../types/Security';
import { FaMinus, FaPlus, FaUser, FaUserShield, FaUserSlash, FaUsers } from 'react-icons/fa';
import SecurityServiceClient from '../../services/security-service.client';
import RoutesListView from '../routes/RoutesListView';
import RoutesTabbedView from '../routes/RoutesTabbedView';
import { CiLink } from "react-icons/ci";

interface IRolesViewModalProps {
    show: boolean;
    setShowModal: (show: boolean) => void;
    role: HttpProtectedRole;
    allUsers: Array<HttpProtectedUserViewModel>;
    allRoles: Array<HttpProtectedRole>;
    routes: Array<HttpProtectedRouteViewModel>;
    routesByService: Map<string, Array<HttpProtectedRouteViewModel>>;
    requestRefresh?: (indices: Array<number>) => void;
}

const RolesViewModal: React.FC<IRolesViewModalProps> = (props) => {

    const securityService = new SecurityServiceClient();

    const [routesForThisRole, setRoutesForThisRole] = useState<Map<string, Array<HttpProtectedRouteViewModel>>>(
        new Map<string, Array<HttpProtectedRouteViewModel>>()
    );

    const [spinnerExpired, setSpinnerExpired] = useState<boolean>(false);
    // on mount
    React.useEffect(() => {
        sortRoutesByServiceForThisRole(props?.routes);
        setTimeout(() => {
            setSpinnerExpired(true);
        }, 3000);
    }, [props?.routes])

    const [navRoutes, setNavRoutes] = useState<Array<string>>([]);
    const [navViews, setNavViews] = useState<Array<string>>([]);
    React.useEffect(() => {
        getAllItemsFromRoutes();
        getAllItemsFromNavbar();
    }, []);

    const [colour, setColour] = useState<string>(props?.role?.colour);

    const setRoleColour = (colour: string) => {
        securityService.setRoleColour(props?.role?.name, colour).then((res: Response) => {
            if (res?.status === 200) {
                props?.requestRefresh?.([]);
            }
        });
    }

    const sortRoutesByServiceForThisRole = (routes: Array<HttpProtectedRouteViewModel>) => {
        const routesByService = new Map<string, Array<HttpProtectedRouteViewModel>>();
        routes.forEach((route: HttpProtectedRouteViewModel) => {

            if (!route?.allowedRoles?.map((role) => role?.name)?.includes(props?.role?.name)) {
                return;
            }

            if (!routesByService.has(route.service)) {
                routesByService.set(route.service, []);
            }
            routesByService.get(route.service)?.push(route);
        });
        setRoutesForThisRole(routesByService);
    }

    const deleteRole = (roleName: string) => {
        securityService.deleteRole(roleName).then((res: Response) => {
            if (res?.status === 200) {
                props?.requestRefresh?.([]);
                props?.setShowModal(false);
            }
        });
    }

    const getAllItemsFromRoutes = () => {
        const requestEvent = new Event('nav-routes-request');
        window.dispatchEvent(requestEvent);

        return new Promise((resolve) => {
            const handleEvent = (event: Event) => {
                const customEvent = event as CustomEvent;

                window.removeEventListener('nav-routes-event', handleEvent);

                const navRoutes = customEvent?.detail?.routeKeys;
                setNavRoutes(navRoutes);
                resolve(navRoutes);
            };

            window.addEventListener('nav-routes-event', handleEvent);
        });
    };

    const getAllItemsFromNavbar = () => {
        const requestEvent = new Event('nav-views-request');
        window.dispatchEvent(requestEvent);

        return new Promise((resolve) => {
            const handleEvent = (event: Event) => {
                const customEvent = event as CustomEvent;

                window.removeEventListener('nav-views-event', handleEvent);

                const navViews = customEvent?.detail?.views;
                setNavViews(navViews);
                resolve(navViews);
            };

            window.addEventListener('nav-views-event', handleEvent);
        });
    }

    const addViewToRole = (roleName: string, view: string) => {
        securityService.addViewToRole(roleName, view).then(() => {
            props?.requestRefresh?.([]);
        });
    }

    const removeViewFromRole = (roleName: string, view: string) => {
        securityService.removeViewFromRole(roleName, view).then(() => {
            props?.requestRefresh?.([]);
        });
    }

    const addRouteToRole = (roleName: string, routeName: string) => {
        securityService.addRouteToRole(roleName, routeName).then(() => {
            props?.requestRefresh?.([]);
        });
    }

    const removeRouteFromRole = (roleName: string, routeName: string) => {
        securityService.removeRouteFromRole(roleName, routeName).then(() => {
            props?.requestRefresh?.([]);
        });
    }

    const setAsDefaultRole = (roleName: string) => {
        securityService.setDefaultRole(roleName).then(() => {
            props?.requestRefresh?.([]);
        });
    }

    const unsetAsDefaultRole = (roleName: string) => {
        securityService.unsetDefaultRole(roleName).then(() => {
            props?.requestRefresh?.([]);
        });
    }

    return (
        <>
            <style>
                {`
                .protected-user-view-modal-body {
                    color: snow;
                    background-color: #222222;
                }

                .role-select-list-group-header-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2em;
                }

                .role-select-list-group-item {
                    background-color: #222222;
                    color: snow;
                    border:1px solid #333333;
                }

                .role-select-list-group-item:hover {
                    background-color: #333333;
                    color: snow;
                    border:1px solid #333333;
                }

                .role-select-list-group-item-row {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .role-select-list-group-item-icon-col {
                    height: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .role-select-btn-minus {
                    visibility: hidden;
                    border: 0;
                }

                .role-select-btn-plus {
                    visibility: hidden;
                    border: 0;
                }

                .role-select-list-group-item:hover > * > * > .role-select-btn-minus {
                    visibility: visible;
                    background-color: red;
                    border: 0;
                }

                .role-select-list-group-item:hover > * > * > .role-select-btn-plus {
                    visibility: visible;
                    background-color: #0d6efd;
                    border: 0;
                }

                .role-select-btn-minus::focus, .role-select-btn-plus::focus {
                    outline: none;
                }
                
                .user-role-tab-style {
                    background-color: #222222;
                }
                
                .user-role-tab-style .nav-link.active {
                    background-color: #222222;
                    color: snow;
                }
                
                .user-role-tab-style .nav-link:hover {
                    background-color: #333333;
                    color: snow;
                }
            `}
            </style>
            <div className="row">
                <div className="col-12">
                    <Modal show={props?.show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

                        <Modal.Header style={{ backgroundColor: "#222222", borderColor: "#333333", color: "snow" }} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row className="d-flex justify-content-between align-items-start">
                                    <Col xs="auto" className="d-flex justify-content-between align-items-start">
                                        {
                                            props?.role == void (0) ?
                                                <FaUserSlash size="1.5em" color='gray' />
                                                :
                                                props?.role?.name === "SuperAdmin" ?
                                                    <FaUserShield size="1.5em" color='red' />
                                                    :
                                                    <FaUser size="1.5em" color={props?.role?.colour} />
                                        }
                                    </Col>
                                    <Col xs="auto" className="d-flex justify-content-between align-items-start">
                                        <h4 style={{ color: "snow" }}>{props?.role?.name} Role Management</h4>
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="protected-user-view-modal-body">
                            <Row>
                                <Tabs
                                    defaultActiveKey="general-info"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 user-role-tab-style"
                                >
                                    <Tab eventKey="general-info" title="General Info" className="user-role-tab-style">
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <span style={{ fontWeight: "bold" }}>Name: </span>
                                                        <span>{props?.role?.name}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <span style={{ fontWeight: "bold" }}>Number of users: </span>
                                                        <span>{props?.allUsers?.filter((user) => user?.roles?.map((role) => role?.name)?.includes(props?.role?.name))?.length}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <span style={{ fontWeight: "bold" }}>Authorised Endpoints: </span>
                                                        <span>{props?.routes?.filter((route) => route?.allowedRoles?.map((role) => role?.name)?.includes(props?.role?.name))?.length}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    {
                                        props?.role?.name !== "SuperAdmin" &&
                                        <Tab eventKey="edit-info" title="Edit">

                                            <Row className="my-3">
                                                <Col xs="auto">
                                                    <label htmlFor="color-input" className="form-label">Colour:</label>
                                                </Col>
                                                <Col xs="1" className="text-center">
                                                    <input
                                                        id="color-input"
                                                        type="color"
                                                        value={colour}
                                                        onChange={(e) => { setColour(e.target.value) }}
                                                        className="form-control"
                                                    />
                                                </Col>
                                                <Col xs="auto">
                                                    <button onClick={() => setRoleColour(colour)} className="btn btn-primary">
                                                        Save Colour
                                                    </button>
                                                </Col>
                                            </Row>
                                            {
                                                props?.role?.name !== "SuperAdmin" && props?.role?.isDefault === false &&
                                                <Row>
                                                    <Row>
                                                        <Col>
                                                            <label htmlFor="role-is-default" className="form-label">Set as default role? (only one can be default, others will be unset automatically)</label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label htmlFor="role-is-default" className="form-label">New users will automatically be given this role on sign-up</label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Button variant="success" onClick={() => { setAsDefaultRole(props?.role?.name) }}>
                                                                Set as Default
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            }
                                            {
                                                props?.role?.name !== "SuperAdmin" && props?.role?.isDefault === true &&
                                                <Row>
                                                    <Row>
                                                        <Col>
                                                            <label htmlFor="role-is-default" className="form-label">This role is currently the default</label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label htmlFor="role-is-default" className="form-label">If there isn't a default, new users will not be given a role</label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Button variant="primary" onClick={() => { unsetAsDefaultRole(props?.role?.name) }}>
                                                                Unset as Default
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            }

                                        </Tab>
                                    }
                                    {
                                        props?.role?.name !== "SuperAdmin" &&
                                        <Tab eventKey="endpoints" title="Endpoints">
                                            <Row className="d-flex justify-content-center align-items-center">
                                                {
                                                    routesForThisRole?.size === 0 && spinnerExpired === false ?
                                                        <Col className="d-flex justify-content-center align-items-center top-50 start-50 translate-middle">
                                                            <Spinner animation="border" style={{ width: '4vh', height: '4vh', marginTop: "6vh" }} />
                                                        </Col>
                                                        : routesForThisRole?.size === 0 && spinnerExpired === true ?
                                                            <Col className="d-flex justify-content-center align-items-center">
                                                                <span>No endpoints authorised for this role</span>
                                                            </Col>
                                                            :
                                                            <RoutesTabbedView
                                                                routes={props?.routes}
                                                                routesByService={routesForThisRole}
                                                                allRoles={props?.allRoles}
                                                                requestRefresh={props?.requestRefresh}
                                                            />
                                                }
                                            </Row>
                                        </Tab>
                                    }
                                    {
                                        props?.role?.name !== "SuperAdmin" &&
                                        <Tab eventKey="views" title="App Views">
                                            <Row>
                                                <Col className="role-select-list-group-header-col">
                                                    Available Views
                                                </Col>
                                                <Col className="role-select-list-group-header-col">
                                                    Authorised Views
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <ListGroup>
                                                        {
                                                            navViews?.filter?.((item) => !props?.role?.allowedViews?.includes(item))?.map((item, index) => {
                                                                return (
                                                                    <ListGroup.Item key={index} className="role-select-list-group-item">
                                                                        <Row className="role-select-list-group-item-row">
                                                                            <Col xs={1} className="role-select-list-group-item-icon-col">
                                                                                <CiLink size="1.5em" color="snow" />
                                                                            </Col>
                                                                            <Col xs={10}>
                                                                                {item}
                                                                            </Col>
                                                                            <Col xs={1} className="role-select-list-group-item-icon-col">
                                                                                <Button onClick={() => addViewToRole(props?.role?.name, item)} className="role-select-btn-plus" variant="primary">
                                                                                    <FaPlus color="snow" />
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                )
                                                            })
                                                        }
                                                    </ListGroup>
                                                </Col>
                                                <Col>
                                                    <ListGroup>
                                                        {
                                                            props?.role?.allowedViews?.map((item, index) => {
                                                                return (
                                                                    <ListGroup.Item key={index} className="role-select-list-group-item">
                                                                        <Row className="role-select-list-group-item-row">
                                                                            <Col xs={1} className="role-select-list-group-item-icon-col">
                                                                                <CiLink size="1.5em" color="snow" />
                                                                            </Col>
                                                                            <Col xs={10}>
                                                                                {item}
                                                                            </Col>
                                                                            <Col xs={1} className="role-select-list-group-item-icon-col">
                                                                                <Button onClick={() => removeViewFromRole(props?.role?.name, item)} className="role-select-btn-minus" variant="danger">
                                                                                    <FaMinus color="snow" />
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                )
                                                            })
                                                        }
                                                    </ListGroup>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    }
                                    {
                                        props?.role?.name !== "SuperAdmin" &&
                                        <Tab eventKey="routes" title="App Routes">
                                            <Row>
                                                <Col className="role-select-list-group-header-col">
                                                    Available Routes
                                                </Col>
                                                <Col className="role-select-list-group-header-col">
                                                    Authorised Routes
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <ListGroup>
                                                        {
                                                            navRoutes?.filter?.((item) => !props?.role?.allowedRoutes?.includes(item))?.map((item, index) => {
                                                                return (
                                                                    <ListGroup.Item key={index} className="role-select-list-group-item">
                                                                        <Row className="role-select-list-group-item-row">
                                                                            <Col xs={1} className="role-select-list-group-item-icon-col">
                                                                                <CiLink size="1.5em" color="snow" />
                                                                            </Col>
                                                                            <Col xs={10}>
                                                                                {item}
                                                                            </Col>
                                                                            <Col xs={1} className="role-select-list-group-item-icon-col">
                                                                                <Button onClick={() => addRouteToRole(props?.role?.name, item)} className="role-select-btn-plus" variant="primary">
                                                                                    <FaPlus color="snow" />
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                )
                                                            })
                                                        }
                                                    </ListGroup>
                                                </Col>
                                                <Col>
                                                    <ListGroup>
                                                        {
                                                            props?.role?.allowedRoutes?.map((item, index) => {
                                                                return (
                                                                    <ListGroup.Item key={index} className="role-select-list-group-item">
                                                                        <Row className="role-select-list-group-item-row">
                                                                            <Col xs={1} className="role-select-list-group-item-icon-col">
                                                                                <CiLink size="1.5em" color="snow" />
                                                                            </Col>
                                                                            <Col xs={10}>
                                                                                {item}
                                                                            </Col>
                                                                            <Col xs={1} className="role-select-list-group-item-icon-col">
                                                                                <Button onClick={() => removeRouteFromRole(props?.role?.name, item)} className="role-select-btn-minus" variant="danger">
                                                                                    <FaMinus color="snow" />
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                )
                                                            })
                                                        }
                                                    </ListGroup>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    }
                                    {
                                        props?.role?.name !== "SuperAdmin" &&
                                        <Tab eventKey="delete" title="Delete" className="user-role-tab-style">
                                            <Row className="d-flex align-items-center">
                                                <Col xs={5}>
                                                    By deleting this role, you will remove this role for all users and remove all authorisations for this role.
                                                    <br></br>
                                                    <br></br>
                                                    This operation cannot be undone.
                                                </Col>
                                                <Col xs={7} className="d-flex justify-content-center align-items-center">
                                                    <Button variant="danger" onClick={() => { deleteRole(props?.role?.name) }}>
                                                        Delete Role
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    }
                                </Tabs>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer style={{ backgroundColor: "#222222", borderColor: "#333333" }}>
                            <Button onClick={() => props?.setShowModal(false)}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default withAuthentication(RolesViewModal);
