import { useEffect, useState } from "react";

import Select from "react-select";
import { Item, SubItem } from "../../types/event/Content";
import { InstalledContent } from "../../types/InstalledContent";
import ContentManagerClient from "../../services/content-manager.client";
import { Col } from 'react-bootstrap';
import { createPortal } from 'react-dom';

const client = new ContentManagerClient();

interface TrackSelectionProps {
  track: Item;
  layout: SubItem;
  game: string;
  onSelected: (content: Item) => void;
}

const TrackSelection = (props: TrackSelectionProps) => {
  const [tracks, setTracks] = useState<InstalledContent[]>([]);
  
  const [selectedTrack, setSelectedTrack] = useState<any>(props.track ?? null);
  const [selectedSubItem, setSelectedSubItem] = useState<any>(null);
  
  const [trackOptions, setTrackOptions] = useState<any[]>([]);
  const [subItemOptions, setSubItemOptions] = useState<any[]>([]);

  const portalId = `track-select-portal-${Math.random().toString(36).substring(7)}`;

  useEffect(() => {
    client.getContentByType("track", props.game, ".*")
      .then((content) => setTracks(content ?? []));
  }, [props.game]);

  useEffect(() => {
    console.log("props updated track", props.track);
    console.log("props updated layout", props.layout);

    if (props.track) {
      setSelectedTrack({ value: props.track, label: props.track.friendly ?? props.track.name });
      //setSubItemOptions(props.track.subItems.map(subitem => ({ value: subitem, label: subitem.friendly ?? subitem.name })));
    }
    
    if (props.layout) 
      setSelectedSubItem({ value: props.layout, label: props.layout.friendly ?? props.layout.name });

  }, [props.track, props.layout])

  useEffect(() => {
    const options = tracks.sort((a, b) => a.name.localeCompare(b.name)).map(installed => ({ value: installed.content, label: installed.content.friendly ?? installed.content.name }));
    setTrackOptions(options);
  }, [tracks]);

  const handleTrackChange = (selected: any) => {
    const selectedTrack : Item = selected ? selected.value : null;
    setSelectedTrack(selected);
    setSelectedSubItem(null);
    
    setSubItemOptions(selectedTrack?.subItems ? selectedTrack.subItems.map(subitem => ({
      value: subitem,
      label: subitem.friendly ?? subitem.name
    })) : []);
  }

  const handleSubItemChange = (selected: any) => {
    setSelectedSubItem(selected);
    updateConfigTrack(selectedTrack.value, selected.value);
  }

  const updateConfigTrack = (track: Item | null, subItem: SubItem | null) => {
    console.log("updateConfigTrack", track, subItem);

    if (track && subItem) {
      const trackCopy = JSON.parse(JSON.stringify(track)) as Item; // make a deep copy to avoid mutating state directly
      trackCopy.subItems = trackCopy.subItems.filter(subitem => subitem.name === subItem?.name);
      
      console.log("updating parent", trackCopy);
      props.onSelected(trackCopy);
    }
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#fff'
    }),
    singleValue: (provided: any) => {
      const opacity = 0.5;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition, color: 'black' };
    },
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      const color = 'black';
      return {
        ...styles, color, backgroundColor: isSelected ? '#f0f0f0' : isFocused ? '#e0e0e0' : null,
      };
    },
  };


  return (
    <>
      <style>
        {`
          .dark-select {
              background-color: #333333 !important;
              color: snow;
              border: 1px solid #454545;
              outline: none !important;
          }

          .dark-select > * {
              background-color: #333333 !important;
              color: snow !important;
              border: 1px solid #454545;
              outline: none !important;
          }

          .dark-select > * > *, .dark-select > * > * > *, .dark-select > * > * > * > * {
              background-color: #333333 !important;
              color: snow !important;
              outline: none !important;
          }

          .dark-select:focus, .dark-select:active, .dark-select:hover {
              background-color: #333333 !important;
              color: snow;
              border: 1px solid #454545;
              outline: none !important;
          }
          
          .track-select-portal {
            position: absolute !important;
            z-index: 9999 !important;
            top: 0 !important;
            display: hidden !important;
          }
      `}
      </style>
    {/* The Select component can get stuck under other components when it pops out so this portal is necessary... */}
    {createPortal(<div id={portalId} className="track-select-portal" />, document.body)}
    <Col>
      {/* <h2>Track Select</h2> */}
      <h5 className="mt-3">Select Track</h5>
      <Select className="dark-select"
        options={trackOptions}
        onChange={handleTrackChange}
        value={selectedTrack}
        styles={customStyles}
        menuPosition="fixed"
        menuPortalTarget={document.getElementById(portalId) as HTMLElement}
      />
      <h5 className="mt-3">Select Layout</h5>
      <Select className="dark-select"
        options={subItemOptions}
        onChange={handleSubItemChange}
        value={selectedSubItem}
        styles={customStyles}
        menuPosition="fixed"
        menuPortalTarget={document.getElementById(portalId) as HTMLElement}
      />
    </Col>
    </>
  );
}

export default TrackSelection;
