import { useEffect, useRef, useState } from "react";
import CoopClient from "./coop.client";
import { useNavigate } from "react-router-dom";
import { CoopEvent } from "./coopEvent";
import withAuthentication from "../../withAuthentication";

const CoopEventList = () => {
  const client = useRef(new CoopClient());
  const navigate = useNavigate();

  const [events, setEvents] = useState<CoopEvent[]>([]);

  useEffect(() => fetch(), []);

  const fetch = () => {
    client.current.events().then((events) => {
      setEvents(events);
    });
  }

  const view = (id: string) => {
    navigate('/rm/coop/event/' + id);
  }

  return (
    <>
    <div style={{margin: "2rem"}}>
      <h1>COOP Events</h1>

      <button className="btn btn-primary" style={{ margin: "1rem 0 1rem 0" }} onClick={() => navigate('/rm/coop/event')}>Create Event</button>

      <table className="table table-dark">
        <thead>
          <th>Id</th>
          <th>Name</th>
          <th>Track</th>
          <th>Length</th>
          <th>DLC's</th>
          <th>Starts</th>
          <th>Ends</th>
        </thead>
        <tbody>  
          {events?.map((event) => (
            <tr onClick={() => view(event.id ?? "")}>
              <td>{event.id}</td>
              <td>{event.name}</td>
              <td>{event.track}</td>
              <td>{event.length}</td>
              <td>{event.dlcAppIds?.join(', ')}</td>
              <td>{event.starts}</td>
              <td>{event.ends}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default withAuthentication(CoopEventList);