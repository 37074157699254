import React from "react";
import withAuthentication from "../withAuthentication";
import { Driver } from "../types/event/Driver";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import NakamaClient from "../services/nakama.client";
import RaceManagementClient from "../services/race-manaement.client";
import { EventResult } from "../types/event/EventResults";
import { Statistics } from "../types/Statistics";
import { RankChange } from "../types/RankChange";
import { Chart, registerables  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import DriverAccordionViewComponent from "../components/driver/DriverAccordionViewComponent";
import moment from "moment";
import ViewCoopSessions from "../rm/coop/sessions";
import ViewEvents from "./events";
import SafetyRankBadge from "../components/safteyRank";
import DriverRankBadge from "../components/driverRank";

const PlayersList = () => {
  Chart.register(...registerables);

  const { playerId, game } = useParams<{ playerId: string, game: string }>();

  const [lmuPlayer, setLMUPlayer] = React.useState<Driver | null>(null);
  const [rf2Player, setRF2Player] = React.useState<Driver | null>(null);
  const [steamId, setSteamId] = React.useState<string>("");
  

  const [srRankHistory, setSrRankHistory] = React.useState<RankChange[]>([]);
  const [drRankHistory, setDrRankHistory] = React.useState<RankChange[]>([]);
  const [srChartData, setChartData] = React.useState<any>(null);

  const [lmuStats, setLMUStats] = React.useState<Statistics | null>(null);
  const [rf2Stats, setRF2Stats] = React.useState<Statistics | null>(null);
  
  const [loading, setLoading] = React.useState<boolean>(true);

  const nk = new NakamaClient();
  const rm = new RaceManagementClient();

  const interval = React.useRef<any>(null);

  const fetch = async (game: string, nakamaId: string) => {
    const driver = await nk.playersGetByNakamaId(game, nakamaId)
      .then((driver: Driver) => {
        if (game === "lmu") setLMUPlayer(driver);
        if (game === "rf2") setRF2Player(driver);
        
        fetchPlayerStats(game, driver.id);
        setLoading(false);

        return driver;
      })
      .catch((err: Error) => {
        console.error(err);
      })

    if (!driver) return;
    setSteamId(driver.steamId);

    game = game === "lmu" ? "rf2" : "lmu"; 
    nk.playerGetBySteamId(game, driver.steamId)
      .then((driver: Driver) => {
        if (game === "lmu") setLMUPlayer(driver);
        if (game === "rf2") setRF2Player(driver);

        fetchPlayerStats(game, driver.id);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error(err);
      })    
  }

  const fetchPlayerStats = async (game: string, nakamaId: string) => {
    nk.playerStatistics(game, nakamaId)
      .then((stats: Statistics) => {
        if (game === "lmu") setLMUStats(stats);
        if (game === "rf2") setRF2Stats(stats);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error(err);
      })
  }

  React.useEffect(() => { 
    console.log("page load");
    fetch(game ?? "", playerId ?? "");
  }, []);

  React.useEffect(() => {
    const fetchSRAndDRHistories = async () => {
      const [srHistory, drHistory] = await Promise.all([
        rm.getSRHistory(steamId),
        rm.getDRHistory(steamId),
      ]);

      setSrRankHistory(srHistory);
      setDrRankHistory(drHistory);

      const srData = srHistory.map((change) => ({
        x: moment(change.dateTime).toDate(),
        y: change.change,
      }));

      const drData = drHistory.map((change) => ({
        x: moment(change.dateTime).toDate(),
        y: change.change,
      }));

      const chartData = {
        datasets: [
          {
            label: "SR Rating",
            data: srData,
            borderColor: "blue",
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            fill: false,
          },
          {
            label: "DR Rating",
            data: drData,
            borderColor: "red",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            fill: false,
          },
        ],
      };

      setChartData(chartData);
    };

    if (steamId) fetchSRAndDRHistories().catch(console.error);
  }, [steamId]);

  return (
    <>
    <div style={{ margin: "2rem" }}>
      <h1>{lmuPlayer?.name ?? rf2Player?.name}</h1>
      <br/>
    
      <Row>
        <Col>
          <h3>LMU Account</h3>
          {lmuPlayer && (
            <DriverAccordionViewComponent
              drivers={[lmuPlayer]}
              game="lmu"
              requestRefresh={() => fetch("lmu", lmuPlayer?.id)}
            />
          )}
        </Col>

        <Col>
          <h3>RF2 Account</h3>
          {rf2Player && (
            <DriverAccordionViewComponent
              drivers={[rf2Player]}
              game="rf2"
              requestRefresh={() => fetch("rf2", rf2Player?.id)}
            />
          )}
        </Col>

        <Col style={{height: "400px", width: "100%"}}>
          <h3>Rank History</h3>
          {srChartData && (
            <Line
              data={srChartData}
              options={{
                responsive: true,
                scales: {
                  x: {
                    type: "time",
                    time: {
                      parser: "iso", // Adjust the parser format as needed to match your dateTime format
                      tooltipFormat: "LLL",
                      unit: "month", // or 'hour', 'month', etc., depending on the granularity you prefer
                    },
                    title: {
                      display: true,
                      text: 'Date',
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Rating Change',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true, // To display the legend
                  },
                },
              }}
            />
          )}
          
        </Col>
      </Row>

      <Row style={{marginTop: "2rem"}}>
        {lmuStats && (
          <Col>
            {displayStats("LMU", lmuStats)}
          </Col>
        )}

        {rf2Stats && (
          <Col>
            {displayStats("RF2", rf2Stats)}
          </Col>
        )}
      </Row>

      <Row style={{marginTop: "2rem", paddingBottom: "5rem"}}>
        <Col>
          <Tab.Container defaultActiveKey="events">
          <Nav variant="pills" className="flex">
            <Nav.Item>
              <Nav.Link eventKey="events">Events</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="coop">Coop Sessions</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="events">
              <ViewEvents steamId={steamId} />
            </Tab.Pane>

            <Tab.Pane eventKey="coop">
              <Tab.Container defaultActiveKey="coop-active">
              <Nav variant="pills" className="flex">
                <Nav.Item><Nav.Link eventKey="coop-active">Active</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="coop-historic">Historic</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="coop-invites">Invited</Nav.Link></Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="coop-active"><ViewCoopSessions driverId={lmuPlayer?.id} type="active" /></Tab.Pane>
                <Tab.Pane eventKey="coop-historic"><ViewCoopSessions driverId={lmuPlayer?.id} type="historic" /></Tab.Pane>
                <Tab.Pane eventKey="coop-invites"><ViewCoopSessions driverId={lmuPlayer?.id} type="invites" /></Tab.Pane>
              </Tab.Content>
              </Tab.Container>
            </Tab.Pane>
          </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </div>

    {loading && <p>Loading...</p>}
    </>
  );
};

const displayDriverData = (driver: Driver | null) => {
  if (!driver) return null;

  return (
    <>
      <p>{driver?.id}</p>
      <p>{driver?.steamId}</p>
      <p>{driver?.name} ({driver?.username})</p>
      <p>{driver?.profile?.badge}</p>
      <p>{driver?.profile?.email} ({driver?.profile.verified.email ? "Y" : "N"})</p>
      <p>{driver?.profile?.nationality}</p>
      <p>
        <DriverRankBadge dr={driver.driverRank} />
        &nbsp;
        <SafetyRankBadge sr={driver.safetyRank} />
      </p>
      <p>
        Rules: {driver?.profile?.optins.competitionRules ? "Y" : "N"}&nbsp;
        Marketing: {driver?.profile?.optins.emailMarketing ? "Y" : "N"}&nbsp;
        ToS: {driver?.profile?.optins.termsOfService ? "Y" : "N"}
      </p>
    </> 
  );
}

const displayStats = (game: string, stats: Statistics | null) => {
  if (!stats) return null;

  const statsBoxStyle: React.CSSProperties = {
    backgroundColor: "#222",
    borderRadius: "8px",
    padding: "8px",
    textAlign: "center",
    margin: "2px",
  };

  const statsTextStyle: React.CSSProperties = {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#fff",
  };

  const statsNumberStyle: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#fff",
  };

  return (
    <>
      <h4>{game}</h4>
      <Row>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Races</div>
          <div style={statsNumberStyle}>{stats.total?.races}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Wins</div>
          <div style={statsNumberStyle}>{stats.total?.wins}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Pole Positions</div>
          <div style={statsNumberStyle}>{stats.total?.polePositions}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Podiums</div>
          <div style={statsNumberStyle}>{stats.total?.podiums}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Top 5</div>
          <div style={statsNumberStyle}>{stats.total?.top5}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Laps Lead</div>
          <div style={statsNumberStyle}>{stats.total?.lapsLead}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Fastest Laps</div>
          <div style={statsNumberStyle}>{stats.total?.fastestLaps}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Laps</div>
          <div style={statsNumberStyle}>{stats.total?.lapsCompleted}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>DNFs</div>
          <div style={statsNumberStyle}>{stats.total?.dnfs}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Beginner</div>
          <div style={statsNumberStyle}>{stats.dailyRaces?.beginner.total.races}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Intermediate</div>
          <div style={statsNumberStyle}>{stats.dailyRaces?.intermediate.total.races}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Advanced</div>
          <div style={statsNumberStyle}>{stats.dailyRaces?.advanced.total.races}</div>
        </Col>
        <Col xs={2} style={statsBoxStyle}>
          <div style={statsTextStyle}>Special</div>
          <div style={statsNumberStyle}>{stats.specialEvents?.total.races}</div>
        </Col>
      </Row>
    </>
  );
}

export default withAuthentication(PlayersList);
