import { useEffect, useRef, useState  } from "react";
import { DailyRaceConfiguration, games, dailyTiers, trackLimitsRule, closedRaceSessions } from "../../../types/event/DailyRaceConfiguration";
import withAuthentication from "../../../withAuthentication";
import RaceManagementClient from "../../../services/race-manaement.client";
import { Button, Form, Container, Row, Col, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import ContentModal from "../ContentModal";
import SessionModal from "../SessionModal";
import Accordion from 'react-bootstrap/Accordion';
import { ErrorResponse } from "../../../types/ErrorResponse";
import AssistsComponent from "../Assists";
import { DailyRacesContent } from "../../../types/event/DailyRaceContent";
import { Session } from "../../../types/event/Session";
import { CronExamplesResponse } from "../../../types/responses/CronExamplesResponse";
import { serverSizes } from "../../../types/event/SpecialEvent";
import ServerSettingsComponent from "../ServerSettingsComponent";

import '../../../css/dark-mode.css';
import RatingsComponent from "../Ratings";

const CreateDailyConfigurations = () => {
  const client = new RaceManagementClient();
  
  const [config, setConfig] = useState<DailyRaceConfiguration>(new DailyRaceConfiguration());
  const [mode, setMode] = useState('Create'); // ['create', 'edit']
  const [errors, setErrors] = useState<ErrorResponse[]>([]);
  const [splitSettings, setSplitSettings] = useState(["even", "filltop"]);
  const [crons, setCrons] = useState<CronExamplesResponse | null>(null);

  const timeout = useRef<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    const copy = params.get('copy');
    if (id) {
      copy ? setMode('Copy') : setMode('Edit');
      client.getDailyConfiguration(id)
        .then((config) => {
          setConfig(config);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const save = async () => {
    console.log(`Saving config ${mode}`);

    if (mode === 'Edit') {
      const response = await client.editDailyConfiguration(config ?? new DailyRaceConfiguration());
      console.log("response: "+response.statusText);
      if (response.status === 200) {
        navigate('/rm/configs/dailies/list');
      } else if (response.status === 400) {
        const errors = await response.json() as ErrorResponse[]; 
        setErrors(errors);
        console.log(errors);
      } else {
        setErrors([{field: "unknown", error: "yeah race management didn't like that"}]);
      }

    } else {
      const response = await client.createDailyConfiguration(config ?? new DailyRaceConfiguration());
      console.log("response: "+response.statusText);
      if (response.status === 200) {
        navigate('/rm/configs/dailies/list');
      } else if (response.status === 400) {
        const errors = await response.json() as ErrorResponse[]; 
        setErrors(errors);
        console.log(errors);
      } else {
        setErrors([{field: "unknown", error: "yeah race management didn't like that"}]);
      }
    }
  }

  const generateCrons = async () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      client.crons(config.starts, config.frequency)
        .then((response) => {
          if (response) {
            setCrons(crons);
          }
        });
    }, 1000);
  }

  return (
    <Container>
      <h1>{mode} Configuration</h1>

      <Modal show={errors && errors.length > 0} onHide={() => { setErrors([]) }}>
        <Modal.Header closeButton>
          <Modal.Title>You've got issues, Get Good Scrub</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {errors.map((error) => (
              <li>{error.field}: {error.error}</li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
      
      <Form>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Event Config</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col>
                  <Form.Group controlId="tierName">
                    <Form.Label>Game:</Form.Label>
                    <Form.Control as="select" value={config.game} onChange={(e) => setConfig({...config, game: e.target.value})}>
                      <option value="">Please Select</option> 
                      {games.map((game) => (
                        <option value={game} selected={config.game === game}>{game}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="tierName">
                    <Form.Label>Tier:</Form.Label>
                    <Form.Control as="select" value={config.tier.name} onChange={(e) => setConfig({...config, tier: {...config.tier, name: e.target.value}})}>
                      <option value="">Please Select</option> 
                      {dailyTiers.map((tier) => (
                        <option value={tier} selected={config.tier.name === tier}>{tier}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="starts">
                    <Form.Label>Starts:</Form.Label>
                    <Form.Control type="string" value={config.starts} onChange={(e) => setConfig({...config, starts: e.target.value})} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="ends">
                    <Form.Label>Ends:</Form.Label>
                    <Form.Control type="string" value={config.ends} onChange={(e) => setConfig({...config, ends: e.target.value})} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="registrationOpens">
                    <Form.Label>Registration Opens:</Form.Label>
                    <Form.Control type="string" value={config.registrationOpens} onChange={(e) => setConfig({...config, registrationOpens: e.target.value})} />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="name">
                    <Form.Label>Instance Type:</Form.Label>
                    <Form.Control as="select" value={config.ServerSize} onChange={(e) => setConfig({...config, ServerSize: e.target.value})}>
                      <option value="">Please Select</option>
                      {serverSizes.map((instance) => (
                        <option value={instance} selected={instance === config.ServerSize}>{instance}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <OverlayTrigger overlay=
                    {<Popover id="split-setting-popover">
                      <Popover.Header as="h3">Defines how to distribute players in splits</Popover.Header>
                      <Popover.Body>
                        <strong>Even:</strong> full or nearly full even splits + no splits are low<br></br>
                        <strong>FillTop:</strong> fill top split and distribute others evenly (min 15 registrants)<br></br>
                      </Popover.Body>
                    </Popover>}>
                    <Row>
                      <Form.Label>Split Setting:</Form.Label>
                      <Form.Control as="select" onChange={(e) => setConfig({...config, splitSetting: e.target.value})}>
                        <option value="">Please Select</option>
                        {splitSettings.map((setting) => (
                          <option value={setting} selected={config.splitSetting === setting}>{setting.charAt(0).toUpperCase() + setting.slice(1)}</option>
                        ))}
                      </Form.Control>
                    </Row>
                  </OverlayTrigger>
                </Col>

                {config.frequency.map((freq, i) => (
                  <Col>
                    <Form.Group controlId="frequency">
                      <Form.Label>Frequency [{i+1}]:</Form.Label>
                      <Form.Control type="string" value={freq} 
                        onChange={(e) => {
                          config.frequency[i] = e.target.value;
                          setConfig({...config, frequency: config.frequency})

                          generateCrons();
                        }} />
                    </Form.Group>
                  </Col>
                ))}
                
                <Col>
                  <Button style={{ marginTop: "2rem", marginRight: "1rem" }} variant="primary" type="button" onClick={() => {
                      let freq = config.frequency;
                      freq.pop();
                      setConfig({ ...config, frequency: freq }) 
                    }}
                  >Del</Button>

                  <Button style={{marginTop: "2rem"}} variant="primary" type="button" onClick={() => {
                      let freq = config.frequency;
                      freq.push("0 9-22/1 * * *");
                      setConfig({ ...config, frequency: freq }) 
                    }}
                  >Add</Button>
                </Col>
              </Row>

              {crons && (
                <Row>
                  <p><strong>Frequency is:</strong> {crons.duration}</p>
                  <p><strong>Examples:</strong> {crons.dates.map((date) => date)}</p>
                </Row>
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Server Settings</Accordion.Header>
            <Accordion.Body>
              <ServerSettingsComponent settings={config.settings} onChanged={(settings) => setConfig({...config, settings: settings})} />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Assists</Accordion.Header>
            <Accordion.Body>
              <AssistsComponent assists={config.settings.assists} onChanged={(assists) => setConfig({...config, settings: { ...config.settings, assists: assists }})} />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>SR/DR Ratings Configuration </Accordion.Header>
            <Accordion.Body>
              <RatingsComponent ratings={config.ratings} onChanged={(ratings) => setConfig({...config, ratings: ratings })} />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>Content Configuration</Accordion.Header>
            <Accordion.Body>
              <Row>
                <ContentModal game={config.game} config={config.content} setConfig={(content) => setConfig({...config, content})} />
                
                <Col sm={4}> 
                  <Button variant="primary" style={{height: "calc(100% - 15px)", width: "100%", background: "transparent", borderStyle: "dashed", borderWidth: "4px", minHeight: "235px", borderColor: "#4b5660", color: "#4b5660", fontWeight: 600, fontSize: "2.3rem"}} 
                    onClick={() => setConfig({...config, content: [...config.content, new DailyRacesContent()]})}>Add Content</Button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>Session Configuration </Accordion.Header>
            <Accordion.Body>
              <Row>
                {config.sessions.map((session, index) => (
                  <SessionModal session={session} index={index} sessions={config.sessions} setSessions={(newSessions) => setConfig({...config, sessions: newSessions})} />
                ))}

                <div className="col-sm-4">
                  <Button variant="primary" 
                    style={{height: "calc(100% - 15px)", width: "100%", background: "transparent", borderStyle: "dashed", borderWidth: "4px", minHeight: "201px", borderColor: "#4b5660", color: "#4b5660", fontWeight: 600, fontSize: "2.3rem"}} 
                    onClick={() => setConfig({...config, sessions: [...config.sessions, new Session()]})}>Add Session</Button>
                </div>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="submit-buttons d-flex justify-content-end" style={{position: "fixed", bottom: 0, left: 0, width: "100%", padding: "1rem 2rem", background: "#222222"}}>
          <div className="container">
            <Row className="flex-row-reverse">
              <Col sm={12}><div className="d-flex flex-row-reverse">
                <div className="p-2"><Button variant="primary" type="button" onClick={save}>Save</Button></div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Container>
  )
}

export default withAuthentication(CreateDailyConfigurations);
