import moment from "moment";
import { Tier } from "./Tier";
import { Item } from "./Content";
import { Session } from "./Session";
import { Ratings } from "./Ratings";
import { ServerSettings } from "./ServerSettings";

export const serverSizes: string[] = [ "small", "medium", "large" ];

export class SpecialEvent {
  _id?: string = "";
  
  game: string = "RF2";
  title: string = "";
  smallImage: string = "";
  cardImage: string = "";
  heroImage: string = "";

  serverSize: string = "";

  tier: Tier = new Tier();
  splitSetting: string = ""; // legacy, even, filltop
  starts: string = moment().startOf('day').format();
  ends: string = moment().startOf('day').add(7, 'day').add(-1, 'second').format();
  frequency: string = "0 17 * * SAT,SUN";
  practiceFrequency: string = "0 */1 * * *";
  registrationOpens: string = "1h";
  
	cars: Item[] = [];
	track: Item = new Item();
  
  sessions: Session[] = [
    new Session("Practice", "5m", "0m"),
    new Session("Qualifying", "10m", "0m"),
    new Session("Race", "15m", "5m")
  ]
  
  settings: ServerSettings = new ServerSettings()
  ratings: Ratings = new Ratings()
}
