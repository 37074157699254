import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col } from 'react-bootstrap';
import SecurityServiceClient from '../../services/security-service.client';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { HttpProtectedRole, HttpProtectedUser, HttpProtectedUserViewModel } from '../../types/Security';
import PermissionsUserListView from './PermissionsUserListView';

const PermissionsView = () => {

    const securityService = new SecurityServiceClient();

    const [allRoles, setAllRoles] = useState<Array<HttpProtectedRole>>([]);
    const [protectedUsers, setProtectedUsers] = useState<Array<HttpProtectedUserViewModel>>([]);
    useEffect(() => {
        securityService.getAllProtectedUsers(0, 100).then((users) => {
            setProtectedUsers(users);
        });
        securityService.getAllRoles().then((roles) => {
            setAllRoles(roles);
        });
    }, []);

    const refreshUsers = () => {
        securityService.getAllProtectedUsers(0, 100).then((users) => {
            setProtectedUsers(users);
        });
    }

    return (
        <>
            <style>
            {`
            `}
            </style>
            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row>
                    <Col>
                        <h3>Permissions</h3>
                    </Col>
                </Row>
                <Row>
                {
                    protectedUsers != void(0) && protectedUsers?.length > 0 ?
                    <Col>
                        <PermissionsUserListView
                            users={protectedUsers}
                            allRoles={allRoles}
                            requestRefresh={refreshUsers}
                        />
                    </Col>
                    :
                    <Col className="d-flex flex-column justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
                        <Spinner animation="border" style={{ width: '7vh', height: '7vh' }} />
                        <div className="text-center">
                            {
                                protectedUsers != void(0) && typeof(protectedUsers) === 'object' && (protectedUsers as any)?.message?.length > 0 ?
                                <p>{(protectedUsers as any)?.message}</p>
                                :
                                <p>Loading...</p> 
                            }
                        </div>
                    </Col>
                }
                </Row>
            </Container>
        </>
    );
};

export default withAuthentication(PermissionsView);
