import React from 'react';

const Health = () => {
  return (
    <div>
       <h1>OK</h1>
       <h2>{process.env.REACT_APP_ENV}</h2>
     </div>
  );
};

export default Health;
