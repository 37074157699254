import { useEffect, useRef, useState } from "react";
import { RaceResult } from "../../types/event/EventResults";
import { Adjustments, RankHistory } from "../../types/event/RankChange";
import withAuthentication from "../../withAuthentication";
import NakamaClient from "../../services/nakama.client";
import { Driver } from "../../types/event/Driver";

interface ResultTableProps {
  results: RaceResult[] | undefined;
  rankChanges: RankHistory;
}

const ResultTable = ({ results, rankChanges } : ResultTableProps) => {
  const nk = useRef(new NakamaClient());

  const getDriver = async (game: string, steamId: string) : Promise<Driver> => {
    return nk.current.playerGetBySteamId(game, steamId);
  }

  const viewPlayerInfo = async (game: string, steamId: string) => {
    const driver = await getDriver(game, steamId);
    if (!driver) {
      return;
    }

    window.location.href = `/players/view/${driver.id}/${game}`;
  }

  const driverRankChange = (driverId: string) : Adjustments | null => {
    if (!rankChanges) {
      return null;
    }

    const change = rankChanges[driverId];
    if (!change) {
      return null;
    }
    return change;
  }

  return !results 
    ? <h1>Loading...</h1>
    : (
    <table className="table table-dark">
      <thead>
        <tr>
          <th>Finished</th>
          <th>Qualified</th>
          <th>Driver</th>
          <th>Car Class</th>
          <th>Laps</th>
          <th>Finish Time</th>
          <th>Finish Status</th>
          <th>Points</th>
          <th>DR +/-</th>
          <th>SR +/-</th>
        </tr>
      </thead>
      <tbody>
        {results.sort((a, b) => parseInt(a.position) - parseInt(b.position)).map((r, index) => {
          const rankChange = driverRankChange(r.steamId);
          return (
            <tr key={index} onClick={() => viewPlayerInfo("lmu", r.steamId)}>
              <td>{r.position}</td>
              <td>{r.classPosition}</td>
              <td>{r.name}</td>
              <td>{r.carClass}</td>
              <td>{r.noLaps}</td>
              <td>{index == 0 ? RaceResult.finishTimeStr(r) : RaceResult.finishTimeDiff(results[index-1], r)}</td>
              <td>{r.finishStatus}</td>
              <td>{r.points}</td>
              <td>{rankChange?.driver?.change}</td>
              <td>{rankChange?.safety?.change}</td>
            </tr>
          )}
        )}
      </tbody>
    </table>
  )
}

export default withAuthentication(ResultTable);