import React, { useState } from "react";
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Season } from "../models/Season";
import withAuthentication from "../../withAuthentication";
import ChampionshipServiceClient from "../../services/championship-service.client";
import { SeasonDatesValidResponse } from "../responses/SeasonDatesValidResponse";
import EditSeasonFormComponent from "./SeasonEditFormComponent";

const CreateSeasonView = () => {

    const championshipService = new ChampionshipServiceClient();

    const navigate = useNavigate();

    const [seasonSaveError, setSeasonSaveError] = useState<string>("");
    const [seasonDatesValid, setSeasonDatesValid] = useState<boolean>(true);
    const [newSeason, setNewSeason] = useState<Season>({
        id: "",
        game: "",
        name: "",
        dateFrom: "",
        dateTo: "",
        championships: []
    });

    React.useEffect(() => {
        if(!isValid(newSeason)) {
            return;
        }

        championshipService.seasonDatesValid(newSeason)
            .then((res: SeasonDatesValidResponse) => {
                setSeasonDatesValid(res?.valid);
            });
    
    }, [newSeason]);

    const save = () => {
        if(!isValid(newSeason)) {
            return;
        }

        setSeasonSaveError("");
        championshipService.createSeason(newSeason)
            .then((seasonId: string) => {
                if(seasonId == void(0) || seasonId.length == 0) {
                    setSeasonSaveError("Failed to save season");
                    return;
                }

                navigate(`/rm/configs/seasons/edit/${seasonId}`);
            }).catch((err) => {
                setSeasonSaveError("Failed to save season");
            });
    }

    const isValid = (season: Season) => {
        return season.game.length > 0 && season.name.length > 0 && season.dateFrom.length > 0 && season.dateTo.length > 0
    }

    return (
        <>
            <style>
            {`
                .seasons-header {
                    background-color: #f8f9fa;
                    color: black;
                    border-bottom: 1px solid #dee2e6;
                    font-weight: bold;
                    padding: 0.5rem 0;
                    margin-bottom: 1rem;
                }
                
                .seasons-header-item {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                .seasons-header-item-start {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start !important;
                    align-items: center;
                }

                .seasons-header-item-end {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end !important;
                    align-items: center;
                }
            `}
            </style>
            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row>
                    <Col className="seasons-header-item-start">
                        <h3>Create New Season</h3>
                    </Col>
                </Row>
                <Row>
                    <EditSeasonFormComponent
                        season={newSeason}
                        dateValidity={seasonDatesValid}
                        onUpdate={setNewSeason}
                        saveButton={
                            <Row>
                                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button variant="success" onClick={save}>Save</Button>
                                </Col>
                            </Row>
                        }
                    />
                </Row>
                <Row>
                    <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                        {
                            seasonSaveError != void(0) && seasonSaveError?.length > 0 ? (
                                <p style={{ color: "red" }}>{seasonSaveError}</p>
                            ) : <></>    
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default withAuthentication(CreateSeasonView);
