import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { UsernameReservation } from '../types/User';
import UsernameReservationServiceClient from '../services/username-reservation-service.client';
import NakamaClient from '../services/nakama.client';
import { Driver } from '../types/event/Driver';
import DriverAccordionViewComponent from '../components/driver/DriverAccordionViewComponent';

export interface ViewDriversModalProps {
    reservation: UsernameReservation;
    show: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const ViewDriversModal = (props: ViewDriversModalProps) => {


    const [lmuDriver, setLmuDriver] = React.useState<Driver | null | undefined>(null);
    const [rf2Driver, setRf2Driver] = React.useState<Driver | null | undefined>(null);

    React.useEffect(() => {
        fetchDrivers();
    }, [props?.reservation]);

    const fetchDrivers = async () => {
        const lmuResult = await getDriverFromNakama('lmu');
        const rf2Result = await getDriverFromNakama('rf2');

        setLmuDriver(lmuResult);
        setRf2Driver(rf2Result);
    };

    const getDriverFromNakama = async (game: string): Promise<Driver | null> => {
        if (props?.reservation?.steamId === null || props?.reservation?.steamId === "") {
            return null;
        }
    
        try {
            const response = await new NakamaClient().playerGetBySteamId(game, props?.reservation?.steamId || "");
            console.log(response);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return (
        <>
            <style>
                {`
                    .link-steamid-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .link-steamid-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-steamid-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-steamid-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-steamid-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .link-steamid-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .link-form-text-grey {
                        color: grey !important;
                    }

                    .border-red {
                        border: 1px solid red !important;
                    }
                    
                    .border-gray {
                        border: 1px solid gray;
                    }
                `}
            </style>
            <Modal className="link-steamid-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="link-steamid-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Driver profiles linked with username: {props?.reservation?.username ?? "Unknown"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="link-steamid-modal-body">
                    <Container className="link-steamid-modal-body-container">
                        <Row>
                            <Col>
                                {
                                    lmuDriver != void(0) &&
                                    <DriverAccordionViewComponent
                                        drivers={[lmuDriver]}
                                        game={"lmu"}
                                        requestRefresh={(indices: Array<number>) => {
                                            fetchDrivers();
                                        }}
                                    />
                                }
                            </Col>
                            <Col>
                                {
                                    rf2Driver != void(0) &&
                                    <DriverAccordionViewComponent
                                        drivers={[rf2Driver]}
                                        game={"rf2"}
                                        requestRefresh={(indices: Array<number>) => {
                                            fetchDrivers();
                                        }}
                                    />
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="link-steamid-modal-footer">
                    <Row style={{width: "100%"}} xs="auto" className="justify-content-end">
                        <Col xs="auto" className="justify-content-end">
                            <Button variant="primary" onClick={props?.onCancel}>Close</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ViewDriversModal;