import React, { useEffect, useState, } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';

import { Driver, Suspension, UsernameViolation } from '../../../types/event/Driver'
import { SuspendUserRequest } from '../../../types/Protest';

import UserServiceClient from '../../../services/user-service.client';
import moment from 'moment';

export interface DriverViewBanModalProps {
    driver: Driver;
    game: string;
    ban: Suspension | null;
    relatedUsernameViolation: UsernameViolation | null | undefined;
    show: boolean;
    onCancel: () => void;
    onRedacted: () => void;
}

const DriverViewBanModal = (props: DriverViewBanModalProps) => {

    const userService = new UserServiceClient();

    const banLengths: Map<string, string> = new Map<string, string>([
        ["24h", "1 Day"],
        ["48h", "2 Days"],
        ["168h", "7 Days"],
        ["720h", "30 Days"],
        ["4320h", "180 Days"],
        ["867240h", "Permanently"]
    ]);

    const [duration, setDuration] = useState<string>("24h");
    const onBanLengthChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDuration(event.target.value);
    }

    const [reason, setReason] = useState<string>("");
    const onReasonChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReason(event.target.value);
    }

    const formatDateString = (timestamp: number | undefined) => {
        let result = "";
        if (timestamp != void (0)) {
            const date = new Date(timestamp * 1000);
            const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            result = date.toLocaleDateString("en-GB", dateOptions);
        }

        return result;
    }

    const timeBetweenTwoUnixTimestamps = (timestamp1: number | undefined, timestamp2: number | undefined) => {
        let result = "Unknown";
        if (timestamp1 != void (0) && timestamp2 != void (0)) {
            const diff = moment(timestamp1 / 1000).diff(moment(timestamp2 / 1000));
            var mind = diff % (60 * 60);
            const hours = Math.floor(diff / (60 * 60));
            result = `${hours}h`;
        }

        return result;
    }

    const formatHourString = (hours: string) => {
        let result = "";
        switch (hours) {
            case "24h":
                result = "1 Day";
                break;
            case "48h":
                result = "2 Days";
                break;
            case "168h":
                result = "7 Days";
                break;
            case "720h":
                result = "30 Days";
                break;
            case "4320h":
                result = "180 Days";
                break;
            case "867240h":
                result = "Permanently";
                break;
            default:
                result = "Unknown";
                break;
        }

        return result;
    }

    const getBanLength = () => {
        return formatHourString(timeBetweenTwoUnixTimestamps(props?.ban?.to, props?.ban?.from))
    }

    const openProtestInNewTab = (id: String) => {
        if (id != void (0) && id) {
            window.open(`${window.location.origin}/protest/${id}`, "_blank");
        }
    }

    const undoBan = (banReference: string) => {
        Driver.prototype.undoBan
            .call(props?.driver, banReference, props?.game)
            .then(Driver.prototype.refresh.bind(props?.driver, props?.game))
            .catch(console.error)
            .finally(props?.onRedacted);
    }

    return (
        <>
            <style>
                {`
                    .ban-action-modal-container {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    .ban-action-modal-header {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }

                    .ban-action-modal-body-container > * > * > .form-select {
                        background-color: rgba(20, 20, 20, 1);
                        color: snow;
                    }

                    .ban-action-modal-footer {
                        background-color: rgba(15, 15, 15, 1);
                        color: snow;
                    }
                `}
            </style>
            <Modal className="ban-action-modal-container" centered {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="ban-action-modal-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                    {
                        props?.relatedUsernameViolation ?
                            `Ban Details (Automatic: Username Violation)`
                        :
                            `Ban Details`
                    }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ban-action-modal-body">
                    <Container className="ban-action-modal-body-container">
                        <Row>
                            {
                                props?.relatedUsernameViolation ?
                                    <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                                        <Form.Label>Ban Length: Until username changed</Form.Label>
                                        <Form.Label>Date: {moment(props?.ban?.from).format("DD MMMM YYYY HH:mm")}</Form.Label>
                                        <Form.Label>Expiration: Until username changed</Form.Label>
                                    </Col>
                                    :
                                    <Col sm="4" md="4" lg="4" xl="4" xxl="4">
                                        <Form.Label>Ban Length: {getBanLength()}</Form.Label>
                                        <Form.Label>Date: {moment(props?.ban?.from).format("DD MMMM YYYY HH:mm")}</Form.Label>
                                        <Form.Label>Expiration: {moment(props?.ban?.to).format("DD MMMM YYYY HH:mm")}</Form.Label>
                                    </Col>
                            }
                        </Row>
                        {
                            props?.ban?.protestId?.startsWith("Manual:") ?
                                <Row>
                                    <Row>
                                        <Col>
                                            <Form.Label>Manually banned by: {props?.ban?.protestId?.split(":")[1]}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label>Reason</Form.Label>
                                            <Form.Control as="textarea" rows={3} readOnly defaultValue={props?.ban?.notes} />
                                        </Col>
                                    </Row>
                                </Row>
                                :
                                <Row>
                                    <Col>
                                        <Button variant="primary" onClick={openProtestInNewTab.bind(this, props?.ban?.protestId || "invalid")}>View Protest</Button>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer className="ban-action-modal-footer">
                    <Row style={{ width: "100%" }}>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6">
                            <Button variant="primary" onClick={props?.onCancel}>Cancel</Button>
                        </Col>
                        <Col sm="6" md="6" lg="6" xl="6" xxl="6" style={{ display: "flex", justifyContent: "flex-end" }} >
                            <Button variant="secondary" onClick={undoBan.bind(this, props?.ban?.protestId || "")}>Undo Ban</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DriverViewBanModal;