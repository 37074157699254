import { DailyRaceConfiguration } from "../types/event/DailyRaceConfiguration";
import { Event } from "../types/event/Event";
import { S3Object } from "../types/S3Object";
import { SpecialEvent } from "../types/event/SpecialEvent";
import { KeyValue } from "../types/keyValue";
import OAuthClient from "./oauth.client";
import {saveAs} from 'file-saver';
import { CronExamplesResponse } from "../types/responses/CronExamplesResponse";
import { RankHistory } from "../types/event/RankChange";
import { FastestLapStat, Stat, Stats } from "../types/Stats";
import { Driver } from "../types/event/Driver";
import { EventResult } from "../types/event/EventResults";
import { RankChange } from "../types/RankChange";

class RaceManagementClient {
  url = process.env.REACT_APP_RM_URL;
  oauth: OAuthClient;

  constructor() {
    this.oauth = new OAuthClient();
  }

  async listDailyConfigurations(): Promise<DailyRaceConfiguration[]> {
    const response = await this.oauth.fetch(`${this.url}/dailies/list`);
    return await response?.json() as DailyRaceConfiguration[];
  }
  
  async getDailyConfiguration(seriesId : string): Promise<DailyRaceConfiguration> {
    const response = await this.oauth.fetch(`${this.url}/dailies/${seriesId}`);
    return await response?.json() as DailyRaceConfiguration;
  }

  async createDailyConfiguration(config : DailyRaceConfiguration): Promise<Response> {
    return await this.oauth.fetch(`${this.url}/dailies`, "POST", JSON.stringify(config)) as Response;
  }

  async editDailyConfiguration(config : DailyRaceConfiguration): Promise<Response> {
    return await this.oauth.fetch(`${this.url}/dailies`, "PUT", JSON.stringify(config)) as Response;
  }

  async deleteDailyConfiguration(seriesId : string): Promise<Response> {
    return await this.oauth.fetch(`${this.url}/dailies/${seriesId}`, "DELETE") as Response;
  }


  async listSpecialEventConfigurations(): Promise<SpecialEvent[]> {
    const response = await this.oauth.fetch(`${this.url}/specialevents/all`);
    return await response?.json() as SpecialEvent[];
  }

  async getSpecialEventConfiguration(seriesId : string): Promise<SpecialEvent> {
    const response = await this.oauth.fetch(`${this.url}/specialevents/${seriesId}`);
    return await response?.json() as SpecialEvent;
  }

  async createSpecialEventConfiguration(config : SpecialEvent): Promise<Response> {
    // remove the _id field from the object
    delete config._id;
    return await this.oauth.fetch(`${this.url}/specialevents`, "PUT", JSON.stringify(config)) as Response;
  }

  async editSpecialEventConfiguration(config : SpecialEvent): Promise<Response> {
    return await this.oauth.fetch(`${this.url}/specialevents/${config._id}`, "POST", JSON.stringify(config)) as Response;
  }

  async deleteSpecialEventConfiguration(seriesId : string): Promise<Response> {
    return await this.oauth.fetch(`${this.url}/specialevents/${seriesId}`, "DELETE") as Response;
  }

  async getEvents(game: string, page: number, take: number, name: string, eventType: string, tier: string, signal?: AbortSignal) : Promise<Event[]> {
    return await this.oauth.fetch(`${this.url}/events/list?page=${page}&take=${take}&game=${game}&name=${name}&eventType=${eventType}&tier=${tier}`, "GET", "", signal)
      .then(async res => {
        const data = await res.json();
        return data as Event[];
      });
  }


  async listImagesInS3Bucket(bucket: string, prefix: string) : Promise<KeyValue[]> {
    return await this.oauth.fetch(`${this.url}/s3/${bucket}?prefix=${prefix}`)
      .then(async res => {
        const data = await res.json();
        if (data) {
          return data.map((item: any) => { 
            return { 
              key: process.env.REACT_APP_ENV === "development" 
                ? `https://${bucket}.s3.eu-west-2.amazonaws.com/${item.Key}`
                : `https://${bucket}.s3.eu-west-1.amazonaws.com/${item.Key}`,
              value: item.Key,
            }
          });
        }
      });
  }

  async listS3Bucket(bucket: string, prefix: string) : Promise<KeyValue[]> {
    return await this.oauth.fetch(`${this.url}/s3/${bucket}?prefix=${prefix}`)
      .then(async res => {
        const data = await res.json();
        if (data) {
          return data.map((item: any) => { 
            return { 
              key: `${item.bucket}/${item.Key}`,
              value: item.Key,
            }
          });
        }
      });
  }

  async getS3BucketContent(bucket: string, prefix: string) : Promise<S3Object[]> {
    return await this.oauth.fetch(`${this.url}/s3/${bucket}?prefix=${encodeURIComponent(prefix)}`)
      .then(async res => {
        const data = await res.json();
        return data as S3Object[];
      });
  }

  async downloadFromS3(bucket: string, item: string): Promise<any> {
    return this.oauth.fetch(`${this.url}/s3/download/${bucket}?prefix=${encodeURIComponent(item)}`)
      .then(res => res.blob())
      .then(blob => {
        const filename = item.split("/").pop() || item;
        const blobObjectURL = window.URL.createObjectURL(blob);
        saveAs(blobObjectURL, filename);
      });
  }

  async reprocessResults(eventType: string, seriesId: string, eventId: string, splitNo: number): Promise<Response> {
    return await this.oauth.fetch(`${this.url}/results/reprocess/${eventType}/${seriesId}/${eventId}/${splitNo}`, "POST") as Response;
  }

  async crons(from: string, crons: string[]): Promise<CronExamplesResponse | null> {
    return await this.oauth.fetch(`${this.url}/ui/cron/examples`, "POST", JSON.stringify({ from, crons }))
      .then(async res => {
        const data = await res.json();
        return data as CronExamplesResponse;
      })
      .catch(err => {
        console.log(err);
        return null;
      });
  }

  async rankChangesForEvent(eventId: string): Promise<RankHistory> {
    return this.oauth.fetch(`${this.url}/rank/history/event/${eventId}`)
      .then(async res => {
        const data = await res.json();
        return data as RankHistory;
      })
      .catch(err => {
        console.log(err);
        return {} as RankHistory;
      });
  }

  async resultsBySteamId(steamId: string, page: number, take: number, eventType: string, game: string) : Promise<EventResult[]> {
    return await this.oauth.fetch(`${this.url}/dailies/results/${steamId}?page=${page}&take=${take}&eventType=${eventType}&game=${game}`)
      .then(async res => {
        const data = await res.json();
        return data as EventResult[];
      });
  }

  async getSRHistory(steamId: string) : Promise<RankChange[]> {
    return await this.oauth.fetch(`${this.url}/rank/history/sr/${steamId}?page=0&take=100`)
      .then(async res => {
        const data = await res.json();
        return data as RankChange[];
      });
  }

  async getDRHistory(steamId: string) : Promise<RankChange[]> {
    return await this.oauth.fetch(`${this.url}/rank/history/dr/${steamId}?page=0&take=100`)
      .then(async res => {
        const data = await res.json();
        return data as RankChange[];
      });
  }

  async driverStats(nakamaId: string) : Promise<Driver> {
    return await this.oauth.fetch(`${this.url}/ui/player/stats/${nakamaId}`)
      .then(async res => {
        const data = await res.json();
        return data as Driver;
      });
  }

  async fatestLaps(page: number, take: number, track: string, car: string, _class: string, rank: string, bop: string) : Promise<FastestLapStat[]> {
    return await this.oauth.fetch(`${this.url}/ui/stats/fastest-laps?page=${page}&take=${take}&track=${track}&car=${car}&class=${_class}&rank=${rank}&bop=${bop}`)
      .then(async res => {
        const data = await res.json();
        return data as FastestLapStat[];
      });
  }

  async getStats(name: string) : Promise<Stat> {
    return await this.oauth.fetch(`${this.url}/ui/stats/db/${name}`)
      .then(async res => {
        const data = await res.json();
        return data as Stat;
      });
  }
}

export default RaceManagementClient;
