import { DriverRank } from '../types/event/Ranks';

export interface DriverRankProps {
  dr: DriverRank | null | undefined;
}

const DriverRankBadge = (props: DriverRankProps) => {
  return !props.dr 
    ? <></> 
    : (<>
      <img alt="DR Badge" 
          style={{margin: "-10px 0.2rem", height: "50px", width: "50px"}} 
          src={`https://rf2-ui-images-prod.s3.eu-west-1.amazonaws.com/ranks/dr-lmu/${props.dr.rank}${props.dr.tier}-small.svg`} />

      <span style={{marginTop: "5px"}}>{props.dr.elo.toFixed(1)}</span>
      </>)
}

export default DriverRankBadge;
