import { useEffect, useState, useRef } from "react";
import { SpecialEvent, serverSizes } from "../../../types/event/SpecialEvent";
import withAuthentication from "../../../withAuthentication";
import RaceManagementClient from "../../../services/race-manaement.client";
import { Button, Form, Container, Row, Col, Modal, OverlayTrigger, Popover, Accordion } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { games, multiFormationLap, ranks, trackLimitsRule, specialEventTiers } from "../../../types/event/DailyRaceConfiguration";
import TrackSelection from "../TrackSelection";
import CarSelection from "../CarSelection";
import SessionModal from "../SessionModal";
import ImageSelection from "../ImageSelection";
import S3ObjectSelection from "../S3ObjectSelection";
import { ErrorResponse } from "../../../types/ErrorResponse";
import AssistsComponent from "../Assists";
import { Session } from "../../../types/event/Session";
import { ServerSettings } from "../../../types/event/ServerSettings";
import ServerSettingsComponent from "../ServerSettingsComponent";
import RatingsComponent from "../Ratings";

const SpecialEventConfiguration = () => {
  const client = useRef<RaceManagementClient>(new RaceManagementClient());

  const [config, setConfig] = useState<SpecialEvent>(new SpecialEvent());
  const [mode, setMode] = useState('Create'); // ['create', 'edit']
  const [errors, setErrors] = useState<ErrorResponse[]>([]); // ['create', 'edit'
  const navigate = useNavigate();

  const [splitSettings, setSplitSettings] = useState(["even", "filltop"]);
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    const copy = params.get('copy');
    if (id) {
      copy ? setMode('Copy') : setMode('Edit');
      client.current.getSpecialEventConfiguration(id)
        .then((config) => {
          setConfig(config);
        })
        .catch((err) => {
          console.error(err);
        });
    }

  }, []);

  useEffect(() => {}, [config]);


  const save = async () => {
    console.log(`Saving config ${mode}`);

    if (mode === 'Edit') {
      await client.current.editSpecialEventConfiguration(config ?? new SpecialEvent())
        .then((response) => {
          console.log("response: "+response.statusText);
          if (response.status === 200) {
            navigate('/rm/configs/specialEvents/list');
          } else if (response.status === 400) {
            response.json().then((errors) => {
              setErrors(errors);
            });
          } else {
            setErrors([{field: "", error: "An unknown error occurred"}]);
          }
        })
        .catch((err) => {
          console.error(err);
        });

    } else {
      await client.current.createSpecialEventConfiguration(config ?? new SpecialEvent())
        .then((response) => {
          console.log("response: "+response.statusText);
          if (response.status === 200) {
            navigate('/rm/configs/specialEvents/list');
          } else if (response.status === 400) {
            response.json().then((errors) => {
              setErrors(errors);
            });
          } else {
            setErrors([{field: "", error: "An unknown error occurred"}]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  return (
    <Container>
      <h1>{mode} Configuration</h1>

      <Modal show={errors && errors.length > 0} onHide={() => { setErrors([]) }}>
        <Modal.Header closeButton>
          <Modal.Title>You've got issues, Get Good Scrub</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {errors.map((error) => (
              <li>{error.field}: {error.error}</li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>

      <Form>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Event Config</Accordion.Header>
            <Accordion.Body>
            <Row>
              <Col>
                <Form.Group controlId="game">
                  <Form.Label>Game:</Form.Label>
                  <Form.Control as="select" value={config.game} onChange={(e) => setConfig({...config, game: e.target.value})}>
                    <option value="">Please Select</option> 
                    {games.map((game) => (
                      <option value={game} selected={config.game === game}>{game}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="tierName">
                  <Form.Label>Tier:</Form.Label>
                  <Form.Control as="select" value={config.tier.name} onChange={(e) => setConfig({...config, tier: {...config.tier, name: e.target.value}})}>
                    <option value="">Please Select</option> 
                    {specialEventTiers.map((tier) => (
                      <option value={tier} selected={config.tier.name === tier}>{tier}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="entryRank"> 
                  <Form.Label>Tier:</Form.Label>
                  <Form.Control as="select" value={config.tier.entryRequirement.rank} onChange={(e) => setConfig({...config, tier: {...config.tier, entryRequirement: {...config.tier.entryRequirement, rank: e.target.value}}})}>
                    <option value="">Please Select</option>
                    {ranks.map((rank) => (
                      <option value={rank.key} selected={config.tier.entryRequirement.rank === rank.key}>{rank.value}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="entryTier">              
                  <Form.Label>Entry Tier:</Form.Label>
                  <Form.Control as="select" value={config.tier.entryRequirement.tier} onChange={(e) => setConfig({...config, tier: {...config.tier, entryRequirement: {...config.tier.entryRequirement, tier: parseInt(e.target.value)}}})}>
                    <option value="">Please Select</option>
                    {Array.from(Array(4).keys()).map((tier) => (
                      <option value={tier} selected={config.tier.entryRequirement.tier === tier}>{tier}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            
            <Row>
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>Instance Type:</Form.Label>
                  <Form.Control as="select" value={config.serverSize} onChange={(e) => setConfig({...config, serverSize: e.target.value})}>
                    <option value="">Please Select</option>
                    {serverSizes.map((instance) => (
                      <option value={instance} selected={instance === config.serverSize}>{instance}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>Title:</Form.Label>
                  <Form.Control type="string" value={config.title} onChange={(e) => setConfig({...config, title: e.target.value})} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="starts">
                  <Form.Label>Starts:</Form.Label>
                  <Form.Control type="string" value={config.starts} onChange={(e) => setConfig({...config, starts: e.target.value})} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="ends">
                  <Form.Label>Ends:</Form.Label>
                  <Form.Control type="string" value={config.ends} onChange={(e) => setConfig({...config, ends: e.target.value})} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="frequency">
                  <Form.Label>Frequency: <span className="text-muted">(eg */30 09-18 * * *)</span></Form.Label>
                  <Form.Control type="string" value={config.frequency} onChange={(e) => setConfig({...config, frequency: e.target.value})} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="registrationOpens">
                  <Form.Label>Registration Opens: <span className="text-muted">(eg 20m)</span></Form.Label>
                  <Form.Control type="string" value={config.registrationOpens} onChange={(e) => setConfig({...config, registrationOpens: e.target.value})} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="multiFormationLap">
                  <Form.Label>Formation Lap:</Form.Label>
                  <Form.Control as="select" onChange={(e) => setConfig({...config, settings: {...config.settings, multiFormationLap: Number(e.target.value)}})}>
                    <option value="">Please Select</option>
                    {multiFormationLap.map((option) => (
                      <option value={option.key} selected={config.settings.multiFormationLap.toString() === option.key}>{option.value}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <OverlayTrigger overlay=
                  {<Popover id="split-setting-popover">
                    <Popover.Header as="h3">Defines how to distribute players in splits</Popover.Header>
                    <Popover.Body>
                      <strong>Legacy:</strong> full splits but bottom can be nearly empty<br></br>
                      <strong>Even:</strong> full or nearly full even splits + no splits are low<br></br>
                      <strong>FillTop:</strong> fill top split and distribute others evenly (min 15 registrants)<br></br>
                    </Popover.Body>
                  </Popover>}>
                  <Row>
                    <Form.Label>Split Setting:</Form.Label>
                    <Form.Control as="select" onChange={(e) => setConfig({...config, splitSetting: e.target.value})}>
                      <option value="">Please Select</option>
                      {splitSettings.map((setting) => (
                        <option value={setting} selected={config.splitSetting === setting}>{setting.charAt(0).toUpperCase() + setting.slice(1)}</option>
                      ))}
                    </Form.Control>
                  </Row>
                </OverlayTrigger>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="vehicleFilter">
                  <Form.Label>Vehicle Filter:</Form.Label>
                  <Form.Control value={config.settings.vehicleFilter} onChange={(e) => setConfig({...config, settings: {...config.settings, vehicleFilter: e.target.value}})} />
                </Form.Group>
              </Col>

              <Col>
                <ImageSelection image={config.smallImage} filter="small/" title="Small Image" onImageSelected={(image) => setConfig({ ...config, smallImage: image }) } />
              </Col>
              <Col>
                <ImageSelection image={config.cardImage} filter="card/" title="Card Image" onImageSelected={(image) => setConfig({ ...config, cardImage: image }) } />
              </Col>
              <Col>
                <ImageSelection image={config.heroImage} filter="hero/" title="Hero Image" onImageSelected={(image) => setConfig({ ...config, heroImage: image }) } />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Server Settings</Accordion.Header>
          <Accordion.Body>
            <ServerSettingsComponent settings={config.settings} onChanged={(settings) => setConfig({...config, settings: settings})} />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Event Config</Accordion.Header>
          <Accordion.Body>
            <AssistsComponent assists={config.settings.assists} 
              onChanged={(assists) => {
                setConfig(prevConfig => ({
                  ...prevConfig,
                  settings: {
                    ...prevConfig.settings,
                    assists: assists
                  }
                }));
              }}
              //onChanged={(assists) => setConfig({...config, settings: { ...config.settings, assists: assists }})} 
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Ratings Config</Accordion.Header>
          <Accordion.Body>
            <RatingsComponent ratings={config.ratings} onChanged={(ratings) => setConfig({...config, ratings: ratings})} />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Track & Cars</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                <TrackSelection game={config.game} track={config.track} layout={config.track.subItems[0]} onSelected={(track) => setConfig({...config, track: track})} />
              </Col>
              <Col>
                <CarSelection game={config.game} cars={config.cars} onSelected={(cars) => setConfig({...config, cars: cars})} />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Sessions</Accordion.Header>
            <Accordion.Body>
              <Row>
                {config.sessions.map((session, index) => (
                  <SessionModal session={session} index={index} sessions={config.sessions} setSessions={(newSessions) => setConfig({...config, sessions: newSessions})} />
                ))}
              </Row>
              <Button variant="primary" onClick={() => setConfig({...config, sessions: [...config.sessions, new Session()]})}>Add Session</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        

        <div className="submit-buttons d-flex justify-content-end" style={{position: "fixed", bottom: 0, left: 0, width: "100%", padding: "1rem 2rem", background: "#222222"}}>
          <div className="container">
            <Row className="flex-row-reverse">
              <Col sm={12}>
                <div className="d-flex flex-row-reverse">
                  <Button variant="primary" type="button" onClick={save}>Save</Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Container>
  )
}

export default withAuthentication(SpecialEventConfiguration);
