import React, { ChangeEvent, useEffect, useState } from 'react';
import withAuthentication from '../../withAuthentication';
import { Badge, Button, Container, Form, ListGroup, Modal, Nav, NavDropdown, Navbar, Spinner, Row, Col, Tab } from 'react-bootstrap';
import SecurityServiceClient from '../../services/security-service.client';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import RoutesListView from './RoutesListView';
import { HttpProtectedRole, HttpProtectedRoute, HttpProtectedRouteViewModel } from '../../types/Security';
import RoutesTabbedView from './RoutesTabbedView';

const RoutesView = () => {

    const securityService = new SecurityServiceClient();

    const [routes, setRoutes] = useState<Array<HttpProtectedRouteViewModel>>([]);
    const [routesByService, setRoutesByService] = useState<Map<string, Array<HttpProtectedRouteViewModel>>>(
        new Map<string, Array<HttpProtectedRouteViewModel>>()
    );

    const [allRoles, setAllRoles] = useState<Array<HttpProtectedRole>>([]);

    useEffect(() => {
        refreshRoutes();

        securityService.getAllRoles().then((roles) => {
            setAllRoles(roles);
        });
    }, []);

    const refreshRoutes = () => {
        securityService.getAllRoutes(0, 10000).then((routes) => {
            setRoutes(routes);
            sortRoutesByService(routes);
        });
    }

    const sortRoutesByService = (routes: Array<HttpProtectedRouteViewModel>) => {
        const routesByService = new Map<string, Array<HttpProtectedRouteViewModel>>();
        routes.forEach((route) => {
            if (!routesByService.has(route.service)) {
                routesByService.set(route.service, []);
            }
            routesByService.get(route.service)?.push(route);
        });
        setRoutesByService(routesByService);
    }

    return (
        <>
            <style>
                {`
            `}
            </style>
            <Container style={{ margin: 0, width: "100%", minWidth: "100%" }}>
                <Row>
                    <Col>
                        <h3>Routes</h3>
                    </Col>
                </Row>
                <Row>
                {
                    routesByService?.size === 0 ?
                        <Col className="d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
                            <Spinner animation="border" style={{ width: '7vh', height: '7vh' }} />
                        </Col>
                   :
                        <RoutesTabbedView
                            routes={routes}
                            routesByService={routesByService}
                            allRoles={allRoles}
                            requestRefresh={refreshRoutes}
                        />
                }
                </Row>
            </Container>
        </>
    );
};

export default withAuthentication(RoutesView);
